// TODO: fix types
export enum RollerDataPointsMountLogActionsEnum {
  ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG = 'RollerDataPointsMountLog/ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG',
  DELETE = 'RollerDataPointsMountLog/DELETE',
  DELETE_MULTIPLE = 'RollerDataPointsMountLog/DELETE_MULTIPLE',
  UPDATE = 'RollerDataPointsMountLog/UPDATE',
}

export function addRollerDataPointsMountLog (dataLineHash: Record<number, RollerDataPointsMountLog>) {
  return {
    type: RollerDataPointsMountLogActionsEnum.ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerDataPointsMountLogReducers: Record<string, Function | undefined> = {
  [RollerDataPointsMountLogActionsEnum.ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [RollerDataPointsMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerDataPointsMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerDataPointsMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = RollerDataPointsMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
