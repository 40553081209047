export enum SegmentGroupMountLogActionsEnum {
  ACTION_ADD_SEGMENT_GROUP_MOUNT_LOG = 'SegmentGroupMountLog/ACTION_ADD_SEGMENT_GROUP_MOUNT_LOG',
  DELETE = 'SegmentGroupMountLog/DELETE',
  DELETE_MULTIPLE = 'SegmentGroupMountLog/DELETE_MULTIPLE',
  UPDATE = 'SegmentGroupMountLog/UPDATE',
  RENAME = 'SegmentGroupMountLog/RENAME',
  RESET = 'SegmentGroupMountLog/RESET',
}

export function addSegmentGroupMountLog (segmentGroupMountLogHash: SegmentGroupMountLogHash) {
  return {
    type: SegmentGroupMountLogActionsEnum.ACTION_ADD_SEGMENT_GROUP_MOUNT_LOG,
    segmentGroupMountLogHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentGroupMountLogReducers: Record<string, Function | undefined> = {
  [SegmentGroupMountLogActionsEnum.ACTION_ADD_SEGMENT_GROUP_MOUNT_LOG]: (
    _state: SegmentGroupMountLogHash,
    action: { segmentGroupMountLogHash: SegmentGroupMountLogHash },
  ) => (
    action.segmentGroupMountLogHash
  ),

  [SegmentGroupMountLogActionsEnum.DELETE]: (state: SegmentGroupMountLogHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentGroupMountLogActionsEnum.DELETE_MULTIPLE]: (state: SegmentGroupMountLogHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentGroupMountLogActionsEnum.UPDATE]: (
    state: SegmentGroupMountLogHash,
    action: { elements: SegmentGroupMountLogHash },
  ) => ({
    ...state,
    ...action.elements,
  }),
  [SegmentGroupMountLogActionsEnum.RENAME]: (
    state: SegmentGroupMountLogHash,
    action: { name: string, id: string },
  ) => ({
    ...state,
    [action.id]: {
      ...state[action.id],
      name: action.name,
    },
  }),

  [SegmentGroupMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: SegmentGroupMountLogHash = {},
  action: any,
) {
  const handler = SegmentGroupMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
