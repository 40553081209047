export enum passlineMountLogActionsEnum {
  ACTION_ADD_PASSLINE_MOUNT_LOG = 'PasslineMountLog/ACTION_ADD_PASSLINE_MOUNT_LOG ',
  DELETE = 'PasslineMountLog/DELETE',
  RESET = 'PasslineMountLog/RESET',
}

export function addPasslineMountLog (passlineMountLog: PasslineMountLog) {
  return {
    type: passlineMountLogActionsEnum.ACTION_ADD_PASSLINE_MOUNT_LOG,
    passlineMountLog,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const passlineMountLogReducers: Record<string, Function | undefined> = {
  [passlineMountLogActionsEnum.ACTION_ADD_PASSLINE_MOUNT_LOG]: (
    _state: Passline,
    action: { passlineMountLog: Passline },
  ) => action.passlineMountLog,

  [passlineMountLogActionsEnum.DELETE]: (_state: Passline) => null,

  [passlineMountLogActionsEnum.RESET]: () => null,
}

export default function (state: Passline | null = null, action: any) {
  const handler = passlineMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
