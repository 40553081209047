// TODO: fix types
export enum RollerSensorPointMountLogActionsEnum {
  ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG = 'RollerSensorPointMountLog/ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG',
  DELETE = 'RollerSensorPointMountLog/DELETE',
  DELETE_MULTIPLE = 'RollerSensorPointMountLog/DELETE_MULTIPLE',
  UPDATE = 'RollerSensorPointMountLog/UPDATE',
}

export function addRollerSensorPointMountLog (dataLineHash: Record<number, RollerSensorPointsMountLog>) {
  return {
    type: RollerSensorPointMountLogActionsEnum.ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerSensorPointMountLogReducers: Record<string, Function | undefined> = {
  [RollerSensorPointMountLogActionsEnum.ACTION_ADD_ROLLER_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [RollerSensorPointMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerSensorPointMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerSensorPointMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = RollerSensorPointMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
