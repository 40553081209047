import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css, ThemeProvider } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import StyleConfig from '@/react/visualization/dashboard/config/StyleConfig'
import { DefaultState } from '@/types/state'

import ApplicationActions from './ApplicationActions'
import ApplicationTitle from './ApplicationTitle'
import ApplicationUsageTime from './ApplicationUsageTime'
import MenuBuilder from './MenuBuilder'
import ProjectActions from './ProjectActions'
import TitleBarLogo from './TitleBarLogo'

const Wrapper = styled.div<{ $isElectron?: boolean }>`${({ $isElectron }) =>
  css`
  display: flex;
  width: ${$isElectron ? 'calc(100% - 138px)' : '100%'};
`}`

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
}))

type PropsFromRedux = ConnectedProps<typeof connector>

class TitleBarWrapper extends Component<PropsFromRedux> {
  public override componentDidMount () {
    if (window.isElectron) {
      const controls = document.getElementById('window-controls')

      if (controls) {
        controls.style.display = 'grid'
      }
    }
  }
  
  public override render () {
    const { isLoggedIn, featureFlags, darkTheme } = this.props
    const auth = (isLoggedIn || window.isElectron) && FeatureFlags.canViewCaster(featureFlags)

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <Wrapper $isElectron={window.isElectron}>
          <TitleBarLogo />
          {auth && <MenuBuilder />}
          {auth && <ProjectActions />}
          <ApplicationTitle />
          <ApplicationUsageTime />
          {((auth && !window.isElectron) || window.isElectron) && <ApplicationActions />}
        </Wrapper>
      </ThemeProvider>
    )
  }
}

export default connector(TitleBarWrapper as any) as any
