import React from 'react'
import CirclePicker from 'react-color/lib/components/circle/Circle'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`${() =>
  css`
  display: inline-block;
  position: relative;
  margin: 17px 0 0;
`}`

const Button = styled.button<{ $color: string }>`${({ $color }) =>
  css`
  background-color: ${$color};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 5px;
  outline: none;
  border: none;
  cursor: pointer;
`}`

const Box = styled.div<{ $active?: boolean }>`${({ theme, $active }) =>
  css`
  position: absolute;
  top: 19px;
  right: 0;
  background-color: ${theme.colors.swatch5};
  z-index: 6000;
  border: 1px solid ${theme.borderColor};
  border-radius: 10px;
  transform: translate(0, -50%);
  padding: 10px;
  ${!$active && 'display: none;'}
`}`

const Spacer = styled.div`${() =>
  css`
  height: 25px;
`}`

type Props = {
  color: string
  colors: Array<string>
  labelSpacer?: boolean
  onChange: (e: any) => void
  id?: string
  name?: string // TODO: is this used?
}

type State = {
  isActive: boolean
}

export default class ColorPicker extends React.Component<Props, State> {
  public override state: State = {
    isActive: false,
  }

  private readonly handleToggle = () => this.setState({ isActive: !this.state.isActive })

  private readonly handleClose = () => this.setState({ isActive: false })

  private readonly handleChange = (color: { hex: string }) => {
    const { onChange: handleChange, ...restProps } = this.props

    this.handleClose()

    handleChange({ target: { value: color.hex, ...restProps } })
  }
  
  public override render () {
    const { isActive } = this.state
    const { color, colors, labelSpacer, ...restProps } = this.props

    return (
      <Wrapper {...restProps}>
        {labelSpacer && <Spacer>&nbsp;</Spacer>}
        <Button $color={color} onClick={this.handleToggle} />
        <Box $active={isActive} onMouseLeave={this.handleClose}>
          <CirclePicker color={color} colors={colors} onChange={this.handleChange} />
        </Box>
      </Wrapper>
    )
  }
}
