export enum AirLoopMountLogActionsEnum {
  ACTION_ADD_AIR_LOOP_MOUNT_LOG = 'AirLoopMountLog/ACTION_ADD_AIR_LOOP_MOUNT_LOG',
  UPDATE = 'AirLoopMountLog/UPDATE',
  RESET = 'AirLoopMountLog/RESET',
}

export function addAirLoopMountLog (airLoopMountLogHash: Record<number, AirLoopMountLog>) {
  return {
    type: AirLoopMountLogActionsEnum.ACTION_ADD_AIR_LOOP_MOUNT_LOG,
    airLoopMountLogHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const AirLoopMountLogReducers: Record<string, Function | undefined> = {
  [AirLoopMountLogActionsEnum.ACTION_ADD_AIR_LOOP_MOUNT_LOG]: (
    _state: AirLoopMountLogHash,
    action: { airLoopMountLogHash: AirLoopMountLogHash },
  ) => (
    action.airLoopMountLogHash
  ),

  [AirLoopMountLogActionsEnum.UPDATE]: (
    state: AirLoopMountLogHash,
    action: { elements: AirLoopMountLogHash },
  ) => ({
    ...state,
    ...action.elements,
  }),

  [AirLoopMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: AirLoopMountLogHash = {},
  action: any,
) {
  const handler = AirLoopMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
