import { request } from '@/api'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info } from '@/logic/Info'
import { parseJWT } from '@/Util/authUtil'

/**
 * Refreshes the access token using the refresh token. If no token is present, nothing happens.
 */
export async function refresh (): Promise<void> {
  const tokenData = AuthDataStore.get()

  if (!tokenData) {
    return
  }

  const authResult = await request<AuthResult>(
    'post',
    'auth/refresh',
    'Failed to refresh token',
    {
      data: {
        /* eslint-disable camelcase */
        refresh_token: tokenData.refreshToken,
        client_id: 'application',
        scopes: 'write',
        grant_type: 'refresh_token',
        /* eslint-enable camelcase */
      },
      skipRefresh: true,
    },
    true,
  )

  if (!authResult) {
    return
  }

  // delete featureFlags from authResult as the cookie cannot store this much data
  delete authResult.featureFlags // TODO: do we need to update our stored feature flags?

  AuthDataStore.set(authResult)
}

/**
 * Ensures that the access token is valid and refreshes it if necessary. If no token is present, nothing happens.
 */
export async function ensureValidAccessToken (): Promise<void> {
  const tokenData = AuthDataStore.get()

  if (!tokenData) {
    return
  }

  const accessJWTData = parseJWT(tokenData.accessToken)
  const refreshJWTData = parseJWT(tokenData.refreshToken)
  const nowP30 = Date.now() / 1000 + 30

  // If the access token is about to expire, refresh it
  if (accessJWTData.exp < nowP30 && refreshJWTData.exp > nowP30) {
    await refresh()

    return
  }

  // If both tokens have expired, clear the stored data and reload the page
  if (accessJWTData.exp < nowP30 && refreshJWTData.exp < nowP30) {
    AuthDataStore.clear()

    Info.removeRecentlyUsedInfo()

    window.location.reload()
  }
}
