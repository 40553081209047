import React from 'react'

import { NetworkStatus } from '@/api/network-event'
import { MenuID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import { ApplicationMainActionsEnum, AppState } from '@/store/application/main/consts'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import { I } from '../../ApplicationActions'

export default class Menu {
  public static evaluateActiveKey (_activeKey: string, _caster: Caster) {
    // if (!activeKey) {
    return true
    // }

    // FIXME: this is a hack, because we don't have caster in state
    // if (!caster || (activeKey && !caster.XMLDataBase)) {
    //   return false
    // }

    // // https://stackoverflow.com/questions/18473326/javascript-break-sentence-by-words
    // // to get all words
    // const keys = activeKey.match(/\b(\w+)\b/g) ?? []

    // for (const key of keys) {

    //   const value = ![ '0', undefined ].includes(caster.XMLDataBase?.[`_${key}`])

    // eslint-disable-next-line max-len
    //   // TODO: review security, it should be secure enough, if word is not in keys, then everything should be false or
    //   // true, no room for malicious functions or code
    //   // replace all occurrences of key with value ( match whole word only )
    //   activeKey = activeKey.replace(new RegExp(`\\b${key}\\b`, 'g'), `${value}`)
    // }

    // let result

    // try {
    //   // eslint-disable-next-line no-eval
    //   result = eval(activeKey)
    // }
    // catch (error) {
    //   console.log('Evaluation Error:', activeKey, error)

    //   return false
    // }

    // return result
  }
  
  private static getDefaultFileMenu (menuEntries: any) {
    return [
      {
        id: MenuID.MenuFile.OpenProject,
        label: (t: Translation) => t('openProject'),
        shortcut: 'Ctrl+O',
        action: 'openProjectDialog',
        visible: (permissions: Record<string, boolean>, state: any) => (
          !state.util.isConsistencyCheck &&
          FeatureFlags.canViewOpenProjectMenuOption(permissions)
        ),
        disabled: ({ application, util }: DefaultState) => (
          !FeatureFlags.canUseOpenProjectMenuOption(application.main.authenticationData.featureFlags) ||
          util.isConsistencyCheck
        ),
      },
      {
        id: MenuID.MenuFile.NewProject,
        label: (t: Translation) => t('newProject'),
        shortcut: 'Ctrl+Shift+O',
        action: 'openNewProjectDialog',
        visible: (permissions: Record<string, boolean>, state: any) => (
          FeatureFlags.canViewCreateProjectMenuOption(permissions) &&
          !state.util.isConsistencyCheck
        ),
        disabled: (state: any) => {
          const permissions = FeatureFlags.getRealFeatureFlags(state)

          return (
            !FeatureFlags.isLoggedIn(state) ||
            state.util.isConsistencyCheck ||
            !FeatureFlags.canUseCreateProjectMenuOption(permissions)
          )
        },
      },
      {
        visible: (permissions: Record<string, boolean>, state: any) => (
          FeatureFlags.canViewOpenProjectMenuOption(permissions) ||
          FeatureFlags.canViewCreateProjectMenuOption(permissions) ||
          !state.util.isConsistencyCheck
        ),
        type: 'separator',
      },
      {
        id: MenuID.MenuFile.UploadCaster,
        label: (t: Translation) => t('uploadCaster'),
        action: 'uploadCaster',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadCasterXMLMenuOption(permissions)
        ),
        disabled: (state: DefaultState) => (
          !FeatureFlags.canUseUploadCasterXMLMenuOption(FeatureFlags.getRealFeatureFlags(state))
        ),
      },
      {
        id: MenuID.MenuFile.UploadCatalog,
        label: (t: Translation) => t('uploadCatalog'),
        action: 'uploadCatalog',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadNozzleCatalogMenuOption(permissions)
        ),
        disabled: (state: DefaultState) => (
          !FeatureFlags.canUseUploadNozzleCatalogMenuOption(FeatureFlags.getRealFeatureFlags(state))
        ),
      },
      ...menuEntries,
      {
        type: 'separator',
      },
      {
        id: MenuID.MenuFile.LoadCaster,
        label: (t: Translation) => t('loadCaster'),
        action: 'loadCaster',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewLoadCasterMenuOption(permissions)
        ),
        disabled: ({ loading, application }: DefaultState) => (
          !application.main.currentSimulationCase?.currentCasterId ||
            loading.uploadLoading.CasterDataIsLoading ||
            !FeatureFlags.canUseLoadCasterMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        type: 'separator',
        visible: (permissions: Record<string, boolean>) => FeatureFlags.usesSlimVersion(permissions),
      },
      {
        id: MenuID.MenuFile.SelectCasterDashboard,
        label: (t: Translation) => t('selectDashboard'),
        action: 'openSelectDashboard',
        visible: (permissions: Record<string, boolean>) =>
          FeatureFlags.canViewSelectCasterDashboardMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseSelectCasterDashboardMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        id: MenuID.MenuFile.SendToConsistencyCheck,
        label: (t: Translation) => t('consistencyCheck'),
        action: 'sendCasterToConsistencyCheck',
        visible: (permissions: Record<string, boolean>, state: any) =>
          FeatureFlags.canViewSendToConsistencyCheckOption(permissions) &&
            Object.keys(state.CCElement ?? {}).length > 0,
        disabled: ({ data: { rootData }, application }: DefaultState) => (
          !rootData.Caster ||
            !FeatureFlags.canUseSendToConsistencyCheckOption(application.main.authenticationData.featureFlags)
        ),
      },
    ]
  }
  
  private static getDefaultEditMenu (menuEntries: any) {
    return [
      {
        id: MenuID.MenuEdit.ManageDynamicDataSources,
        label: (t: Translation) => t('manageDynamicDataSourcesDialog'),
        action: 'openManageDynamicDataSourcesDialog',
        visible: (permissions: Record<string, boolean>, state: DefaultState) => 
          // TODO: add specific FF
          FeatureFlags.canViewManageDynamicDataMenuOption(permissions) &&
            state.application.main.appState === AppState.Caster,        
        disabled: ({ application, visualization: { visualizationMetaInformation } }: DefaultState) => {
          const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}
          const featureFlags = application.main.authenticationData.featureFlags
          const appState = application.main.appState

          return (
            !config ||
            !FeatureFlags.canUseManageDynamicDataMenuOption(featureFlags, visualizationMetaInformation, appState)
          )
        },
      },
      {
        id: MenuID.MenuEdit.ConfigurePlots,
        label: (t: Translation) => t('configurePlots'),
        action: ApplicationMainActionsEnum.configurePlots,
        disabled: ({ visualization, application }: DefaultState) => (
          application.main.networkStatus !== NetworkStatus.Connected ||
          Object.keys(visualization.plotConfigs ?? {}).length === 0 ||
          !visualization.isEditModeOn ||
          !FeatureFlags.canUseConfigurePlotsMenuOption(
            application.main.authenticationData.featureFlags,
            visualization.visualizationMetaInformation,
            application.main.appState,
          )
        ),
        visible: (permissions: any) => FeatureFlags.canViewConfigurePlotsMenuOption(permissions),
      },
      {
        id: MenuID.MenuEdit.ResetAllShim,
        label: (t: Translation) => t('resetAllShim'),
        action: 'resetAllShim',
        disabled: ({ application }: DefaultState) => (
          !application.main.currentSimulationCase?.currentCasterId
        ),
        visible: (permissions: any) => FeatureFlags.canViewModulesMenuOption(permissions),
      },
      {
        id: MenuID.MenuEdit.CreateRealDataCase,
        label: (t: Translation) => t('createRealDataCase'),
        action: 'openCreateRealDataCaseDialog',
        // TODO: define FeatureFlags for this
        visible: (_permissions: any) => true, // FeatureFlags.canViewCreateRealDataCaseMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !application.main.currentSimulationCase?.currentCasterId ||
          Boolean(application.main.currentSimulationCase?.blueprintId) // if blueprintId is set, it's a real data case
        ),
      },
      ...menuEntries,
    ]
  }

  private static getViewMenu () {
    return [
      {
        id: MenuID.MenuView.ToggleFullScreen,
        label: (t: Translation) => t('toggleFullScreen'),
        shortcut: 'F11',
        doNotPreventDefault: false,
        action: 'toggleFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewToggleFullScreenMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseToggleFullScreenMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        id: MenuID.MenuView.ExitFullScreen,
        label: (t: Translation) => t('exitFullScreen'),
        shortcut: 'ESC',
        action: 'exitFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewExitFullScreenMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseExitFullScreenMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        id: MenuID.MenuView.Reload3D,
        label: (t: Translation) => t('kill3D'),
        action: 'kill3D',
        visible: (permissions: any) => FeatureFlags.canViewRecreate3DMenuOption(permissions),
        disabled: ({ data, application: { main } }: DefaultState) => (
          Object.keys(data.rootData ?? {}).length === 0 ||
          !FeatureFlags.canUseRecreate3DMenuOption(main.authenticationData.featureFlags)
        ),
      },
    ]
  }
  
  private static getRunMenu (executableDefinitions: ExecutableDefinition[], permissions?: any) {
    return {
      id: 'caster-menu-run',
      disabled: () => !FeatureFlags.canUseModulesMenuOption(permissions),
      visible: (_permissions: Record<string, boolean>, state: DefaultState) =>
        !state.util.isConsistencyCheck && FeatureFlags.canViewModulesMenuOption(permissions),
      label: (t: Translation) => t('modules'),
      submenu: executableDefinitions.length
        ? executableDefinitions.map((definition) => ({
          id: `caster-menu-run-${definition.name.toLowerCase().replace(/\s+/g, '-')}`,
          label: () => definition.name,
          action: definition.isDownloadXMLModule ? 'openDownloadXMLDialog' : 'openExecutableDialog',
          params: () => definition,
          disabled: ({ application: { main: { currentSimulationCase } }, data: { rootData } }: DefaultState) =>
            !currentSimulationCase ||
            !currentSimulationCase.id ||
            !this.evaluateActiveKey(definition.activeKey ?? '', rootData.Caster),
        }))
        : [ { label: (t: Translation) => t('noExecutables'), disabled: () => true } ],
    }
  }

  private static getCasterMenu (featureFlags: any) {
    return [
      {
        id: MenuID.MenuFile.ID,
        label: (t: Translation) => t('file'),
        visible: (permissions: any) => FeatureFlags.canViewCaster(permissions),
        submenu: Menu.getDefaultFileMenu([
          {
            id: MenuID.MenuFile.SaveCasterXML,
            label: (t: Translation) => t('download'),
            shortcut: 'Ctrl+S',
            action: 'saveCasterXML',
            visible: (permissions: any) => FeatureFlags.canViewDownloadCasterXMLMenuOption(permissions),
            params: ({ application: { main: { currentSimulationCase } } }: DefaultState) => currentSimulationCase.id,
            disabled: ({ application: { main: { currentSimulationCase } } }: DefaultState) => (
              !currentSimulationCase?.id ||
              !FeatureFlags.canUseDownloadCasterXMLMenuOption(featureFlags)
            ),
          },
        ]),
      },
      {
        id: MenuID.MenuEdit.ID,
        label: (t: Translation) => t('edit'),
        submenu: Menu.getDefaultEditMenu([]),
      },
      {
        id: MenuID.MenuView.ID,
        label: () => 'View', // TODO: use translation
        submenu: Menu.getViewMenu(),
      },
    ]
  }

  private static readonly dashboardMenu = [
    {
      id: MenuID.MenuFile.ID,
      label: (t: Translation) => t('file'),
      visible: (_permissions: any) => true,
      submenu: Menu.getDefaultFileMenu([
        { // this is here to bind the shortcut and prevent default
          // TODO: is this necessary?
          id: MenuID.MenuFile.SaveVisualizationChanges,
          label: (_t: Translation) => '',
          visible: () => false,
          shortcut: 'Ctrl+S',
          action: 'saveVisChanges',
        },
      ]),
    },
    {
      id: MenuID.MenuEdit.ID,
      label: (t: Translation) => t('edit'),
      submenu: Menu.getDefaultEditMenu([
        {
          id: MenuID.MenuEdit.ToggleEditMode,
          label: (t: Translation, state: DefaultState) =>
            t('editMode', { state: state.visualization.isEditModeOn ? 'On' : 'Off' }),
          shortcut: 'F7',
          action: ApplicationMainActionsEnum.editMode,
          visible: (permissions: any) => FeatureFlags.canViewToggleEditMode(permissions),
          disabled: ({ application }: DefaultState) =>
            !FeatureFlags.canUseToggleEditMode(application.main.authenticationData.featureFlags),
        },
        {
          id: MenuID.MenuEdit.ExportPlots,
          label: (t: Translation) => t('exportPlots'),
          action: ApplicationMainActionsEnum.exportPlots,
          visible: (permissions: any) => FeatureFlags.canViewExportPlots(permissions),
          disabled: ({ visualization, application }: DefaultState) => (
            application.main.networkStatus !== NetworkStatus.Connected ||
            (
              !FeatureFlags.canUseExportPlots(application.main.authenticationData.featureFlags) ||
              Object.keys(visualization.plotConfigs ?? {}).length === 0
            )
          ),
        },
        {
          id: MenuID.MenuEdit.MapCommands,
          label: (t: Translation) => t('mapCommands'),
          action: ApplicationMainActionsEnum.mapCommands,
          // TODO: Store commands in redux
          visible: (permissions: any) => FeatureFlags.canViewCommandFileMapping(permissions),
          disabled: ({ application }: DefaultState) =>
            !FeatureFlags.canUseCommandFileMapping(application.main.authenticationData.featureFlags),
        },
      ]),
    },
  ]

  private static readonly applicationMenu = [
    {
      id: MenuID.MenuHelp.ID,
      label: 'pe-7s-help1',
      submenu: [
        {
          id: MenuID.MenuHelp.about,
          label: (t: Translation) => t('about'),
          action: 'openAboutDialog',
        },
      ],
    },
    {
      id: MenuID.MenuUser.ID,
      label: (t: Translation, state: DefaultState) => (
        state.application.main.authenticationData?.name
          ? (state.application.main.authenticationData?.name ?? ' ')[0].toUpperCase()
          : <I className='pe-7s-user' />
      ),
      submenu: [
        {
          label: (t: Translation, state: DefaultState) => state.application.main.authenticationData?.name ?? 'offline',
        },
        {
          id: MenuID.MenuUser.Settings,
          label: (t: Translation) => t('settings'),
          disabled: () => true,
        },
        {
          type: 'separator',
          visible: (_permission: any, state: DefaultState) =>
            (state.application.main.authenticationData?.name ?? '').length > 0,
        },
        {
          id: MenuID.MenuUser.Logout,
          label: (t: Translation) => t('logout'),
          visible: (_permission: any, state: DefaultState) =>
            (state.application.main.authenticationData?.name ?? '').length > 0,
          action: 'ACTION_RESET_STORE',
        },
      ],
    },
  ]
  
  public static menu (
    appState: AppState,
    executableDefinitions: ExecutableDefinition[],
    permissions?: Record<string, boolean>,
  ) {
    const showRunMenu = permissions?.Menu_Modules_View

    return appState !== AppState.Caster
      ? Menu.dashboardMenu
      : showRunMenu
        ? [ ...Menu.getCasterMenu(permissions), Menu.getRunMenu(executableDefinitions ?? [], permissions) ]
        : Menu.getCasterMenu(permissions)
  }
  
  public static getApplicationMenu () {
    return Menu.applicationMenu
  }
}
