/* eslint-disable camelcase */
/* eslint-disable key-spacing */

import { defaultFields } from './defaults'

export const SegmentGroup = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    // id:               { type: 'number', category: 2, defaultValue: 0, disabled: true },
    name: { type: 'text', category: 2, defaultValue: '' },
    endPasslineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    startPasslineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    ...defaultFields,
  },
}
/* eslint-enable key-spacing */
