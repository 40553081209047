/* eslint-disable camelcase */
/* eslint-disable key-spacing */
import { defaultFields } from './defaults'

export const Segment = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    side: { // FIXME: use StrandSides if possible
      type: 'select',
      category: 1,
      defaultValue: 'FixedSide',
      options: [ 'FixedSide', 'LoseSide', 'NarrowFaceLeft', 'NarrowFaceRight' ],
    },
    segType: {
      type: 'select',
      category: 2,
      defaultValue: 'foot',
      options: [ 'foot', 'bow' ],
    },
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true },
    // id:            { type: 'number', category: 2, defaultValue: 0, disabled: true },
    name: { type: 'text', category: 2, defaultValue: '' },
    ...defaultFields,
  },
}
/* eslint-enable key-spacing */
