import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import MatrixUtil from '@/react/visualization/MatrixUtil'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

const T = 'projectMatrixDialog.simulationCaseNameEditor'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
  value?: string
  data?: { id: string }
  selectedCellData?: Array<any>
  onClose: () => void
}

type State = {
  name: string
  error: string
  loading: boolean
}

export class SimulationCaseNameEditor extends Component<Props, State> {
  private timeoutRef?: NodeJS.Timeout

  public override state: State = {
    name: '',
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    const { value } = this.props

    if (value) {
      this.setState({ name: value })
    }
  }
  
  public override componentWillUnmount () {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)

      this.handleSubmit()
    }
  }

  private readonly handleChange = (event: any) => {
    this.setState({ name: event.target.value })

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }

    this.timeoutRef = setTimeout(this.handleSubmit, 2000)
  }

  private readonly handleError = (error: any) => {
    this.setState({
      error: error ? error.status : 'unknown',
      loading: false,
    })
  }

  private readonly handleSubmit = () => {
    const { name } = this.state
    const { data, onClose, currentProject, setCurrentProject, selectedCellData } = this.props
    const { id } = data ?? {}

    if (name) {
      if (!id) {
        return this.handleError('no id given')
      }

      this.setState({ loading: true })

      MatrixUtil.updateSimulationCaseName(
        currentProject,
        setCurrentProject,
        name,
        id,
        selectedCellData,
        onClose,
        this.handleError,
      )
    }
  }
  
  public override render () {
    const { name } = this.state
    const { t } = this.props

    return (
      <Form>
        <Input
          autoFocus
          label={t(`${T}.label`)}
          title={t(`${T}.label`)}
          placeholder={t(`${T}.name`)}
          name={name}
          type='text'
          value={name}
          onChange={this.handleChange}
        />
      </Form>
    )
  }
}

export default withNamespaces('application')(connector(SimulationCaseNameEditor as any) as any) as any
