import { Props } from '@/react/context/form/FormBuilder'

import { defaultFields, getHiddenFields } from './defaults'
import { SupportPoint } from './SupportPointDefinition'

export function getCategoryName ({ path, editElements }: Props, isTitle = false) {
  const categoryLabel = path.substring(path.lastIndexOf('/') + 1)
  const { name } = editElements?.[path] ?? {}

  if (!name) {
    return isTitle ? categoryLabel : 'SupportPoint'
  }

  return `${name} - ${isTitle ? categoryLabel : 'SupportPoint'}`
}

const VISIBLE_FIELDS = [ 'shimMin', 'shimActual', 'shimPropose', 'shimMax' ]

/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SegmentGroupSupportPoints = {
  categories: {
    1: getCategoryName,
  },
  fields: {
    ...Object.keys(SupportPoint.fields).reduce((fields, key) => {
      const field = SupportPoint.fields[key]

      return { ...fields, [key]: { ...field, hidden: !VISIBLE_FIELDS.includes(key) } }
    }, {}),
    ...getHiddenFields([
      'passlineCoord',
      'widthCoord',
      'thicknessCoord',
      'segmentGroupMountLogId',
      'supportPointId',
    ]),
    ...defaultFields,
  },
}
/* eslint-enable key-spacing */
