import TextureUtil from '@/three/logic/TextureUtil'
import Mold from '@/three/objects/Mold'
import { StrandSides } from '@/types/elements/enum'

import SectionView from '.'
import DrawHandlers from './DrawHandlers'

export default class ToggleHandlers {
  public static expandSectionView (view: SectionView) {
    // toggles the expanded section view
    if (!view.strand || !view.views || !view.views.mainView) {
      return
    }

    view.sectionViewExpanded = !view.sectionViewExpanded
    view.strand.visible = view.sectionViewExpanded

    if (view.minStrand) {
      view.minStrand.visible = view.sectionViewExpanded && Boolean(Mold.minWidth)
    }

    if (view.maxStrand) {
      view.maxStrand.visible = view.sectionViewExpanded && Boolean(Mold.maxWidth)
    }

    const { width, height } = view.views.mainView.viewport

    view.resize(width, height)

    view.buttons.expandSectionViewButton.userData.tooltip = view.sectionViewExpanded ? 'Collapse' : 'Expand'

    DrawHandlers.drawSwitcherIcon(
      view,
      null,
      null,
      'expandSectionViewButton',
      'expandSectionView',
      view.sectionViewExpanded,
      TextureUtil.load('textures/ui/collapse.png'),
      TextureUtil.load('textures/ui/expand.png'),
      view.sectionViewExpanded ? 'Collapse' : 'Expand',
      false,
    )

    Object.keys(view.buttons).forEach(name => {
      if (name === 'expandSectionViewButton') {
        return
      }

      if (name === 'RollerNozzleSwitcher' || name === 'switcherPrevNextButton') {
        return (view.buttons[name].visible = view.viewMode && view.sectionViewExpanded)
      }

      view.buttons[name].visible = view.sectionViewExpanded
    })
  }

  public static togglePrevNextElements (view: SectionView) {
    // Toggles if the extra elements shown are from the next layer or the previous
    view.statusPrevNext = !view.statusPrevNext

    DrawHandlers.drawSwitcherWithBackground(
      view,
      null,
      null,
      'switcherPrevNextButton',
      'togglePrevNextElements',
      view.statusPrevNext,
      `Additional Layer: ${!view.statusPrevNext ? 'Next' : 'Prev'}`,
      TextureUtil.load('textures/ui/toggle-prev.png'),
      TextureUtil.load('textures/ui/toggle-next.png'),
    )

    DrawHandlers.drawNextElements(view)
  }

  public static toggleShowedElements (view: SectionView) {
    // toggles if the shown elements are nozzles or rollers
    view.additionalLayerType = view.additionalLayerType === 'Nozzle' ? 'Roller' : 'Nozzle'

    DrawHandlers.drawSwitcherWithBackground(
      view,
      null,
      null,
      'RollerNozzleSwitcher',
      'toggleShowedElements',
      view.additionalLayerType === 'Roller',
      `Additional Layer Type: ${view.additionalLayerType}`,
      TextureUtil.load('textures/ui/toggle-roller.png'),
      TextureUtil.load('textures/ui/toggle-nozzle.png'),
    )

    DrawHandlers.drawNextElements(view)
  }

  public static toggleSides (view: SectionView, side: string) {
    // Toggles the using of sides of snaps
    ;(view.side as any)[side] = !(view.side as any)[side]

    const x = -Mold.thickness / 2 + (view.sectionPlaneWidth ?? 0) / 2
    const y = (view.sectionPlaneHeight ?? 0) / 2

    let calculatedX, calculatedY

    switch (side) {
      case StrandSides.Left:
        calculatedX = -Mold.thickness / 2
        calculatedY = y - 0.3
        break
      case StrandSides.Right:
        calculatedX = -Mold.thickness / 2
        calculatedY = -(y - 0.3)
        break
      case StrandSides.Fixed:
        calculatedX = -x - Mold.thickness + 0.1
        calculatedY = 0
        break
      case StrandSides.Loose:
        calculatedX = x - 0.1
        calculatedY = 0
        break
      default:
        calculatedX = x
        calculatedY = y
    }

    DrawHandlers.drawSwitcher(
      view,
      calculatedX,
      calculatedY,
      side,
      `toggle${side}`,
      (view.side as any)[side],
      false,
      false,
      `Use passLineCoordinates, ${side}`,
    )
  }

  public static toggleViewMode (view: SectionView) {
    // Toggles the details section in section view
    view.viewMode = !view.viewMode

    DrawHandlers.drawSwitcherIcon(
      view,
      null,
      null,
      'ViewMode',
      'toggleViewMode',
      view.viewMode,
      TextureUtil.load('textures/ui/eyeOpen.png'),
      TextureUtil.load('textures/ui/eyeClose.png'),
      'Details',
      false,
    )

    view.buttons.RollerNozzleSwitcher.visible = view.viewMode
    ToggleHandlers.toggleSpecificElement(
      view.buttons.RollerNozzleSwitcher.children,
      'RollerNozzleSwitcherSwitcher',
      view.viewMode,
    )
    view.buttons.switcherPrevNextButton.visible = view.viewMode
    ToggleHandlers.toggleSpecificElement(
      view.buttons.switcherPrevNextButton.children,
      'switcherPrevNextButtonSwitcher',
      view.viewMode,
    )

    if (view.additionalLayerType === 'Roller') {
      view.rollerGroup.visible = view.viewMode
    }
    else {
      view.nozzleGroup.visible = view.viewMode
    }
  }

  public static toggleSpecificElement (children: any, name: string, visible: boolean) {
    for (let i = 0; i < children.length; i++) {
      if (children[i].name === name) {
        children[i].visible = visible
        break
      }
    }
  }
}
