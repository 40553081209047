import { ElementsHashes } from '@/types/state'

export type PartsWarehouseEntryElement<T extends CasterElementBaseEntity | null> = {
  listType: 'element'
  elementType: CasterElementNames
  data: T
}

export type PartsWarehouseEntryGroup = {
  listType: 'group'
  name: CasterElementNames
  expanded: boolean
  childCount: number
}

export type PartsWarehouseEntry<T extends CasterElementBaseEntity | null> =
  | PartsWarehouseEntryElement<T>
  | PartsWarehouseEntryGroup

export class PartsWarehouseLogic {
  private static readonly typesToRender: CasterElementNames[] = [
    'SegmentGroup',
    'Segment',
    'Nozzle',
    'Roller',
    'RollerBody',
    'RollerBearing',
  ]

  private static getElementsOfType (elementsHashes: ElementsHashes, type: CasterElementNames) {
    const elementsOfType = elementsHashes[type] as Record<string, CasterElementBaseEntity> | undefined

    return Object.values(elementsOfType ?? {})
  }

  public static toggleExpanded = (
    expandedElements: Record<CasterElementNames, boolean | undefined>,
    name: CasterElementNames,
  ) => ({
    ...expandedElements,
    [name]: !(expandedElements[name] ?? true),
  })

  public static getElements = (
    elementsHashes: ElementsHashes,
    expandedElements: Record<CasterElementNames, boolean | undefined>,
  ) => {
    const result: PartsWarehouseEntry<CasterElementBaseEntity>[] = []

    for (const type of PartsWarehouseLogic.typesToRender) {
      const elementsOfType = PartsWarehouseLogic.getElementsOfType(elementsHashes, type)

      result.push({
        listType: 'group',
        name: type,
        expanded: expandedElements[type] ?? true,
        childCount: elementsOfType.length,
      })

      if (expandedElements[type] === false) {
        continue
      }

      for (const key in elementsOfType) {
        result.push({
          listType: 'element',
          elementType: type,
          data: elementsOfType[key],
        })
      }
    }

    return result
  }
}
