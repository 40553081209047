import { Button, ButtonGroup } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Util from '@/logicHandlers/ServerLogic/actions/Util'
import Icon from '@/react/specific/Icon'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'

import { ButtonBar } from './Styles'

const T = 'projectMatrixDialog.configureDialog.actions'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onUpload: any
  enqueueSnackbar: enqueueSnackbar
  t(key: string, params?: Record<string, unknown>): string
}

class Actions extends Component<Props> {
  private readonly handleDownloadButtonClick = async () => {
    const { currentProject: { id }, enqueueSnackbar, t } = this.props

    const { config } = await ApiClient.get(`${'Network.URI(deprecated)'}/matrix/config/${id}`)

    if (window.isElectron) {
      enqueueSnackbar('Download initiated...', { variant: 'info', autoHideDuration: 3000 })
    }

    Util
      .openDownloadFileDialog(JSON.stringify(config), 'matrix_config.json')
      .then(() => {
        if (!window.isElectron) {
          enqueueSnackbar(t(`${T}.download_successful`), { variant: 'success' })
        }
      })
      .catch(() => {
        enqueueSnackbar(t(`${T}.download_failed`), { variant: 'error' })
      })
  }

  private readonly handleUploadButtonClick = () => {
    const { currentProject: { id }, onUpload, setCurrentProject, enqueueSnackbar, t } = this.props

    Util
      .openUploadFileDialog('.json', `/matrix/config/${id}`, 'patch')
      .then(
        (
          { project, missingCommands, missingResultKeys }: {
            project: Project
            missingCommands: string[]
            missingResultKeys: string[]
          },
        ) => {
          setCurrentProject(project)

          onUpload(project.matrixColumnOrder)

          const hasMissingCommands = missingCommands && missingCommands.length
          const hasMissingResultKeys = missingResultKeys && missingResultKeys.length

          if (hasMissingCommands || hasMissingResultKeys) {
            if (hasMissingCommands) {
              enqueueSnackbar(
                t(
                  `${T}.upload_successful_missing_commands`,
                  { missingCommands: missingCommands.map((entry: string) => `-> ${entry}`).join('\n') },
                ),
                { variant: 'warning', persist: true },
              )
            }

            if (hasMissingResultKeys) {
              enqueueSnackbar(
                t(
                  `${T}.upload_successful_missing_result_keys`,
                  { missingResultKeys: missingResultKeys.map(entry => `-> ${entry}`).join('\n') },
                ),
                { variant: 'warning', persist: true },
              )
            }

            enqueueSnackbar(t(`${T}.upload_successful_missing_info`), { variant: 'info', persist: true })

            return
          }

          enqueueSnackbar(t(`${T}.upload_successful`), { variant: 'success' })
        },
      )
      .catch(() => {
        enqueueSnackbar(t(`${T}.upload_failed`), { variant: 'error' })
      })
  }
  
  public override render () {
    const { t } = this.props

    return (
      <ButtonBar>
        <ButtonGroup color='primary' size='small' variant='contained' aria-label='small contained button group'>
          <Button onClick={this.handleDownloadButtonClick} title={t(`${T}.download`)}>
            <Icon icon='download' />
          </Button>
          <Button onClick={this.handleUploadButtonClick} title={t(`${T}.upload`)}>
            <Icon icon='upload' />
          </Button>
        </ButtonGroup>
      </ButtonBar>
    )
  }
}

export default withNamespaces('application')(withSnackbar((Actions as any) as any) as any) as any
