export enum moldFaceActionsEnum {
  ACTION_ADD_MOLD_FACE = 'moldFace/ACTION_ADD_MOLD_FACE',
  DELETE = 'moldFace/DELETE',
  DELETE_MULTIPLE = 'moldFace/DELETE_MULTIPLE',
  UPDATE = 'moldFace/UPDATE',
  RESET = 'moldFace/RESET',
}

export function addMoldFaces (moldFaces: MoldFace[]) {
  return {
    type: moldFaceActionsEnum.ACTION_ADD_MOLD_FACE,
    moldFaces,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const moldFaceReducers: Record<string, Function | undefined> = {
  [moldFaceActionsEnum.ACTION_ADD_MOLD_FACE]: (_state: MoldFace[], action: { moldFaces: MoldFace[] }) =>
    action.moldFaces,

  [moldFaceActionsEnum.DELETE]: (state: MoldFace[], action: { id: number }) => {
    const newState = [ ...state ]

    newState.splice(action.id, 1)

    return newState
  },

  [moldFaceActionsEnum.RESET]: () => [],
}

export default function (state: MoldFace[] = [], action: any) {
  const handler = moldFaceReducers[action.type]

  return handler ? handler(state, action) : state
}
