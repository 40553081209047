export enum RollerMountLogActionsEnum {
  ACTION_ADD_ROLLER_BODY_MOUNT_LOG = 'RollerBodyMountLog/ACTION_ADD_ROLLER_BODY_MOUNT_LOG',
  DELETE = 'RollerBodyMountLog/DELETE',
  DELETE_MULTIPLE = 'RollerBodyMountLog/DELETE_MULTIPLE',
  UPDATE = 'RollerBodyMountLog/UPDATE',
  RESET = 'RollerBodyMountLog/RESET',
}

export function addRollerBodyMountLog (rollerMountLogHash: Record<number, RollerMountLog>) {
  return {
    type: RollerMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_MOUNT_LOG,
    rollerMountLogHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerMountLogReducers: Record<string, Function | undefined> = {
  [RollerMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_MOUNT_LOG]: (
    _state: RollerMountLogHash,
    action: { rollerMountLogHash: RollerMountLogHash },
  ) => (
    action.rollerMountLogHash
  ),

  [RollerMountLogActionsEnum.DELETE]: (state: RollerMountLogHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerMountLogActionsEnum.DELETE_MULTIPLE]: (state: RollerMountLogHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerMountLogActionsEnum.UPDATE]: (state: RollerMountLogHash, action: { elements: RollerMountLogHash }) => ({
    ...state,
    ...action.elements,
  }),

  [RollerMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: RollerMountLogHash = {},
  action: any,
) {
  const handler = RollerMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
