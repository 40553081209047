/* eslint-env browser */

import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import { DialogID } from '@/react/driver/DriverID'
import { type PartsWarehouseEntryElement } from '@/react/PartsWarehouseLogic'
import * as ApplicationActions from '@/store/application/main/actions'
import { getElementsHashesObject } from '@/store/elements/logic'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'
import { Content } from '../DialogStyles'

const RealDataContent = styled(Content)`${({ theme }) => css`
  display: flex;
  flex-direction: row;
  color: ${theme.mainFontColor};
  padding: 20px;
  font-size: 12px;

  > div {
    flex: 1;
  }
`}`

const connector = connect((state: DefaultState) => ({
  params: state.application.main.params,
  elementsHashes: getElementsHashesObject(state), // TODO: get real data elements map
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  params: /* override type */ PartsWarehouseEntryElement<CasterElementBaseEntity>
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  t(key: string, params?: Record<string, unknown>): string
}

type State = void

const T = 'realDataElementDetailsDialog'

export class RealDataElementDetailsDialog extends Component<Props, State> {
  @Identifiable('RealDataElementDetailsDialog') public static readonly NAME: string

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(RealDataElementDetailsDialog.NAME)
  }

  public override render () {
    const { params, elementsHashes, t } = this.props

    const mountLogKey = `${params.elementType}MountLog` as keyof typeof elementsHashes
    const elementIdKey = `${params.elementType[0].toLocaleLowerCase() + params.elementType.substring(1)}Id`
    // TODO: maybe we need to get this data via API request
    const mountLogs = Object.values((elementsHashes[mountLogKey] ?? {}) as Record<string, BaseMountLog>).filter(
      (element: any) => element[elementIdKey] === params.data.id,
    )

    return (
      <BaseDialog
        id={DialogID.RealDataElementDetails.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        // small
      >
        <RealDataContent>
          <div>
            <pre>{JSON.stringify(params.data, null, 2)}</pre>
          </div>
          <div>
            {
              mountLogs
                .toSorted((a, b) => a.mountedAt.localeCompare(b.mountedAt))
                .map((log) => (
                  <div key={log.id}>
                    {log.id}:<br />
                    &nbsp;&nbsp;{log.mountedAt} - {log.removedAt ?? 'now'}
                  </div>
                ))
            }
          </div>
        </RealDataContent>
      </BaseDialog>
    )
  }
}

const connected = connector(RealDataElementDetailsDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
