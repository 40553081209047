import hotkeys from 'hotkeys-js'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Button from '@/react/components/Button/index'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { FixedButton } from '@/react/dialogs/DialogStyles'
import { Form, List, Text, TextBody } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import * as MatrixActions from '@/store/matrix/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from '../Logic'

const T = 'projectMatrixDialog.stopSimulationDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
}), {
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string
  loading: boolean
}

export class StopSimulationDialog extends Component<Props, State> {
  @Identifiable('StopSimulationDialog') public static readonly NAME: string

  public override state: State = {
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    hotkeys('Enter', this.handleStopSelected)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleStopSelected)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(StopSimulationDialog.NAME)
  }

  private readonly handleStopSelected = () => {
    const { selections, setSelections } = this.props
    const selectedSimulationCases = this.getSelectedRunningSimulationCases()
    const ids = selectedSimulationCases.map(({ simulationCase }) => simulationCase.id)

    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })
    setSelections(deselect)

    Logic.handleStopSelected(ids)
    this.handleClose()
  }

  private readonly getSelectedRunningSimulationCases = () => {
    const { currentProject, selections } = this.props

    return currentProject
      .simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => (
        selections[simulationCase.id] &&
        simulationCase.simulationStartedAt &&
        !simulationCase.simulationDataReceivedAt
      ))
  }
  
  public override render () {
    const { error, loading } = this.state
    const { t } = this.props

    const selectedSimulationCases = this.getSelectedRunningSimulationCases()

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-display1'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${T}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                selectedSimulationCases.map(({ num, simulationCase }) => (
                  <li key={simulationCase.id}>{num}. {simulationCase.name}</li>
                ))
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleStopSelected}
              error={error}
              loading={loading}
              icon='pe-7s-display1'
              isRef
            >
              {t(`${T}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(StopSimulationDialog as any) as any) as any
