import { StrandSides } from '@/types/elements/enum'

export const PARENT: Record<CasterElementNames, 'Roller' | 'Segment' | 'SegmentGroup' | null | undefined> = {
  SegmentGroup: null,
  Segment: 'SegmentGroup',
  SegmentGroupSupportPoints: 'SegmentGroup',
  SupportPoint: 'SegmentGroup',
  Nozzle: 'Segment',
  Roller: 'Segment',
  RollerBody: 'Roller',
  RollerBearing: 'Roller',
  AirLoop: null,
  CoolingLoop: null,
  CoolingZone: null,
  LoopAssignment: null,
  SensorPoint: null,
  DataPoint: null,
  DataLine: null,
  StrandGuide: null,
}

export const CHILDREN: Record<CasterElementNames, string[]> = {
  SegmentGroup: [ 'Segment', 'SupportPoint' ],
  Segment: [ 'Nozzle', 'Roller' ],
  SegmentGroupSupportPoints: [],
  SupportPoint: [],
  Nozzle: [],
  Roller: [ 'RollerBody', 'RollerBearing' ],
  RollerBody: [],
  RollerBearing: [],
  SensorPoint: [],
  DataPoint: [],
  DataLine: [],
  AirLoop: [],
  CoolingLoop: [],
  CoolingZone: [],
  LoopAssignment: [],
  StrandGuide: [],
}

export const SIDES = [
  StrandSides.Fixed,
  StrandSides.Loose,
  StrandSides.Left,
  StrandSides.Right,
]
