export default class CalculationUtil {
  public static calcPlHeight (passlineSections: Record<number, PasslineSection>) {
    // return the biggest passlineCoord
    return Object.values(passlineSections).reduce(
      (acc, cur) => Math.max(acc, cur.passlineCoord ?? 0),
      0,
    )
  }

  public static calcMaxPosition (min: number, top: number, bottom: number, height: number, viewportHeight: number) {
    return (min + (viewportHeight - top - bottom) - height)
  }

  public static calcButtonYpos (min: number, max: number, scrollValue: number) {
    return (scrollValue * (max - min) + min)
  }

  public static calcUpdatedButtonPos (
    posX: number,
    posY: number,
    bWidth: number,
    bHeight: number,
    dirX: number,
    dirY: number,
    x: number,
    y: number,
  ) {
    return {
      x: posX * dirX + (bWidth / 2 + x) * -dirX,
      y: posY * dirY + (bHeight / 2 + y) * -dirY,
      z: 0,
    }
  }

  public static calcNewScrollValue (min: number, max: number, y: number) {
    return ((y - min) / (max - min))
  }

  public static calcTargetHeight (newScrollValue: number, plHeight: number) {
    return (((newScrollValue * -plHeight) + plHeight) / 1000)
  }
}
