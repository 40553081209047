import hotkeys from 'hotkeys-js'

import { CurrentDashboard, ViewsObject } from '@/types/visualization'

export function initHotkeys () {
  hotkeys.filter = (event) => {
    const element = (event.target ?? event.srcElement) as HTMLElement
    const tagName = element?.tagName ?? ''

    hotkeys.setScope(/^(TEXTAREA|SELECT)$/.test(tagName) ? 'input' : 'other')

    return !(
      /^(TEXTAREA|SELECT)$/.test(tagName) ||
      element?.className.includes('MuiMenuItem-root') ||
      element?.className.includes('MuiSelect-root')
    )
  }
}

export function isConsistencyCheckURL () {
  if (global.location.hash) {
    const hash = global.location.hash.slice(1)

    if (!hash.includes('=')) {
      return
    }

    const [ param, simulationCaseId ] = hash.split('=')

    if (param !== 'consistency_check' || !simulationCaseId || simulationCaseId.length !== 24) {
      return
    }

    return simulationCaseId
  }
}

export const mapAllTypesToPaths = (filteredElements: Record<string, { path: string, type: string }[]>) => {
  const paths: string[] = []

  Object.values(filteredElements ?? {}).forEach((elementType: any) => {
    elementType.forEach((element: any) => {
      paths.push(element.path)
    })
  })

  return paths
}

export const getCurrentDashboardEntry = (currentDashboard: CurrentDashboard, viewsObject: ViewsObject) => {
  const viewId = Object.keys(currentDashboard).find(viewId => viewsObject[viewId])

  return {
    viewId,
    dashboardId: currentDashboard[viewId as string] ?? null,
  }
}
