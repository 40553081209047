export enum airLoopActionsEnum {
  ACTION_ADD_AIR_LOOP = 'AirLoop/ACTION_ADD_AIR_LOOP',
  DELETE = 'AirLoop/DELETE',
  DELETE_MULTIPLE = 'AirLoop/DELETE_MULTIPLE',
  UPDATE = 'AirLoop/UPDATE',
  RESET = 'AirLoop/RESET',
}

export function addAirLoops (airLoopHash: Record<number, AirLoop>) {
  return {
    type: airLoopActionsEnum.ACTION_ADD_AIR_LOOP,
    airLoopHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const airLoopReducers: Record<string, Function | undefined> = {
  [airLoopActionsEnum.ACTION_ADD_AIR_LOOP]: (_state: any, action: { airLoopHash: any }) => action.airLoopHash,

  [airLoopActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [airLoopActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [airLoopActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),

  [airLoopActionsEnum.RESET]: () => ({}),
}

export default function (state: any = {}, action: any) {
  const handler = airLoopReducers[action.type]

  return handler ? handler(state, action) : state
}
