import * as THREE from 'three'
import { Vector3 } from 'three'

import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSegmentGroup from './SegmentGroup'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  supportPoint: THREE.Mesh
  supportPointInner: THREE.Mesh
  supportPointLine: THREE.Line
}

export default class ThreeSupportPoint extends BaseObject<
  SupportPoint,
  SupportPointMountLog,
  ThreeSegmentGroup,
  Objects
> {
  private static readonly lineMaterial1 = new THREE.LineBasicMaterial({ color: '#ff9200' })

  private static readonly lineMaterial2 = new THREE.LineBasicMaterial({ color: '#00b8ff' })

  private static readonly selectedLineMaterial = new THREE.LineBasicMaterial({ color: '#7eda41' })

  private static readonly outerCubeMaterial1 = new THREE.MeshStandardMaterial({
    color: '#ff9200',
    transparent: true,
    opacity: 0.75,
  })

  private static readonly outerCubeMaterial2 = new THREE.MeshStandardMaterial({
    color: '#00b8ff',
    transparent: true,
    opacity: 0.75,
  })

  private static readonly selectedOuterCubeMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.75,
  })

  // maybe use two inner cubes with different colors to show actual and propose values separately
  private static readonly innerCubeMaterial1 = new THREE.MeshStandardMaterial({ color: '#ffe500' })

  private static readonly innerCubeMaterial2 = new THREE.MeshStandardMaterial({ color: '#00fbff' })

  private static readonly selectedInnerCubeMaterial = new THREE.MeshStandardMaterial({ color: '#4fda41' })

  private static cubeGeometryCache: Record<string, THREE.BoxGeometry> = {}

  private static readonly lineLength = 0.5

  private static readonly cubeSize = 0.15

  private static readonly innerCubeSize = ThreeSupportPoint.cubeSize * 0.95

  private data?: any

  private sectionDetail?: boolean

  private isSegmentGroupIDEven = false

  private static get3DGeometry (size: number) {
    const geometryKey = String(size)
    let geometry = ThreeSupportPoint.cubeGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.BoxGeometry(size, size, size) // no Buffer!
      geometry.translate(0, size / 2, 0)

      ThreeSupportPoint.cubeGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  public constructor (container: any, parent: any, clickableObjects: any) {
    super(container, parent)

    const outerGeometry = ThreeSupportPoint.get3DGeometry(ThreeSupportPoint.cubeSize)

    this.objects.supportPoint = new THREE.Mesh(
      outerGeometry,
      this.isSegmentGroupIDEven ? ThreeSupportPoint.outerCubeMaterial1 : ThreeSupportPoint.outerCubeMaterial2,
    )
    this.objects.supportPoint.userData.type = 'SupportPoint'

    const innerGeometry = ThreeSupportPoint.get3DGeometry(ThreeSupportPoint.innerCubeSize)

    this.objects.supportPointInner = new THREE.Mesh(
      innerGeometry,
      this.isSegmentGroupIDEven ? ThreeSupportPoint.innerCubeMaterial1 : ThreeSupportPoint.innerCubeMaterial2,
    )

    clickableObjects.push(this.objects.supportPoint)
    container.add(this.objects.supportPoint)
    container.add(this.objects.supportPointInner)
  }

  protected override internalSetValues (data: SetValuesData<SupportPoint, SupportPointMountLog>) {
    const { elementData, view } = data

    if (view.sectionDetail) {
      return
    }

    super.internalSetValues(data)

    this.data = elementData
    this.sectionDetail = view.sectionDetail

    const parentInfo = Util.getParentInfo(data.path)

    this.isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    this.updateTransform()
  }

  public updateTransform () {
    const fixedSide = this
      .container
      .children
      .find(({ userData }: any) => userData.type === 'Segment' && userData.side === StrandSides.Fixed)

    const { plMaxCoordByRoller, plMinCoordByRoller } = fixedSide?.userData ?? {}

    if (!plMaxCoordByRoller || !plMinCoordByRoller) {
      return
    }

    // eslint-disable-next-line camelcase
    const { name, shimMin, shimMax, shimActual } = this.data ?? {}

    const plCoord = name?.startsWith('IN') ? plMinCoordByRoller : plMaxCoordByRoller
    const widthCoord = (name?.endsWith('R') ? -1 : 1) * (Mold.width / 2)

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const { supportPoint, supportPointInner } = this.objects

    supportPoint.material = this.isSegmentGroupIDEven
      ? ThreeSupportPoint.outerCubeMaterial1
      : ThreeSupportPoint.outerCubeMaterial2

    supportPoint.userData.path = this.path

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const size = this.sectionDetail ? ThreeSupportPoint.cubeSize : 0

    newSectionRotation.x = -Util.RAD90

    newPosition.set(-position.z + size / 2, position.y + 0.00001, widthCoord)

    const lineStart = newPosition.clone()

    newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(ThreeSupportPoint.lineLength))
    newRotation.set(0, 0, -Util.RAD90 - angleX)

    supportPoint.position.copy(newPosition)
    supportPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    // inner

    supportPointInner.material = this.isSegmentGroupIDEven
      ? ThreeSupportPoint.innerCubeMaterial1
      : ThreeSupportPoint.innerCubeMaterial2

    // TODO: use propose?
    // eslint-disable-next-line camelcase
    const percentage = (shimActual - shimMin) / (shimMax - shimMin)
    const offset = (ThreeSupportPoint.innerCubeSize * (1 - percentage)) +
      ((ThreeSupportPoint.cubeSize - ThreeSupportPoint.innerCubeSize) / 2)

    supportPointInner.scale.set(1, percentage ?? 0.0001, 1)

    const newInnerPosition = newPosition.clone()

    newInnerPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(offset))
    supportPointInner.position.copy(newInnerPosition)
    supportPointInner.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    // line
    const lineGeometry = new THREE.BufferGeometry().setFromPoints([ lineStart, newPosition ])
    const line = new THREE.Line(
      lineGeometry,
      this.isSegmentGroupIDEven ? ThreeSupportPoint.lineMaterial1 : ThreeSupportPoint.lineMaterial2,
    )

    line.name = `SupportPointLine_${name}`

    this.objects.supportPointLine = line

    Util.addOrReplace(this.container, line)
  }

  public override setSelected (isSelected: boolean) {
    this.objects.supportPoint.userData.selected = isSelected

    this.objects.supportPoint.material = isSelected
      ? ThreeSupportPoint.selectedOuterCubeMaterial
      : this.isSegmentGroupIDEven
      ? ThreeSupportPoint.outerCubeMaterial1
      : ThreeSupportPoint.outerCubeMaterial2

    this.objects.supportPointInner.material = isSelected
      ? ThreeSupportPoint.selectedInnerCubeMaterial
      : this.isSegmentGroupIDEven
      ? ThreeSupportPoint.innerCubeMaterial1
      : ThreeSupportPoint.innerCubeMaterial2

    if (!this.objects.supportPointLine) {
      return
    }

    this.objects.supportPointLine.material = isSelected
      ? ThreeSupportPoint.selectedLineMaterial
      : this.isSegmentGroupIDEven
      ? ThreeSupportPoint.lineMaterial1
      : ThreeSupportPoint.lineMaterial2
  }

  public override show () {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true
  }
}
