import cloneDeep from 'lodash/cloneDeep'

import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import type { ElementsHashes } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

type MathKeyMap = {
  [match: string]: string
}

export default class Util {
  public static getRollerChildren (parentRoller: RollerMountLog, elementsHashes: ElementsHashes) {
    const allChildren: string[] = []

    const rollerBodyMountLogsHash = ElementsUtil
      .getRollerBodyMountLogHashByRollerMountLogs(elementsHashes, [ parentRoller ])

    const rollerBearingMountLogsHash = ElementsUtil
      .getRollerBearingMountLogHashByRollerMountLogs(elementsHashes, [ parentRoller ])

    const rollerBodyPaths = Object
      .keys(rollerBodyMountLogsHash)
      .map((mountLogId) => MainView.MountLogIdFullPathMap.RollerBody[mountLogId] ?? '')
      .filter(path => path)

    const rollerBearingPaths = Object
      .keys(rollerBearingMountLogsHash)
      .map((mountLogId) => MainView.MountLogIdFullPathMap.RollerBearing[mountLogId] ?? '')
      .filter(path => path)

    allChildren.push(...rollerBodyPaths)
    allChildren.push(...rollerBearingPaths)

    return allChildren
  }

  private static getDataPointMountLogTypeByPath (path: string) {
    const { type } = ThreeUtil.getParentInfo(path) as { type: string }

    switch (type) {
      case 'Roller':
        return 'RollerDataPointsMountLog'
      case 'RollerBody':
        return 'RollerBodyDataPointsMountLog'
      case 'Segment':
        return 'SegmentDataPointsMountLog'
      case 'Mold':
        return 'MoldFaceDataPointsMountLog'
      default:
        return 'StrandDataPointsMountLog'
    }
  }

  public static getSensorPointMountLogTypeByPath (path: string) {
    const { type } = ThreeUtil.getParentInfo(path)

    switch (type) {
      case 'Roller':
        return 'RollerSensorPointsMountLog'
      case 'RollerBody':
        return 'RollerBodySensorPointsMountLog'
      case 'Segment':
        return 'SegmentSensorPointsMountLog'
      default:
        return ''
    }
  }

  private static mergeElementAndElementMountLog<
    Element extends CasterElementBaseEntity,
    MountLog extends BaseMountLog,
  > (
    path: string,
    elementsHashes: ElementsHashes,
  ) {
    const { type, id: numericId } = ThreeUtil.getElementInfo(path)

    const numericIdMountLogType = `${type}MountLog`
    let mountLogType = ''

    switch (type) {
      case 'DataPoint':
        mountLogType = Util.getDataPointMountLogTypeByPath(path)
        break
      case 'SensorPoint':
        mountLogType = Util.getSensorPointMountLogTypeByPath(path)
        break
      default:
        mountLogType = numericIdMountLogType
        break
    }

    const id = MainView.numericIdMountLogMaps[numericIdMountLogType][numericId]

    if (
      !id ||
      !type ||
      !(elementsHashes as any)[type] ||
      !(elementsHashes as any)[mountLogType] ||
      !(elementsHashes as any)[mountLogType][id]
    ) {
      return {} as FullCasterElement<Element, MountLog>
    }

    // first letter to lower case
    const elementIdKey = `${type[0].toLowerCase() + type.substring(1)}Id`

    const elementMountLog = (elementsHashes as any)[mountLogType][id]
    const element = (elementsHashes as any)[type][elementMountLog[elementIdKey]]

    const fullElement = ElementsUtil.getCompleteFullCasterElement<Element, MountLog>(
      element,
      elementMountLog,
    )

    return {
      ...fullElement,
      id: numericId,
    } as FullCasterElement<Element, MountLog>
  }

  public static getElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    path: string,
    elementsHashes: ElementsHashes,
    copy = false,
  ) {
    const element = Util.mergeElementAndElementMountLog<Element, MountLog>(path, elementsHashes)

    if (copy) {
      return cloneDeep(element)
    }

    return element
  }

  public static getPathFromPathArray (array: Array<any>) {
    const names = array.filter((_, i) => i % 2 === 0)
    const ids = array.filter((_, i) => i % 2 === 1)

    return names.map((name, index) => `${name}:${ids[index]}`).join('/')
  }

  public static getPathArrayFromPath (path: string) {
    return path.split('/').reduce((list: Array<any>, part: string) =>
      (parts => [
        ...list,
        parts[0],
        Number(parts[1]),
      ])(part.split(':')), [])
  }

  private static eventStorage: any = {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static AddDelegatedEventListener (context: string, type: string, selector: string, handler: any) {
    const contextElement = context ? document.querySelector(context) : document

    const func = Util.eventStorage[`${context ?? ''}_${type}_${selector}`] = function (e: any) {
      for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(selector)) {
          handler.call(target, e)

          break
        }
      }
    }

    if (contextElement) {
      contextElement.addEventListener(type, func, false)
    }
  }

  public static RemoveDelegatedEventListener (context: string, type: string, selector: string) {
    const contextElement = context ? document.querySelector(context) : document

    if (contextElement) {
      contextElement.removeEventListener(type, Util.eventStorage[`${context ?? ''}_${type}_${selector}`], false)
    }
  }

  private static readonly mathKeyMap: MathKeyMap = Object.getOwnPropertyNames(Math).reduce(
    (map, key) => ({ ...map, [key.toLowerCase()]: key }),
    {},
  )

  private static readonly mathKeys = new RegExp(`(${Object.keys(Util.mathKeyMap).join('|')})`, 'gi')

  public static prepareFormula (value: string) {
    return value
      .replace(/^\./, '0.')
      .replace(/([^\d]|^)\.(\d)/g, '$10.$2') // .1 => 0.1
      .replace(/(\d)\.([^\d])/g, '$1$2') // 1. => 1
      .replace(/([^\d])\.([^\d])/g, '$1$2') // . =>
      .replace(/ /g, '') // get rid of all spaces
      // .replace(/[^y+\-*/\d.]/g, '') // only keep allowed characters
      .replace(/^[+\-*/]/g, '') // no +-*/ start
      .replace(/(\d*)\.(\d*)([\d.]*)/g, '$1#$2$3') // 5.5.5.5
      .replace(/([^.]\d+)\.([^$])/g, '$1$2') // 5.5.5.5
      .replace(/#/g, '.') // 5.5.5.5
      .replace(/([^\d]|^)0(\d)/g, '$1$2') // 08 => 8
      .replace(/([y+\-*/])\1+/g, '$1') // no duplication
      .replace(/[+\-*/.]([+*/])/g, '$1') // e.g. '+ +' will be replaced
      .replace(/(\))([\dy])/g, '$1*$2') // )8 => )*8
      .replace(/(( |^)[\dy]+)(\()/gi, '$1*$3') // 8( => 8*(
      .replace(/(\))(\()/g, '$1*$2') // )( => )*(
      .replace(/(y)(\d)/g, '$1*$2') // y9 => y*9
      .replace(/(\d)(y)/g, '$1*$2') // 9y => 9*y
      .replace(/([+*\-/])/g, ' $1 ') // spaces around +*-/
      .replace(/([+*/] -) /g, '$1') // keep '-' at the number
      .replace(/(\.\d+)\./, '$1')
      .replace(/,/g, ', ') // comma space
      .replace(Util.mathKeys, match => Util.mathKeyMap[match.toLowerCase()])
      .trim()
  }

  private static compileFormula (dynamicFormula: string) {
    return dynamicFormula ? dynamicFormula.replace(Util.mathKeys, match => `Math.${match}`) : 'y'
  }

  public static testFormula (dynamicFormula: string, error: string) {
    const value = 42
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return `y = ${value}; f(y) = ${value}`
    }

    try {
      // eslint-disable-next-line no-eval
      const result = eval(formula.replace(/y/g, value.toString()))

      if (isNaN(result)) {
        throw new Error('not a number')
      }

      return `y = ${value}; f(y) = ${result}`
    }
    catch (e) {
      return `y = ${value}; f(y) = ${error}`
    }
  }

  public static handleFormula (value: number, dynamicFormula: string) {
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return value
    }

    let result = value

    try {
      // eslint-disable-next-line no-eval
      result = eval(formula.replace(/y/g, value.toString()))
    }
    catch (e) {}

    return result
  }

  public static bindMethods (obj: any, context: any) {
    Object
      .getOwnPropertyNames(Object.getPrototypeOf(obj))
      .forEach(key => {
        if (obj[key] instanceof Function && key !== 'constructor') {
          obj[key] = obj[key].bind(context)
        }
      })
  }

  public static hasElementType (elementType: Record<number | string, any>) {
    return Object.keys(elementType).length > 0
  }

  public static fromSnakeToCamelCase (str: string) {
    return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
  }

  public static getChildType (type: CasterElementNames): CasterElementNames[] {
    switch (type) {
      case 'SegmentGroup':
        return [ 'Segment' ] // TODO: should this contain 'SupportPoint'?
      case 'Segment':
        return [ 'Roller', 'Nozzle', 'SensorPoint' ]
      case 'Roller':
        return [ 'RollerBody', 'RollerBearing', 'SensorPoint' ]
      case 'RollerBody':
        return [ 'SensorPoint' ]
      default:
        return []
    }
  }

  /**
   * @param type The parent type
   * @returns Only returns the child types that are eligible for real data creation
   */
  public static getChildTypeForRealData (type: CasterElementNames): CasterElementNames[] {
    switch (type) {
      case 'SegmentGroup':
        return [ 'SupportPoint', 'Segment' ]
      case 'Segment':
        return [ 'Roller', 'Nozzle' ]
      case 'Roller':
        return [ 'RollerBody', 'RollerBearing' ]
      default:
        return []
    }
  }
}
