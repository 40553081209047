import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { getDefaultCase, updateCase } from '@/api/case'
import { updateProject } from '@/api/project'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import TimeUtil from '@/logic/TimeUtil'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Icon from '@/react/specific/Icon'
import Input from '@/react/specific/Input'
import { Button, Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import DataActions from '@/store/data/actions'
import { resetAllElements } from '@/store/elements/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeManager from '@/three/ThreeManager'
import { DefaultState } from '@/types/state'
import { ArrayOfTranslations, Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'
import SnackbarUtils from '@/Util/SnackbarUtils'

import { Description, Empty, IdLabel, InfoMessage, Label, Loading, Spacer } from './Styles'
import { EditProjectDialog } from '../EditProjectDialog'
import { EditSimulationCaseDialog } from '../EditSimulationCaseDialog'
import { NewProjectDialog } from '../NewProjectDialog'
import { NewSimulationCaseDialog } from '../NewSimulationCaseDialog'
import { ProjectDataDialog } from '../ProjectDataDialog'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  authenticationData: state.application.main.authenticationData,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
}), {
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  resetCurrentProjectCasesMetadata: ApplicationActions.resetCurrentProjectCasesMetadata,
  deleteIdFromCurrentProjectCasesMetadata: ApplicationActions.deleteIdFromCurrentProjectCasesMetadata,
  setCurrentProjectCasesMetadata: ApplicationActions.setCurrentProjectCasesMetadata,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  switchProject: ApplicationActions.switchProject,
  setEditProjectId: ApplicationActions.setEditProjectId,
  setEditSimulationCaseId: ApplicationActions.setEditSimulationCaseId,
  setAppState: ApplicationActions.setAppState,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  resetReducer: DataActions.resetReducer,
  setError: ErrorActions.setError,
  saveCatalog: DataActions.saveCatalog,
  resetAllElements,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  t: ArrayOfTranslations & Translation
}

type State = {
  projectId: string | null | undefined
  caseId: string | null | undefined
  projects: Project[]
  cases: SimulationCase[]
  loading: boolean
  error: string
  defaultCase: SimulationCase | null
}

const T = 'OpenProjectDialog'

export class OpenProjectDialog extends Component<Props, State> {
  @Identifiable('OpenProjectDialog') public static readonly NAME: string

  public override state: State = {
    projectId: null,
    caseId: null,
    projects: [],
    cases: [],
    loading: false,
    error: '',
    defaultCase: null,
  }

  public override async componentDidMount () {
    const { currentProject, currentSimulationCase } = this.props

    this.setState({ projectId: currentProject?.id, caseId: currentSimulationCase?.id })

    this.handleUpdateList(currentProject?.id)

    const defaultCase = await getDefaultCase()

    this.setState({ defaultCase })

    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleUpdateList = (forceProjectId?: string) => {
    const { openDialog, currentSimulationCase } = this.props

    this.setState({ loading: true })

    ApiClient
      .get(`${useConfig().apiBaseURL}/projects`, { params: { withCases: true } })
      .then(async (projects: Project[]) => {
        if (projects.length > 0) {
          const projectIdInList = Boolean(projects.find(({ id }: { id: string }) => id === forceProjectId))
          const orderedProjects = projects
            .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
          const projectId = forceProjectId || projectIdInList ? this.state.projectId : orderedProjects[0].id

          const cases = await this.handleUpdateSimulationCases(projectId ?? '')
          const currentCaseInCases = cases?.find(({ id }: { id: string }) => id === currentSimulationCase?.id)

          this.setState({
            projects: orderedProjects,
            loading: false,
            projectId,
            caseId: currentCaseInCases ? currentSimulationCase.id : cases?.length ? cases[0].id : null,
          })
        }
        else {
          this.setState({ loading: false })
          openDialog(NewProjectDialog.NAME)
        }
      })
      .catch(({ status }) => {
        this.setState({
          loading: false,
          error: status,
        })
      })
  }

  private readonly handleUpdateSimulationCases = async (projectId: string) => {
    this.setState({ loading: true })

    try {
      const { cases } = await ApiClient
        .get(`${useConfig().apiBaseURL}/cases`, { params: { projectId } }) as { cases: SimulationCase[] }

      const orderedCases = cases.sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))

      if (!orderedCases || !orderedCases.length) {
        this.setState({ loading: false })

        return
      }

      this.setState({ cases: orderedCases, loading: false })

      return orderedCases
    }
    catch ({ status }: any) {
      this.setState({
        loading: false,
        error: status as string,
      })
    }
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(OpenProjectDialog.NAME)
  }

  private readonly handleCreateDialog = () => {
    const { openDialog } = this.props

    openDialog(NewProjectDialog.NAME)
  }

  private readonly handleCreateSimulationCaseDialog = () => {
    const { projects, projectId } = this.state
    const { openDialog, setCurrentProject } = this.props

    const project = projects.find(p => p.id === projectId)

    setCurrentProject(project)

    openDialog(NewSimulationCaseDialog.NAME)
  }

  private readonly handleChange = (event: any) => {
    if (event.target.value === 'add') {
      this.handleCreateDialog()

      return
    }

    if (!event.target.value) {
      return
    }

    this.setState({
      projectId: event.target.value,
      caseId: null,
    })

    this.handleUpdateSimulationCases(event.target.value)
  }

  private readonly handleChangeSimulationCase = (event: any) => {
    if (event.target.value === 'add') {
      this.handleCreateSimulationCaseDialog()

      return
    }

    this.setState({
      caseId: event.target.value,
    })
  }

  private readonly handleDelete = (type: string, projectId: string) => {
    const {
      enqueueSnackbar,
      currentProject,
      resetReducer,
      resetCurrentProjectCasesMetadata,
      resetAllElements,
    } = this.props

    this.setState({ loading: true })

    ApiClient
      .del(`${useConfig().apiBaseURL}/projects/${projectId}`)
      .then(() => {
        if (projectId === currentProject?.id) {
          resetReducer()
          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
          resetAllElements()
        }

        FilterHandler.filteredElementsByReferencedCase = {}
        resetCurrentProjectCasesMetadata()
        this.handleUpdateList()
      })
      .catch(() => {
        enqueueSnackbar('Error deleting project', { variant: 'error', autoHideDuration: 3000 })
      })
  }

  private readonly handleDeleteCase = (type: string, caseId: string) => {
    const { cases } = this.state
    const {
      currentSimulationCase,
      resetReducer,
      setCurrentSimulationCase,
      enqueueSnackbar,
      deleteIdFromCurrentProjectCasesMetadata,
      resetAllElements,
      currentProjectCasesMetadata,
    } = this.props

    this.setState({ loading: true })

    ApiClient
      .del(`${useConfig().apiBaseURL}/cases/${caseId}`)
      .then(() => {
        const newCases = cases.filter(c => c.id !== caseId)

        const deletedCaseIndex = currentProjectCasesMetadata.findIndex(({ id }) => id === caseId)

        if (deletedCaseIndex !== -1) {
          const caseReference = `C${deletedCaseIndex + 1}@`

          for (const key in FilterHandler.filteredElementsByReferencedCase) {
            if (key.includes(caseReference)) {
              delete FilterHandler.filteredElementsByReferencedCase[key]
            }
          }
        }

        if (currentSimulationCase?.id === caseId) {
          resetReducer()
          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
          resetAllElements()

          if (newCases.length) {
            setCurrentSimulationCase(newCases[0])
          }
        }

        deleteIdFromCurrentProjectCasesMetadata(caseId)
        this.setState({ caseId: null, cases: newCases, loading: false })
      })
      .catch((error) => {
        enqueueSnackbar('Error deleting project', { variant: 'error', autoHideDuration: 3000 })
        // eslint-disable-next-line no-console
        console.log(error)
        this.setState({ loading: false })
      })
  }

  private readonly handleOpen = async (event: any) => {
    event.preventDefault()

    const { projectId, caseId, projects, cases } = this.state
    const {
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentProjectCasesMetadata,
      setCurrentSimulationCase,
      openDialog,
      closeDialog,
      switchProject,
      featureFlags,
      enqueueSnackbar,
    } = this.props

    const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

    if (currentProject?.id === projectId && currentSimulationCase?.id === caseId) {
      closeDialog(OpenProjectDialog.NAME)

      if (!slimVersion) {
        openDialog(ProjectDataDialog.NAME)
      }
      else {
        this.handleLoadCaster()
      }

      return
    }

    const selectedProjectId = projectId ?? projects[0]?.id

    try {
      const simulationCase = caseId
        ? cases.find(({ id }: { id: string }) => id === caseId)
        : cases[0]

      const project = await ApiClient.get(`${useConfig().apiBaseURL}/projects/${selectedProjectId}`)
      const { cases: projectCases } = await ApiClient
        .get(`${useConfig().apiBaseURL}/cases`, { params: { projectId: selectedProjectId } })
      const casesMetadata = projectCases.map(({ id, createdAt }: { id: string, createdAt: string }) => ({
        id,
        createdAt: new Date(createdAt),
      }))

      if (!simulationCase) {
        enqueueSnackbar('Error loading project', { variant: 'error', autoHideDuration: 3000 })

        return
      }

      switchProject()
      setCurrentProject(project)
      setCurrentProjectCasesMetadata(casesMetadata)
      setCurrentSimulationCase(simulationCase, slimVersion)

      if (!simulationCase.currentCasterId) {
        enqueueSnackbar('This case has no caster', { variant: 'error', autoHideDuration: 3000 })

        return
      }

      if (slimVersion) {
        this.handleLoadCaster(simulationCase)
      }
    }
    catch (error) {
      enqueueSnackbar('Error loading project', { variant: 'error', autoHideDuration: 3000 })
      // eslint-disable-next-line no-console
      console.log(error)

      return
    }

    closeDialog(OpenProjectDialog.NAME)

    if (!slimVersion) {
      openDialog(ProjectDataDialog.NAME)
    }
  }

  private readonly handleLoadCaster = (simulationCase?: SimulationCase) => {
    IpcManager.send('loadCurrentCaster', simulationCase, this.state.caseId)
  }

  private readonly handleEditOpen = (key: string) => {
    const { openDialog, setEditProjectId } = this.props

    setEditProjectId(key)
    openDialog(EditProjectDialog.NAME)
  }

  private readonly handleEditOpenSimulationCase = (key: string) => {
    const { openDialog, setEditSimulationCaseId } = this.props

    setEditSimulationCaseId(key)

    openDialog(EditSimulationCaseDialog.NAME)
  }

  private readonly handleSetDefaultCase = async (caseId: string) => {
    const { enqueueSnackbar } = this.props
    const { cases } = this.state

    const selectedCase = cases.find(({ id }: { id: string }) => id === caseId)

    if (!selectedCase) {
      return
    }

    try {
      await ApiClient.patch(`${useConfig().apiBaseURL}/cases/${caseId}/toggle-default`)

      const simulationCasesCopy = [ ...cases ]

      simulationCasesCopy.forEach((simulationCase: SimulationCase) => {
        if (simulationCase.id === caseId) {
          simulationCase.isDefault = !simulationCase.isDefault
        }
        else {
          simulationCase.isDefault = false
        }
      })

      this.setState({ cases: simulationCasesCopy, defaultCase: selectedCase.isDefault ? selectedCase : null })
    }
    catch (error) {
      enqueueSnackbar('Error toggling default', { autoHideDuration: 3000, variant: 'error' })
    }
  }

  private readonly handleCopyProjectId = async () => {
    const { t } = this.props
    const { projects, projectId } = this.state
    const selectedProjectId = projectId ?? projects[0]?.id

    if (!selectedProjectId) {
      SnackbarUtils.error(t(`${T}.message.cannotCopyProjectId`))
    }

    try {
      // this only works in secure contexts (https)
      await navigator.clipboard.writeText(selectedProjectId)

      SnackbarUtils.success(t(`${T}.message.copiedProjectId`))
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error?.message)

      SnackbarUtils.error(t(`${T}.message.cannotCopyProjectId`))
    }
  }

  private readonly handleCopyCaseId = async () => {
    const { t } = this.props
    const { cases, caseId } = this.state
    const selectedCaseId = caseId ?? cases[0]?.id

    if (!selectedCaseId) {
      SnackbarUtils.error(t(`${T}.message.cannotCopyCaseId`))
    }

    try {
      // this only works in secure contexts (https)
      await navigator.clipboard.writeText(selectedCaseId)

      SnackbarUtils.success(t(`${T}.message.copiedCaseId`))
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error?.message)

      SnackbarUtils.error(t(`${T}.message.cannotCopyCaseId`))
    }
  }

  private readonly handleHideUserProject = async (id: string) => {
    const { featureFlags } = this.props
    const { projects } = this.state
    const project = projects.find(({ id: projectId }) => projectId === id)

    if (!FeatureFlags.canHideUser(featureFlags) || !project) {
      return
    }

    await updateProject(id, { hideUser: !project.hideUser })

    project.hideUser = !project.hideUser

    this.setState({ projects })
  }

  private readonly handleHideUserCase = async (id: string) => {
    const { featureFlags } = this.props
    const { cases } = this.state
    const simulationCase = cases.find(({ id: caseId }) => caseId === id)

    if (!FeatureFlags.canHideUser(featureFlags) || !simulationCase) {
      return
    }

    await updateCase(id, { hideUser: !simulationCase.hideUser })

    simulationCase.hideUser = !simulationCase.hideUser

    this.setState({ cases })
  }

  private readonly handleActionClick = async (action: string, id: string) => {
    switch (action) {
      case 'hideUserProject':
        await this.handleHideUserProject(id)
        break
      case 'hideUserCase':
        await this.handleHideUserCase(id)
        break
    }
  }

  private readonly getProjectSelectors = () => {
    const { t, featureFlags } = this.props
    const { projects } = this.state

    const projectsSelectors: SelectorOption[] = [
      ...projects.map(({ name, user, hideUser, createdAt, id }) => {
        let value = String(name)

        if (user?.name) {
          value += ` - ${hideUser ? 'System' : user.name}`
        }

        value += ` (${TimeUtil.getDisplayDate(createdAt)})`

        return {
          value,
          key: id,
        }
      }),
    ]

    if (FeatureFlags.canCreateProject(featureFlags)) {
      projectsSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${T}.select.add`),
        hideActions: true,
      })
    }

    this.markDefaultProject(projectsSelectors)

    return projectsSelectors
  }

  private readonly getCaseSelectors = () => {
    const { t, featureFlags, currentSimulationCase } = this.props
    const { cases } = this.state

    const caseSelectors: SelectorOption[] = [
      ...cases.map(({ name, user, hideUser, createdAt, id, blueprintId }, index) => {
        const value = (
          <span>
            {`C${index + 1}${currentSimulationCase.id === id ? '(R)' : ''} - ${name}`}
            {
              blueprintId
                ? <span className='badge real-data'>{t('label.realData')}</span>
                : <span className='badge blueprint'>{t('label.blueprint')}</span>
            }
            {`${hideUser ? 'System' : user.name} (${TimeUtil.getDisplayDate(createdAt)})`}
          </span>
        )

        return {
          value,
          key: id,
        }
      }),
    ]

    if (FeatureFlags.canCreateCase(featureFlags)) {
      caseSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${T}.selectCase.add`),
        hideActions: true,
      })
    }

    this.markDefaultCase(caseSelectors)

    return caseSelectors
  }

  private markDefaultCase (caseSelectors: Array<any>): void {
    const { defaultCase } = this.state

    caseSelectors?.forEach(caseSelector => {
      if (caseSelector.key === defaultCase?.id) {
        caseSelector.value = <>{caseSelector.value} (default)</>
        caseSelector.isDefault = true
      }
    })
  }

  private markDefaultProject (projectSelectors: SelectorOption[]): void {
    const { defaultCase } = this.state

    projectSelectors?.forEach(projectSelector => {
      if (projectSelector.key === defaultCase?.projectId) {
        projectSelector.value += ' (default)'
      }
    })
  }

  public override render () {
    const { projects, cases, loading, error, projectId, caseId } = this.state
    const { t, currentProject, featureFlags } = this.props
    const selectedProjectId = projectId ?? projects[0]?.id
    const selectedProject = projects.find(project => project.id === selectedProjectId) ?? {} as Project
    const selectedCaseId = caseId ?? cases[0]?.id
    const selectedSimulationCase = cases.find(case_ => case_.id === selectedCaseId) ?? {} as SimulationCase
    const projectsSelectors = this.getProjectSelectors()
    const caseSelectors = this.getCaseSelectors()
    const onSetDefaultCase = FeatureFlags.canSetDefaultCase(featureFlags) ? this.handleSetDefaultCase : undefined

    return (
      <BaseDialog
        id={DialogID.OpenProject.ID}
        title={t('OpenProjectDialog.title')}
        icon='pe-7s-folder'
        header={t('OpenProjectDialog.header')}
        hideCloseButton={!currentProject.id}
        onClose={this.handleClose}
        small
      >
        {
          loading
            ? <Loading type='spin' />
            : error && error.length > 0
              ? <InfoMessage>{t([ `error.${error}`, 'error.default' ])}</InfoMessage>
              : (
                <Form>
                  {
                    projects && projects.length > 0
                      ? (
                        <div>
                          <Input
                            id={DialogID.OpenProject.ProjectSelector}
                            name='project'
                            type='select'
                            label={t(`${T}.select.label`)}
                            title={t(`${T}.select.title`)}
                            value={selectedProjectId}
                            selectors={projectsSelectors}
                            onChange={this.handleChange}
                            onDelete={FeatureFlags.canDeleteProject(featureFlags) && this.handleDelete}
                            onEdit={
                              (
                                FeatureFlags.canEditProjectDescription(featureFlags) ||
                        FeatureFlags.canRenameProject(featureFlags)
                              ) &&
                        this.handleEditOpen
                            }
                            actions={
                              [
                                {
                                  key: 'hideUserProject',
                                  icon: 'eye-slash',
                                  title: t(`${T}.select.hideUser`),
                                  condition: () => FeatureFlags.canHideUser(featureFlags),
                                },
                              ]
                            }
                            onActionClick={this.handleActionClick}
                          />
                          <Spacer $h={17} $br />
                          <div style={{ display: 'flex' }}>
                            <Label style={{ flex: '0 0 auto' }}>
                              {t(`${T}.description.label`)}
                            </Label>
                            <IdLabel>
                              (<span className='id'>{selectedProjectId}</span>&nbsp;
                              <Icon icon='copy' onClick={this.handleCopyProjectId} />)
                            </IdLabel>
                          </div>
                          <Spacer $h={10} $br />
                          <Description>
                            {selectedProject.description}
                          </Description>
                        </div>
                      )
                      : <Empty>{t('OpenProjectDialog.empty')}</Empty>
                  }
                  {
                    cases && cases.length > 0
                      ? (
                        <div>
                          <Input
                            name='simulationCase'
                            type='select'
                            label={t(`${T}.selectCase.label`)}
                            title={t(`${T}.selectCase.title`)}
                            value={selectedCaseId}
                            selectors={caseSelectors}
                            onChange={this.handleChangeSimulationCase}
                            onDelete={FeatureFlags.canDeleteCase(featureFlags) && this.handleDeleteCase}
                            onEdit={
                              (
                                FeatureFlags.canEditCaseDescription(featureFlags) ||
                        FeatureFlags.canRenameCase(featureFlags)
                              ) &&
                        this.handleEditOpenSimulationCase
                            }
                            onSetDefault={onSetDefaultCase}
                            actions={
                              [
                                {
                                  key: 'hideUserCase',
                                  icon: 'eye-slash',
                                  title: t(`${T}.select.hideUser`),
                                  condition: () => FeatureFlags.canHideUser(featureFlags),
                                },
                              ]
                            }
                            onActionClick={this.handleActionClick}
                          />
                          <Spacer $h={17} $br />
                          <div style={{ display: 'flex' }}>
                            <Label style={{ flex: '0 0 auto' }}>
                              {t(`${T}.descriptionCase.label`)}
                            </Label>
                            <IdLabel>
                              (<span className='id'>{selectedCaseId}</span>&nbsp;
                              <Icon icon='copy' onClick={this.handleCopyCaseId} />)
                            </IdLabel>
                          </div>
                          <Spacer $h={10} $br />
                          <Description>
                            {selectedSimulationCase.description}
                          </Description>
                        </div>
                      )
                      : (
                        <div>
                          <Empty>{t('OpenProjectDialog.emptyCases')}</Empty>
                          <Button
                            value=''
                            onClick={this.handleCreateSimulationCaseDialog}
                            title={t('newSimulationCaseDialog.title')}
                          >
                            {t('newSimulationCaseDialog.title')}
                          </Button>
                        </div>
                      )
                  }
                  <div>
                    {
                      projects && projects.length > 0 && (
                        <Button
                          id={DialogID.OpenProject.OpenButton}
                          value=''
                          onClick={this.handleOpen}
                          title={t('OpenProjectDialog.open')}
                          disabled={!selectedProjectId || !selectedCaseId}
                        >
                          {t('OpenProjectDialog.open')}
                        </Button>
                      )
                    }
                  </div>
                </Form>
              )
        }
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(OpenProjectDialog as any) as any) as any) as any
