// TODO: fix types
export enum StrandDataPointsMountLogActionsEnum {
  ACTION_ADD_STRAND_DATA_POINTS_MOUNT_LOG = 'StrandDataPointsMountLog/ACTION_ADD_STRAND_DATA_POINTS_MOUNT_LOG',
  DELETE = 'StrandDataPointsMountLog/DELETE',
  DELETE_MULTIPLE = 'StrandDataPointsMountLog/DELETE_MULTIPLE',
  UPDATE = 'StrandDataPointsMountLog/UPDATE',
}

export function addStrandDataPointsMountLog (dataLineHash: Record<number, StrandDataPointsMountLog>) {
  return {
    type: StrandDataPointsMountLogActionsEnum.ACTION_ADD_STRAND_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StrandDataPointsMountLogReducers: Record<string, Function | undefined> = {
  [StrandDataPointsMountLogActionsEnum.ACTION_ADD_STRAND_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [StrandDataPointsMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [StrandDataPointsMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [StrandDataPointsMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = StrandDataPointsMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
