import React, { Component, ReactNode } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { getVisualizationConfig } from '@/api/visualization-config'
import Icon from '@/react/specific/Icon'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

import FeatureFlags from './FeatureFlags'
import TabPage from './specific/Tabs/TabPage'
import TabView from './specific/Tabs/TabView'
import SimpleDashboard from './visualization/dashboard/SimpleDashboard'

const IconWrapper = styled.div`
  padding-left: 5px;
`

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  currentProject: state.application.main.currentProject,
  openDialogs: state.application.main.openDialogs,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  viewsObject: state.visualization.viewsObject,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setDashboardObject: VisualizationActions.setDashboardObject,
  setCurrentDashboard: VisualizationActions.setCurrentDashboard,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  defaultContent: ReactNode
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  loading: boolean
}

export class CasterTree extends Component<Props, State> {
  public override state: State = {
    loading: false,
  }

  public override componentDidUpdate (prevProps: Props) {
    const { currentSimpleDashboardTabIndex, appState } = this.props

    if (prevProps.currentSimpleDashboardTabIndex !== currentSimpleDashboardTabIndex) {
      this.handleTabClick(currentSimpleDashboardTabIndex)
    }

    if (prevProps.appState !== appState && appState === AppState.Caster) {
      this.loadTabs()
    }
  }

  private readonly handleTabClick = (index: number) => {
    const { viewsObject, setCurrentDashboard } = this.props
    const values: Array<any> = Object.values(viewsObject)
    let dashboards = {}

    if (values.length) {
      dashboards = values[0].dashboards ?? {}
    }

    const keys = Object.keys(dashboards)
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]

    if (index !== keys.length + 1) {
      if (index !== 0) {
        setCurrentDashboard(viewId, keys[index - 1])
      }

      return
    }

    this.handleAddDashboard()
  }

  private readonly handleAddDashboard = (storeOnly = false) => {
    const { viewsObject, setDashboardObject, t } = this.props
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]
    const dashboardId = `dashboard_${uuid()}`

    if (!viewId) {
      return
    }

    setDashboardObject(viewId, { name: t('tabs.defaultName') }, dashboardId, storeOnly)
  }

  private readonly loadTabs = async () => {
    const { visualizationMetaInformation, setConfig, setDataSources } = this.props

    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    if (!config) {
      return
    }

    this.setState({ loading: true })

    const visualizationConfig = await getVisualizationConfig(config)

    if (!visualizationConfig) {
      return
    }

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources ?? [])

    this.setState({ loading: false })
  }

  public override render () {
    const { loading } = this.state
    const {
      defaultContent,
      viewsObject,
      featureFlags,
      visualizationMetaInformation,
      appState,
    } = this.props
    const views: Array<any> = Object.values(viewsObject ?? {})
    let dashboards: { [key: string]: any } = {}
    const canAddTab = FeatureFlags.canAddCasterDashboardTab(featureFlags, visualizationMetaInformation, appState)
    const hideTreeViewTab = !FeatureFlags.canViewTreeView(featureFlags)

    if (views.length) {
      dashboards = views[0].dashboards ?? {}
    }

    const keys = Object.keys(dashboards)

    return keys.length > 0
      ? (
        <TabView>
          <TabPage title='T' tooltip='Tree View' hide={hideTreeViewTab}>{defaultContent}</TabPage>
          {
            !loading && keys.map((key, i) => (
              <TabPage key={key} title={String(i + 1)} tooltip={dashboards[key].name}>
                <SimpleDashboard />
              </TabPage>
            ))
          }
          {
            loading &&
            (
              <TabPage
                title={
                  <IconWrapper>
                    <Icon fixedWidth icon='sync-alt' spin />
                  </IconWrapper>
                }
                tooltip='Loading ...'
              />
            )
          }
          <TabPage
            hide={!canAddTab}
            title={<i className='pe-7s-plus' />}
            tooltip='Add Dashboard'
          />
        </TabView>
      )
      : <div>{defaultContent}</div>
  }
}

export default withNamespaces('caster')(connector(CasterTree as any) as any) as any
