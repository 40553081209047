/* eslint-env browser */

import { Button, ButtonGroup, LinearProgress } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import ReactDataSheet from 'react-datasheet'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { getAllCaseVerifications } from '@/api/case-verification'
import { allVerified } from '@/logic/case-verification'
import Icon from '@/react/specific/Icon'
import StyleConfig from '@/react/visualization/dashboard/config/StyleConfig'
import * as ApplicationActions from '@/store/application/main/actions'
import * as MatrixActions from '@/store/matrix/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from './Logic'
import DeleteSelected from './massActions/DeleteSelected'
import Duplicate from './massActions/Duplicate'
import ResetSelected from './massActions/ResetSelected'
import SimulateAll from './massActions/SimulateAll'
import SimulateSelected from './massActions/SimulateSelected'
import StopSelected from './massActions/StopSelected'
import { ButtonBar, Content, I, SelectionInfo, Title, TopBar, Wrapper } from './Styles'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  caseVerifications: state.application.main.caseVerifications,
  darkTheme: state.application.main.darkTheme,
  loadingStatus: state.application.main.loadingStatus,
  selections: state.matrix.selections,
  grid: state.matrix.grid,
  columns: state.matrix.columns,
  resultData: state.matrix.resultData,
  lastScrollTopPosition: state.matrix.lastScrollTopPosition,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setColumns: MatrixActions.setColumns,
  setSelections: MatrixActions.setSelections,
  setGrid: MatrixActions.setGrid,
  updateResultData: MatrixActions.updateResultData,
  setLastScrollPosition: MatrixActions.setLastScrollPosition,
  setCaseVerifications: ApplicationActions.setCaseVerifications,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar
  t: Translation
}

export type State = {
  selectedCells: any
  editorProps?: any
  selectedCellData?: Array<any>
  commands: Array<any>
  updatedCells: any
  simulateLoading: boolean
  simulateError: string
  stopSimulationLoading: boolean
  stopSimulationError: string
  resetSimulationLoading: boolean
  resetSimulationError: string
  openEditor: boolean
}

export class ProjectMatrixDialog extends Component<Props, State> {
  @Identifiable('ProjectMatrixDialog') public static readonly NAME: string

  private static readonly scrollCellHeight = 30

  private readonly setLoadingStatus = () => null // TODO: remove, this is not being used

  private contentRef: HTMLElement | null | undefined

  private scrollTimeout?: NodeJS.Timeout

  public constructor (props: Props) {
    super(props)

    Logic.init(this)
  }

  public override state: State = {
    selectedCells: {},
    editorProps: null,
    selectedCellData: [],
    commands: [],
    updatedCells: {},
    simulateLoading: false,
    simulateError: '',
    stopSimulationLoading: false,
    stopSimulationError: '',
    resetSimulationLoading: false,
    resetSimulationError: '',
    openEditor: false,
  }
  
  public override async componentDidMount () {
    await Logic.prepare()
    Logic.buildColumns()
    Logic.buildGrid()

    document.addEventListener('copy', Logic.handleCopy)
    document.addEventListener('paste', Logic.handlePaste)

    setTimeout(() => {
      ;((this.contentRef ?? {}) as any).scrollTop = this.props.lastScrollTopPosition
    }, 100)

    const { currentProject, setCaseVerifications } = this.props

    setCaseVerifications(await getAllCaseVerifications(currentProject.id) ?? [])
  }
  
  public override componentDidUpdate (prevProps: Props, prevState: State) {
    Logic.buildColumns(prevProps)
    Logic.buildGrid(prevProps, prevState)
  }
  
  public override componentWillUnmount () {
    if (this.contentRef) {
      this.contentRef.removeEventListener('wheel', this.handleWheel)
      this.contentRef.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('keyup', this.handleEditor)
      window.removeEventListener('keydown', this.handleEditor)
      document.removeEventListener('copy', Logic.handleCopy)
      document.removeEventListener('paste', Logic.handlePaste)
    }
  }

  private readonly handleWheel = (event: any) => {
    let element = event.target
    let count = 0

    while (element !== this.contentRef && count < 100) {
      count++

      if (Array.from(element.classList ?? []).includes('allowScroll')) {
        return
      }

      element = element.parentNode
    }

    event.preventDefault()

    if (!this.contentRef) {
      return
    }

    if (event.shiftKey) {
      this.contentRef.scrollLeft += event.deltaY

      return
    }

    if (event.deltaY > 0) {
      this.contentRef.scrollTop += ProjectMatrixDialog.scrollCellHeight
    }
    else if (event.deltaY < 0) {
      this.contentRef.scrollTop -= ProjectMatrixDialog.scrollCellHeight
    }
  }

  private readonly handleScroll = (event: any) => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    if (this.contentRef) {
      this.contentRef.scrollTop = (this.contentRef.scrollTop / ProjectMatrixDialog.scrollCellHeight) *
        ProjectMatrixDialog.scrollCellHeight
    }

    this.scrollTimeout = setTimeout(() => {
      this.props.setLastScrollPosition(event.srcElement.scrollTop)
    }, 1000)
  }

  private readonly handleDataEditor = (editorProps: any) => {
    const { selectedCells, selectedCellData } = this.state

    return Logic.handleDataEditor(editorProps, selectedCells, selectedCellData)
  }

  private readonly handleEditor = (event: any) => {
    this.setState({
      openEditor: event.ctrlKey,
    })
  }

  private readonly bindContent = (ref: any) => {
    if (Boolean(this.contentRef) || !ref) {
      return
    }

    this.contentRef = ref

    if (this.contentRef) {
      this.contentRef.addEventListener('wheel', this.handleWheel)
      this.contentRef.addEventListener('scroll', this.handleScroll)
      window.addEventListener('keyup', this.handleEditor)
      window.addEventListener('keydown', this.handleEditor)
    }
  }
  
  public override render () {
    const {
      simulateLoading,
      stopSimulationLoading,
      resetSimulationLoading,
      editorProps,
      selectedCells,
      selectedCellData,
    } = this.state
    const { currentProject, caseVerifications, grid, selections, loadingStatus, darkTheme, t } = this.props
    const selectionsKeys = Object.keys(selections)
    const selectedCount = selectionsKeys.filter(id => selections[id]).length
    const allSelected = selectedCount === selectionsKeys.length
    const isSelected = selectedCount > 0

    // FIXME: there is no currentProject.simulationCases anymore

    const isSelectedAndNotSimulatedAndVerified = currentProject
      .simulationCases
      .filter(simulationCase =>
        selections[simulationCase.id] &&
        !simulationCase.simulationStartedAt &&
        allVerified(simulationCase.id, caseVerifications, 'default'), // FIXME: get selected catalog
        // eslint-disable-next-line function-paren-newline
      )
      .length > 0
    const isSelectedAndRunning = currentProject
      .simulationCases
      .filter(simulationCase =>
        selections[simulationCase.id] &&
        simulationCase.simulationStartedAt &&
        !simulationCase.simulationDataReceivedAt)
      .length > 0
    const isSelectedAndNotRunning = currentProject
      .simulationCases
      .filter(simulationCase =>
        selections[simulationCase.id] &&
        !(simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt))
      .length > 0
    const isSelectedAndSimulated = currentProject
      .simulationCases
      .filter(simulationCase => selections[simulationCase.id] && simulationCase.simulationDataReceivedAt)
      .length > 0
    const simulationRunning = currentProject
      .simulationCases
      .filter(simulationCase => simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt)
      .length > 0
    const isNotSimulatedAndVerified = currentProject
      .simulationCases
      .filter(simulationCase =>
        !simulationCase.simulationStartedAt &&
        allVerified(simulationCase.id, caseVerifications, 'default'), // FIXME: get selected catalog
        // eslint-disable-next-line function-paren-newline
      )
      .length > 0

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <Wrapper>
          <TopBar>
            <Title>
              {t(`${Logic.T}.title`, { name: currentProject.name })}
            </Title>
            <ButtonBar>
              <SelectionInfo>
                {t(`${Logic.T}.selectionInfo`, { selected: selectedCount, total: grid.length })}
                {
                  (
                    simulationRunning ||
                  simulateLoading ||
                  stopSimulationLoading ||
                  resetSimulationLoading ||
                  loadingStatus
                  ) &&
                    <LinearProgress className='loading' color='primary' variant='query' />
                }
              </SelectionInfo>
              <ButtonGroup color='primary' size='large' variant='contained' aria-label='small contained button group'>
                <Button onClick={Logic.handleConfigure} title={t(`${Logic.T}.button.configure`)}>
                  <Icon icon='cogs' />
                </Button>
                <Duplicate
                  handleLoadingStatus={this.setLoadingStatus} // TODO: this doesnt exist, remove?
                  isSelected={isSelected}
                  contentRef={this.contentRef}
                />
                <DeleteSelected
                  isSelected={isSelected}
                  allSelected={allSelected}
                  isSelectedAndNotRunning={isSelectedAndNotRunning}
                />
                <SimulateSelected
                  isSelected={isSelected}
                  simulateLoading={simulateLoading}
                  simulationRunning={simulationRunning}
                  isSelectedAndNotSimulated={isSelectedAndNotSimulatedAndVerified}
                />
                <SimulateAll
                  simulateLoading={simulateLoading}
                  simulationRunning={simulationRunning}
                  isNotSimulated={isNotSimulatedAndVerified}
                />
                <StopSelected
                  isSelected={isSelected}
                  stopSimulationLoading={stopSimulationLoading}
                  isSelectedAndRunning={isSelectedAndRunning}
                />
                <ResetSelected
                  isSelected={isSelected}
                  resetSimulationLoading={resetSimulationLoading}
                  isSelectedAndSimulated={isSelectedAndSimulated}
                />
              </ButtonGroup>
            </ButtonBar>
            <I className='pe-7s-close' onClick={Logic.handleClose} title={t(`${Logic.T}.button.close`)} />
          </TopBar>
          <Content ref={this.bindContent}>
            <ReactDataSheet
              data={grid}
              sheetRenderer={Logic.sheetRenderer}
              rowRenderer={Logic.rowRenderer}
              valueRenderer={Logic.valueRenderer as any} // TODO: fix type
              // eslint-disable-next-line react/jsx-handler-names
              dataEditor={this.handleDataEditor}
              onSelect={Logic.handleSelect}
              selected={selectedCells as any} // TODO: fix type - is selected cells working pro
            />
            {editorProps && Logic.renderDataEditor(editorProps, selectedCellData)}
          </Content>
        </Wrapper>
      </ThemeProvider>
    )
  }
}

export default withSnackbar(withNamespaces('application')(connector(ProjectMatrixDialog as any) as any) as any) as any
