import { Buffer } from 'buffer'

interface JWTData {
  exp: number
  groups: Array<string>
  iat: number
  isDemoUser: boolean
  sub: string
  username: string
}

export function parseJWT (token: string): JWTData {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
}
