import Menu, { Divider, MenuItem, SubMenu, SubMenuProps } from 'rc-menu'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import 'rc-menu/assets/index.css'
import './rcMenuStyles.css'

import { PlaybackWindow } from '@/react/casterDataServer/PlaybackWindow'
import FeatureFlags from '@/react/FeatureFlags'
import * as ApplicationActions from '@/store/application/main/actions'
import * as DataActions from '@/store/data/actions'
import { getFilterControlDefinitions } from '@/store/filter/actions'
import { DefaultState } from '@/types/state'

import HotKeyHandler from './HotKeyHandler'
import logic from './logic/'
import { Label, MenuBar, Shortcut } from './styles'

type FixedSubMenuProps = SubMenuProps & { id: string }

const FixedSubMenu = (props: FixedSubMenuProps) => <SubMenu {...props} />

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  executableDefinitions: state.data.executableDefinitions,
  filterControlDefinitions: state.filter.filterControlDefinitions,
  openAppDialogs: state.application.main.openAppDialogs,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  authenticationData: state.application.main.authenticationData,
  state,
}), {
  handleMenuAction: ApplicationActions.handleMenuAction,
  getExecutableDefinitions: DataActions.getExecutableDefinitions,
  getFilterControlDefinitions,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  openKeys: string[]
  dontClose: boolean
  alreadyFetchedDefinitions: boolean
  alreadyFetchedFilterControlDefinitions: boolean
}

class MenuBuilder extends Component<Props> {
  public override state: State = {
    openKeys: [],
    dontClose: false,
    alreadyFetchedDefinitions: false,
    alreadyFetchedFilterControlDefinitions: false,
  }
  
  public override componentDidMount (): void {
    const { menu } = logic
    const { appState, executableDefinitions, state, featureFlags } = this.props

    menu(appState, executableDefinitions, featureFlags).forEach((item: any) => {
      const isDisabled = item.disabled !== undefined ? item.disabled(state) : false

      if (!isDisabled) {
        HotKeyHandler.handleShortcuts(true, item, this.handleClick, state)
      }
    })
  }
  
  public override componentDidUpdate (prevProps: Props): void {
    const { menu } = logic
    const { alreadyFetchedDefinitions, alreadyFetchedFilterControlDefinitions } = this.state
    const {
      state,
      appState,
      executableDefinitions,
      featureFlags,
      filterControlDefinitions,
      getExecutableDefinitions,
      getFilterControlDefinitions,
      authenticationData,
    } = this.props

    let refetchExecutablesDefinitions = false
    let refetchFilterControlDefinitions = false

    if (prevProps.executableDefinitions?.length && !this.props.executableDefinitions?.length) {
      refetchExecutablesDefinitions = true
    }

    if (prevProps.filterControlDefinitions?.length && !this.props.filterControlDefinitions?.length) {
      refetchFilterControlDefinitions = true
    }

    if (prevProps.authenticationData.name !== authenticationData.name) {
      refetchExecutablesDefinitions = true
      refetchFilterControlDefinitions = true
    }

    if (
      !executableDefinitions?.length &&
      state.application.main.authenticationData.name &&
      (!alreadyFetchedDefinitions || refetchExecutablesDefinitions)
    ) {
      this.setState({ alreadyFetchedDefinitions: true })
      getExecutableDefinitions()
    }

    if (
      !filterControlDefinitions.length &&
      state.application.main.authenticationData.name &&
      (!alreadyFetchedFilterControlDefinitions || refetchFilterControlDefinitions)
    ) {
      this.setState({ alreadyFetchedFilterControlDefinitions: true })
      getFilterControlDefinitions()
    }

    if (prevProps.appState !== appState) {
      menu(prevProps.appState, executableDefinitions, featureFlags)
        .forEach(item => HotKeyHandler.handleShortcuts(false, item))
    }

    menu(appState, executableDefinitions, featureFlags)
      .forEach((item: any) => {
        const featureFlags = state.application.main.authenticationData.featureFlags
        const isVisible = (item.visible ? item.visible(featureFlags, state) : true) ?? true
        const isDisabled = item.disabled ? item.disabled(state) : false

        if (!isVisible || isDisabled) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          HotKeyHandler.handleShortcuts(false, item, () => {}, state)
        }
        else {
          HotKeyHandler.handleShortcuts(true, item, this.handleClick, state)
        }
      })
  }
  
  public override componentWillUnmount (): void {
    const { menu } = logic
    const { appState, executableDefinitions, featureFlags } = this.props

    menu(appState, executableDefinitions, featureFlags).forEach(item => HotKeyHandler.handleShortcuts(false, item))
  }

  private readonly handleCloseAllKeys = () => {
    this.setState({
      openKeys: [],
      dontClose: false,
    })
  }

  private readonly handleUpdateOpenKeys = (e: { key: string, domEvent: any }) => {
    if (!(this.state.openKeys[0] === e.key)) {
      this.setState({
        openKeys: e.key ? [ e.key ] : [],
      })

      return
    }

    this.setState({
      openKeys: [],
    })
  }

  private readonly handleClick = ({ action, params: rawParams, disabled }: any) => {
    const { handleMenuAction, state } = this.props
    const isDisabled = disabled !== undefined ? disabled(state) : false

    if (isDisabled) {
      return
    }

    const params = typeof rawParams === 'function' ? rawParams(state) : rawParams

    handleMenuAction(action, params)
  }

  private readonly handleBlur = () => {
    setTimeout(() => {
      if (this.state.dontClose) {
        return
      }

      this.setState({
        openKeys: [],
      })
    }, 100)
  }

  private readonly handleMenuItemMouseDown = (item: any) => {
    this.setState({ dontClose: true })
    this.handleClick(item)
    this.handleCloseAllKeys()
  }
  
  private getSubMenu (item: any, index: any) {
    const { t, state } = this.props
    const { id, label, type, shortcut, disabled, submenu, visible } = item
    const isDisabled = disabled !== undefined ? disabled(state) : false
    const featureFlags = FeatureFlags.getRealFeatureFlags(state)
    const isVisible = visible !== undefined ? visible(featureFlags, state) : true

    if (!isVisible) {
      return
    }

    if (type === 'separator') {
      return <Divider key={index} />
    }

    if (submenu) {
      return (
        // to disable tooltip in submenus the title has to be an html element
        // https://github.com/ant-design/ant-design/issues/12277
        <FixedSubMenu
          id={id}
          key={index}
          title={<span>{label(t, state)}</span>}
          onTitleClick={this.handleUpdateOpenKeys}
          disabled={isDisabled}
        >
          {submenu.map((subMenu: any, childIndex: number) => this.getSubMenu(subMenu, `${index}-${childIndex}`))}
        </FixedSubMenu>
      )
    }

    return (
      <MenuItem
        id={id}
        key={index}
        disabled={isDisabled}
        onMouseDown={() => this.handleMenuItemMouseDown(item)}
      >
        <Label>{label(t, state)}</Label>
        <Shortcut $disabled={isDisabled}>{shortcut}</Shortcut>
      </MenuItem>
    )
  }
  
  public override render () {
    const { appState, executableDefinitions, openAppDialogs, featureFlags } = this.props
    const { openKeys } = this.state
    const { menu } = logic
    const allowedDialogs = [ PlaybackWindow.NAME ]
    const filteredOpenAppDialogs = openAppDialogs.filter(dialog => !allowedDialogs.includes(dialog))
    const props: any = filteredOpenAppDialogs.length ? { openKeys: [] } : { openKeys }

    // to open submenus with clicks instead of hovering
    props.triggerSubMenuAction = 'click'

    return (
      <MenuBar>
        <Menu mode='horizontal' {...props} onBlur={this.handleBlur}>
          {menu(appState, executableDefinitions, featureFlags).map((item, index) => this.getSubMenu(item, index))}
        </Menu>
      </MenuBar>
    )
  }
}

export default withNamespaces('titlebar')(connector(MenuBuilder as any) as any) as any
