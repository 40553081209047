import Menu, { Divider, MenuItem, SubMenu } from 'rc-menu'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import * as Actions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'

import logic from './MenuBuilder/logic'
import 'rc-menu/assets/index.css'
import './MenuBuilder/rcMenuStyles.css'
import { Label, Shortcut } from './MenuBuilder/styles'

export const I = styled.i`
  font-size: 22px;
  font-weight: 600;
  vertical-align: middle;
`

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  state,
}), {
  handleMenuAction: Actions.handleMenuAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

class ApplicationActions extends Component<Props> {
  private readonly handleClick = (event: any, { action, params: rawParams, disabled }: any, hide = false) => {
    if (action === undefined || !action) {
      return
    }

    const { handleMenuAction, state } = this.props
    const isDisabled = disabled !== undefined ? disabled(state) : false

    if (isDisabled) {
      return
    }

    if (hide) {
      const element = document.getElementById(`${event.key[0]}$Menu`)

      if (element) {
        element.classList.add('rc-menu-hidden')
      }
    }

    const params = typeof rawParams === 'function' ? rawParams(state) : rawParams

    handleMenuAction(action, params)
  }

  private readonly getSubMenu = (item: any, index: any) => {
    const { t, state, featureFlags } = this.props
    const { label, type, shortcut, disabled, submenu, visible } = item
    const isDisabled = disabled !== undefined ? disabled(state) : false
    const isVisible = visible !== undefined ? visible(featureFlags, state) : true

    const currentLabel = typeof label === 'function' ? label(t, state) : <I className={label} />

    if (!isVisible) {
      return
    }

    if (type === 'separator') {
      return <Divider key={index} />
    }

    if (submenu) {
      return (
        <SubMenu title={currentLabel} key={index}>
          {submenu.map((subMenu: any, childIndex: number) => this.getSubMenu(subMenu, `${index}-${childIndex}`))}
        </SubMenu>
      )
    }

    return (
      <MenuItem
        key={index}
        title={shortcut}
        disabled={isDisabled}
        onClick={(event: any) => this.handleClick(event, item)}
      >
        <Label>{currentLabel}</Label>
        {shortcut && <Shortcut $disabled={isDisabled}>{shortcut}</Shortcut>}
      </MenuItem>
    )
  }
  
  public override render () {
    const { getApplicationMenu } = logic

    return (
      <div style={{ minWidth: '84px' }}>
        <Menu mode='horizontal'>
          {getApplicationMenu().map((item, index) => this.getSubMenu(item, index))}
        </Menu>
      </div>
    )
  }
}

export default withNamespaces('titlebar')(connector(ApplicationActions))
