import { DEFINITION, DefinitionKey } from '@/store/type/consts'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'

export function handleDirtyDeletePaths (dirtyDeletePaths: string[], paths: string[]) {
  // remove path from list if already in list
  // add to list otherwise
  paths.forEach((path) => {
    const index = dirtyDeletePaths.indexOf(path)

    if (~index) {
      dirtyDeletePaths.splice(index, 1)
    }
    else {
      dirtyDeletePaths.push(path)
    }
  })
}

export function handleUndoChanges (editElements: any, action: any, editElementsInitial: any) {
  for (const path in editElements) {
    if (path.includes(action.elementType)) {
      if (action.key) {
        editElements[path][action.key] = editElementsInitial[path][action.key]
      }
      else {
        editElements[path] = { ...editElementsInitial[path] }
      }
    }
  }
}

export function _shouldBeArray (type: string): boolean {
  switch (type) {
    case 'CoolingZone':
    case 'SegmentGroup':
    case 'Segment':
    case 'SupportPoint':
    case 'Nozzle':
    case 'Roller':
    case 'RollerBody':
    case 'RollerBearing':
      return true
    default:
      return false
  }
}

export function updateSetWithSelectedElement (
  selectedPaths: Set<string>,
  element: string,
  massSelect: boolean,
  multiSelect: boolean,
) {
  const found = selectedPaths.has(element)
  const length = selectedPaths.size

  if (massSelect) {
    if (found) {
      selectedPaths.clear()

      selectedPaths.add(element)
    }
    else {
      if (length < 1) {
        selectedPaths.add(element)
      }
      else {
        const { done, value } = selectedPaths.values().next()
        const firstElement = !done ? value : ''

        const cutFirst = firstElement.length - firstElement.lastIndexOf(':') - 1 // TODO: does this work with ids?
        const cutLast = element.length - element.lastIndexOf(':') - 1

        const first = Number(firstElement.substr(firstElement.length - cutFirst))
        const last = Number(element.substr(element.length - cutLast))

        const min = Math.min(last, first)
        const max = Math.max(last, first)

        for (let i = min; i <= max; i++) {
          selectedPaths.add(element.slice(0, -cutLast) + i)
        }
      }
    }
  }
  else {
    if (!multiSelect) {
      selectedPaths.clear()

      if (length > 1 || !found) {
        selectedPaths.add(element)
      }
    }
    else {
      if (found) {
        selectedPaths.delete(element)
      }
      else {
        selectedPaths.add(element)
      }
    }
  }
}

export function addPathsIfNotAlreadyInList (list: string[], paths: string[]) {
  paths.forEach(path => {
    if (!list.includes(path)) {
      list.push(path)
    }
  })
}

export function updateSetWithSelectedData (
  selectedPaths: Set<string>,
  selectedData: string[],
  massSelect = false,
  multiSelect = false,
) {
  selectedData.forEach(element => {
    updateSetWithSelectedElement(selectedPaths, element, massSelect, multiSelect)
  })
}

export function ensureActionPathIsArray (action: any): string[] {
  return action.path instanceof Array ? action.path : [ action.path ]
}

export function setEditValuesForEachType (stateEditValues: any, action: any, types: DefinitionKey[]) {
  let editValues = {
    ...stateEditValues,
  }

  types.forEach(type => {
    Object.keys(DEFINITION[type].fields).forEach(field => {
      editValues = {
        ...editValues,
        [type]: {
          ...editValues[type],
          ...action.editValues[type], // TODO: this may override [field] from a previous iteration of the inner loop
          [field]: action.editValues[type][field],
        },
      }
    })
  })

  return editValues
}

export function getMountLogIdByFullPath (fullPath: string) {
  const { type } = ThreeUtil.getElementInfo(fullPath)
  const [ key ] = Object
    .entries((MainView.MountLogIdFullPathMap as any)[type])
    .find(([ , value ]) => value === fullPath) ?? [ null, null ]

  return key
}

// TODO: the first string should be CasterElementNames but this would require us to add all keys of CasterElementNames
const ElementTypePathMap: Readonly<Record<string, string>> = {
  CoolingZone: 'cooling-zones',
  Nozzle: 'nozzles',
  Roller: 'rollers',
  RollerBearing: 'roller-bearings',
  RollerBody: 'roller-bodies',
  Segment: 'segments',
  SegmentGroup: 'segment-groups',
  SupportPoint: 'support-points',
  StrandGuide: 'strand-guides',
  SensorPoint: 'sensor-points',
  SegmentGroupSupportPoints: 'support-points',
  DataLine: 'data-lines',
  DataPoint: 'data-points',
}

export function getElementTypeURL (elementType: CasterElementNames) {
  if (!ElementTypePathMap[elementType]) {
    throw new Error(`Invalid element type: ${elementType}`)
  }

  return ElementTypePathMap[elementType]
}

export const ParentMountLogMap: Readonly<Record<string, { key: string, mountLogType: string, type: string }>> = {
  Roller: { key: 'segmentMountLogId', mountLogType: 'SegmentMountLog', type: 'Segment' },
  RollerBody: { key: 'rollerMountLogId', mountLogType: 'RollerMountLog', type: 'Roller' },
  RollerBearing: { key: 'rollerMountLogId', mountLogType: 'RollerMountLog', type: 'Roller' },
  Nozzle: { key: 'segmentMountLogId', mountLogType: 'SegmentMountLog', type: 'Segment' },
  SupportPoint: { key: 'segmentGroupMountLogId', mountLogType: 'SegmentGroupMountLog', type: 'SegmentGroup' },
}

export function handleNewMountLog (
  newElementsHashes: ElementsHashes,
  type: CasterElementNames,
  mountLogType: Exclude<NumericIdMountLogMapsTypes, 'DataPointMountLog' | 'SensorPointMountLog'>,
  newMountLog: ElementsHashes[typeof mountLogType][string],
  oldId?: string,
) {
  const oldMountLog = oldId ? newElementsHashes[mountLogType][oldId] : null

  newElementsHashes[mountLogType][newMountLog.id] = newMountLog

  if (oldMountLog && oldId) {
    delete newElementsHashes[mountLogType][oldId]
  }

  const parentMountLogKey = ParentMountLogMap[type]?.key
  const parentMountLogId = (newMountLog as any)[parentMountLogKey] as string
  // eslint-disable-next-line max-len
  const parentMountLog = (newElementsHashes as any)[ParentMountLogMap[type]?.mountLogType][parentMountLogId]

  if (parentMountLog) {
    // first letter to lowercase
    const lowerCasedType = type[0].toLowerCase() + type.substring(1)
    const typeKey = `${lowerCasedType}MountLogs`

    if (!parentMountLog[typeKey]) {
      parentMountLog[typeKey] = []
    }

    parentMountLog[typeKey].push(newMountLog.id)

    if (oldId) {
      // delete the old one
      const oldIndex = parentMountLog[typeKey].findIndex((mountLogId: string) => mountLogId === oldId)

      parentMountLog[typeKey].splice(oldIndex, 1)
    }
  }

  if (MainView.numericIdMountLogMaps[mountLogType]) {
    const numericId = oldId
      ? MainView.numericIdMountLogMaps[mountLogType][oldId]
      // FIXME: this is a workaround because we neither have a separate map for numeric ids nor do we have a max/count
      // eslint-disable-next-line max-len
      : Math.max(...Object.keys(MainView.numericIdMountLogMaps[mountLogType]).filter(Number) as any as number[]) + 1

    if (oldId) {
      delete MainView.numericIdMountLogMaps[mountLogType][oldId]
    }

    const fullPath = oldId
      ? MainView.MountLogIdFullPathMap[type][oldId]
      // FIXME: we need a function for this!
      // eslint-disable-next-line max-len
      : `${
        (MainView.MountLogIdFullPathMap as any)[ParentMountLogMap[type]?.type][parentMountLogId]
      }/${type}:${numericId}`

    if (oldId) {
      delete MainView.MountLogIdFullPathMap[type][oldId]
    }

    MainView.numericIdMountLogMaps[mountLogType][newMountLog.id] = numericId
    MainView.numericIdMountLogMaps[mountLogType][numericId] = newMountLog.id

    MainView.MountLogIdFullPathMap[type][newMountLog.id] = fullPath
  }
}
