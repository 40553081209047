import * as THREE from 'three'

import FeatureFlags from '@/react/FeatureFlags'
import MainView from '@/three/views/MainView'
import { StrandSides } from '@/types/elements/enum'
import type { ElementsHashes } from '@/types/state'

import { ElementCache, ElementCacheKey } from '.'
import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSegmentGroup from './SegmentGroup'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  upperSection: THREE.Mesh
  lowerSection: THREE.Mesh
}

export default class ThreeSegment extends BaseObject<Segment, SegmentMountLog, ThreeSegmentGroup, Objects> {
  private static planeGeometryCache: Record<string, THREE.PlaneGeometry> = {}

  private static readonly backgroundMaterial = new THREE.MeshStandardMaterial({ color: '#22282e' })

  private static readonly orangeMaterial = new THREE.MeshStandardMaterial({ color: '#ff7e34' })

  private static readonly orangeShadedMaterial = Util.getShadedMaterial(0xff7e34, 0xffa571)

  private static readonly greenMaterial = new THREE.MeshStandardMaterial({ color: '#008500' })

  private static readonly greenShadedMaterial = Util.getShadedMaterial(0x008500, 0x00be00)

  private static readonly buttonMaterial = new THREE.MeshStandardMaterial({ color: '#474b4e' })

  private static readonly buttonDisabledMaterial = new THREE.MeshStandardMaterial({ color: '#373a3c' })

  private static readonly highlightMaterial = new THREE.MeshStandardMaterial({ color: '#BB1B1B' })

  private static readonly textMaterial = new THREE.MeshBasicMaterial({ color: '#CCCCCC' })

  private static readonly textDisabledMaterial = new THREE.MeshBasicMaterial({ color: '#555555' })

  private static sgRim: any

  private static readonly maxSegmentGroupTextLength = 16

  private static readonly maxSegmentGroupTextLengthShort = 11

  private static readonly sideButtonWidth = 0.25

  private static readonly sideButtonHeight = 0.175

  private static readonly sideButtonFontSize = 0.0235

  private static readonly sideButtonBarHeight = 0.02

  private static readonly sideButtonMarginRight = 0.05

  private static readonly supportPointButtonWidth = 0.25 - 0.025

  private static readonly supportPointButtonHeight = 0.15

  private static readonly supportPointButtonMargin = 0.025

  private static readonly shimMarkerButtonWidth = 0.15

  private static readonly shimMarkerButtonHeight = 0.15

  private static readonly shimMarkerButtonMargin = 0.025

  private static readonly shimMarkerBorderMargin = 0.04

  private static readonly shimMarkerBorder = 0.025

  private static readonly sidesAngleOrder = [
    StrandSides.Right,
    StrandSides.Loose,
    StrandSides.Left,
    StrandSides.Fixed,
  ]

  private static readonly sideAcronyms = [
    'NSR',
    'LS',
    'NSL',
    'FS',
  ]

  private static readonly labelSides = [
    StrandSides.Fixed,
    StrandSides.Loose,
  ]

  private static prevSideLabelHeight = {}

  private static prevSideLabelWidth = {}

  private static readonly prevSideLabelPosition = {}

  private readonly sectionDetail?: boolean

  private readonly clickableObjects?: THREE.Object3D[]

  private readonly canViewSupportPoints: boolean

  private plHeight?: number

  private elementList?: ElementCache

  private sgHasSupportPoints?: boolean

  private shimMarker: boolean | null = null

  private shimApplied: boolean | null = null

  private shimProposeChangesCount = 0

  private supportPointsCount = 0

  private isSelected?: boolean

  private shimCheckboxDisabled = false

  private static readonly sections = {}

  public static reset () {
    ThreeSegment.prevSideLabelHeight = {}
    ThreeSegment.prevSideLabelWidth = {}
  }

  private static getRect (
    name: string,
    x: number,
    y: number,
    z: number,
    width: number,
    height: number,
    material: THREE.Material,
  ) {
    const rect = new THREE.Mesh(ThreeSegment.getPlaneGeometry(width, height), material)

    rect.position.set(x, y, z)
    rect.name = name

    return rect
  }

  private static getButton (text: string, x: number, side: string, width: number, height: number) {
    const group = new THREE.Group()

    const rect = ThreeSegment.getRect(
      `Label_${side}`,
      x,
      -height / 2,
      0.0001,
      width,
      height,
      ThreeSegment.buttonMaterial,
    )

    group.add(rect)

    const content = Util.getText(text, 0.06, true)

    if (content) {
      content.position.set(x, -(height / 2 + 0.03), 0.001)
      content.name = `Text_${side}`
      group.add(content)
    }

    group.position.z = 0.0001
    group.name = 'ButtonGroup'

    return group
  }

  private static getSideButton (sideAcronym: string, side: string, index: number, active: boolean) {
    // TODO: optimize these values and make sure they are available where they are needed
    const xPos = index * (ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight)
    const group = new THREE.Group()

    const button = ThreeSegment.getButton(
      sideAcronym,
      xPos,
      side,
      ThreeSegment.sideButtonWidth,
      ThreeSegment.sideButtonHeight,
    )

    group.add(button)

    if (active) {
      const activeBar = new THREE.Mesh(
        ThreeSegment.getPlaneGeometry(ThreeSegment.sideButtonWidth, ThreeSegment.sideButtonBarHeight),
        ThreeSegment.highlightMaterial,
      )

      activeBar.position.set(
        xPos,
        -(ThreeSegment.sideButtonHeight + ThreeSegment.sideButtonFontSize + (ThreeSegment.sideButtonBarHeight / 2)),
        0.0001,
      )
      activeBar.name = `ActiveBar_${side}`
      group.add(activeBar)
    }
    else {
      const activeBar = new THREE.Mesh(
        ThreeSegment.getPlaneGeometry(ThreeSegment.sideButtonWidth, ThreeSegment.sideButtonBarHeight),
        ThreeSegment.textMaterial,
      )

      activeBar.position.set(
        xPos,
        -(ThreeSegment.sideButtonHeight + ThreeSegment.sideButtonFontSize + (ThreeSegment.sideButtonBarHeight / 2)),
        0.0001,
      )
      activeBar.name = `ActiveBar_${side}`
      group.add(activeBar)
    }

    group.position.z = 0.0001
    group.name = side

    return group
  }

  private static getSections (container: any) {
    if ((ThreeSegment.sections as any)[container.userData.side]) {
      return (ThreeSegment.sections as any)[container.userData.side]
    }

    // segment group label
    const upperSection = new THREE.Group()

    upperSection.name = 'UpperSection'

    const width = (ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight) * 4 -
      ThreeSegment.sideButtonMarginRight
    const rim = 0.1

    ThreeSegment.sgRim = rim

    const backgroundGeometry = ThreeSegment.getPlaneGeometry(width + rim, 0.3)
    const background = new THREE.Mesh(backgroundGeometry, ThreeSegment.backgroundMaterial)

    background.rotateY(Util.RAD180)
    background.name = 'Background'
    background.position.set(-(width + rim) / 2, -(0.3 / 2), 0)

    upperSection.add(background)

    const geometryGroupLabel = ThreeSegment.getPlaneGeometry(width, 0.2)
    const segmentGroupLabel = new THREE.Mesh(geometryGroupLabel, ThreeSegment.buttonMaterial)

    segmentGroupLabel.rotateY(Util.RAD180)
    segmentGroupLabel.name = 'Label'
    segmentGroupLabel.position.set(-width / 2 - (rim / 2), -(0.2 / 2) - (rim / 2), -0.0001)

    const segmentGroup = new THREE.Group()

    segmentGroup.name = 'SegmentGroup'

    segmentGroup.add(segmentGroupLabel)

    upperSection.add(segmentGroup)
    upperSection.name = 'UpperSection'

    // segment labels
    const lowerSection = new THREE.Group()

    lowerSection.name = 'LowerSection'

    // TODO: use variables
    const height = 0.16 + ThreeSegment.sideButtonFontSize + 0.02 + 0.02 + rim

    const background2Geometry = ThreeSegment.getPlaneGeometry(width + rim, height)
    const background2 = new THREE.Mesh(background2Geometry, ThreeSegment.backgroundMaterial)

    background2.rotateY(Util.RAD180)
    background2.name = 'Background'
    background2.position.set(-(width + rim) / 2, -(height / 2 + 0.3), 0)

    lowerSection.add(background2)

    const segments = new THREE.Group()

    segments.name = 'Segments'

    // TODO: use real value
    segments.position.x -= (width - (0.15 + 0.1) * (ThreeSegment.sidesAngleOrder.length - 1) - 0.15) / 2
    segments.position.y -= 0.2 + 0.1

    ThreeSegment.sidesAngleOrder.forEach((side, index) => {
      const sideButton = ThreeSegment.getSideButton(
        ThreeSegment.sideAcronyms[index],
        side,
        index,
        container.userData.side === side,
      )

      sideButton.rotateY(Util.RAD180)
      sideButton.position.set(-(rim / 2), -(rim / 2), -0.0001)

      segments.add(sideButton)
    })

    lowerSection.add(segments)
    ;(ThreeSegment.sections as any)[container.userData.side] = {
      upperSection,
      lowerSection,
    }

    return (ThreeSegment.sections as any)[container.userData.side]
  }

  private static getPlaneGeometry (width: number, height: number) {
    const geometryKey = `${width}_${height}`
    let geometry = ThreeSegment.planeGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.PlaneGeometry(width, height, 1)

      ThreeSegment.planeGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  public constructor (
    container: any,
    parent: any,
    clickableObjects: any,
    sectionDetail: any,
    featureFlags: Record<string, boolean>,
  ) {
    super(container, parent)

    this.sectionDetail = sectionDetail
    this.clickableObjects = clickableObjects
    this.canViewSupportPoints = FeatureFlags.canViewSupportPointsIn3D(featureFlags)

    if (this.sectionDetail) {
      return
    }

    if (!ThreeSegment.labelSides.includes(container.userData.side)) {
      return
    }

    const { upperSection, lowerSection } = ThreeSegment.getSections(container)

    const upper = upperSection.clone()
    const lower = lowerSection.clone()

    const sgLabel = upper.getObjectByName('SegmentGroup').getObjectByName('Label')

    clickableObjects.push(sgLabel)

    const segments = lower.getObjectByName('Segments')

    Util.sides.forEach(side => {
      const button = segments.getObjectByName(side).getObjectByName('ButtonGroup').getObjectByName(`Label_${side}`)

      clickableObjects.push(button)
    })

    container.add(upper)
    container.add(lower)

    this.objects.upperSection = upper
    this.objects.lowerSection = lower
  }

  public static getShimCheckboxDisabled (elementsHashes: ElementsHashes, supportPointsCount: number): boolean {
    if ((supportPointsCount ?? 0) < 4) {
      return true
    }

    // if any shimPropose is different from shimActual, the checkbox should be disabled
    const supportPointMountLogs = Object.values(elementsHashes.SupportPointMountLog)

    return supportPointMountLogs.some((supportPointMountLog) => {
      const supportPoint = elementsHashes.SupportPoint[supportPointMountLog.supportPointId ?? '']

      if (!supportPoint) {
        return false
      }

      const shimPropose = supportPoint.shimPropose !== null ? Number(supportPoint.shimPropose) : null
      const shimActual = supportPoint.shimActual !== null ? Number(supportPoint.shimActual) : null

      return shimPropose !== null && shimPropose !== shimActual
    })
  }

  protected override shouldUpdate (_data: SetValuesData<Segment, SegmentMountLog>) {
    // TODO: try to determine if the segment should be updated

    return true
  }

  protected override internalSetValues (data: SetValuesData<Segment, SegmentMountLog>) {
    super.internalSetValues(data)

    const { elementData, view } = data

    if (this.sectionDetail) {
      return
    }

    if (!ThreeSegment.labelSides.includes(this.container.userData.side)) {
      return
    }

    this.plHeight = view.plHeight
    this.elementList = view.elementList

    const { upperSection, lowerSection } = this.objects

    const parentInfo = Util.getParentInfo(data.path)
    const segmentGroupMountLogUUID = MainView.numericIdMountLogMaps.SegmentGroupMountLog[parentInfo?.id]
    const segmentGroupMountLog = view.elementsHashes.SegmentGroupMountLog[segmentGroupMountLogUUID]

    this.sgHasSupportPoints = this.canViewSupportPoints &&
      Util.wideSides.includes(elementData.side as StrandSide) &&
      Boolean(
        segmentGroupMountLog
          ?.supportPointMountLogs
          ?.filter((id) => view.elementsHashes.SupportPointMountLog[id])
          ?.length ?? 0,
      )

    this.shimMarker = segmentGroupMountLog?.shimMarker ?? null
    this.shimApplied = segmentGroupMountLog?.shimApplied ?? null

    const supportPointMountLogIds = segmentGroupMountLog
      ?.supportPointMountLogs
      // this filter should not be necessary, but it is because for some reason the mountLogs array contains more than
      // the used supportPointMountLogs
      ?.filter((id) => view.elementsHashes.SupportPointMountLog[id]) ?? []

    this.supportPointsCount = supportPointMountLogIds.length

    this.shimProposeChangesCount = supportPointMountLogIds
      .map((id) => view.elementsHashes.SupportPointMountLog[id])
      .map((supportPointMountLog) => view.elementsHashes.SupportPoint[supportPointMountLog.supportPointId ?? ''])
      .reduce((acc, supportPoint) => {
        if (!supportPoint) {
          return 0
        }

        const shimPropose = supportPoint.shimPropose !== null ? Number(supportPoint.shimPropose) : null
        const shimActual = supportPoint.shimActual !== null ? Number(supportPoint.shimActual) : null

        return acc + (shimPropose !== null && shimPropose !== shimActual ? 1 : 0)
      }, 0)

    this.shimCheckboxDisabled = ThreeSegment.getShimCheckboxDisabled(view.elementsHashes, this.supportPointsCount)

    this.updateName(segmentGroupMountLog?.name ?? `Seg-${parentInfo?.id}`)
    this.updateSupportPointButton()

    this.updateTransform()

    const segmentGroup = upperSection.getObjectByName('SegmentGroup')

    const label = segmentGroup?.getObjectByName('Label')

    if (label) {
      label.userData.filter = `${parentInfo?.path}/*`
    }

    const availableSides = this
      .container
      .parent
      ?.children
      .filter(child =>
        child.children.filter(cld => cld.name !== 'UpperSection' && cld.name !== 'LowerSection').length > 0
      )
      .map(child => child.userData.side)

    Util.sides.forEach((side) => {
      const segment = lowerSection?.getObjectByName('Segments')?.getObjectByName(side)
      const button = segment?.getObjectByName(`Label_${side}`) as THREE.Mesh | undefined
      const text = segment?.getObjectByName(`Text_${side}`) as THREE.Mesh | undefined
      const activeBar = segment?.getObjectByName(`ActiveBar_${side}`) as THREE.Mesh | undefined

      if (!segment || !button || !text || !activeBar) {
        return
      }

      if (!availableSides?.includes(side)) {
        button.userData.disabled = true
        button.material = ThreeSegment.buttonDisabledMaterial
        text.material = ThreeSegment.textDisabledMaterial
        activeBar.material = ThreeSegment.textDisabledMaterial
      }
      else {
        button.userData.disabled = false
        button.material = ThreeSegment.buttonMaterial
        text.material = ThreeSegment.textMaterial
        activeBar.material = this.container.userData.side === side
          ? ThreeSegment.highlightMaterial
          : ThreeSegment.textMaterial
      }

      const segmentMountLogId = segmentGroupMountLog.segmentMountLogs.find((segmentMountLogId) => {
        const segmentMountLog = view.elementsHashes.SegmentMountLog[segmentMountLogId]
        const segment = view.elementsHashes.Segment[segmentMountLog?.segmentId ?? '']

        return segment?.side === side
      })

      const numericId = segment ? MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLogId ?? ''] : null

      button.userData.filter = `${parentInfo.path}/Segment:${numericId}/*`
    })
  }

  public updateName (name: string) {
    const { upperSection } = this.objects
    const maxLength = this.sgHasSupportPoints
      ? ThreeSegment.maxSegmentGroupTextLengthShort
      : ThreeSegment.maxSegmentGroupTextLength
    const text = name.length > maxLength ? `${name.slice(0, maxLength - 3)}...` : name
    const newText = Util.getText(text, 0.08, false, true)

    if (!newText) {
      // eslint-disable-next-line no-console
      console.warn(`Could not create text for segment group ${name}`)

      return
    }

    newText.rotateY(Util.RAD180)
    newText.name = 'Text'

    const width = (ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight) * 4 -
      ThreeSegment.sideButtonMarginRight

    const centerShift = this.sgHasSupportPoints
      ? -(
        (ThreeSegment.supportPointButtonWidth / 2 + ThreeSegment.supportPointButtonMargin / 2) +
        (ThreeSegment.shimMarkerButtonWidth / 2 + ThreeSegment.shimMarkerButtonMargin / 2)
      )
      : 0

    newText.position.set(-width / 2 - (ThreeSegment.sgRim / 2) - centerShift, -0.1 - (ThreeSegment.sgRim / 2), -0.0003)

    const segmentGroup = upperSection.getObjectByName('SegmentGroup')

    Util.addOrReplace(segmentGroup, newText)
  }

  public setShimMarker (shimMarker: boolean) {
    this.shimMarker = shimMarker

    this.updateSupportPointButton()
  }

  private getSegmentGroupBackground () {
    const hasProposedChange = this.shimProposeChangesCount > 0

    if (hasProposedChange && !this.shimMarker && !this.shimApplied) {
      return ThreeSegment.greenShadedMaterial
    }

    if (hasProposedChange && !this.shimMarker && this.shimApplied) {
      return ThreeSegment.greenMaterial
    }

    if (this.shimMarker && !this.shimApplied) {
      return ThreeSegment.orangeShadedMaterial
    }

    if (this.shimMarker && this.shimApplied) {
      return ThreeSegment.orangeMaterial
    }

    return ThreeSegment.buttonMaterial
  }

  private updateSegmentGroupBackground () {
    const { upperSection } = this.objects
    const background = upperSection.getObjectByName('Label') as THREE.Mesh | undefined

    if (!background) {
      return
    }

    background.material = this.getSegmentGroupBackground()

    // if shimMarker is set OR no changes were made OR all changes were made no cover is needed
    if (
      this.shimMarker ||
      this.shimProposeChangesCount === 0 ||
      this.shimProposeChangesCount >= this.supportPointsCount - 1
    ) {
      const oldBackgroundCover = upperSection.getObjectByName('BackgroundCover') as THREE.Mesh | undefined

      if (oldBackgroundCover) {
        upperSection.remove(oldBackgroundCover)
      }

      return
    }

    // depending on the count of changed support points, the background needs to be covered
    // e.g. the visible part should be full width * (changes count / support points count)

    const width = (ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight) * 4 -
      ThreeSegment.sideButtonMarginRight
    const coveredWidth = width * (1 - (this.shimProposeChangesCount / (this.supportPointsCount - 1)))

    const backgroundCoverGeometry = ThreeSegment.getPlaneGeometry(coveredWidth, 0.2)
    const backgroundCover = new THREE.Mesh(backgroundCoverGeometry, ThreeSegment.buttonMaterial)
    const rim = ThreeSegment.sgRim

    backgroundCover.rotateY(Util.RAD180)
    backgroundCover.name = 'BackgroundCover'
    backgroundCover.position.set(
      -coveredWidth / 2 - (rim / 2) - (width - coveredWidth),
      -0.2 / 2 - (rim / 2),
      -0.0002,
    )

    Util.addOrReplace(upperSection, backgroundCover)
  }

  private drawCheckBox () {
    if (!this.sgHasSupportPoints) {
      return
    }

    const { upperSection } = this.objects
    const segmentGroup = upperSection.getObjectByName('SegmentGroup')
    const sideButtonOuterWidth = ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight
    const contentWidthSM = sideButtonOuterWidth * 3 - ThreeSegment.sideButtonMarginRight

    const checkMark = ThreeSegment.getButton(
      '',
      0,
      'SegmentGroupShimMarker',
      ThreeSegment.shimMarkerButtonWidth,
      ThreeSegment.shimMarkerButtonHeight,
    )

    checkMark.name = 'CheckMarkGroup'

    const checkMarkRect = checkMark.getObjectByName('Label_SegmentGroupShimMarker') as THREE.Mesh | undefined

    if (checkMarkRect) {
      checkMarkRect.material = ThreeSegment.backgroundMaterial
      checkMarkRect.userData.type = 'SegmentGroupShimMarker'
      checkMarkRect.userData.path = this.parent.path

      // add another rect with textMaterial for the check box border

      const borderWidth = ThreeSegment.shimMarkerButtonWidth - ThreeSegment.shimMarkerBorderMargin
      const borderHeight = ThreeSegment.shimMarkerButtonHeight - ThreeSegment.shimMarkerBorderMargin

      const checkMarkBorder = ThreeSegment.getRect(
        'CheckMarkBorder',
        0,
        0,
        0.0001,
        borderWidth,
        borderHeight,
        this.shimCheckboxDisabled ? ThreeSegment.textDisabledMaterial : ThreeSegment.textMaterial,
      )

      checkMarkRect.add(checkMarkBorder)

      // add another rect with backgroundMaterial for the check box background

      const backgroundWidth = borderWidth - ThreeSegment.shimMarkerBorder
      const backgroundHeight = borderHeight - ThreeSegment.shimMarkerBorder

      const checkMarkBackground = ThreeSegment.getRect(
        'CheckMarkBackground',
        0,
        0,
        0.0005,
        backgroundWidth,
        backgroundHeight,
        ThreeSegment.backgroundMaterial,
      )

      checkMarkRect.add(checkMarkBackground)

      // rotate text so that when checked the "L" becomes a check mark

      const content = Util.getText(
        this.shimMarker ? 'L' : '',
        0.06,
        true,
        false,
        true,
        '#FFFFFF',
        true,
      )

      if (content) {
        content.position.set(-0.0175, -(ThreeSegment.shimMarkerButtonHeight / 2 + 0.015), 0.001)
        content.name = 'Text_SegmentGroupShimMarker'

        content.rotateY(Util.RAD180)
        content.rotateZ(Util.RAD45)

        Util.addOrReplace(checkMark, content)
      }
    }

    this.updateSegmentGroupBackground()

    checkMark.rotateY(Util.RAD180)

    const xSM = ThreeSegment.shimMarkerButtonWidth / 2 +
      ThreeSegment.sgRim / 2 +
      contentWidthSM -
      ThreeSegment.shimMarkerButtonWidth +
      ThreeSegment.shimMarkerButtonMargin
    const ySM = ThreeSegment.sgRim / 2 + ThreeSegment.supportPointButtonMargin

    checkMark.position.set(-xSM, -ySM, -0.0002)

    const oldElementSM = segmentGroup
      ?.getObjectByName('CheckMarkGroup')
      ?.getObjectByName('Label_SegmentGroupShimMarker')

    if (this.clickableObjects) {
      Util.addOrReplaceInList(oldElementSM, checkMarkRect, this.clickableObjects)
    }

    Util.addOrReplace(segmentGroup, checkMark)
  }

  private updateSupportPointButton () {
    if (!this.sgHasSupportPoints) {
      return
    }

    const { upperSection } = this.objects
    const segmentGroup = upperSection.getObjectByName('SegmentGroup')
    const sideButtonOuterWidth = ThreeSegment.sideButtonWidth + ThreeSegment.sideButtonMarginRight

    // shimMarker Check Mark

    this.drawCheckBox()

    // SP Button

    const contentWidthSP = sideButtonOuterWidth * 4 - ThreeSegment.sideButtonMarginRight

    const button = ThreeSegment.getButton(
      'SP',
      0,
      'SegmentGroupDetails',
      ThreeSegment.supportPointButtonWidth,
      ThreeSegment.supportPointButtonHeight,
    )

    const rect = button.getObjectByName('Label_SegmentGroupDetails') as THREE.Mesh | undefined

    if (rect) {
      rect.material = this.isSelected ? ThreeSegment.highlightMaterial : ThreeSegment.backgroundMaterial
      rect.userData.type = 'SegmentGroupDetails'
      rect.userData.path = this.parent.path
      // TODO: show selected when path is in selections!
    }

    button.rotateY(Util.RAD180)

    const x = ThreeSegment.supportPointButtonWidth / 2 +
      ThreeSegment.sgRim / 2 +
      contentWidthSP -
      ThreeSegment.supportPointButtonWidth -
      ThreeSegment.supportPointButtonMargin
    const y = ThreeSegment.sgRim / 2 + ThreeSegment.supportPointButtonMargin

    button.position.set(-x, -y, -0.0002)

    const oldElement = segmentGroup?.getObjectByName('ButtonGroup')?.getObjectByName('Label_SegmentGroupDetails')

    if (this.clickableObjects) {
      Util.addOrReplaceInList(oldElement, rect, this.clickableObjects)
    }

    Util.addOrReplace(segmentGroup, button)
  }

  public setSegmentGroupSelected (isSelected: boolean) {
    this.isSelected = isSelected

    const { upperSection } = this.objects
    const segmentGroup = upperSection?.getObjectByName('SegmentGroup')
    const spButton = segmentGroup?.getObjectByName('ButtonGroup')?.getObjectByName('Label_SegmentGroupDetails')

    if (!spButton || !(spButton instanceof THREE.Mesh)) {
      return
    }

    spButton.material = isSelected ? ThreeSegment.highlightMaterial : ThreeSegment.backgroundMaterial
  }

  public updateTransform () {
    if (this.sectionDetail) {
      return
    }

    if (!ThreeSegment.labelSides.includes(this.container.userData.side)) {
      return
    }

    this.container.userData = {
      ...this.container.userData,
      heightMin: Infinity,
      heightMax: -Infinity,
    }

    let plMinCoord = this.plHeight ?? 0
    let plMaxCoord = 0
    let plMinCoordByRoller = this.plHeight ?? 0
    let plMaxCoordByRoller = 0
    let count = 0

    this.container.children.forEach((child: any) => {
      const { type, path } = child.userData as { type: ElementCacheKey, path: string }

      if ((type === 'Nozzle' || type === 'Roller') && path) {
        const element = this.elementList?.[type]?.[path]

        if (!element) {
          return
        }

        count++

        const { heightMin, heightMax } = element.getMeasures()

        plMinCoord = Math.min(element.plCoord ?? Infinity, plMinCoord)
        plMaxCoord = Math.max(element.plCoord ?? 0, plMaxCoord)

        this.container.userData = {
          ...this.container.userData,
          heightMin: Math.min(this.container.userData.heightMin, heightMin ?? Infinity),
          heightMax: Math.max(this.container.userData.heightMax, heightMax ?? -Infinity),
          plMinCoord,
          plMaxCoord,
        }
      }

      if (type === 'Roller') {
        plMinCoordByRoller = Math.min(this.elementList?.[type]?.[path].plCoord ?? Infinity, plMinCoordByRoller)
        plMaxCoordByRoller = Math.max(this.elementList?.[type]?.[path].plCoord ?? 0, plMaxCoordByRoller)

        this.container.userData = {
          ...this.container.userData,
          plMinCoordByRoller,
          plMaxCoordByRoller,
        }
      }
    })

    const { heightMax, side } = this.container.userData
    const { upperSection, lowerSection } = this.objects

    if (count === 0) {
      upperSection.visible = false
      lowerSection.visible = false

      return
    }

    upperSection.visible = true
    lowerSection.visible = true
    ;(ThreeSegment.prevSideLabelHeight as any)[side] = heightMax

    const { position, normal, angleX } = PasslineCurve.getInfoAtPlCoord(plMinCoord)
    const { FixedSide, LooseSide, NarrowFaceRightWidest, NarrowFaceLeftWidest } = Mold.sideDistance

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const space = 0.5
    let dis

    switch (side) {
      case StrandSides.Fixed:
        dis = NarrowFaceRightWidest.x - space
        newPosition.set(dis, position.y, position.z + FixedSide.x)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-angleX, 0, 0)
        break
      case StrandSides.Loose:
        dis = NarrowFaceLeftWidest.x + space
        newPosition.set(dis, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(-angleX, Util.RAD180, 0)
        break
      case StrandSides.Right:
        dis = LooseSide.z - space
        newPosition.set(NarrowFaceRightWidest.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(dis))
        newRotation.set(-angleX, Util.RAD90, 0)
        break
      case StrandSides.Left:
        dis = FixedSide.z + space
        newPosition.set(NarrowFaceLeftWidest.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(dis))
        newRotation.set(-angleX, -Util.RAD90, 0)
        break
      default:
    }

    // TODO: use x, y, z distance instead of vector distance
    const distance = ((ThreeSegment.prevSideLabelPosition as any)[side] || new THREE.Vector3(0, 0, 0)).distanceTo(
      newPosition,
    )

    // TODO: 1.21 + 0.1 ? get real width and space vars!?
    if (distance < 1.21 + 0.1) {
      const offset = new THREE.Vector3(-1.31, 0, 0)

      offset.applyEuler(newRotation)

      newPosition.add(offset)
    }

    ;(ThreeSegment.prevSideLabelPosition as any)[side] = newPosition

    upperSection.position.copy(newPosition)
    lowerSection.position.copy(newPosition)

    upperSection.rotation.copy(newRotation)
    lowerSection.rotation.copy(newRotation)

    lowerSection.position.y -= 0.01
  }

  public override hide () {
    super.hide()

    this.container.visible = false

    this.container.children.forEach((child: any) => {
      child.visible = false
    })
  }
}
