/* eslint-env browser */

import FastBase from './FastBase'
import SVG from './SVG'

export default class Shape extends FastBase {
  public constructor () {
    super('', '', '')
  }

  private getLinePathD ({
    x,
    y,
    width,
    height,
    xOffset,
    yOffset,
  }: {
    [x: string]: number
  }) {
    if (!this.additionalData?.flipAxes) {
      return `M${x},${height - yOffset}L${x},${-yOffset}`
    }

    return `M${-xOffset},${y}L${width - xOffset},${y}`
  }

  private getAreaPathD ({
    x,
    y,
    x2,
    y2,
    width,
    height,
    xOffset,
    yOffset,
  }: {
    [x: string]: number
  }) {
    if (!this.additionalData?.flipAxes) {
      return `M${x},${height - yOffset}H${x2}V${-yOffset}H${x}Z`
    }

    return `M${-xOffset},${y}H${width - xOffset}V${y2}H${-xOffset}Z`
  }

  private getPathD (
    plot: HTMLElement,
    { x0, y0, x1, y1 }: Record<string, number>,
    type: string,
  ) {
    const bgRect = plot.getElementsByClassName('bglayer')[0].getBoundingClientRect()
    const svgRect = plot.querySelector('svg:first-of-type')?.getBoundingClientRect()
    const { width, height } = this.getMeta(plot)
    const xOffset = (svgRect?.x ?? 0) - bgRect.x
    const yOffset = (svgRect?.y ?? 0) - bgRect.y
    const x = this.calcX(x0, width) - xOffset
    const y = this.calcY(y0, height) - yOffset
    const x2 = this.calcX(x1, width) - xOffset
    const y2 = this.calcY(y1, height) - yOffset

    if (type === 'line') {
      return this.getLinePathD({ x, y, width, height, xOffset, yOffset })
    }

    return this.getAreaPathD({ x, y, x2, y2, width, height, xOffset, yOffset })
  }

  private getLineStyle ({
    opacity,
    line: { width, color },
  }: any) {
    return `opacity: ${opacity}; stroke: ${color}; stroke-opacity: 1; fill-opacity: 0; stroke-width: ${width}px;`
  }

  private getAreaStyle ({
    opacity,
    fillcolor,
  }: any) {
    return `opacity: ${opacity}; stroke-opacity: 0; fill: ${fillcolor}; fill-opacity: 1; stroke-width: 0px;`
  }

  private getOrCreatePath (plot: HTMLElement, index: number) {
    const container = plot.querySelector('.layer-above .shapelayer')
    let path = container?.querySelector(`.fast-update[data-index="${index}"]`)

    if (!path) {
      path = SVG.getPath()

      path.setAttributeNS(null, 'data-index', String(index))
      path.setAttributeNS(null, 'fill-rule', 'evenodd')
      path.setAttributeNS(null, 'class', 'fast-update')
      container?.appendChild(path)
    }

    return {
      container,
      path,
    }
  }

  private drawSingle (
    plot: HTMLElement,
    shape: any,
    index: number,
  ) {
    const style = shape.type === 'line' ? this.getLineStyle(shape) : this.getAreaStyle(shape)
    const { container, path } = this.getOrCreatePath(plot, index)
    const originalPath = container?.querySelector(`[data-index="${index}"]:not(.fast-update)`) as HTMLElement

    if (!path.getAttributeNS(null, 'clip-path') && originalPath) {
      path.setAttributeNS(null, 'clip-path', originalPath?.getAttributeNS(null, 'clip-path') ?? '')
      originalPath?.remove()
    }

    const d = this.getPathD(plot, shape, shape.type)

    path.setAttributeNS(null, 'style', style)
    path.setAttributeNS(null, 'd', d)
  }

  public override draw (
    plot: HTMLElement,
    data: any[],
    xDomain: Array<number>,
    yDomain: Array<number>,
    flipAxes: boolean,
    layout: any,
  ) {
    if (!layout.shapes) {
      return
    }

    this.additionalData = {
      xDomain,
      yDomain,
      flipAxes,
      traceCount: layout.shapes.length,
    }

    this.calcNeededValues(plot, data)
    layout.shapes.forEach((shape: any, index: number) => this.drawSingle(plot, shape, index))
  }
}
