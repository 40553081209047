/* eslint-env browser */

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import type { WheelEvent } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Button from '@/react/components/Button'
import Input from '@/react/specific/Input'
import CommandUtil from '@/react/visualization/CommandUtil'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import FormBuilder from './FormBuilder'
import { getSteelGradesSelectors, populateSteelGrades, UpdateCurrentSimulationCase } from './logic'
import { Command, FormWrapper, IconButton, InputWrapper, Label, Loading, Warning } from './styles'

const T = 'commandWrapper'

const connector = connect(({ visualization, application: { main } }: DefaultState) => ({
  tileConfigs: visualization.tileConfigs,
  updatableCommandTile: visualization.updatableCommandTile,
  currentProject: main.currentProject,
  currentSimulationCase: main.currentSimulationCase,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
  updateCommandTile: VisualizationActions.updateCommandTile,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  currentProject: Project
  currentSimulationCase: SimulationCase
  selectedCellData?: Array<any>
  tileId: string
  commandId?: string
  fileId?: string
  currentCommand?: any
  enqueueSnackbar: enqueueSnackbar
  t: Translation
}

type State = {
  currentSteelGrade: any
  prevSteelGradeKey: string
  currentSteelGradeKey: string
  steelGrades: any
  steelGradesSelectors: Array<any>
  assignments: Array<any>
  inputValues: Array<any>
  parameterStructure: Array<any>
  loading: boolean
  saveCustomConfirming: boolean
}

class CommandWrapper extends Component<Props, State> {
  private timeoutRef?: number

  public override state: State = {
    loading: true,
    saveCustomConfirming: false,
    prevSteelGradeKey: 'custom',
    currentSteelGradeKey: 'custom',
    inputValues: [],
    assignments: [],
    steelGrades: {},
    parameterStructure: [],
    currentSteelGrade: {},
    steelGradesSelectors: [],
  }
  
  public override componentDidMount () {
    this.handleData(false)
  }
  
  public override componentDidUpdate (prevProps: Props) {
    const {
      tileConfigs,
      tileId,
      fileId,
      commandId,
      currentCommand,
      updatableCommandTile,
    } = this.props

    const { prevSteelGradeKey, steelGrades, currentSteelGrade } = this.state
    const command = this.getCurrentCommand()

    if (command && command.shortName && command.shortName !== prevSteelGradeKey) {
      const { shortName } = command

      if (prevSteelGradeKey && shortName === 'custom') {
        return
      }

      this.setState({
        prevSteelGradeKey: shortName,
        currentSteelGradeKey: shortName,
      })

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.handleSelectPreset({ target: { name: 'steelGradesSelector', value: shortName }, stopPropagation: () => {} })

      const stealGrade = steelGrades[shortName]

      if (stealGrade && !isEqual(stealGrade, currentSteelGrade)) {
        this.handleUseAllPresets(null, steelGrades[shortName])
      }
    }

    if (fileId || commandId) {
      return
    }

    const { tileConfigs: prevTileConfigs } = prevProps
    const currentTile = tileConfigs[tileId] ?? currentCommand ?? {}

    if (
      Boolean(currentTile.commandId && updatableCommandTile.includes(currentTile.commandId)) ||
      prevTileConfigs[tileId].command !== currentTile.command
    ) {
      this.handleData(true)
    }
  }
  
  public override componentWillUnmount () {
    this.handleUpdateDatabase(true)
  }

  private readonly handleWheel = (event: WheelEvent<EventTarget>) => {
    event.stopPropagation()
  }

  private readonly handleData = async (changeCommand = false) => {
    const { parameterStructure, steelGradesSelectors } = this.state
    const { commandId, updatableCommandTile, updateCommandTile } = this.props
    const { commandName, currentFileId, currentCommandId } = this.getCurrentCommandInfo()
    let doNotResetData = false

    if (currentCommandId && !commandId && updatableCommandTile.includes(currentCommandId)) {
      doNotResetData = true

      updateCommandTile(currentCommandId)
    }

    if (!currentFileId) {
      return null
    }

    if (steelGradesSelectors.length < 1) {
      this.handleLoadSteelGrades()
    }

    if (changeCommand || parameterStructure.length < 1) {
      const { commands: rawCommands } =
        await ApiClient.get(`${'Network.URI(deprecated)'}/visualization_command/commands`)
      const rawCommand = CommandUtil.getCommandByName(commandName, rawCommands)

      let parameterStructure = null
      let parameterName: string | null = null

      if (!rawCommand) {
        return
      }

      if (doNotResetData && rawCommand['#NumberOfArguments'].length < 1) {
        this.setState({
          loading: false,
          inputValues: [],
          parameterStructure: [],
        })

        return
      }

      if (rawCommand['#Assignments'].length > 0) {
        const assignments = CommandUtil.getAssignments(rawCommand['#Assignments'])

        this.setState({ assignments })
      }

      parameterStructure = (rawCommand['#NumberOfArguments'] ?? '').substr(1).split('+')
      parameterName = (rawCommand['#Parameter Label'] ?? '').substr(1).split('+').map((val: string) => val.split(','))

      const command = this.getCurrentCommand() ?? {} as Command
      const newInputValue = changeCommand && !doNotResetData ? [] : [ ...(command.parameter ?? []) ]

      if (parameterStructure[0].length > 0) {
        parameterStructure = parameterStructure.map((parameter: any, index: number) => {
          const [ count, rawTypes ] = parameter.split('*')
          const types = rawTypes.split(',')

          const typeArray = new Array(Number(count)).fill(types)
          const defaultValueArray: any = []

          typeArray.forEach(v =>
            v.forEach((t: string) => {
              // default must be empty! not 0 for numbers since 0 is not stored in the command!
              let defaultValue

              if (t === 'text') {
                defaultValue = ''
              }

              defaultValueArray.push(defaultValue)
            }))

          const mappedArray = defaultValueArray.map((val: any, i: number) => (newInputValue[index] ?? [])[i] || val)

          newInputValue[index] = mappedArray

          return ({
            count: Number(count),
            types,
            parameterName: (parameterName ?? [])[index] ?? [],
          })
        })
      }

      this.setState({
        parameterStructure,
        inputValues: newInputValue,
      })
    }

    this.setState({
      loading: false,
    })
  }

  private readonly handleLoadSteelGrades = async (
    defaultSteelGrades: any = [],
    customSteelGrades: any = [],
    steelGradesSelector: any = null,
  ) => {
    const { tileConfigs, tileId, currentCommand, currentSimulationCase, t } = this.props
    const currentTile = tileConfigs[tileId] ?? currentCommand ?? {}

    let defaultSteelGradeList = []
    let customSteelGradeList = []

    if (defaultSteelGrades.length || customSteelGrades.length) {
      defaultSteelGradeList = defaultSteelGrades
      customSteelGradeList = customSteelGrades
    }
    else {
      // TODO: add error handling!!!
      const { defaultSteelGrades, customSteelGrades } = await CommandUtil.getSteelGrades(currentSimulationCase.id)

      defaultSteelGradeList = defaultSteelGrades
      customSteelGradeList = customSteelGrades
    }

    const { defaultSteelGradesSelector, customSteelGradesSelector } = getSteelGradesSelectors(
      defaultSteelGradeList,
      customSteelGradeList,
      T,
      t,
    )

    const custom = { key: 'custom', value: t(`${T}.steelGrade.customEntry`) }
    const steelGradesSelectors = [ ...defaultSteelGradesSelector, ...customSteelGradesSelector ]

    steelGradesSelectors.push(custom)

    const steelGrades: any = populateSteelGrades(defaultSteelGradeList, customSteelGradeList)

    let currentSteelGrade
    const command = this.getCurrentCommand()

    if (steelGradesSelector || currentTile.steelGradesSelector) {
      currentSteelGrade = steelGrades[steelGradesSelector || currentTile.steelGradesSelector] || custom
    }
    else if (command) {
      if (command.shortName) { // FIXME: is this allowed to be empty?
        currentSteelGrade = steelGrades[command.shortName]
      }
    }
    else {
      currentSteelGrade = custom
    }

    this.setState({
      steelGrades,
      currentSteelGrade,
      steelGradesSelectors,
    })
  }

  private readonly handleInput = (event: any) => {
    const { inputValues } = this.state
    const { name, value } = event.target
    const [ group, index ] = name.split(':')

    const newInputValue = [ ...inputValues ]

    newInputValue[group][index] = value

    this.setState({
      inputValues: newInputValue,
    })

    this.handleUpdateDatabase()
  }

  private readonly handleSelectPreset = (event: any) => {
    event.stopPropagation()

    const { name, value } = event.target
    const { steelGrades } = this.state
    const { saveTileConfig, tileConfigs, tileId } = this.props

    this.setState({
      currentSteelGrade: steelGrades[value],
      currentSteelGradeKey: value,
    })

    if (tileId) {
      const currentTile = tileConfigs[tileId]

      saveTileConfig({
        ...currentTile,
        [name]: value,
      })
    }
  }

  private readonly handleUsePreset = (groupIndex: number, fieldIndex: number, value: string) => {
    const { inputValues } = this.state
    const newInputValue = [ ...inputValues ]

    newInputValue[groupIndex][fieldIndex] = value

    this.setState({
      inputValues: newInputValue,
    })

    this.handleUpdateDatabase()
  }

  private readonly handleUseAllPresets = (event: any, steelGrade?: any) => {
    const { assignments, currentSteelGrade } = this.state

    this.setState({
      inputValues: CommandUtil.applySteelGrade(assignments, steelGrade || currentSteelGrade),
    })

    if (event) {
      this.handleUpdateDatabase()
    }
  }

  private readonly handleUpdateDatabase = (instant?: boolean) => {
    if (instant && !this.timeoutRef) {
      return
    }

    clearTimeout(this.timeoutRef)

    this.timeoutRef = window.setTimeout(() => {
      const { inputValues } = this.state
      const {
        tileConfigs,
        tileId,
        fileId,
        commandId,
        currentSimulationCase,
        currentCommand,
        updateCommandTile,
        selectedCellData,
      } = this.props
      const currentTile = tileConfigs[tileId] ?? currentCommand ?? {}

      const currentFileId = fileId ?? currentTile.fileId
      const currentCommandId = commandId ?? currentTile.commandId

      let shortName

      if (currentTile.command === 'init_material') {
        shortName = this.getSteelGradeForSubmit()
      }

      ApiClient
        .post(`${'Network.URI(deprecated)'}/visualization_command/${currentFileId}/${currentCommandId}`, {
          data: {
            command: {
              parameter: inputValues,
              shortName,
            },
            simulationCaseId: currentSimulationCase.id,
            selectedCellData,
            keepVerification: !tileId,
          },
        })
        .then(({ commandFile, results }) => {
          if (results && results.length) {
            this.updateSimulationCases(results)

            return
          }

          if (fileId && commandId && currentCommandId) {
            updateCommandTile(currentCommandId)
          }

          // FIXME: @a.kucharczyk don't just spread props, this could have unwanted side effects ...
          UpdateCurrentSimulationCase({ commandFile, ...this.props })
          clearTimeout(this.timeoutRef)
          this.timeoutRef = undefined
        })
    }, instant ? 0 : 1000)
  }

  private readonly handleSaveCustom = () => {
    const { inputValues } = this.state
    const { currentSimulationCase, enqueueSnackbar, t } = this.props

    this.setState({ saveCustomConfirming: false })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/visualization_command/custom_steelgrade`, {
        data: {
          parameters: inputValues,
          simulationCaseId: currentSimulationCase.id,
        },
      })
      .then(({ defaultSteelGrades, customSteelGrades }) => {
        const key: any = `custom_${customSteelGrades.findIndex((grade: any) => grade.Group === inputValues[0][0])}`

        this.handleLoadSteelGrades(defaultSteelGrades, customSteelGrades, key)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.handleSelectPreset({ target: { name: 'steelGradesSelector', value: key }, stopPropagation: () => {} })
        this.handleUseAllPresets(true)

        enqueueSnackbar(t(`${T}.steelGrade.customSaved`), { autoHideDuration: 3000, variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar(t(`${T}.steelGrade.customError`), { autoHideDuration: 3000, variant: 'error' })
      })
  }

  private readonly handleConfirmCustom = () => this.setState({ saveCustomConfirming: true })

  private readonly handleCancelCustom = () => this.setState({ saveCustomConfirming: false })

  private readonly getSteelGradeForSubmit = () => {
    const { assignments, currentSteelGradeKey, inputValues, steelGrades } = this.state
    const steelGrade = steelGrades[currentSteelGradeKey]

    if (steelGrade && !CommandUtil.hasChanges(assignments, steelGrade, inputValues)) {
      return currentSteelGradeKey
    }

    return 'custom'
  }

  private readonly getCurrentCommandInfo = () => {
    const { tileId, tileConfigs, currentCommand, fileId, commandId } = this.props
    const currentTile = tileConfigs[tileId] ?? currentCommand ?? {}

    return {
      commandName: currentTile.command,
      currentFileId: fileId ?? currentTile.fileId,
      currentCommandId: commandId ?? currentTile.commandId,
    }
  }

  private readonly getCurrentCommand = () => {
    const { currentProject, currentSimulationCase: { id } } = this.props
    const { currentFileId, currentCommandId } = this.getCurrentCommandInfo()
    const simulationCase = currentProject.simulationCases.find(simulationCase => simulationCase.id === id)
    const commandFiles = simulationCase?.commandFiles ?? []
    let commandFile = commandFiles.find((commandFile: any) => commandFile._id === currentFileId)

    if (!commandFile) {
      commandFile = commandFiles[0] ?? {}
    }

    return commandFile.commands?.find((command: any) => command._id === currentCommandId)
  }

  private readonly updateSimulationCases = (results: any) => {
    const { currentProject, setCurrentProject } = this.props

    const project = cloneDeep(currentProject)

    for (const { simulationCaseId, commandFile } of results) {
      const simulationCase = project.simulationCases.find(simCase => simCase.id === simulationCaseId)

      if (!simulationCase) {
        return
      }

      simulationCase.commandFiles = simulationCase.commandFiles.map(cmdFile => {
        if (cmdFile._id === commandFile._id) {
          return commandFile
        }

        return cmdFile
      })
    }

    setCurrentProject(project)
  }
  
  public override render () {
    const {
      inputValues,
      steelGradesSelectors,
      currentSteelGradeKey,
      loading,
      steelGrades,
      saveCustomConfirming,
    } = this.state
    const { tileConfigs, tileId, currentCommand, currentSimulationCase, t } = this.props
    const currentTile = tileConfigs[tileId] ?? currentCommand ?? {}
    const disabled = Boolean(currentSimulationCase.simulationStartedAt)

    if (loading) {
      return <Loading type='spin' />
    }

    let shortName = ''
    let isOverride = false

    if (currentTile.command === 'init_material') {
      const group = inputValues[0][0]

      shortName = Object
        .keys(steelGrades)
        .find(key => key.startsWith('custom_') && steelGrades[key].Group === group) ?? ''

      if (shortName) {
        isOverride = true
        shortName = shortName.replace('custom_', 'C')
      }
    }

    return (
      <FormWrapper onWheel={this.handleWheel}>
        <InputWrapper>
          <Label>{t(`${T}.command.label`)}</Label>
          <Command>{currentTile.command}</Command>
        </InputWrapper>
        {
          currentTile.command === 'init_material' &&
          (
            <InputWrapper>
              <Label />
              <div>
                <Button
                  disabled={disabled}
                  onClick={this.handleSaveCustom}
                  onBeginConfirm={this.handleConfirmCustom}
                  onCancel={this.handleCancelCustom}
                  title={t(`${T}.steelGrade.customTooltip`)}
                  confirm
                >
                  {t(`${T}.steelGrade.custom`)}
                </Button>
              </div>
            </InputWrapper>
          )
        }
        {
          (saveCustomConfirming && isOverride) &&
            <Warning>{t(`${T}.steelGrade.override`, { shortName })}</Warning>
        }
        {
          !disabled && currentTile.command === 'init_material' && steelGradesSelectors.length > 0 && (
            <InputWrapper>
              <Label>Preset</Label>
              <div onMouseEnter={() => this.handleLoadSteelGrades([], [], currentSteelGradeKey)}>
                <Input
                  name='steelGradesSelector'
                  value={currentSteelGradeKey}
                  selectors={steelGradesSelectors}
                  type='select'
                  onChange={this.handleSelectPreset}
                  disabled={disabled}
                  className='largeText'
                />
                {
                  !disabled &&
                  currentSteelGradeKey &&
                  currentSteelGradeKey !== 'custom' &&
                  (
                    <IconButton
                      className='pe-7s-back-2'
                      onClick={this.handleUseAllPresets}
                    />
                  )
                }
              </div>
            </InputWrapper>
          )
        }
        <FormBuilder
          onInput={this.handleInput}
          onUsePreset={this.handleUsePreset}
          disabled={disabled}
          {...this.state}
          {...this.props}
        />
      </FormWrapper>
    )
  }
}
export default withNamespaces('visualization')(withSnackbar(connector(CommandWrapper as any) as any) as any) as any
