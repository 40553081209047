import type { ElementsHashes } from '@/types/state'

export default class FeatureFlagsUtil {
  public static getSensorPointLocations (paths: string[], elementsHashes: ElementsHashes) {
    const locations: any = {
      MoldFace: false,
      RollerBody: false,
      RollerBearing: false,
      Roller: false,
      Segment: false,
      Nozzle: false,
    }

    for (const path of paths) {
      const lastIndexOfColon = path.lastIndexOf(':')

      if (lastIndexOfColon === -1) {
        continue
      }

      const id = path.substring(lastIndexOfColon + 1, path.length)
      // FIXME: remove any once SensorPointHash uses string as key
      const sensorPoint = (elementsHashes.SensorPoint as any)[id]

      if (!sensorPoint || !sensorPoint['#parent'] || !sensorPoint['#parent'].type) {
        continue
      }

      const parentType = sensorPoint['#parent'].type

      if (locations[parentType] !== undefined) {
        locations[parentType] = true
      }
    }

    return locations
  }
}
