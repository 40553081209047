export enum MatrixActionsEnum {
  ACTION_SET_COLUMNS = 'matrix/ACTION_SET_COLUMNS',
  ACTION_SET_SELECTIONS = 'matrix/ACTION_SET_SELECTIONS',
  ACTION_SET_GRID = 'matrix/ACTION_SET_GRID',
  ACTION_SET_CLONE_SIMULATION_CASE_ID = 'matrix/ACTION_SET_CLONE_SIMULATION_CASE_ID',
  ACTION_UPDATE_RESULT_DATA = 'matrix/ACTION_UPDATE_RESULT_DATA',
  ACTION_SET_SCROLL_POSITION = 'matrix/ACTION_SET_SCROLL_POSITION',
}

export const initialState = {
  nonpersistantProperties: [
    'grid',
    'resultData',
  ],
  selections: {} as Selections,
  columns: [] as Columns,
  grid: [] as Grid,
  cloneSimulationCaseId: null,
  resultData: {} as ResultData,
  lastScrollTopPosition: 0,
}

export type MatrixAction = {
  lastScrollTopPosition?: number
  type: MatrixActionsEnum
  columns: Columns
  selections: Selections
  grid: Grid
  id: string
  data: any
}
