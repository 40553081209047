// TODO: fix types
export enum SegmentDataPointsMountLogActionsEnum {
  ACTION_ADD_SEGMENT_DATA_POINTS_MOUNT_LOG = 'SegmentDataPointsMountLog/ACTION_ADD_SEGMENT_DATA_POINTS_MOUNT_LOG',
  DELETE = 'SegmentDataPointsMountLog/DELETE',
  DELETE_MULTIPLE = 'SegmentDataPointsMountLog/DELETE_MULTIPLE',
  UPDATE = 'SegmentDataPointsMountLog/UPDATE',
}

export function addSegmentDataPointsMountLog (dataLineHash: Record<number, SegmentDataPointsMountLog>) {
  return {
    type: SegmentDataPointsMountLogActionsEnum.ACTION_ADD_SEGMENT_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentDataPointsMountLogReducers: Record<string, Function | undefined> = {
  [SegmentDataPointsMountLogActionsEnum.ACTION_ADD_SEGMENT_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [SegmentDataPointsMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentDataPointsMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentDataPointsMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = SegmentDataPointsMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
