import * as THREE from 'three'

import Util from '@/three/logic/Util'

import UIView from '.'
import Getters from './Getters'

export default class DrawHandlers {
  public static textureImageMapper = {
    Nozzle: 'viewswitch-nozzle.png',
    Roller: 'viewswitch-roller.png',
    DataPoint: 'viewswitch-datapoint.png',
  }

  private static drawBar (view: UIView) {
    const width = 6
    const x = 50 - width / 2
    const y = 150
    const height = view.viewport.height - y - 50

    const barGeometry = new THREE.PlaneGeometry(width, height, 1)
    const barMaterial = new THREE.MeshBasicMaterial({ color: '#c7e5ff', transparent: true, opacity: 0.25 })
    const scrollBar = new THREE.Mesh(barGeometry, barMaterial)

    scrollBar.position.copy(Getters.getPosition(width, height, x, y, view.viewport))
    scrollBar.visible = view.isSectionActive
    scrollBar.name = 'scrollBar'

    Util.addOrReplace(view.scene, scrollBar)

    view.scrollBar = scrollBar
  }

  private static drawJumpOverElementTypeButton (view: UIView) {
    const { width } = UIView.sliderDimensions
    const position = Getters.getPosition(width, 0, 50 - width / 2, 150, view.viewport)
    const material = view.buttonMaterials.jumpOver[view.views?.sectionView?.jumpOver ?? 'Nozzle']
    const geometry = new THREE.PlaneGeometry(width, width, 1)

    const switchScrollElement = new THREE.Mesh(geometry, material)

    if (view.switchScrollElementDisabled) {
      material.transparent = true
      material.opacity = 0.5
    }

    switchScrollElement.name = 'switchScrollElement'
    switchScrollElement.position.copy(position)
    switchScrollElement.position.y += width * 2.5
    switchScrollElement.visible = view.isSectionActive
    Util.addOrReplace(view.scene, switchScrollElement, view.clickableObjects)
    view.switchScrollElement = switchScrollElement
    view.views.sectionView?.updateMinAndMaxPasslineCoordinates()
  }

  public static drawUpDownButtons (view: UIView) {
    const { width } = UIView.sliderDimensions
    const position = Getters.getPosition(width, 0, 50 - width / 2, 150, view.viewport)

    const a = new THREE.Vector3(width / 2, 0, 0)
    const b = new THREE.Vector3(-width / 2, 0, 0)
    const c = new THREE.Vector3(0, width / 2, 0)
    const d = new THREE.Vector3(0, -width / 2, 0)

    const { buttonUp, buttonDown } = Getters.getUpAndDownButtons(a, b, c, d, width, position, view)

    buttonUp.visible = view.isSectionActive
    buttonDown.visible = view.isSectionActive

    // add to scene
    Util.addOrReplace(view.scene, buttonUp, view.clickableObjects)
    Util.addOrReplace(view.scene, buttonDown, view.clickableObjects)

    view.buttonUp = buttonUp
    view.buttonDown = buttonDown
  }

  public static drawJumpToSectionPlaneButton (view: UIView, scrollBarButtonGroup: THREE.Group, width: number) {
    const buttonWidth = 20
    const jumpToSectionButton = Getters.getJumpToSectionButton(buttonWidth)

    jumpToSectionButton.position.set(0, 0, 0)

    jumpToSectionButton.name = 'jumpToSectionButton'
    jumpToSectionButton.userData.type = 'Button'
    jumpToSectionButton.userData = {
      action: 'jumpToSection',
    }

    jumpToSectionButton.position.set(width + buttonWidth / 2, 0, 0)

    Util.addOrReplace(scrollBarButtonGroup, jumpToSectionButton, view.clickableObjects)
  }

  public static drawScrollBar (view: UIView) {
    DrawHandlers.drawBar(view)
    DrawHandlers.drawUpDownButtons(view)
    DrawHandlers.drawJumpOverElementTypeButton(view)

    const { width, height, top, bottom } = UIView.sliderDimensions

    const x = bottom - width / 2
    const min = top

    const barGeometry = new THREE.PlaneGeometry(width, height, 1)
    const sliderMaterial = UIView.barMaterial
    const slider = new THREE.Mesh(barGeometry, sliderMaterial)

    slider.name = 'scrollBarSlider'

    if (!view.scrollBarButtonGroup) {
      view.scrollBarButtonGroup = new THREE.Group()
      view.scrollBarButtonGroup.name = 'scrollBarButtonGroup'
      view.scrollBarButtonGroup.visible = view.isSectionActive
      view.scrollBarButtonGroup.userData.width = width
      view.scrollBarButtonGroup.userData.height = height
      view.scrollBarButtonGroup.userData.min = min
      view.scrollBarButtonGroup.userData.x = x
      view.scrollBarButtonGroup.userData.top = top
      view.scrollBarButtonGroup.userData.bottom = bottom

      Util.addOrReplace(view.scene, view.scrollBarButtonGroup)
    }

    Util.addOrReplace(view.scrollBarButtonGroup, slider, view.clickableObjects)

    view.setButtonPos()
  }

  public static drawPasslineCoord (scrollBarButtonGroup: any, width: number) {
    const coordMesh = Util.getText(UIView.currentPsslnPosition.toString(), 15, true, true)

    if (!coordMesh) {
      // eslint-disable-next-line no-console
      console.warn('No passline coordinate mesh found')

      return
    }

    coordMesh.name = 'PassLineCoord'
    coordMesh.geometry.computeBoundingBox()

    if (!coordMesh.geometry.boundingBox) {
      // eslint-disable-next-line no-console
      console.warn('No passline coordinate bounding box found')

      return
    }

    const { max: bbMax, min: bbMin } = coordMesh.geometry.boundingBox
    const plX = bbMax.x - bbMin.x

    coordMesh.position.set(-(plX / 2 + width), 0, 0)

    Util.addOrReplace(scrollBarButtonGroup, coordMesh)
  }
}
