import React from 'react'
import { useResizeDetector } from 'react-resize-detector'
import styled from 'styled-components'

type Props = {
  className?: string
  skipOnMount?: boolean
  onResize: (data: { ref: React.RefObject<HTMLElement>, width?: number, height?: number }) => void
  [key: string]: any
}

const Container = styled.div`
`

export const ResizeDetector: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { children, className, skipOnMount, onResize, ...restProps } = props
  const targetRef = React.createRef<HTMLDivElement>()

  useResizeDetector({
    targetRef,
    onResize: (width?: number, height?: number) => onResize({ ref: targetRef, width, height }),
    skipOnMount,
  })

  return (
    <Container ref={targetRef} className={className} {...restProps}>
      {children}
    </Container>
  )
}
