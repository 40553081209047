import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

export const DeleteComponent = styled.div`${() =>
  css`
  display: inline-block;
  min-width: 70px;
  text-align: center;
  font-size: 24px;
`}`

const StyledIcon = styled.i`${() =>
  css`
  margin: 5px;
  cursor: pointer;
`}`

export const CheckIcon = styled(StyledIcon as any)`${({ theme }) =>
  css`
  &:hover {
    color: ${theme.colors.swatch10}
  }
`}`

export const AbortIcon = styled(StyledIcon as any)`${({ theme }) =>
  css`
  &:hover {
    color: ${theme.colors.swatch16}
  }
`}`

export const DeleteIcon = styled(StyledIcon as any)`${({ theme }) =>
  css`
  &:hover {
    color: ${theme.colors.swatch16}
  }
`}`

export const Loading = styled(ReactLoading)`${({ theme }) =>
  css`
  color: ${theme.primary.font}
  max-width: 20px;
  max-height: 20px;
  margin-left: 50%;
  transform: translate(-50%, 0%);
`}`
