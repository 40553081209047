export enum tileWarningsActions {
  ACTION_SET_TILE_WARNINGS = 'TileWarningsActions/ACTION_SET_TILE_WARNINGS',
  DELETE = 'TileWarningsActions/DELETE',
}

export function setTileWarnings (warnings: any) {
  return {
    type: tileWarningsActions.ACTION_SET_TILE_WARNINGS,
    tileWarnings: warnings,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const tileWarningsReducers: Record<string, Function | undefined> = {
  [tileWarningsActions.ACTION_SET_TILE_WARNINGS]: (state: any, action: { tileWarnings: any }) => ({
    ...action.tileWarnings,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = tileWarningsReducers[action.type]

  return handler ? handler(state, action) : state
}
