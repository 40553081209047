import { CDSData } from '@/api/caster-data-server'

enum CasterDataServerActionsEnum {
  ACTION_ADD_CDS_TIMESTAMP = 'casterDataServer/ACTION_ADD_CDS_TIMESTAMP',
  ACTION_UPDATE_CDS_TIMESTAMP = 'casterDataServer/ACTION_UPDATE_CDS_TIMESTAMP',
  ACTION_REMOVE_CDS_TIMESTAMP = 'casterDataServer/ACTION_REMOVE_CDS_TIMESTAMP',
  ACTION_SET_CDS_TIMESTAMP = 'casterDataServer/ACTION_SET_CDS_TIMESTAMP',
}

type CDSAction = { type: CasterDataServerActionsEnum }

type AddTimestampDataAction = CDSAction & { compareEntry: CompareEntry }

type UpdateTimestampDataAction = CDSAction & { compareEntry: CompareEntry }

type RemoveTimestampDataAction = CDSAction & { id: string }

type SetTimestampDataAction = CDSAction & { timestamp: number, data: CDSData }

export function addTimestamp (compareEntry: CompareEntry): AddTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_ADD_CDS_TIMESTAMP,
    compareEntry,
  }
}

export function updateTimestamp (compareEntry: CompareEntry): UpdateTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_UPDATE_CDS_TIMESTAMP,
    compareEntry,
  }
}

export function removeTimestampAndData (id: string): RemoveTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_REMOVE_CDS_TIMESTAMP,
    id,
  }
}

export function setTimestampData (timestamp: number, data: CDSData): SetTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_SET_CDS_TIMESTAMP,
    timestamp,
    data,
  }
}

const initialState: CasterDataServerState = {
  compareEntries: [],
  timestampData: {},
}

const actionHandlers: Record<string, (state: CasterDataServerState, action: any) => CasterDataServerState> = {
  [CasterDataServerActionsEnum.ACTION_ADD_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: AddTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: [ ...state.compareEntries, action.compareEntry ],
  }),

  [CasterDataServerActionsEnum.ACTION_UPDATE_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: UpdateTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: state
      .compareEntries
      .map(compareEntry => compareEntry.id === action.compareEntry.id ? action.compareEntry : compareEntry),
  }),

  [CasterDataServerActionsEnum.ACTION_REMOVE_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: RemoveTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: state.compareEntries.filter(({ id }) => id !== action.id),
    timestampData: Object.keys(state.timestampData).reduce((acc, key) => {
      const timestamp = Number(key)
      const compareEntry = state.compareEntries.find(compareEntry => compareEntry.timestamp === timestamp)

      if (timestamp !== compareEntry?.timestamp) {
        acc[timestamp] = state.timestampData[timestamp]
      }

      return acc
    }, {} as CasterDataServerState['timestampData']),
  }),

  [CasterDataServerActionsEnum.ACTION_SET_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: SetTimestampDataAction,
  ) => ({
    ...state,
    timestampData: {
      ...state.timestampData,
      [action.timestamp]: action.data,
    },
  }),
}

export default function <T extends CDSAction> (state = initialState, action: T) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
