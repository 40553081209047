import { Tooltip, withStyles } from '@material-ui/core'
import React from 'react'

import ApiClient from '@/store/apiClient'

import Builder from './builders'
import Getter from './getters'
import Handler from './handlers'
import Renderer from './renderers'
import { ProjectMatrixDialog } from '../.'
import RowRenderer from '../RowRenderer'
import SheetRenderer from '../SheetRenderer'

export const StyledTooltip = withStyles(_theme => ({
  tooltip: {
    maxHeight: 300,
    overflow: 'auto',
    userSelect: 'none',
  },
}))(Tooltip)

export default class Logic {
  public static T = 'projectMatrixDialog'

  public static dialogInstance: ProjectMatrixDialog

  public static init (dialogInstance: ProjectMatrixDialog): void {
    Logic.dialogInstance = dialogInstance
  }
  
  public static async prepare () {
    // TODO: this should be stored in the store not requested over and over again...
    const { commands } = await ApiClient.get(`${'Network.URI(deprecated)'}/visualization_command/commands`)

    // TODO: error handling

    Logic.dialogInstance.setState({ commands })
  }

  public static getMissingData = Getter.getMissingData

  public static buildColumns = Builder.buildColumns

  public static buildGrid = Builder.buildGrid

  public static getStatus = Getter.getStatus

  public static handleClose = Handler.handleClose

  public static handleConfigure = Handler.handleConfigure

  public static handleSimulateMultiple = Handler.handleSimulateMultiple

  public static handleStopSelected = Handler.handleStopSelected

  public static handleResetSelected = Handler.handleResetSelected

  public static sheetRenderer = (renderProps: any) => <SheetRenderer {...renderProps} />

  public static rowRenderer = (renderProps: any) => <RowRenderer {...renderProps} />

  public static valueRenderer = Renderer.valueRenderer

  public static renderDataEditor = Renderer.renderDataEditor

  public static handleDataEditor = Handler.handleDataEditor

  public static handleSelect = Handler.handleSelect

  public static handleCopy = Handler.handleCopy

  public static handlePaste = Handler.handlePaste
}
