import * as THREE from 'three'
import { Vector3 } from 'three'

import { StrandSides } from '@/types/elements/enum'

import { SetValuesData } from './BaseObject'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSensorPoint from './SensorPoint'
import Util from '../logic/Util'

export default class ThreeDataPoint extends ThreeSensorPoint {
  protected thickness: number

  private readonly isInSegment: boolean = false

  private readonly isInMoldFace: boolean = false

  private readonly side?: StrandSide

  protected override readonly type: string = 'DataPoint'

  public constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent, clickableObjects, tooltipObjects)
    this.thickness = 0

    if (container.userData.type === 'Segment' && container.userData.side) {
      this.side = container.userData.side
      this.isInSegment = true
    }

    if (container.userData.type === 'MoldFace' && container?.userData?.side) {
      this.side = container.userData.side
      this.isInMoldFace = true
    }
  }

  public override updateTransform (): void {
    const { sensorPoint } = this.objects

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
      true,
    )

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const size = this.sectionDetail ? this.size : 0 // add the size only in SectionView

    newSectionRotation.x = -Util.RAD90

    const { LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    if (this.isInSegment) {
      this.handleSegmentDataPointTransform()

      return
    }

    if (this.isInMoldFace) {
      this.handleMoldFaceDataPointTransform()

      return
    }

    if (this.wCoord >= Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on left side
      const padding = (this.sectionDetail ? 0 : this.size) / 2

      newPosition.set(-position.z - padding, position.y + 0.00001, this.wCoord + this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.wCoord <= -Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on right side
      const padding = (this.sectionDetail ? 0 : this.size) / 2

      newPosition.set(-position.z - padding, position.y + 0.00001, this.wCoord - this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.thickness <= 0) {
      // Fixed side
      const diff = this.thickness < 0 ? this.thickness + Mold.thickness : 0 // + because this.thickness is negative

      newPosition.set(-(position.z) + size / 2, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(diff))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.thickness >= Mold.thickness) {
      // Loose side
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x + size / 2), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
    }
    else if (this.thickness <= Mold.thickness / 2) {
      newPosition.set(-position.z - this.thickness, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z))
    }
    else {
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  private handleMoldFaceDataPointTransform () {
    const { sensorPoint } = this.objects
    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const padding = this.sectionDetail ? this.size / 2 : 0 // add the size only in SectionView

    const { LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    if (this.side === StrandSides.Fixed) {
      const diff = this.thickness < 0 ? this.thickness + Mold.thickness : 0 // + because this.thickness is negative

      newPosition.set(-(position.z) + padding / 2, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(diff))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Loose) {
      newPosition.set(-(position.z + LooseSide.x + padding / 2), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z))
      newRotation.set(0, 0, Util.RAD90 - angleX)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Right) {
      newPosition.set(NarrowFaceRight.x - padding, position.y, position.z)
      newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
      newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Left) {
      newPosition.set(NarrowFaceLeft.x + padding, position.y, position.z)
      newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
      newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
      newSectionRotation.x = -Util.RAD90
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  private handleSegmentDataPointTransform () {
    const { sensorPoint } = this.objects
    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const padding = this.sectionDetail ? this.size / 2 : 0 // add the size only in SectionView

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    switch (this.side) {
      case StrandSides.Fixed:
        newPosition.set(this.wCoord, position.y, position.z + FixedSide.x - padding)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        newSectionRotation.x = -Util.RAD90
        break
      case StrandSides.Loose:
        newPosition.set(this.wCoord, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        newSectionRotation.y = Util.RAD180
        break
      case StrandSides.Right:
        newPosition.set(NarrowFaceRight.x - padding, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        newSectionRotation.x = -Util.RAD90
        break
      case StrandSides.Left:
        newPosition.set(NarrowFaceLeft.x + padding, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        newSectionRotation.x = -Util.RAD90
        break
      default:
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  protected override internalSetValues (data: SetValuesData<DataPoint, BaseMountLog>): void {
    this.thickness = (data.elementData.thicknessCoord ?? 0) / 1000

    super.internalSetValues(data)
  }
}
