import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'

import { Translation } from '@/types/translation'

import { FormLabel, FromField, Selector } from './InputStyles'

type Props = {
  label: string
  values: Array<any>
  value?: any
  onChange: (e: any) => void
  t: Translation
  disabled?: boolean
  multiValues?: boolean
}

class DropDown extends Component<Props> {
  public override render () {
    const { label, disabled, values, value: propsValues, onChange: handleChange, multiValues, t } = this.props
    const value = propsValues !== undefined ? propsValues : ''

    return (
      <FromField>
        {label && label !== '' && <FormLabel>{label}</FormLabel>}
        <Selector
          onChange={handleChange}
          disabled={disabled}
          title={label}
          name={label}
          value={value}
        >
          <option value='' disabled>{t('dropDown.choose')}</option>
          {multiValues && <option value='multi-value' disabled>{t('dropDown.multiValue')}</option>}
          {
            values.map(option => (
              <option key={option.key ?? option} value={option.key ?? option}>
                {option.value !== undefined ? option.value : option}
              </option>
            ))
          }
        </Selector>
      </FromField>
    )
  }
}

export default withNamespaces('caster')(DropDown as any) as any
