import styled, { css } from 'styled-components'

const width = 200
const height = 595

export const Wrapper = styled.div`${({ theme }) =>
  css`
  background: ${theme.colors.swatch5 ?? '#2A3038'};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: calc(50% - ${height / 2}px);
  left: calc(50% - 450px - ${width}px);
  width: ${width}px;
  height: ${height}px;
  z-index: 199;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px;
`}`

export const Title = styled.div`${({ theme }) =>
  css`
  font-size: 20px;
  font-weight: 500;
  user-select: none;
  color: ${theme.colors.swatch9};
  text-align: center;
`}`

export const FileName = styled.div`${({ theme }) =>
  css`
  margin-top: 5px;
  font-size: 12px;
  display: block;
  text-align: center;
  color: ${theme.colors.swatch9};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 15px;

  + * > * {
    margin-top: 4px;
  }
`}`
