import * as THREE from 'three'
import { Intersection, Object3D, Vector2, Vector3 } from 'three'

import TextureUtil from '@/three/logic/TextureUtil'
import Util from '@/three/logic/Util'
import type { ElementsHashes } from '@/types/state'

import UIView from '.'

export default class Getters {
  public static getSelectionRect () {
    const geometry = new THREE.PlaneGeometry(1, 1, 1)
    const material = new THREE.MeshBasicMaterial({ color: '#6295ff', transparent: true, opacity: 0.5 })

    return new THREE.Mesh(geometry, material)
  }

  public static getCamera () {
    const camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.1, 1000)

    camera.position.z = 4

    return camera
  }

  public static getNozzleRollerAndDataPointCount (elementsHashes: ElementsHashes) {
    const nozzleCount = Object.keys(elementsHashes?.Nozzle ?? {}).length
    const rollerCount = Object.keys(elementsHashes?.Roller ?? {}).length
    const dataPointCount = Object.keys(elementsHashes?.DataPoint ?? {}).length

    return { nozzleCount, rollerCount, dataPointCount }
  }

  public static getPosition (width: number, height: number, x: number, y: number, viewport: ViewPort) {
    const posX = viewport.width / 2
    const posY = viewport.height / 2
    const dirX = 1
    const dirY = 1

    return new THREE.Vector3(posX * dirX + (width / 2 + x) * -dirX, posY * dirY + (height / 2 + y) * -dirY, 0)
  }

  public static getUpAndDownButtons (
    a: Vector3,
    b: Vector3,
    c: Vector3,
    d: Vector3,
    width: number,
    position: Vector3,
    view: UIView,
  ) {
    const buttonMaterial = UIView.barMaterial
    const disabledButtonMaterial = UIView.disabledBarMaterial
    const isUpButtonDisabled = view.minPasslineCoord >= UIView.currentPsslnPosition
    const isDownButtonDisabled = view.maxPasslineCoord <= UIView.currentPsslnPosition

    UIView.upOrDownButtonDisabled = isUpButtonDisabled || isDownButtonDisabled

    // Up
    const buttonUp = Util.getTriangleMesh(a, c, b, isUpButtonDisabled ? disabledButtonMaterial : buttonMaterial)

    buttonUp.name = 'scrollBarBtnUp'
    buttonUp.position.copy(position)
    buttonUp.position.y += width + 5
    buttonUp.visible = true

    // Down
    const buttonDown = Util.getTriangleMesh(b, d, a, isDownButtonDisabled ? disabledButtonMaterial : buttonMaterial)

    buttonDown.name = 'scrollBarBtnDown'
    buttonDown.position.copy(position)
    buttonDown.position.y += width
    buttonDown.visible = true

    return { buttonUp, buttonDown }
  }

  public static getMouse (mouseOnCanvas: Vector2, width: number, height: number, x: number, y: number) {
    const mouse = new THREE.Vector2()

    mouse.x = ((mouseOnCanvas.x - x) / width) * 2 - 1
    mouse.y = -((mouseOnCanvas.y - y) / height) * 2 + 1

    return mouse
  }

  public static getIntersectedTooltips (intersects: Intersection<Object3D>[]) {
    return intersects.reduce((list: Tooltip[], intersect) => {
      const { userData } = intersect.object ?? {}

      if (
        (intersect.object && userData.type === 'TooltipMarker') &&
        (!list.filter(element => element.tooltip === userData.tooltip).length)
      ) {
        list.push({
          tooltip: userData.tooltip,
          position: 'bottom',
        })
      }

      return list
    }, [])
  }

  public static getJumpToSectionButton (width: number) {
    const geometry = new THREE.PlaneGeometry(width - 0.01, width - 0.01, 1)

    // geometry.translate(0, 0, 0.00001)
    const texture = TextureUtil.load('textures/ui/crosshairs.png')
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })

    return new THREE.Mesh(geometry, material)
  }
}
