// https://stackoverflow.com/a/71921574/7015138

import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()

  return null
}

export default {
  success (msg: string, autoHideDurationInMs?: number) {
    this.toast(msg, 'success', autoHideDurationInMs)
  },
  warning (msg: string, autoHideDurationInMs?: number) {
    this.toast(msg, 'warning', autoHideDurationInMs)
  },
  info (msg: string, autoHideDurationInMs?: number) {
    this.toast(msg, 'info', autoHideDurationInMs)
  },
  error (msg: string, autoHideDurationInMs?: number) {
    this.toast(msg, 'error', autoHideDurationInMs)
  },
  toast (msg: string, variant: VariantType = 'default', autoHideDuration?: number) {
    useSnackbarRef.enqueueSnackbar(msg, { variant, autoHideDuration })
  },
}
