import * as THREE from 'three'

import DrawUtil from '@/three/logic/DrawUtil'
import Util from '@/three/logic/Util'
import type { ElementCacheKey } from '@/three/objects'
import CoordinateAxes from '@/three/objects/CoordinateAxes'
import ThreeNozzle from '@/three/objects/Nozzle'
import ThreeSegment from '@/three/objects/Segment'
import { StrandSides } from '@/types/elements/enum'
import { ElementsUtil } from '@/Util/ElementsUtil'

import MainView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'
import Getters from './Getters'
import MainUtil from './MainHandlers'

export default class DrawHandlers {
  public static handleRedrawView (view: MainView, data: any) {
    const { isRedrawing, dataChanged, hasChanges } = view // redraw,

    const caster = view.elementsHashes.Caster

    if (!caster) {
      return
    }

    // TODO: rework this it's messy...
    // TODO: rework redraw
    if (ConditionUtil.shouldBeRedrawing(true, caster, data, hasChanges, isRedrawing, dataChanged)) {
      view.isRedrawing = true
      view.sceneReady = false // TODO: check if this is correct here

      if (view.caster) {
        view.scene.remove(view.caster)

        delete view.caster
      }

      view.reset()

      if (view.isNewCaster) {
        view.views?.uiView?.reset()
      }
    }
  }

  // TODO: view gets called too often
  public static drawStrandGuide (view: MainView) {
    if (!view.caster) {
      return
    }

    const caster = view.caster

    const {
      SegmentGroup,
      SupportPoint,
      Segment,
      Nozzle,
      Roller,
      RollerBody,
      RollerBearing,
      DataPoint,
      DataLine,
      SensorPoint,
    } = view.elementsHashes

    let container = caster

    ThreeSegment.reset()

    const paths: Record<CasterElementNames, Record<string, string>> = {} as any

    const moldMountLog = Object.values(view.elementsHashes.MoldMountLog)[0]
    const mold = view.elementsHashes.Mold[moldMountLog.moldId ?? '']
    const moldFaces = Object
      .values(view.elementsHashes.MoldFace)
      .filter(moldFace => moldFace.moldMountLogId === moldMountLog.id)

    if (moldFaces && moldFaces.length && mold) {
      const moldFacesGroup = view.caster.getObjectByName('moldFaces')

      moldFaces.forEach((moldFace, index) => {
        const face = new THREE.Group()

        face.name = moldFace.side ?? 'MoldFaceMissingSide'
        face.userData.side = moldFace.side
        face.userData.type = 'MoldFace'

        const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

        switch (moldFace.side) {
          case StrandSides.Right:
            newRotation.set(0, -Util.RAD90, 0)
            break
          case StrandSides.Left:
            newRotation.set(0, -Util.RAD90, 0)
            break
          default:
        }

        face.rotation.copy(newRotation)

        Util.addOrReplace(moldFacesGroup, face)

        const dataPointMountLogs = Object
          .values(view.elementsHashes.MoldFaceDataPointsMountLog)
          .filter(dataPoint => dataPoint.moldFaceId === moldFace.id)

        for (const dpMountLog of dataPointMountLogs) {
          const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[dpMountLog.id]

          if (isNaN(numericId)) {
            continue
          }

          const path = `Mold:${index}/DataPoint:${numericId}`

          paths.DataPoint = paths.DataPoint ?? {}
          paths.DataPoint[dpMountLog.id] = path

          const elementInfo = view.elementsHashes.DataPoint[dpMountLog.dataPointId ?? '']
          const dataPoint = ElementsUtil.getFullCasterElement(elementInfo, dpMountLog, numericId)

          if (
            !(
              view.elementList.DataPoint &&
              view.elementList.DataPoint[path] &&
              view.dirtyList &&
              !view.dirtyList.includes(path)
            )
          ) {
            DrawHandlers.handleDrawable(view, face, path, 'DataPoint', dataPoint)
          }
        }
      })
    }

    // Draw order 1- SegmentGroup, 2- Segments, 3- Nozzles, 4- Rollers, 5- RollerBodies, 6- RollerBearings
    // SegmentGroups
    const segmentGroupMountLogHash = ElementsUtil.getSegmentGroupMountLogs(view.elementsHashes)

    const segmentGroupMountLogs = Object.values(segmentGroupMountLogHash)

    paths.SegmentGroup = paths.SegmentGroup ?? {}

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const numericId = MainView.numericIdMountLogMaps.SegmentGroupMountLog[segmentGroupMountLog.id]
      const segmentGroup = SegmentGroup[segmentGroupMountLog.segmentGroupId ?? '']
      const element = ElementsUtil.getFullCasterElement(segmentGroup, segmentGroupMountLog, numericId)

      const path = `SegmentGroup:${MainView.numericIdMountLogMaps.SegmentGroupMountLog[segmentGroupMountLog.id]}`

      paths.SegmentGroup[segmentGroupMountLog.id] = path

      container = Getters.getContainer(view, container, caster, path, 'SegmentGroup', element)
      DrawHandlers.handleDrawable(view, container, path, 'SegmentGroup', element)
    })

    // DataPoints in Strand
    const dataPointMountLogHash = view.elementsHashes.StrandDataPointsMountLog

    const dataPointMountLogs = Object.values(dataPointMountLogHash)

    paths.DataPoint = paths.DataPoint ?? {}

    for (const dataPointMountLog of dataPointMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[dataPointMountLog.id]
      const dataPoint = DataPoint[dataPointMountLog.dataPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(dataPoint, dataPointMountLog, numericId)

      const path = `DataPoint:${MainView.numericIdMountLogMaps.DataPointMountLog[dataPointMountLog.id]}`

      paths.DataPoint[dataPointMountLog.id] = path

      const strandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      DrawHandlers.handleDrawable(view, strandContainer, path, 'DataPoint', element)
    }

    // Segments
    const segmentMountLogHash = ElementsUtil.getSegmentMountLogHashBySegmentGroupMountLogs(
      view.elementsHashes,
      segmentGroupMountLogs,
    )
    const segmentMountLogs = Object.values(segmentMountLogHash)

    paths.Segment = paths.Segment ?? {}

    for (const segmentMountLog of segmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      const numericId = MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLog.id]
      const segment = Segment[segmentMountLog.segmentId ?? '']
      const element = ElementsUtil.getFullCasterElement(segment, segmentMountLog, numericId)

      // TODO: how can i get the segment group mount log id??
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = paths.SegmentGroup[segmentGroupMountLogId ?? '']
      const path = `${parentPath}/Segment:${MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLog.id]}`

      paths.Segment[segmentMountLog.id] = path

      container = view.containerList.SegmentGroup[parentPath]
      container = Getters.getContainer(view, container, caster, path, 'Segment', element)
      DrawHandlers.handleDrawable(view, container, path, 'Segment', element)
    }

    // DataPoints in Segments
    const segmentDataPointsMountLogHash = ElementsUtil.getSegmentDataPointsMountLogHashBySegmentMountLogs(
      view.elementsHashes,
      segmentMountLogs,
    )

    const segmentDataPointsMountLogs = Object.values(segmentDataPointsMountLogHash)

    for (const segmentDataPointsMountLog of segmentDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[segmentDataPointsMountLog.id]
      const dataPoint = DataPoint[segmentDataPointsMountLog.dataPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(dataPoint, segmentDataPointsMountLog, numericId)

      const parentPath = paths.Segment[segmentDataPointsMountLog.segmentMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/DataPoint:${
        MainView.numericIdMountLogMaps.DataPointMountLog[segmentDataPointsMountLog.id]
      }`

      paths.DataPoint[segmentDataPointsMountLog.id] = path

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(view, container, path, 'DataPoint', element)
    }

    // Nozzles
    const nozzleMountLogHash = ElementsUtil.getNozzleMountLogHashBySegmentMountLogs(
      view.elementsHashes,
      segmentMountLogs,
    )
    const nozzleMountLogs = Object.values(nozzleMountLogHash)

    paths.Nozzle = paths.Nozzle ?? {}

    for (const nozzleMountLog of nozzleMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.NozzleMountLog[nozzleMountLog.id]
      const nozzle = Nozzle[nozzleMountLog.nozzleId ?? '']
      const element = ElementsUtil.getFullCasterElement(nozzle, nozzleMountLog, numericId)

      const parentPath = paths.Segment[nozzleMountLog.segmentMountLogId ?? '']
      const path = `${parentPath}/Nozzle:${MainView.numericIdMountLogMaps.NozzleMountLog[nozzleMountLog.id]}`

      paths.Nozzle[nozzleMountLog.id] = path

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(view, container, path, 'Nozzle', element)
    }

    // Rollers
    const rollerMountLogHash = ElementsUtil.getRollerMountLogHashBySegmentMountLogs(
      view.elementsHashes,
      segmentMountLogs,
    )

    const rollerMountLogs = Object.values(rollerMountLogHash)

    paths.Roller = paths.Roller ?? {}

    for (const rollerMountLog of rollerMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.RollerMountLog[rollerMountLog.id]
      const roller = Roller[rollerMountLog.rollerId ?? '']
      const element = ElementsUtil.getFullCasterElement(roller, rollerMountLog, numericId)

      const parentPath = paths.Segment[rollerMountLog.segmentMountLogId ?? '']
      const path = `${parentPath}/Roller:${MainView.numericIdMountLogMaps.RollerMountLog[rollerMountLog.id]}`

      paths.Roller[rollerMountLog.id] = path

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(view, container, path, 'Roller', element)
    }

    // RollerBodies
    const rollerBodyMountLogHash = ElementsUtil.getRollerBodyMountLogHashByRollerMountLogs(
      view.elementsHashes,
      rollerMountLogs,
    )

    const rollerBodyMountLogs = Object.values(rollerBodyMountLogHash)

    paths.RollerBody = paths.RollerBody ?? {}

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.RollerBodyMountLog[rollerBodyMountLog.id]
      const rollerBody = RollerBody[rollerBodyMountLog.rollerBodyId ?? '']
      const element = ElementsUtil.getFullCasterElement(rollerBody, rollerBodyMountLog, numericId)

      const parentPath = paths.Roller[rollerBodyMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/RollerBody:${
        MainView.numericIdMountLogMaps.RollerBodyMountLog[rollerBodyMountLog.id]
      }`

      paths.RollerBody[rollerBodyMountLog.id] = path

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(view, container, path, 'RollerBody', element)
    }

    // RollerBearings
    const rollerBearingMountLogHash = ElementsUtil.getRollerBearingMountLogHashByRollerMountLogs(
      view.elementsHashes,
      rollerMountLogs,
    )

    const rollerBearingMountLogs = Object.values(rollerBearingMountLogHash)

    paths.RollerBearing = paths.RollerBearing ?? {}

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.RollerBearingMountLog[rollerBearingMountLog.id]
      const rollerBearing = RollerBearing[rollerBearingMountLog.rollerBearingId ?? '']
      const element = ElementsUtil.getFullCasterElement(rollerBearing, rollerBearingMountLog, numericId)

      const parentPath = paths.Roller[rollerBearingMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/RollerBearing:${
        MainView.numericIdMountLogMaps.RollerBearingMountLog[rollerBearingMountLog.id]
      }`

      paths.RollerBearing[rollerBearingMountLog.id] = path

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(view, container, path, 'RollerBearing', element)
    }

    // DataPoints in Rollers
    const rollerDataPointsMountLogHash = ElementsUtil.getRollerDataPointsMountLogHashByRollerMountLogs(
      view.elementsHashes,
      rollerMountLogs,
    )

    const rollerDataPointsMountLogs = Object.values(rollerDataPointsMountLogHash)

    paths.DataPoint = paths.DataPoint ?? {}

    for (const rollerDataPointsMountLog of rollerDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[rollerDataPointsMountLog.id]
      const dataPoint = DataPoint[rollerDataPointsMountLog.dataPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(dataPoint, rollerDataPointsMountLog, numericId)

      const parentPath = paths.Roller[rollerDataPointsMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/DataPoint:${
        MainView.numericIdMountLogMaps.DataPointMountLog[rollerDataPointsMountLog.id]
      }`

      paths.DataPoint[rollerDataPointsMountLog.id] = path

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(view, container, path, 'DataPoint', element)
    }

    // SensorPoints in Rollers
    const rollerSensorPointsMountLogHash = ElementsUtil.getRollerSensorPointsMountLogHashByRollerMountLogs(
      view.elementsHashes,
      rollerMountLogs,
    )

    const rollerSensorPointsMountLogs = Object.values(rollerSensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const rollerSensorPointsMountLog of rollerSensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[rollerSensorPointsMountLog.id]
      const sensorPoint = SensorPoint[rollerSensorPointsMountLog.sensorPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(sensorPoint, rollerSensorPointsMountLog, numericId)

      const parentPath = paths.Roller[rollerSensorPointsMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/SensorPoint:${
        MainView.numericIdMountLogMaps.SensorPointMountLog[rollerSensorPointsMountLog.id]
      }`

      paths.SensorPoint[rollerSensorPointsMountLog.id] = path

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(view, container, path, 'SensorPoint', element)
    }

    // DataPoints in Roller Bodies
    // eslint-disable-next-line max-len
    const rollerBodyDataPointsMountLogHash = ElementsUtil.getRollerBodyDataPointsMountLogHashByRollerBodyMountLogs(
      view.elementsHashes,
      rollerBodyMountLogs,
    )

    const rollerBodyDataPointsMountLogs = Object.values(rollerBodyDataPointsMountLogHash)

    for (const rollerBodyDataPointsMountLog of rollerBodyDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[rollerBodyDataPointsMountLog.id]
      const dataPoint = DataPoint[rollerBodyDataPointsMountLog.dataPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(dataPoint, rollerBodyDataPointsMountLog, numericId)

      const parentPath = paths.RollerBody[rollerBodyDataPointsMountLog.rollerBodyMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/DataPoint:${
        MainView.numericIdMountLogMaps.DataPointMountLog[rollerBodyDataPointsMountLog.id]
      }`

      paths.DataPoint[rollerBodyDataPointsMountLog.id] = path

      const { path: rollerPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[rollerPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'RollerBody', element)
      DrawHandlers.handleDrawable(view, container, path, 'DataPoint', element)
    }

    const threeSegmentHash = view.elementList.Segment

    if (!threeSegmentHash) {
      return
    }

    Object.keys(view.containerList.Segment).forEach(path => {
      const data = view.containerList.Segment[path].userData

      threeSegmentHash[path].setValues({
        elementData: data,
        path,
        isDeleted: view.deleteList?.includes(path) ?? false,
        isHidden: view.hideList?.includes(path) ?? false,
        isPhantom: false,
        view,
      })
    })

    // sensor points in roller bodies
    const rollerBodySensorPointsMountLogHash = ElementsUtil.getRollerBodySensorPointsMountLogHashByRollerBodyMountLogs(
      view.elementsHashes,
      rollerBodyMountLogs,
    )

    const rollerBodySensorPointsMountLogs = Object.values(rollerBodySensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const rollerBodySensorPointsMountLog of rollerBodySensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[rollerBodySensorPointsMountLog.id]
      const sensorPoint = SensorPoint[rollerBodySensorPointsMountLog.sensorPointId ?? '']

      const element = ElementsUtil.getFullCasterElement(sensorPoint, rollerBodySensorPointsMountLog, numericId)

      const parentPath = paths.RollerBody[rollerBodySensorPointsMountLog.rollerBodyMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/SensorPoint:${
        MainView.numericIdMountLogMaps.SensorPointMountLog[rollerBodySensorPointsMountLog.id]
      }`

      paths.SensorPoint[rollerBodySensorPointsMountLog.id] = path

      const { path: rollerPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[rollerPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'RollerBody', element)
      DrawHandlers.handleDrawable(view, container, path, 'SensorPoint', element)
    }

    // sensor points in segments
    const segmentSensorPointsMountLogHash = ElementsUtil.getSegmentSensorPointsMountLogHashBySegmentMountLogs(
      view.elementsHashes,
      segmentMountLogs,
    )

    const segmentSensorPointsMountLogs = Object.values(segmentSensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const segmentSensorPointsMountLog of segmentSensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[segmentSensorPointsMountLog.id]
      const sensorPoint = SensorPoint[segmentSensorPointsMountLog.sensorPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(sensorPoint, segmentSensorPointsMountLog, numericId)

      const parentPath = paths.Segment[segmentSensorPointsMountLog.segmentMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = `${parentPath}/SensorPoint:${
        MainView.numericIdMountLogMaps.SensorPointMountLog[segmentSensorPointsMountLog.id]
      }`

      paths.SensorPoint[segmentSensorPointsMountLog.id] = path

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Segment', element)
      DrawHandlers.handleDrawable(view, container, path, 'SensorPoint', element)
    }

    // Segments Update
    for (const segmentMountLog of segmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = paths.SegmentGroup[segmentGroupMountLogId ?? '']
      const path = `${parentPath}/Segment:${MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLog.id]}`

      view.elementList.Segment?.[path]?.updateTransform()
    }

    // SupportPoints
    const supportPointMountLogHash = ElementsUtil.getSupportPointMountLogHashBySegmentGroupMountLogs(
      view.elementsHashes,
      segmentGroupMountLogs,
    )
    const supportPointMountLogs = Object.values(supportPointMountLogHash)

    paths.SupportPoint = paths.SupportPoint ?? {}

    for (const supportPointMountLog of supportPointMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SupportPointMountLog[supportPointMountLog.id]
      const supportPoint = SupportPoint[supportPointMountLog.supportPointId ?? '']
      const element = ElementsUtil.getFullCasterElement(supportPoint, supportPointMountLog, numericId)

      const parentPath = paths.SegmentGroup[supportPointMountLog.segmentGroupMountLogId ?? '']
      const path = `${parentPath}/SupportPoint:${
        MainView.numericIdMountLogMaps.SupportPointMountLog[supportPointMountLog.id]
      }`

      paths.SupportPoint[supportPointMountLog.id] = path

      container = Getters.getContainer(view, container, caster, parentPath, 'SegmentGroup', element)
      DrawHandlers.handleDrawable(view, container, path, 'SupportPoint', element)
    }

    MainView.MountLogIdFullPathMap = paths

    if (view.className !== 'SectionView' && DataLine) {
      const StrandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      const dataLineMountLogs = ElementsUtil.getDataLineMountLogs(view.elementsHashes)

      const mergedDataLinesAndMountLogs = Object.values(dataLineMountLogs).map(dataLineMountLogs => {
        const dataLine = DataLine[dataLineMountLogs.dataLineId ?? '']
        const numericId = MainView.numericIdMountLogMaps.DataLineMountLog[dataLineMountLogs.id]

        return ElementsUtil.getFullCasterElement(dataLine, dataLineMountLogs, numericId)
      })

      // DrawHandlers.buildDataLineIndex(mergedDataLinesAndMountLogs)

      for (let i = 0; i < mergedDataLinesAndMountLogs.length; i++) {
        const dataLine = mergedDataLinesAndMountLogs[i]

        DrawHandlers.handleDrawable(
          view,
          StrandContainer,
          `DataLine:${dataLine.id}`,
          'DataLine',
          dataLine,
        )
      }
    }
  }

  // TODO: is this used?
  // static buildDataLineIndex (dataLines: any[]) {
  //   const dataLinePositionIndexes: any = {}

  //   dataLines.forEach(id => {
  //     const dataLine = DataLine[id]

  //     if (!dataLine) {
  //       return
  //     }

  //     const positionKey = `${dataLine.xCoords[0]}-${dataLine.thicknessCoord}-${dataLine.widthCoord}`

  //     if (dataLinePositionIndexes[positionKey] === undefined) {
  //       dataLinePositionIndexes[positionKey] = 0
  //       dataLine.index = 0
  //     }
  //     else {
  //       dataLine.index = ++dataLinePositionIndexes[positionKey]
  //     }
  //   })
  // }

  private static handleNoDrawableElement (
    type: string,
    path: string,
    container: THREE.Group,
    clickableObjects: any[],
    tooltipObjects: any[],
    sectionDetail: any,
    elementList: any,
    featureFlags?: Record<string, boolean>,
  ) {
    let parent = null

    if (type !== 'SegmentGroup' && type !== 'DataPoint' && type !== 'DataLine') {
      const parentInfo = Util.getParentInfo(path)

      if (!elementList[parentInfo.type]) {
        return
      }

      if (!parentInfo.path.includes('Mold')) {
        parent = elementList[parentInfo.type][parentInfo.path]
      }
    }

    elementList[type][path] = DrawUtil.getNewElementByType(
      type,
      parent,
      container,
      clickableObjects,
      tooltipObjects,
      sectionDetail,
      undefined,
      featureFlags,
    )
  }

  private static handleDrawableNozzle (path: string, element: any, view: MainView) {
    const { elementList, largestNozzle, widestNozzle, largestNarrowNozzle, widestNarrowNozzle } = view
    const keysToBeChanged: Pick<
      MainView,
      'largestNarrowNozzle' | 'largestNozzle' | 'widestNarrowNozzle' | 'widestNozzle'
    > = {} as any
    const height = element.height / 1000
    const widthCoord = element.widthCoord / 1000
    const angleWidth = element.angleWidth

    const b = ThreeNozzle.getB(height, angleWidth / 2)
    const wide = b + Math.abs(widthCoord)

    const { side } = elementList.Nozzle?.[path]?.parent?.container?.userData ?? {}

    if (side !== StrandSides.Left && side !== StrandSides.Right) {
      if (largestNozzle < height) {
        keysToBeChanged.largestNozzle = height
      }

      if (widestNozzle < wide) {
        keysToBeChanged.widestNozzle = wide
      }
    }
    else if (side === StrandSides.Left || side === StrandSides.Right) {
      if (largestNarrowNozzle < height) {
        keysToBeChanged.largestNarrowNozzle = height
      }

      if (widestNarrowNozzle < wide) {
        keysToBeChanged.widestNarrowNozzle = wide
      }
    }

    return keysToBeChanged
  }

  private static handleDrawableRoller (type: string, path: string, element: any, view: MainView) {
    const { elementList, largestRoller, widestRoller, largestNarrowRoller, widestNarrowRoller } = view

    const keysToBeChanged: Pick<
      MainView,
      'largestNarrowRoller' | 'largestRoller' | 'widestNarrowRoller' | 'widestRoller'
    > = {} as any
    const diameter = type !== 'RollerBearing' ? element.diameter / 1000 : 0
    const width = type === 'Roller'
      ? element.rollWidth / 2 / 1000
      : element.widthCoord > 0
      ? element.width / 1000 + element.widthCoord / 1000
      : Math.abs(element.widthCoord / 1000)
    const { side } =
      elementList[type as 'Roller' | 'RollerBearing' | 'RollerBody']?.[path]?.parent?.container?.userData ?? {}

    if (side !== StrandSides.Left && side !== StrandSides.Right) {
      if (largestRoller < diameter) {
        keysToBeChanged.largestRoller = diameter
      }

      if (widestRoller < width) {
        keysToBeChanged.widestRoller = width
      }
    }
    else if (side === StrandSides.Left || side === StrandSides.Right) {
      if (largestNarrowRoller < diameter) {
        keysToBeChanged.largestNarrowRoller = diameter
      }

      if (widestNarrowRoller < width) {
        keysToBeChanged.widestNarrowRoller = width
      }
    }

    return keysToBeChanged
  }

  private static handleDrawable (
    view: MainView,
    container: THREE.Group,
    path: string,
    type: ElementCacheKey,
    element: any,
  ) {
    if (!element) {
      return
    }

    if (!view.elementList[type]) {
      view.elementList[type] = {}
    }

    if (!view.elementList[type]?.[path]) {
      DrawHandlers.handleNoDrawableElement(
        type,
        path,
        container,
        view.clickableObjects,
        view.tooltipObjects,
        view.sectionDetail,
        view.elementList,
        view.featureFlags,
      )
    }

    if (!view.elementList[type]?.[path]) {
      return
    }

    const element2 = view.elementList[type]?.[path]

    if (type !== 'Segment' && element2) {
      element2.setValues({
        elementData: element as never, // TODO: fix the never type
        path,
        isDeleted: view.deleteList?.includes(path) ?? false,
        isHidden: view.hideList?.includes(path) ?? false,
        isPhantom: false,
        view,
      })

      switch (type) {
        case 'Roller':
        case 'RollerBody':
        case 'RollerBearing':
          {
            const keysToBeChangedRoller = DrawHandlers.handleDrawableRoller(type, path, element, view)

            for (const key in keysToBeChangedRoller) {
              ;(view as any)[key] = keysToBeChangedRoller[key as keyof typeof keysToBeChangedRoller]
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            element2.setVisibility(view.rollerChildren)
          }

          break

        case 'Nozzle':
          {
            const keysToBeChangedNozzle = DrawHandlers.handleDrawableNozzle(path, element, view)

            for (const key in keysToBeChangedNozzle) {
              ;(view as any)[key] = keysToBeChangedNozzle[key as keyof typeof keysToBeChangedNozzle]
            }
          }

          break
        default:
      }

      // use to optimize
      // this.time3[type] = (this.time3[type] ?? 0) + (Date.now() - start3)
    }
  }

  public static drawGridHelper (view: MainView) {
    if (!view.passlineCurve?.objects?.line) {
      return
    }

    const { geometry } = view.passlineCurve.objects.line

    geometry.computeBoundingBox()

    if (!geometry.boundingBox) {
      // eslint-disable-next-line no-console
      console.warn('No bounding box')

      return
    }

    const { min, max } = geometry.boundingBox

    const { length, calculatedPosition } = CalculationUtil.calcGridHelper(min, max)

    view.gridHelper = new THREE.GridHelper(length, length)
    view.gridHelper.name = 'GridHelper'
    view.gridHelper.position.set(
      calculatedPosition.x,
      calculatedPosition.y,
      calculatedPosition.z,
    )

    Util.addOrReplace(view.scene, view.gridHelper)
  }

  public static redrawSectionPlane (view: MainView, Caster: Caster) {
    if (!view.views || !view.views.sectionView || !view.elementsHashes.Passline || !view.caster) {
      return
    }

    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementsHashes, view.referenceCasterDate)

    view.coordinate = new CoordinateAxes(view.caster)
    view.coordinateStraight = new CoordinateAxes(view.caster, true)

    const { mold, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementsHashes)

    for (const side of Util.wideSides) {
      if (!mold || !moldMountLog) {
        break
      }

      view.coordinate?.generateCoordinateAxes(Caster, sections, mold, moldMountLog, side)
      view.coordinateStraight?.generateCoordinateAxes(Caster, sections, mold, moldMountLog, side)
    }

    if (view.applyCurve) {
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
    }

    const sectionView = view.views.sectionView

    const sectionPlaneGeometry = CalculationUtil.getSectionPlaneGeometry(
      mold?.thickness ?? 0,
      moldMountLog?.width ?? 0,
      sectionView.largestNarrowNozzle,
      sectionView.largestNozzle,
      sectionView.widestNarrowNozzle,
      sectionView.widestNozzle,
      sectionView.largestNarrowRoller,
      sectionView.largestRoller,
      sectionView.widestNarrowRoller,
      sectionView.widestRoller,
    )

    view.sectionPlane.geometry = sectionPlaneGeometry
    view.sectionPlane.geometry.translate(-(mold?.thickness ?? 0) / 1000 / 2, 0, 0)

    view.views.sectionView.updatePlane = true

    if (!view.sectionDetail) {
      DrawHandlers.drawGridHelper(view)
    }
  }

  public static drawCaster (view: MainView) {
    const { mold, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementsHashes)

    if (!mold || !moldMountLog) {
      return
    }

    const { thickness: rawThickness } = mold.realDataUUID ? moldMountLog : mold
    const thickness = (rawThickness ?? 0) / 1000

    MainUtil.resetSegments(view.containerList)

    view.center2d = {
      x: -thickness / 2,
      y: view.plHeight - 1.5,
    }

    Object.values(view.sideLabels).forEach((label: any) => {
      label.visible = false
    })

    if (view.gridHelper) {
      view.gridHelper.visible = !view.sectionDetail
    }

    view.passlineCurve?.showStrand()
    view.passlineCurve?.setPosition(new THREE.Vector3(0, 0, 0))
    view.camera = view.perspectiveCamera
    view.sectionPlane.visible = !view.sectionDetail && Boolean(view.views?.uiView?.isSectionActive)

    view.setupControls()

    if (!view.sectionDetail) {
      view.jumpToFirst(false)
    }

    view.resize()
  }
}
