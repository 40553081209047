export type MountLogToKeyUUIDsMap = {
  [elementType in FilterableElementType]: {
    [elementAttribute: string]: {
      [mountLogId: string]: number
    }
  }
}

type actionType = {
  type: string
  data: MountLogToKeyUUIDsMap
}

enum MountLogToKeyUUIDsMapActionsEnum {
  ACTION_SET_MLTK_UUID = 'MountLogToKeyUUIDsMap/ACTION_SET_MLTK_UUID',
}

export function setMountLogToKeyUUIDsMap (data: MountLogToKeyUUIDsMap) {
  return {
    type: MountLogToKeyUUIDsMapActionsEnum.ACTION_SET_MLTK_UUID,
    data,
  }
}

const initialState: MountLogToKeyUUIDsMap = {
  AirLoop: {},
  CoolingLoop: {},
  DataLine: {},
  DataPoint: {},
  Nozzle: {},
  Roller: {},
  RollerBearing: {},
  RollerBody: {},
  Segment: {},
  SegmentGroup: {},
  SensorPoint: {},
  SupportPoint: {},
}

const actionHandlers: Record<string, (state: MountLogToKeyUUIDsMap, action: actionType) => MountLogToKeyUUIDsMap> = {
  [MountLogToKeyUUIDsMapActionsEnum.ACTION_SET_MLTK_UUID]: (state: MountLogToKeyUUIDsMap, action: actionType) => ({
    ...state,
    ...action.data,
  }),
}

export default function (state = initialState, action: actionType) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
