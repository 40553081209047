export enum coolingZoneActionsEnum {
  ACTION_ADD_COOLING_ZONE = 'CoolingZone/ACTION_ADD_COOLING_ZONE',
  DELETE = 'CoolingZone/DELETE',
  DELETE_MULTIPLE = 'CoolingZone/DELETE_MULTIPLE',
  UPDATE = 'CoolingZone/UPDATE',
  RESET = 'CoolingZone/RESET',
}

export function addCoolingZone (coolingZoneHash: any) {
  return {
    type: coolingZoneActionsEnum.ACTION_ADD_COOLING_ZONE,
    coolingZoneHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const coolingZoneReducers: Record<string, Function | undefined> = {
  [coolingZoneActionsEnum.ACTION_ADD_COOLING_ZONE]: (_state: any, action: { coolingZoneHash: any }) => (
    action.coolingZoneHash
  ),

  [coolingZoneActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [coolingZoneActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [coolingZoneActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),

  [coolingZoneActionsEnum.RESET]: () => ({}),
}

export default function (state: any = {}, action: any) {
  const handler = coolingZoneReducers[action.type]

  return handler ? handler(state, action) : state
}
