import Mold from '@/three/objects/Mold'
import { StrandSides } from '@/types/elements/enum'
import type { ElementsHashes } from '@/types/state'

import { defaultFields, getHiddenFields } from './defaults'

/* eslint-disable camelcase */

const getDistToPassline = (element: any, elementsHashes: ElementsHashes) => {
  if (Mold.thickness && Mold.width) {
    const segmentMountLogId = element.segmentMountLog
    const segmentMountLog = elementsHashes.SegmentMountLog[segmentMountLogId]
    const segmentId = segmentMountLog?.segmentId
    const segment = elementsHashes.Segment[segmentId ?? '']
    const { side } = segment ?? {}
    const radius = element.diameter / 2

    switch (side) {
      case StrandSides.Fixed:
        return radius
      case StrandSides.Loose:
        return radius + (Mold.thickness * 1000)
      case StrandSides.Left:
      case StrandSides.Right:
        return radius + ((Mold.width / 2) * 1000)
      default:
    }
  }

  return -1
}

/* eslint-disable max-len */
/* eslint-disable key-spacing */
export const Roller = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    diameter: { type: 'number', category: 1, defaultValue: 0, positive: true },
    dist2passline: {
      type: 'number',
      category: 1,
      defaultValue: 0,
      disabled: true,
      generate: getDistToPassline,
      positive: true,
    },
    jawsSize: { type: 'number', category: 1, defaultValue: 0, positive: true },
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true },
    penetrationLength: { type: 'number', category: 1, defaultValue: 0, positive: true },
    rollWidth: { type: 'number', category: 1, defaultValue: 0, positive: true },
    drive: { type: 'select', category: 2, defaultValue: 0, options: [ 0, 1 ] },
    isCooled: { type: 'select', category: 2, defaultValue: 0, options: [ 0, 1 ] },
    // id:                        { type: 'number', category: 2, defaultValue: 0, disabled: true },
    internalCoolWaterFlowRate: { type: 'number', category: 2, defaultValue: 0, positive: true },
    name: { type: 'text', category: 2, defaultValue: '' },
    ...defaultFields,
    ...getHiddenFields([
      'rollerId',
      'widthCoord',
      'thicknessCoord',
      'segmentMountLogId',
      'dataPointMountLogs',
      'rollerBearingMountLogs',
      'rollerBodyMountLogs',
      'sensorPointMountLogs',
    ]),
  },
}
/* eslint-enable max-len */
/* eslint-enable key-spacing */
