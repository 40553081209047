import * as THREE from 'three'
import { PerspectiveCamera } from 'three'

import Util from '@/three/logic/Util'
import { Views } from '@/three/ThreeBase'

import BaseView from '../BaseView'

export default class AxisView extends BaseView {
  private readonly axesHelper: THREE.Group

  public override readonly camera: PerspectiveCamera

  private static getArrow (x: number, y: number, z: number, color: string, length = 0.85) {
    const direction = new THREE.Vector3(x, y, z)

    direction.normalize()

    return new THREE.ArrowHelper(direction, new THREE.Vector3(), length, color, 0.2, 0.1)
  }

  private static getLetter (x: number, y: number, z: number, letter: string, size = 0.2) {
    const letterMesh = Util.getText(letter, size, false, true)

    if (!letterMesh) {
      return null
    }

    letterMesh.position.set(x, y, z)
    letterMesh.name = `letter_${letter}`

    return letterMesh
  }

  public constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    AxisView.staticClassName = 'AxisView'
    this.className = 'AxisView'

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000)
    this.camera.position.z = 3

    this.axesHelper = new THREE.Group()

    this.axesHelper.add(AxisView.getArrow(1, 0, 0, '#FF0000'))
    this.axesHelper.add(AxisView.getArrow(0, 1, 0, '#00FF00'))
    this.axesHelper.add(AxisView.getArrow(0, 0, 1, '#0000FF'))

    const x = AxisView.getLetter(1, 0, 0, 'x')

    if (x) {
      this.axesHelper.add(x)
    }

    const y = AxisView.getLetter(0, 1.1, 0, 'y')

    if (y) {
      this.axesHelper.add(y)
    }

    const z = AxisView.getLetter(0, 0, 1, 'z')

    if (z) {
      this.axesHelper.add(z)
    }

    this.scene.add(this.axesHelper)
  }

  public override resize (_width: number, _height: number) {
    const size = 125

    this.viewport = {
      x: 0,
      y: 0,
      width: size,
      height: size,
    }
  }

  public override animate () {
    const { mainView } = this.views ?? {}

    if (mainView) {
      this.camera.position.copy(mainView.camera.position)
      this.camera.position.sub(mainView.controls.target)
    }

    this.camera.position.setLength(3)
    this.camera.lookAt(this.scene.position)

    this.axesHelper.getObjectByName('letter_x')?.quaternion.copy(this.camera.quaternion)
    this.axesHelper.getObjectByName('letter_y')?.quaternion.copy(this.camera.quaternion)
    this.axesHelper.getObjectByName('letter_z')?.quaternion.copy(this.camera.quaternion)
  }

  public hide () {
    this.axesHelper.visible = false
  }

  public show () {
    this.axesHelper.visible = true
  }
}
