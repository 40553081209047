import { useConfig } from '@/config'
import FilterHandler from '@/three/logic/FilterHandler'
import { DefaultState } from '@/types/state'

import { FilterActionsEnum } from './consts'

export function setTerm (term: string, isCtrl = false, isFilterClick = false, isElementClick = false) {
  return {
    type: FilterActionsEnum.ACTION_SET_TERM,
    term,
    isCtrl,
    isFilterClick,
    isElementClick,
  }
}

export function setTarget (target: string) {
  return {
    type: FilterActionsEnum.ACTION_SET_TARGET,
    target,
  }
}

export function resetTarget () {
  return {
    type: FilterActionsEnum.ACTION_RESET_TARGET,
  }
}

export function setFilterValues (filterElement: Record<CasterDialogElementType, any>) {
  return {
    type: FilterActionsEnum.ACTION_SET_FILTER_VALUES,
    filterElement,
  }
}

export function setCurrentFilterControlValue (value?: number | string) {
  return {
    type: FilterActionsEnum.ACTION_SET_CURRENT_FILTER_CONTROL_VALUE,
    currentFilterControlValue: value,
  }
}

export function setActiveFilterControlIndex (activeFilterControlIndex?: number) {
  return {
    type: FilterActionsEnum.ACTION_SET_ACTIVE_FILTER_CONTROL_INDEX,
    activeFilterControlIndex,
  }
}

function getFilterVariables (definitions: FilterControlDefinition[]) {
  const filterVariables: string[] = []

  definitions.forEach(definition => {
    if (definition.setVar && typeof definition.setVar === 'string' && !filterVariables.includes(definition.setVar)) {
      filterVariables.push(definition.setVar)
    }
  })

  return filterVariables
}

export function setTermDisabled (termDisabled: boolean) {
  return {
    type: FilterActionsEnum.ACTION_SET_IS_TERM_DISABLED,
    termDisabled,
  }
}

export function getFilterControlDefinitions () {
  return {
    types: [
      FilterActionsEnum.ACTION_GET_FILTER_CONTROL_DEFINITIONS_REQUEST,
      FilterActionsEnum.ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS,
      FilterActionsEnum.ACTION_GET_FILTER_CONTROL_DEFINITIONS_ERROR,
    ],
    promise: (
      client: any,
      _state: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      _dispatch: Function,
    ) =>
      client
        .get(`${useConfig().apiBaseURL}/data/filter-controls`)
        .then((filterControlDefinitions: FilterControlDefinition[]) => {
          const filterControlVariables = getFilterVariables(filterControlDefinitions)

          filterControlVariables.forEach(variable => {
            if (!FilterHandler.variables.includes(variable)) {
              FilterHandler.variables.push(variable)
            }
          })
          FilterHandler.variableRegEx = new RegExp(`(${FilterHandler.variables.join('|')})`, 'g')

          return {
            filterControlDefinitions,
            filterControlVariables,
          }
        })
        .catch((error: any) => {
          throw new Error(error)
        }),
  }
}
