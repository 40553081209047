export class SetUtil {
  /**
   * Equivalent to Array.prototype.some
   */
  public static some<T> (set: Set<T>, predicate: (value: T) => boolean): boolean {
    for (const value of set) {
      if (predicate(value)) {
        return true
      }
    }

    return false
  }

  /**
   * Equivalent to Array.prototype.filter
   */
  public static filter<T> (set: Set<T>, predicate: (value: T) => boolean): Set<T> {
    const result = new Set<T>()

    for (const value of set) {
      if (predicate(value)) {
        result.add(value)
      }
    }

    return result
  }
}
