export default class Logic {
  public static readonly GRID_STEP_SIZE = 16 // 16px

  public static readonly FULL_HD_VIEW_WIDTH: number = Logic.GRID_STEP_SIZE * 240 // 3840

  public static readonly FULL_HD_VIEW_HEIGHT: number = Logic.GRID_STEP_SIZE * 120 * 3 // 1920 // * 3 = 5760

  public static readonly GRID_COLUMNS: number = Logic.FULL_HD_VIEW_WIDTH / Logic.GRID_STEP_SIZE

  public static readonly GRID_ROWS: number = Logic.FULL_HD_VIEW_HEIGHT / Logic.GRID_STEP_SIZE

  public static readonly GRID_SIZE: number = Logic.GRID_ROWS * Logic.GRID_COLUMNS

  public static readonly MIN_COLUMNS = 15 // 240 / 15 = 16

  public static readonly MIN_ROWS = 10 // 120 / 10 = 12

  public static isBlocked (
    { x, y, w, h }: { x: number, y: number, w: number, h: number },
    index: number,
    layouts: Array<any>,
    blockedPositions: Record<string, boolean>,
  ) {
    let newPos
    let contentW = w
    let contentH = h
    const newBlockedPositions: Record<string, boolean> = {}
    const amountOfBlockedPositions = Object.keys(blockedPositions).length

    if (contentW * contentH > Logic.GRID_SIZE - amountOfBlockedPositions - (layouts.length - index) * Logic.MIN_ROWS) {
      return true
    }

    while (contentW + x > x) {
      while (contentH + y > y) {
        newPos = `${x + contentW - 1}:${y + contentH - 1}`

        const includes = blockedPositions[newPos]

        if (includes || x + contentW - 1 >= Logic.GRID_COLUMNS || y + contentH - 1 >= Logic.GRID_ROWS) {
          return true
        }

        newBlockedPositions[newPos] = true

        contentH--
      }

      contentH = h
      contentW--
    }

    const possibleLength = Logic.GRID_SIZE - (layouts.length - index) * Logic.MIN_ROWS
    const newLength = amountOfBlockedPositions + Object.keys(newBlockedPositions).length

    if (newLength > possibleLength) {
      return true
    }

    blockedPositions = {
      ...blockedPositions,
      ...newBlockedPositions,
    }

    return false
  }

  public static getLayout (state: any, action: any) {
    const layouts: any[] = action.layout
    const blockedPositions: Record<string, boolean> = {}
    let blockNewPlots = false

    let totalHeight = 0

    for (const layout of layouts) {
      totalHeight += layout.h
    }

    if (totalHeight + Logic.MIN_ROWS + Logic.GRID_STEP_SIZE * 10 > Logic.GRID_ROWS) {
      blockNewPlots = true
    }

    layouts.forEach((layout: any, index: number) => {
      layout.minH = Logic.MIN_ROWS
      layout.w = Logic.MIN_COLUMNS > layout.w ? Logic.MIN_COLUMNS : layout.w

      if (layout.h < Logic.MIN_ROWS) {
        layout.h = Logic.MIN_ROWS
      }

      layout.h = layout.h < Logic.GRID_ROWS ? layout.h : Logic.GRID_ROWS

      if (layout.y + layout.h > Logic.GRID_ROWS || Logic.isBlocked(layout, index + 1, layouts, blockedPositions)) {
        layout.y = 0
        layout.x = 0

        while (Logic.isBlocked(layout, index + 1, layouts, blockedPositions)) {
          layout.y++

          if (layout.y + layout.h - 1 >= Logic.GRID_ROWS) {
            layout.y = 0
            layout.x++
          }

          if (layout.x === Logic.GRID_COLUMNS) {
            layout.x = 0

            if (layout.w > 1) {
              layout.w--
            }
            else if (layout.h > Logic.MIN_ROWS) {
              layout.h--
            }
          }
        }
      }
    })

    if (Object.keys(blockedPositions).length >= Logic.GRID_SIZE) {
      blockNewPlots = true
    }

    return {
      ...state.viewsObject,
      [action.viewId]: {
        ...state.viewsObject[action.viewId],
        dashboards: {
          ...state.viewsObject[action.viewId].dashboards,
          [action.dashboardId]: {
            ...(state.viewsObject[action.viewId].dashboards[action.dashboardId] ?? {}),
            layout: [
              ...layouts,
            ],
            blockNewPlots,
          },
        },
      },
    }
  }
}
