// TODO: check if this is needed once the offline server is used

import LogicInterface from './LogicInterface'

// if (!window.require) {
//   window.require = () => ({ ipcRenderer: {} })
// }
function getIpcRenderer () {
  if (!window.require) {
    return { ipcRenderer: {} }
  }

  return window.require('electron')
}

const { ipcRenderer } = getIpcRenderer() as any

export default class ElectronLogic extends LogicInterface {
  public on (...params: any[]) {
    ipcRenderer.on(...params)
  }

  public send (...params: any[]) {
    ipcRenderer.send(...params)
  }

  public removeListener (...params: any[]) {
    ipcRenderer.removeListener(...params)
  }
}
