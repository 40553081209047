export enum moldActionsEnum {
  ACTION_ADD_MOLD = 'Mold/ACTION_ADD_MOLD',
  DELETE = 'Mold/DELETE',
  RESET = 'Mold/RESET',
}

export function addMold (mold: Mold) {
  return {
    type: moldActionsEnum.ACTION_ADD_MOLD,
    mold,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const moldReducers: Record<string, Function | undefined> = {
  [moldActionsEnum.ACTION_ADD_MOLD]: (_state: Mold, action: { mold: Mold }) => action.mold,

  [moldActionsEnum.DELETE]: (_state: Mold) => null,

  [moldActionsEnum.RESET]: () => null,
}

export default function (state: Mold | null = null, action: any) {
  const handler = moldReducers[action.type]

  return handler ? handler(state, action) : state
}
