import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ $size?: number }>`${({ $size }) =>
  css`
  position: relative;
  ${$size && css`font-size: ${$size}px;`}
`}`

const Position = styled.div<{ $x: number, $y: number }>`${({ theme, $x, $y }) =>
  css`
  display: inline-block;
  position: absolute;
  top: ${$y}px;
  left: ${$x}px;

  i {
    color: ${theme.colors.swatch19};
    cursor: help;
  }
`}`

const Text = styled.div`
  white-space: pre-wrap;
`

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: 425,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '0 0 5px 0 rgba(127,127,127,.5)',
  },
}))(Tooltip)

type Props = {
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined
  message: string
  x: number
  y: number
  size?: number
}

export class InfoMarker extends Component<Props> {
  public override render () {
    const { placement, message, x, y, size } = this.props

    return (
      <Wrapper $size={size}>
        <Position $x={x} $y={y}>
          <StyledTooltip title={<Text>{message}</Text>} placement={placement ?? 'top'}>
            <i className='pe-7s-info' />
          </StyledTooltip>
        </Position>
      </Wrapper>
    )
  }
}

export default InfoMarker
