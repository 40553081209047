import cloneDeep from 'lodash/cloneDeep'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { createCaseVerification } from '@/api/case-verification'
import { useConfig } from '@/config'
import { allVerified, isVerified } from '@/logic/case-verification'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import Icon from '@/react/specific/Icon'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import * as MatrixActions from '@/store/matrix/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import { ActionButton } from './Styles'
import { CloneDialog } from '../CloneDialog'
import Logic from '../Logic'

const T = 'projectMatrixDialog'

const connector = connect((state: DefaultState) => ({
  selections: state.matrix.selections,
  grid: state.matrix.grid,
  currentProject: state.application.main.currentProject,
  simulationCases: state.application.main.currentProject.simulationCases,
  caseVerifications: state.application.main.caseVerifications,
}), {
  resetReducer: DataActions.resetReducer,
  resetAllElements: ElementsActions.resetAllElements,
  setSelections: MatrixActions.setSelections,
  setCloneSimulationCaseId: MatrixActions.setCloneSimulationCaseId,
  openDialog: ApplicationActions.openDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  addCaseVerification: ApplicationActions.addCaseVerification,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  row: number
  children: any
  t: Translation
}

type State = {
  shiftKey: boolean
}

class RowRenderer extends Component<Props, State> {
  public override state: State = {
    shiftKey: false,
  }

  public override componentDidMount () {
    window.addEventListener('keydown', this.handleKeyDown, false)
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  public override componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown, false)
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  private readonly handleKeyDown = ({ shiftKey }: any) => {
    if (shiftKey) {
      this.setState({ shiftKey })
    }
  }

  private readonly handleKeyUp = ({ shiftKey }: any) => {
    if (!shiftKey) {
      this.setState({ shiftKey })
    }
  }

  private readonly handleSelectionChange = (event: any) => {
    const { row, grid, selections, setSelections } = this.props
    const { shiftKey } = this.state
    const newSelections = cloneDeep(selections)
    const selectionValueArray = Object.values(selections)

    if (shiftKey && selectionValueArray.filter(val => val).length > 0) {
      const index = selectionValueArray.indexOf(true)

      const start = Math.min(index, row)
      const end = Math.max(index, row)

      Object.keys(newSelections).forEach((key, index) => {
        if (index >= start && index <= end) {
          newSelections[key] = event.target.checked
        }
      })
    }
    else {
      const { id } = grid[row].find(cell => cell.key === 'simulation_case')?.data ?? {}

      newSelections[id] = event.target.checked
    }

    setSelections(newSelections)
  }

  private readonly handleCloneClick = () => {
    const { row, grid, setCloneSimulationCaseId, openDialog } = this.props
    const { id } = grid[row].find(cell => cell.key === 'simulation_case')?.data ?? {}

    if (id) {
      setCloneSimulationCaseId(id)
      openDialog(CloneDialog.NAME)
    }
  }

  private readonly handleVerifyProcessingParameters = async (simulationCaseId: string) => {
    const { currentProject } = this.props // , setCurrentProject, addCaseVerification

    const verification = await createCaseVerification(
      currentProject.id,
      simulationCaseId,
      VerificationType.Parameters,
      true,
    )

    if (!verification) {
      // return
    }

    // FIXME: only works for the currentCase and project.simulationCases does not contain exist anymore
    // addCaseVerification(verification)

    // ApiClient
    //   .patch(`${'Network.URI(deprecated)'}/simulation_case_verify/processing_parameters/${simulationCaseId}`)
    //   .then(({ verificationList }) => {
    //     const project = cloneDeep(currentProject)
    //     const simulationCase = project.simulationCases.find(simulationCase => simulationCase.id === simulationCaseId)

    //     if (simulationCase) {
    //       simulationCase.processingParametersVerifications = verificationList

    //       setCurrentProject(project)
    //     }
    //   })
    //   .catch(error => {
    //     // eslint-disable-next-line no-console
    //     console.log(error)
    //   })
  }

  private readonly handleSwitchToCase = () => {
    const {
      row,
      grid,
      caseVerifications,
      openDialog,
      setCurrentSimulationCase,
      resetReducer,
      resetAllElements,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
    } = this.props
    const { id } = grid[row].find(cell => cell.key === 'simulation_case')?.data ?? {}

    // FIXME: get selected catalog from the store
    const selectedCatalog = 'default'

    // FIXME: this is missing verifications for all cases
    const isCasterVerified = isVerified(id, caseVerifications, VerificationType.Caster)
    const isCatalogVerified = isVerified(id, caseVerifications, VerificationType.Catalog, selectedCatalog)
    const isParametersVerified = isVerified(id, caseVerifications, VerificationType.Parameters)

    if (isCasterVerified && isCatalogVerified && !isParametersVerified) {
      this.handleVerifyProcessingParameters(id)

      return
    }

    ApiClient
      .get(`${useConfig().apiBaseURL}/cases/${id}`)
      .then(({ simulationCase }) => {
        resetReducer()
        resetAllElements()

        const { dataId } = simulationCase.visualizationDataList?.slice(-1)?.[0] ?? {}

        setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
        setVisualizationMetaInformation('data', dataId ?? '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.Caster)
        setCurrentCatalogId()

        setCurrentSimulationCase(simulationCase)
        openDialog(ProjectDataDialog.NAME)

        Logic.handleClose()
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  public override render () {
    const { grid, selections, row, children, t, simulationCases, caseVerifications } = this.props

    if (row >= grid.length) {
      return null
    }

    const { id } = grid[row].find(cell => cell.key === 'simulation_case')?.data ?? {}
    const simulationCase = simulationCases.filter(sc => sc.id === id)[0] ?? {}
    const isVerified = (simulationCase.simulationStartedAt ?? '').length > 0 ||
      allVerified(simulationCase.id, caseVerifications, 'default') // FIXME: get selected catalog

    return (
      <tr className='data-row'>
        <td className='row-number-cell cell'>{row + 1}</td>
        <td className='selection-cell cell'>
          <input type='checkbox' checked={selections[id]} onChange={this.handleSelectionChange} />
        </td>
        <td className='action-cell cell'>
          <ActionButton
            variant='contained'
            color='primary'
            onClick={this.handleCloneClick}
            title={t(`${T}.button.clone`)}
          >
            <Icon icon='clone' />
          </ActionButton>
          <ActionButton
            variant='contained'
            color='primary'
            onClick={this.handleSwitchToCase}
            title={t(`${T}.button.${isVerified ? 'view' : 'verify'}`)}
          >
            {isVerified ? <Icon icon='eye' /> : <Icon icon='check' />}
          </ActionButton>
        </td>
        {children}
      </tr>
    )
  }
}

export default withNamespaces('application')(connector(RowRenderer as any) as any) as any
