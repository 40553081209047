export enum SegmentMountLogActionsEnum {
  ACTION_ADD_SEGMENT_MOUNT_LOG = 'SegmentMountLog/ACTION_ADD_SEGMENT_MOUNT_LOG',
  DELETE = 'SegmentMountLog/DELETE',
  DELETE_MULTIPLE = 'SegmentMountLog/DELETE_MULTIPLE',
  UPDATE = 'SegmentMountLog/UPDATE',
  RENAME = 'SegmentMountLog/RENAME',
  RESET = 'SegmentMountLog/RESET',
}

export function addSegmentMountLog (segmentHash: SegmentMountLogHash) {
  return {
    type: SegmentMountLogActionsEnum.ACTION_ADD_SEGMENT_MOUNT_LOG,
    segmentHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentMountLogReducers: Record<string, Function | undefined> = {
  [SegmentMountLogActionsEnum.ACTION_ADD_SEGMENT_MOUNT_LOG]: (
    _state: SegmentMountLogHash,
    action: { segmentHash: SegmentMountLogHash },
  ) => (
    action.segmentHash
  ),

  [SegmentMountLogActionsEnum.DELETE]: (state: SegmentMountLogHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentMountLogActionsEnum.DELETE_MULTIPLE]: (state: SegmentMountLogHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentMountLogActionsEnum.UPDATE]: (state: SegmentMountLogHash, action: { elements: SegmentMountLogHash }) => ({
    ...state,
    ...action.elements,
  }),
  [SegmentMountLogActionsEnum.RENAME]: (state: SegmentMountLogHash, action: { name: string, id: number }) => ({
    ...state,
    [action.id]: {
      ...state[action.id],
      name: action.name,
    },
  }),

  [SegmentMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: SegmentMountLogHash = {},
  action: any,
) {
  const handler = SegmentMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
