export const StrandSides = {
  Fixed: 'fixed',
  Loose: 'loose',
  Left: 'left',
  Right: 'right',
} as Record<keyof typeof StrandSide, StrandSide>

export const DisplayStrandSides = {
  fixed: 'FixedSide',
  loose: 'LooseSide',
  left: 'NarrowFaceLeft',
  right: 'NarrowFaceRight',
} as Record<StrandSide, string>
