import * as THREE from 'three'
import { Material, Vector2 } from 'three'

import TextureUtil from '@/three/logic/TextureUtil'
import Util from '@/three/logic/Util'
import PasslineCurve from '@/three/objects/PasslineCurve'

import SectionView from '.'
import CalculationUtil from './CalculationUtil'
import MainView from '../MainView'

export default class Getters {
  public static getSwitcher (dto: {
    type?: string
    x: number | null
    y: number
    width: number
    height: number
    name: string
    action: any
    switcherValue: boolean
    tooltip: string
    // textureRepeat,
    // textureOffset,
    buttons: any
    visible: boolean
    setPosition: boolean
    enableIcon?: any
    disableIcon?: any
    iconNextToSwitcher?: boolean
    buttonGroup?: any
    space?: number
  }) {
    const {
      type,
      x,
      y,
      width,
      height,
      name,
      action,
      switcherValue,
      tooltip,
      // textureRepeat,
      // textureOffset,
      buttons,
      visible,
      setPosition,
      enableIcon,
      disableIcon,
      iconNextToSwitcher,
      buttonGroup,
      space,
    } = dto

    let textureOn = TextureUtil.load('textures/ui/toggle-on-solid.png')
    let textureOff = TextureUtil.load('textures/ui/toggle-off-solid.png')

    const switcherGeometry = new THREE.PlaneGeometry(width, height, 1)
    const texture = switcherValue ? textureOn : textureOff

    if (iconNextToSwitcher && buttonGroup && space && enableIcon && disableIcon) {
      const texture = switcherValue ? enableIcon : disableIcon
      const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
      const icon = new THREE.Mesh(switcherGeometry, material)

      icon.name = `icon-${name}`
      icon.position.x -= width + space * 2
      Util.addOrReplace(buttonGroup, icon)
    }
    else {
      textureOn = enableIcon || textureOn
      textureOff = disableIcon || textureOff
    }

    // if (textureRepeat) {
    //   SectionUtil.repeatTexture(texture, textureRepeat)
    // }
    // if (textureOffset) {
    //   SectionUtil.repeatTexture(texture, textureOffset)
    // }
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
    const switcher = new THREE.Mesh(switcherGeometry, material)

    if (type === 'withIcon') {
      switcher.name = `${name}Switcher`
      switcher.position.x -= space ?? 0
    }
    else {
      switcher.name = name
      switcher.visible = visible
    }

    switcher.userData.type = 'Button'
    switcher.userData.action = action

    if (tooltip) {
      switcher.userData.tooltip = tooltip
    }

    if (setPosition) {
      const { x: xCoordinate, y: yCoordinate } = CalculationUtil.getButtonCoordinates(x, y, name, buttons)

      switcher.position.set(xCoordinate, yCoordinate, 0.0003)
    }

    return switcher
  }

  public static getSwitcherWithIcon (
    _x: number | null,
    _y: number | null,
    size: number,
    name: string,
    action: any,
    switcherValue: boolean,
    tooltip: string,
    _buttons: any,
    _pivot: any,
    iconNextToSwitcher: boolean,
    enableIcon: any,
    disableIcon: any,
    _visible: any,
    buttonGroup: any,
  ) {
    const space = 0.05
    const switcherGeometry = new THREE.PlaneGeometry(size, size, 1)

    let textureOn = TextureUtil.load('textures/ui/toggle-on-solid.png')
    let textureOff = TextureUtil.load('textures/ui/toggle-off-solid.png')

    if (iconNextToSwitcher) {
      const texture = switcherValue ? enableIcon : disableIcon
      const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
      const icon = new THREE.Mesh(switcherGeometry, material)

      icon.name = `icon-${name}`
      icon.position.x -= size + space * 2
      Util.addOrReplace(buttonGroup, icon)
    }
    else {
      textureOn = enableIcon || textureOn
      textureOff = disableIcon || textureOff
    }

    const texture = switcherValue ? textureOn : textureOff
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
    const switcher = new THREE.Mesh(switcherGeometry, material)

    switcher.name = `${name}Switcher`
    switcher.userData.type = 'Button'
    switcher.userData.action = action
    switcher.position.x -= space

    if (tooltip) {
      switcher.userData.tooltip = tooltip
    }

    return switcher
  }

  public static getStrand (width: number, thickness: number, strandMaterial: Material, minOrMax?: 'max' | 'min') {
    const strandGeometry = new THREE.PlaneGeometry(thickness - 0.01, width - 0.01, 1)
    let zPositionAddition = 0

    switch (minOrMax) {
      case 'min':
        zPositionAddition = 0.000001
        break
      case 'max':
        zPositionAddition = -0.000001
        break
    }

    strandGeometry.translate(-thickness / 2, 0, 0.00001 + zPositionAddition)

    return new THREE.Mesh(strandGeometry, strandMaterial)
  }

  public static getMinStrandLines (width: number, thickness: number) {
    const minStrandLines = new THREE.Group()

    // upper min arrow
    const upperMinArrow = new THREE.Group()

    const p2 = new THREE.Vector3(0, 0.025, 0)
    const p3 = new THREE.Vector3(-0.05, 0, 0)
    const p1 = new THREE.Vector3(0, -0.025, 0)
    const material = new THREE.MeshBasicMaterial({ color: SectionView.minArrowColor })
    const arrowTriangle = Util.getTriangleMesh(p1, p2, p3, material)

    arrowTriangle.translateX(-thickness / 6)

    // TODO: remove old code
    // const arrowLineGeometry = new (THREE as any).Geometry()

    // arrowLineGeometry.vertices.push(
    //   new THREE.Vector3(-thickness / 6, 0, 0),
    //   new THREE.Vector3(0, 0, 0),
    // )
    // const upperArrowLine = new THREE.Line(arrowLineGeometry, SectionView.minStrandLineMaterial)

    const upperArrowLine = Util.getLine(
      [
        new THREE.Vector3(-thickness / 6, 0, 0),
        new THREE.Vector3(0, 0, 0),
      ],
      SectionView.minStrandLineMaterial,
    )

    // upper min text
    const upperMinText = Util.getText('min', 0.05, true)

    if (!upperMinText) {
      // eslint-disable-next-line no-console
      console.warn('upperMinText is not defined')

      return
    }

    // clone the material because it is a cashed material, if we change it, all text will change
    const minTextMaterial = upperMinText.material.clone()

    minTextMaterial.color = new THREE.Color(SectionView.minArrowColor)
    upperMinText.material = minTextMaterial
    upperMinText.name = 'upperMinText'
    upperMinText.position.set(-0.01, width / 2 - 0.1, 0)
    upperMinText.rotation.z = Math.PI / 2

    const lowerMinText = upperMinText.clone()

    lowerMinText.name = 'lowerMinText'
    lowerMinText.position.set(-0.01, -(width / 2 - 0.1), 0)

    upperMinArrow.translateY(width / 2)
    upperMinArrow.add(upperArrowLine, arrowTriangle)

    // lower min arrow
    const lowerMinArrow = upperMinArrow.clone()

    lowerMinArrow.translateY(-width)

    minStrandLines.translateZ(0.0002)
    minStrandLines.add(upperMinText, lowerMinText, upperMinArrow, lowerMinArrow)

    return minStrandLines
  }

  public static getMaxStrand (width: number, thickness: number) {
    const maxStrandGroup = new THREE.Group()

    // upper max arrow
    const upperMaxArrow = new THREE.Group()

    const p2 = new THREE.Vector3(0, 0.025, 0)
    const p3 = new THREE.Vector3(-0.05, 0, 0)
    const p1 = new THREE.Vector3(0, -0.025, 0)
    const material = new THREE.MeshBasicMaterial({ color: SectionView.maxArrowColor })
    const arrowTriangle = Util.getTriangleMesh(p1, p2, p3, material)

    arrowTriangle.translateX(-thickness / 6)

    // TODO: remove old code
    // const arrowLineGeometry = new (THREE as any).Geometry()

    // arrowLineGeometry.vertices.push(
    //   new THREE.Vector3(-thickness / 6, 0, 0),
    //   new THREE.Vector3(0, 0, 0),
    // )
    // const upperArrowLine = new THREE.Line(arrowLineGeometry, SectionView.maxStrandLineMaterial)

    const upperArrowLine = Util.getLine(
      [
        new THREE.Vector3(-thickness / 6, 0, 0),
        new THREE.Vector3(0, 0, 0),
      ],
      SectionView.maxStrandLineMaterial,
    )

    // upper max text
    const upperMaxText = Util.getText('max', 0.05, true)

    if (!upperMaxText) {
      // eslint-disable-next-line no-console
      console.warn('upperMaxText is not defined')

      return
    }

    // clone the material because it is a cashed material, if we change it, all text will change
    const maxTextMaterial = upperMaxText.material.clone()

    maxTextMaterial.color = new THREE.Color(SectionView.maxArrowColor)
    upperMaxText.material = maxTextMaterial
    upperMaxText.name = 'upperMaxText'
    upperMaxText.position.set(-0.01, width / 2 - 0.1, 0)
    upperMaxText.translateZ(0.0002)
    upperMaxText.rotation.z = Math.PI / 2

    const lowerMaxText = upperMaxText.clone()

    lowerMaxText.name = 'lowerMaxText'
    lowerMaxText.position.set(-0.01, -(width / 2 - 0.1), 0)

    upperMaxArrow.translateY(width / 2)
    upperMaxArrow.translateZ(0.0002)
    upperMaxArrow.add(upperArrowLine, arrowTriangle)

    // lower max arrow
    const lowerMaxArrow = upperMaxArrow.clone()

    lowerMaxArrow.translateY(-width)
    // for some reason the translation isn't cloned
    lowerMaxText.translateZ(0.0002)

    // strand rectangle
    const maxStrand = Getters.getStrand(width, thickness, SectionView.maxStrandMaterial, 'max')

    maxStrandGroup.add(upperMaxText, lowerMaxText, upperMaxArrow, lowerMaxArrow, maxStrand)

    return maxStrandGroup
  }

  public static getButtonGroup (
    x: number | null,
    y: number | null,
    name: string,
    size: number,
    visible: boolean,
    buttons: any,
    pivot: 'left' | 'right',
  ) {
    const { x: xCoordinate, y: yCoordinate } = CalculationUtil.getButtonCoordinates(x, y, name, buttons)

    const buttonGroup = new THREE.Group()

    buttonGroup.name = name
    buttonGroup.position.set(xCoordinate, yCoordinate, 0.0002)

    if (x !== null) {
      switch (pivot) {
        case 'right':
          buttonGroup.position.x = xCoordinate - size / 2
          break
        case 'left':
          buttonGroup.position.x = xCoordinate + size / 2
          break
        default:
      }
    }

    buttonGroup.visible = visible

    return buttonGroup
  }

  public static getCurrentElementListYPositionsInOrderDesc (view: SectionView) {
    if (!view.jumpOver) {
      return []
    }

    const selectedSides = Object.keys(view.side).filter(side => ((view.side ?? {}) as any)[side])

    const lowerCaseKey = view.jumpOver !== 'DataPoint' ? view.jumpOver.toLowerCase() : 'sensorPoint'
    const key = view.jumpOver

    const rawElementList = view.elementList[key]
    const elementValues = Object.values(rawElementList ?? {}) ?? []

    const yPositions: Record<number, boolean> = {}

    elementValues
      .filter((el: any) => {
        if (!el.objects[lowerCaseKey].visible) {
          return false
        }

        if (key === 'DataPoint') {
          return true
        }

        const { id } = Util.getParentInfo(el.objects[lowerCaseKey].userData.path)

        const segmentMountLogId = MainView.numericIdMountLogMaps.SegmentMountLog[id]

        const segmentMountLog = segmentMountLogId &&
          view.elementsHashes.SegmentMountLog[segmentMountLogId]

        const segment = view.elementsHashes.Segment[segmentMountLog?.segmentId]

        return segment?.side && selectedSides.includes(segment.side)
      })
      .forEach((el: any) => {
        const { type, id } = Util.getElementInfo(el.objects[lowerCaseKey].userData.path)
        const elementMountLogType = `${type}MountLog`
        const elementMountLogId = MainView.numericIdMountLogMaps[elementMountLogType]?.[id]
        let mountLog: any = {}

        if (type === 'DataPoint') {
          mountLog = Getters.getDataPointMountLog(view, elementMountLogId)
        }
        else {
          mountLog = elementMountLogId &&
            view.elementsHashes[elementMountLogType as CasterElementNames][elementMountLogId]
        }

        const { position } = PasslineCurve.getInfoAtPlCoord(Number(mountLog.passlineCoord ?? 0) / 1000, true)

        yPositions[position.y] = true
      })

    return Object.keys(yPositions).map(y => Number(y)).sort((a, b) => b - a)
  }

  public static getCurrentElementListPasslineCoordinates (view: SectionView) {
    if (!view.jumpOver) {
      return []
    }

    const selectedSides = Object.keys(view.side).filter(side => ((view.side ?? {}) as any)[side])

    const lowerCaseKey = view.jumpOver !== 'DataPoint' ? view.jumpOver.toLowerCase() : 'sensorPoint'
    const key = view.jumpOver

    const rawElementList = view.elementList[key]
    const elementValues = Object.values(rawElementList ?? {}) ?? []

    return elementValues
      .filter((el: any) => {
        if (!el.objects[lowerCaseKey].visible) {
          return false
        }

        if (key === 'DataPoint') {
          return true
        }

        const path = el.objects[lowerCaseKey].userData.path

        // parent should always be segment, because we are jumping over nozzles or rollers
        const { id } = Util.getParentInfo(path)

        const segmentMountLogId = MainView.numericIdMountLogMaps.SegmentMountLog[id]

        const segmentMountLog = view.elementsHashes.SegmentMountLog[segmentMountLogId]

        const segment = view.elementsHashes.Segment[segmentMountLog?.segmentId ?? '']

        return segment?.side && selectedSides.includes(segment.side)
      })
      .map((el: any) => {
        const { type, id } = Util.getElementInfo(el.objects[lowerCaseKey].userData.path)
        const elementMountLogType = `${type}MountLog`
        const elementMountLogId = MainView.numericIdMountLogMaps[elementMountLogType]?.[id]

        if (type === 'DataPoint') {
          const dataPointMountLog = Getters.getDataPointMountLog(view, elementMountLogId)

          return Number(dataPointMountLog.passlineCoord)
        }

        const mountLog = elementMountLogId &&
          view.elementsHashes[elementMountLogType as CasterElementNames][elementMountLogId]

        return Number(mountLog.passlineCoord)
      })
  }

  public static getCenterAndPosition (
    selectedElementList: number[],
    jumpDirection: number,
    targetHeight: number,
    jump: boolean,
    center2d: Coord,
    currentHeightPos: number,
  ) {
    const elementTypeChanged = !selectedElementList.includes(targetHeight)
    let heightPos = Util.closest(targetHeight, selectedElementList)
    // if element type changed and jump and jump direction, make an aux selectedElementList array
    // place the targetHeight in it and sort it, use the index of the targetHeight in the sorted array
    // to get the next or previous element in the selectedElementList
    // if the index is out of bounds, don't jump
    // if the index is in bounds, jump to the element in the selectedElementList

    if (elementTypeChanged && jump) {
      // ins
      const auxSelectedElementList = [ ...selectedElementList ]
      let targetHeightIndex = -1

      // insert the targetHeight in the auxSelectedElementList in descending order with a for loop
      for (let i = 0; i < auxSelectedElementList.length; i++) {
        if (targetHeight > auxSelectedElementList[i]) {
          auxSelectedElementList.splice(i, 0, targetHeight)
          targetHeightIndex = i
          break
        }
      }

      if (targetHeightIndex !== -1 && targetHeightIndex + jumpDirection < auxSelectedElementList.length) {
        heightPos = auxSelectedElementList[targetHeightIndex + jumpDirection]
      }
    }

    const index = selectedElementList.indexOf(heightPos)
    const newIndex = index + jumpDirection

    if (
      jump &&
      index !== -1 &&
      newIndex > -1 &&
      newIndex < selectedElementList.length &&
      heightPos === currentHeightPos
    ) {
      heightPos = selectedElementList[newIndex]
    }

    const position = new THREE.Vector3(center2d.x, heightPos + 0.1005, 0)
    const center = new THREE.Vector3(center2d.x, heightPos, 0)

    return { position, center, heightPos }
  }

  public static getSectionPlaneFoldedGeometry (thickness: number, width: number, view: SectionView) {
    const planeThickness = thickness < 0.2 ? 0.2 : thickness

    view.sectionPlaneWidth = CalculationUtil.calcSectionPlane(
      planeThickness,
      view.largestNozzle,
      view.widestNarrowNozzle,
      view.largestRoller,
      view.widestNarrowRoller,
      0.75,
    )
    view.sectionPlaneHeight = CalculationUtil.calcSectionPlane(
      width,
      view.largestNarrowNozzle,
      view.widestNozzle,
      view.largestNarrowRoller,
      view.widestRoller,
      1.1,
    )

    return new THREE.PlaneGeometry(view.sectionPlaneWidth, view.sectionPlaneHeight)
  }

  public static getSectionPlaneHeader (view: SectionView, thickness: number) {
    const { sectionPlaneHeight, sectionPlaneWidth } = view
    const planeGeometryHeader = new THREE.PlaneGeometry(
      sectionPlaneWidth,
      0.25,
      1,
    )
      .translate(-thickness / 2, 0.125, 0)
    const sectionPlaneHeader = new THREE.Mesh(planeGeometryHeader, SectionView.planeHeaderMaterial)
    const planeHeight = sectionPlaneHeight ?? 0

    sectionPlaneHeader.position.y = (planeHeight > 0 ? planeHeight : 1) / 2 + 0.01

    return sectionPlaneHeader
  }

  public static getPlanePartingLineHeader (view: SectionView, thickness: number) {
    const { sectionPlaneWidth, sectionPlaneHeight } = view
    const planePartingLineHeader = new THREE.PlaneGeometry(
      sectionPlaneWidth,
      0.27,
    )
      .translate(-thickness / 2, 0.145, -0.00001)
    const sectionPartingLineHeader = new THREE.Mesh(planePartingLineHeader, SectionView.headerPartingMaterial)
    const planeHeight = sectionPlaneHeight ?? 0

    sectionPartingLineHeader.position.y = (planeHeight > 0 ? planeHeight : 1) / 2 - 0.01
    sectionPartingLineHeader.name = 'partingLineHeader'

    return sectionPartingLineHeader
  }

  public static getMouse (mouseOnCanvas: Vector2, x: number, y: number, width: number, height: number) {
    const mouse = new THREE.Vector2()

    mouse.x = CalculationUtil.calcMousePosition(mouseOnCanvas.x, x, width)
    mouse.y = CalculationUtil.calcMousePosition(mouseOnCanvas.y, y, height, true)

    return mouse
  }

  public static getIntersectedTooltipsAndSnaps (tooltipObjects: any[], raycaster: THREE.Raycaster) {
    tooltipObjects = tooltipObjects.filter(object => object.visible)

    const intersects = raycaster.intersectObjects(tooltipObjects, false)
    const snaps: any[] = []
    const tooltips = intersects.reduce((list: Tooltip[], intersect) => {
      const { userData } = intersect.object ?? {}

      if (intersect.object) {
        switch (userData.type) {
          case 'Button':
          case 'TooltipMarker':
            list.push({
              tooltip: userData.tooltip,
              position: 'right',
            })
            break
          case 'TooltipMarkerSnap':
            snaps.push(intersect.object)
            break
          default:
        }
      }

      return list
    }, [])

    return { tooltips, snaps }
  }

  private static getDataPointMountLog (view: SectionView, mountLogId: string) {
    return (
      view.elementsHashes.StrandDataPointsMountLog[mountLogId] ??
        view.elementsHashes.MoldFaceDataPointsMountLog[mountLogId] ??
        view.elementsHashes.RollerDataPointsMountLog[mountLogId] ??
        view.elementsHashes.RollerBodyDataPointsMountLog[mountLogId] ??
        view.elementsHashes.SegmentDataPointsMountLog[mountLogId]
    )
  }
}
