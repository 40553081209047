import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'

export class ElementsUtil {
  public static compareNumericIdMountLogMaps: Record<string, NumericIdMountLogMaps> = {}

  // object with caseIds, that leads to object of casterElementNames, that leads to object
  // that has mountLogId as key and numericId as value
  public static comparePaths: Record<string, Record<CasterElementNames, Record<string, string>>> = {}

  private static getElementMountLogHashByParentMountLogs<P extends BaseMountLog, E extends BaseMountLog> (
    elementsHashes: ElementsHashes,
    parentMountLogs: P[],
    elementMountLogsKey: keyof P,
    elementsHashKey: keyof ElementsHashes,
  ): Record<string, E> {
    const result = {} as Record<string, E>

    if (!elementsHashes[elementsHashKey]) {
      return result
    }

    for (const parentMountLog of parentMountLogs) {
      const elementMountLogIds = (parentMountLog[elementMountLogsKey] ?? []) as string[]

      for (const elementMountLogId of elementMountLogIds) {
        const elementMountLog = elementsHashes[elementsHashKey][elementMountLogId] as E

        if (elementMountLog) {
          result[elementMountLogId] = elementMountLog
        }
      }
    }

    return result
  }

  public static getStrandGuideMountLog (elementsHashes: ElementsHashes): StrandGuideMountLog | null {
    const { StrandGuideMountLog, Caster } = elementsHashes

    if (!StrandGuideMountLog || !Caster) {
      return null
    }

    return Object.values(StrandGuideMountLog).find(({ casterId }) => casterId === Caster.id) ?? null
  }

  /**
   * @deprecated use Object.values(elementsHashes.SegmentGroupMountLog) instead
   */
  public static getSegmentGroupMountLogs (elementsHashes: ElementsHashes): SegmentGroupMountLogHash {
    return elementsHashes.SegmentGroupMountLog ?? {}
  }

  public static getSupportPointMountLogHashBySegmentGroupMountLogs (
    elementsHashes: ElementsHashes,
    segmentGroupMountLogs: SegmentGroupMountLog[],
  ): SupportPointMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentGroupMountLogs,
      'supportPointMountLogs',
      'SupportPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.SupportPointMountLog) instead
   */
  public static getSupportPointMountLogsByDate (
    elementsHashes: ElementsHashes,
    supportPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SupportPointMountLog[] {
    const { SupportPointMountLog } = elementsHashes

    if (!SupportPointMountLog || !date) {
      return []
    }

    return Object
      .values(SupportPointMountLog)
      .filter(supportPointMountLog => {
        const mountedAt = new Date(supportPointMountLog.mountedAt)
        const removedAt = supportPointMountLog.removedAt !== null ? new Date(supportPointMountLog.removedAt) : null

        return (
          supportPointMountLogIdsHash[supportPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentMountLogHashBySegmentGroupMountLogs (
    elementsHashes: ElementsHashes,
    segmentGroupMountLogs: SegmentGroupMountLog[],
  ): SegmentMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentGroupMountLogs,
      'segmentMountLogs',
      'SegmentMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.SegmentMountLog) instead
   */
  public static getSegmentMountLogsByDate (
    elementsHashes: ElementsHashes,
    segmentMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentMountLog[] {
    const { SegmentMountLog } = elementsHashes

    if (!SegmentMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentMountLog)
      .filter(segmentMountLog => {
        const mountedAt = new Date(segmentMountLog.mountedAt)
        const removedAt = segmentMountLog.removedAt !== null ? new Date(segmentMountLog.removedAt) : null

        return (
          segmentMountLogIdsHash[segmentMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  /**
   * @deprecated
   */
  public static getPasslineSectionsByDate (elementsHashes: ElementsHashes, date?: Date): PasslineSection[] {
    const { Passline, PasslineMountLog, Caster } = elementsHashes

    if (!Caster || !Object.keys(Passline) || !Object.keys(PasslineMountLog) || !date) {
      return []
    }

    const passlineId = ElementsUtil
      .getPasslineMountLogsByDate(elementsHashes, Caster.id, date)
      ?.passlineId
    const passline = Object.values(Passline).find(passline => passlineId === passline.id)

    return passline?.sections ?? []
  }

  /**
   * @deprecated use Object.values(elementsHashes.PasslineMountLog) instead
   */
  private static getPasslineMountLogsByDate (
    elementsHashes: ElementsHashes,
    casterId: string,
    date?: Date,
  ): PasslineMountLog | null {
    const { PasslineMountLog } = elementsHashes

    if (!PasslineMountLog || !date) {
      return null
    }

    const passlineMountLog = Object
      .values(PasslineMountLog)
      .find(passline => {
        const mountedAt = new Date(passline.mountedAt)
        const removedAt = passline.removedAt !== null ? new Date(passline.removedAt) : null

        return (
          passline.casterId === casterId &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })

    return passlineMountLog ?? null
  }

  private static getMoldMountLog (elementsHashes: ElementsHashes): MoldMountLog | null {
    const { MoldMountLog, Caster } = elementsHashes

    if (!Caster || !MoldMountLog) {
      return null
    }

    return Object.values(MoldMountLog).find(({ casterId }) => casterId === Caster.id) ?? null
  }

  public static getMoldAndMoldMountLogByDate (
    elementsHashes: ElementsHashes,
  ): { mold: Mold | null, moldMountLog: MoldMountLog | null } {
    const { Mold } = elementsHashes

    if (!Mold) {
      return {
        mold: null,
        moldMountLog: null,
      }
    }

    const moldMountLog = ElementsUtil.getMoldMountLog(elementsHashes)

    if (!moldMountLog) {
      return {
        mold: null,
        moldMountLog: null,
      }
    }

    const mold = Object.values(Mold).find(mold => mold.id === moldMountLog.moldId) ?? null

    return {
      mold,
      moldMountLog,
    }
  }

  public static getMoldFacesByMoldId = (elementsHashes: ElementsHashes, moldMountLogId?: string): MoldFace[] => {
    const { MoldFace } = elementsHashes

    if (!MoldFace || !moldMountLogId) {
      return []
    }

    return Object.values(MoldFace).filter(moldFace => moldFace.moldMountLogId === moldMountLogId)
  }

  public static getNozzleMountLogHashBySegmentMountLogs (
    elementsHashes: ElementsHashes,
    segmentMountLogs: SegmentMountLog[],
  ): NozzleMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentMountLogs,
      'nozzleMountLogs',
      'NozzleMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.NozzleMountLog) instead
   */
  public static getNozzleMountLogsByDate (
    elementsHashes: ElementsHashes,
    nozzleMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): NozzleMountLog[] {
    const { NozzleMountLog } = elementsHashes

    if (!NozzleMountLog || !date) {
      return []
    }

    return Object
      .values(NozzleMountLog)
      .filter(nozzleMountLog => {
        const mountedAt = new Date(nozzleMountLog.mountedAt)
        const removedAt = nozzleMountLog.removedAt !== null ? new Date(nozzleMountLog.removedAt) : null

        return (
          nozzleMountLogIdsHash[nozzleMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerMountLogHashBySegmentMountLogs (
    elementsHashes: ElementsHashes,
    segmentMountLogs: SegmentMountLog[],
  ): RollerMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentMountLogs,
      'rollerMountLogs',
      'RollerMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerMountLog) instead
   */
  public static getRollerMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerMountLog[] {
    const { RollerMountLog } = elementsHashes

    if (!RollerMountLog || !date) {
      return []
    }

    return Object
      .values(RollerMountLog)
      .filter(rollerMountLog => {
        const mountedAt = new Date(rollerMountLog.mountedAt)
        const removedAt = rollerMountLog.removedAt !== null ? new Date(rollerMountLog.removedAt) : null

        return (
          rollerMountLogIdsHash[rollerMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodyMountLogHashByRollerMountLogs (
    elementsHashes: ElementsHashes,
    rollerMountLogs: RollerMountLog[],
  ): RollerBodyMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerMountLogs,
      'rollerBodyMountLogs',
      'RollerBodyMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerBodyMountLog) instead
   */
  public static getRollerBodyMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerBodyMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodyMountLog[] {
    const { RollerBodyMountLog } = elementsHashes

    if (!RollerBodyMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodyMountLog)
      .filter(rollerBodyMountLog => {
        const mountedAt = new Date(rollerBodyMountLog.mountedAt)
        const removedAt = rollerBodyMountLog.removedAt !== null ? new Date(rollerBodyMountLog.removedAt) : null

        return (
          rollerBodyMountLogIdsHash[rollerBodyMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBearingMountLogHashByRollerMountLogs (
    elementsHashes: ElementsHashes,
    rollerMountLogs: RollerMountLog[],
  ): RollerBearingMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerMountLogs,
      'rollerBearingMountLogs',
      'RollerBearingMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerBearingMountLog) instead
   */
  public static getRollerBearingMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerBearingMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBearingMountLog[] {
    const { RollerBearingMountLog } = elementsHashes

    if (!RollerBearingMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBearingMountLog)
      .filter(rollerBearingMountLog => {
        const mountedAt = new Date(rollerBearingMountLog.mountedAt)
        const removedAt = rollerBearingMountLog.removedAt !== null ? new Date(rollerBearingMountLog.removedAt) : null

        return (
          rollerBearingMountLogIdsHash[rollerBearingMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerDataPointsMountLog) instead
   */
  public static getRollerDataPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerDataPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerDataPointsMountLog[] {
    const { RollerDataPointsMountLog } = elementsHashes

    if (!RollerDataPointsMountLog || !date) {
      return []
    }

    return Object
      .values(RollerDataPointsMountLog)
      .filter(rollerDataPointsMountLog => {
        const mountedAt = new Date(rollerDataPointsMountLog.mountedAt)
        const removedAt = rollerDataPointsMountLog.removedAt !== null
          ? new Date(rollerDataPointsMountLog.removedAt)
          : null

        return (
          rollerDataPointsMountLogIdsHash[rollerDataPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerDataPointsMountLogHashByRollerMountLogs (
    elementsHashes: ElementsHashes,
    rollerMountLogs: RollerMountLog[],
  ): RollerDataPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerMountLogs,
      'dataPointMountLogs',
      'RollerDataPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerBodyDataPointsMountLog) instead
   */
  public static getRollerBodyDataPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerBodyDataPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodyDataPointsMountLog[] {
    const { RollerBodyDataPointsMountLog } = elementsHashes

    if (!RollerBodyDataPointsMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodyDataPointsMountLog)
      .filter(rollerBodyDataPointsMountLog => {
        const mountedAt = new Date(rollerBodyDataPointsMountLog.mountedAt)
        const removedAt = rollerBodyDataPointsMountLog.removedAt !== null
          ? new Date(rollerBodyDataPointsMountLog.removedAt)
          : null

        return (
          rollerBodyDataPointsMountLogIdsHash[rollerBodyDataPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodyDataPointsMountLogHashByRollerBodyMountLogs (
    elementsHashes: ElementsHashes,
    rollerBodyMountLogs: RollerBodyMountLog[],
  ): RollerBodyDataPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerBodyMountLogs,
      'dataPointMountLogs',
      'RollerBodyDataPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.SegmentDataPointsMountLog) instead
   */
  public static getSegmentDataPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    segmentDataPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentDataPointsMountLog[] {
    const { SegmentDataPointsMountLog } = elementsHashes

    if (!SegmentDataPointsMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentDataPointsMountLog)
      .filter(segmentDataPointsMountLog => {
        const mountedAt = new Date(segmentDataPointsMountLog.mountedAt)
        const removedAt = segmentDataPointsMountLog.removedAt !== null
          ? new Date(segmentDataPointsMountLog.removedAt)
          : null

        return (
          segmentDataPointsMountLogIdsHash[segmentDataPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentDataPointsMountLogHashBySegmentMountLogs (
    elementsHashes: ElementsHashes,
    segmentMountLogs: SegmentMountLog[],
  ): SegmentDataPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentMountLogs,
      'dataPointMountLogs',
      'SegmentDataPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerSensorPointsMountLog) instead
   */
  public static getRollerSensorPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerSensorPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerSensorPointsMountLog[] {
    const { RollerSensorPointsMountLog } = elementsHashes

    if (!RollerSensorPointsMountLog || !date) {
      return []
    }

    return Object
      .values(RollerSensorPointsMountLog)
      .filter(rollerSensorPointsMountLog => {
        const mountedAt = new Date(rollerSensorPointsMountLog.mountedAt)
        const removedAt = rollerSensorPointsMountLog.removedAt !== null
          ? new Date(rollerSensorPointsMountLog.removedAt)
          : null

        return (
          rollerSensorPointsMountLogIdsHash[rollerSensorPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerSensorPointsMountLogHashByRollerMountLogs (
    elementsHashes: ElementsHashes,
    rollerMountLogs: RollerMountLog[],
  ): RollerSensorPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerMountLogs,
      'sensorPointMountLogs',
      'RollerSensorPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.RollerBodySensorPointsMountLog) instead
   */
  public static getRollerBodySensorPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    rollerBodySensorPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodySensorPointsMountLog[] {
    const { RollerBodySensorPointsMountLog } = elementsHashes

    if (!RollerBodySensorPointsMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodySensorPointsMountLog)
      .filter(rollerBodySensorPointsMountLog => {
        const mountedAt = new Date(rollerBodySensorPointsMountLog.mountedAt)
        const removedAt = rollerBodySensorPointsMountLog.removedAt !== null
          ? new Date(rollerBodySensorPointsMountLog.removedAt)
          : null

        return (
          rollerBodySensorPointsMountLogIdsHash[rollerBodySensorPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodySensorPointsMountLogHashByRollerBodyMountLogs (
    elementsHashes: ElementsHashes,
    rollerBodyMountLogs: RollerBodyMountLog[],
  ): RollerBodySensorPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      rollerBodyMountLogs,
      'sensorPointMountLogs',
      'RollerBodySensorPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.SegmentSensorPointsMountLog) instead
   */
  public static getSegmentSensorPointsMountLogsByDate (
    elementsHashes: ElementsHashes,
    segmentSensorPointsMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentSensorPointsMountLog[] {
    const { SegmentSensorPointsMountLog } = elementsHashes

    if (!SegmentSensorPointsMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentSensorPointsMountLog)
      .filter(segmentSensorPointsMountLog => {
        const mountedAt = new Date(segmentSensorPointsMountLog.mountedAt)
        const removedAt = segmentSensorPointsMountLog.removedAt !== null
          ? new Date(segmentSensorPointsMountLog.removedAt)
          : null

        return (
          segmentSensorPointsMountLogIdsHash[segmentSensorPointsMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentSensorPointsMountLogHashBySegmentMountLogs (
    elementsHashes: ElementsHashes,
    segmentMountLogs: SegmentMountLog[],
  ): SegmentSensorPointsMountLogHash {
    return ElementsUtil.getElementMountLogHashByParentMountLogs(
      elementsHashes,
      segmentMountLogs,
      'sensorPointMountLogs',
      'SegmentSensorPointsMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementsHashes.DataLineMountLog) instead
   */
  public static getDataLineMountLogs (elementsHashes: ElementsHashes): DataLineMountLog[] {
    const { DataLineMountLog, Caster } = elementsHashes

    if (!DataLineMountLog || !Caster) {
      return []
    }

    return Object.values(DataLineMountLog).filter(({ casterId }) => casterId === Caster.id)
  }

  public static getDrawableElementPaths (elementsHashes: ElementsHashes): Record<DrawableCasterElementTypes, string[]> {
    const paths: Record<DrawableCasterElementTypes, Record<string, string>> = {} as any

    // SegmentGroups
    const segmentGroupMountLogs = Object.values(elementsHashes.SegmentGroupMountLog)

    paths.SegmentGroup = paths.SegmentGroup ?? {}

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const path = `SegmentGroup:${MainView.numericIdMountLogMaps.SegmentGroupMountLog[segmentGroupMountLog.id]}`

      paths.SegmentGroup[segmentGroupMountLog.id] = path
    })

    // Segments
    const segmentMountLogs = Object.values(elementsHashes.SegmentMountLog)

    paths.Segment = paths.Segment ?? {}

    for (const segmentMountLog of segmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      // TODO: how can i get the segment group mount log id??
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = paths.SegmentGroup[segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Segment:${MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLog.id]}`

      paths.Segment[segmentMountLog.id] = path
    }

    // Nozzles
    const nozzleMountLogs = Object.values(elementsHashes.NozzleMountLog)

    paths.Nozzle = paths.Nozzle ?? {}

    for (const nozzleMountLog of nozzleMountLogs) {
      const parentPath = paths.Segment[nozzleMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Nozzle:${MainView.numericIdMountLogMaps.NozzleMountLog[nozzleMountLog.id]}`

      paths.Nozzle[nozzleMountLog.id] = path
    }

    // Rollers
    const rollerMountLogs = Object.values(elementsHashes.RollerMountLog)

    paths.Roller = paths.Roller ?? {}

    for (const rollerMountLog of rollerMountLogs) {
      const parentPath = paths.Segment[rollerMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Roller:${MainView.numericIdMountLogMaps.RollerMountLog[rollerMountLog.id]}`

      paths.Roller[rollerMountLog.id] = path
    }

    // RollerBodies
    const rollerBodyMountLogs = Object.values(elementsHashes.RollerBodyMountLog)

    paths.RollerBody = paths.RollerBody ?? {}

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const parentPath = paths.Roller[rollerBodyMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBody:${
        MainView.numericIdMountLogMaps.RollerBodyMountLog[rollerBodyMountLog.id]
      }`

      paths.RollerBody[rollerBodyMountLog.id] = path
    }

    // RollerBearings
    const rollerBearingMountLogs = Object.values(elementsHashes.RollerBearingMountLog)

    paths.RollerBearing = paths.RollerBearing ?? {}

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const parentPath = paths.Roller[rollerBearingMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBearing:${
        MainView.numericIdMountLogMaps.RollerBearingMountLog[rollerBearingMountLog.id]
      }`

      paths.RollerBearing[rollerBearingMountLog.id] = path
    }

    // SupportPoints
    const supportPointMountLogHash = ElementsUtil.getSupportPointMountLogHashBySegmentGroupMountLogs(
      elementsHashes,
      segmentGroupMountLogs,
    )
    const supportPointMountLogs = Object.values(supportPointMountLogHash)

    paths.SupportPoint = paths.SupportPoint ?? {}

    for (const supportPointMountLog of supportPointMountLogs) {
      const parentPath = paths.SegmentGroup[supportPointMountLog.segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SupportPoint:${
        MainView.numericIdMountLogMaps.SupportPointMountLog[supportPointMountLog.id]
      }`

      paths.SupportPoint[supportPointMountLog.id] = path
    }

    // DataPoints
    // DataPoints in MoldFaces
    const moldFaceDataPointsMountLogs = Object.values(elementsHashes.MoldFaceDataPointsMountLog)

    paths.DataPoint = paths.DataPoint ?? {}

    // FIXME: @c.betele this is not working, how can we fix this?
    for (const moldFaceDataPointsMountLog of moldFaceDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[moldFaceDataPointsMountLog.id]
      // const parentPath = paths.MoldFace[moldFaceDataPointsMountLog.moldFaceId]

      // if (!parentPath) {
      //   continue
      // }

      // const path = `${parentPath}/DataPoint:${numericId}`
      // FIXME: this is a temporary workaround
      const path = `MoldFace/DataPoint:${numericId}`

      paths.DataPoint[moldFaceDataPointsMountLog.id] = path
    }

    // DataPoints in Strand
    const strandDataPointsMountLogs = Object.values(elementsHashes.StrandDataPointsMountLog)

    // FIXME: @c.betele this is not working, how can we fix this?
    for (const strandDataPointsMountLog of strandDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[strandDataPointsMountLog.id]
      // const parentPath = paths.Caster[strandDataPointsMountLog.casterId]

      // if (!parentPath) {
      //   continue
      // }

      // const path = `${parentPath}/DataPoint:${numericId}`
      // FIXME: this is a temporary workaround
      const path = `Strand/DataPoint:${numericId}`

      paths.DataPoint[strandDataPointsMountLog.id] = path
    }

    // DataPoints in Segments
    const segmentDataPointsMountLogs = Object.values(elementsHashes.SegmentDataPointsMountLog)

    for (const segmentDataPointsMountLog of segmentDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[segmentDataPointsMountLog.id]
      const parentPath = paths.Segment[segmentDataPointsMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[segmentDataPointsMountLog.id] = path
    }

    // DataPoints in Rollers
    const rollerDataPointsMountLogs = Object.values(elementsHashes.RollerDataPointsMountLog)

    paths.DataPoint = paths.DataPoint ?? {}

    for (const rollerDataPointsMountLog of rollerDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[rollerDataPointsMountLog.id]
      const parentPath = paths.Roller[rollerDataPointsMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[rollerDataPointsMountLog.id] = path
    }

    // DataPoints in RollerBodies
    const rollerBodyDataPointsMountLogs = Object.values(elementsHashes.RollerBodyDataPointsMountLog)

    for (const rollerBodyDataPointsMountLog of rollerBodyDataPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.DataPointMountLog[rollerBodyDataPointsMountLog.id]
      const parentPath = paths.RollerBody[rollerBodyDataPointsMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[rollerBodyDataPointsMountLog.id] = path
    }

    // SensorPoints
    paths.SensorPoint = paths.SensorPoint ?? {}

    // SensorPoints in Segments
    const segmentSensorPointsMountLogs = Object.values(elementsHashes.SegmentSensorPointsMountLog)

    for (const segmentSensorPointsMountLog of segmentSensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[segmentSensorPointsMountLog.id]
      const parentPath = paths.Segment[segmentSensorPointsMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[segmentSensorPointsMountLog.id] = path
    }

    // SensorPoints in Rollers
    const rollerSensorPointsMountLogs = Object.values(elementsHashes.RollerSensorPointsMountLog)

    for (const rollerSensorPointsMountLog of rollerSensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[rollerSensorPointsMountLog.id]
      const parentPath = paths.Roller[rollerSensorPointsMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[rollerSensorPointsMountLog.id] = path
    }

    // SensorPoints in RollerBodies
    const rollerBodySensorPointsMountLogs = Object.values(elementsHashes.RollerBodySensorPointsMountLog)

    for (const rollerBodySensorPointsMountLog of rollerBodySensorPointsMountLogs) {
      const numericId = MainView.numericIdMountLogMaps.SensorPointMountLog[rollerBodySensorPointsMountLog.id]
      const parentPath = paths.RollerBody[rollerBodySensorPointsMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[rollerBodySensorPointsMountLog.id] = path
    }

    // return the paths
    return Object.keys(paths).reduce(
      (acc, key) => {
        ;(acc as any)[key] = Object.values((paths as any)[key])

        return acc
      },
      {} as Record<DrawableCasterElementTypes, string[]>,
    )
  }

  public static getCoolingLoopMountLogsByStrandGuideMountLog (
    coolingLoopMountLogs: CoolingLoopMountLogHash,
    strandGuideMountLog: StrandGuideMountLog | null,
  ): CoolingLoopMountLog[] {
    if (!coolingLoopMountLogs || !strandGuideMountLog) {
      return []
    }

    return Object
      .values(coolingLoopMountLogs)
      .filter(coolingLoopMountLog => coolingLoopMountLog.strandGuideMountLogId === strandGuideMountLog.id)
  }

  public static getDataLinesByDataLineMountLogs (
    dataLinesHash: DataLineHash,
    dataLineMountLogs: DataLineMountLog[],
  ): DataLine[] {
    if (!dataLinesHash || !dataLineMountLogs) {
      return []
    }

    return Object
      .values(dataLinesHash)
      .filter(dataLine => dataLineMountLogs.some(dataLineMountLog => dataLineMountLog.dataLineId === dataLine.id))
  }

  public static getSensorPointSide (
    // TODO: type: RollerSensorPointsMountLog | RollerBodySensorPointsMountLog | SegmentSensorPointsMountLog | null
    sensorPointData: any,
    elementsHashes: ElementsHashes,
  ) {
    // sensor points can be mounted on rollers, rollerBodies and segments
    const { RollerMountLog, RollerBodyMountLog, Segment, SegmentMountLog } = elementsHashes

    if (!sensorPointData) {
      return null
    }

    if (sensorPointData.rollerMountLogId) {
      const rollerMountLog = RollerMountLog[sensorPointData.rollerMountLogId]
      const segmentMountLog = SegmentMountLog[rollerMountLog.segmentMountLogId ?? '']
      const segment = Segment[segmentMountLog.segmentId ?? '']

      return segment?.side ?? null
    }

    if (sensorPointData.rollerBodyMountLogId) {
      const rollerBodyMountLog = RollerBodyMountLog[sensorPointData.rollerBodyMountLogId]
      const rollerMountLog = RollerMountLog[rollerBodyMountLog?.rollerMountLogId ?? '']
      const segmentMountLog = SegmentMountLog[rollerMountLog?.segmentMountLogId ?? '']
      const segment = Segment[segmentMountLog?.segmentId ?? '']

      return segment?.side ?? null
    }

    if (sensorPointData.segmentMountLogId) {
      const segmentMountLog = SegmentMountLog[sensorPointData.segmentMountLogId]
      const segment = Segment[segmentMountLog.segmentId ?? '']

      return segment?.side ?? null
    }

    return null
  }

  public static typeIsOfTypeCasterElementName (type: string) {
    const types: CasterElementNames[] = [
      'AirLoop',
      'CoolingLoop',
      'DataLine',
      'Nozzle',
      'Roller',
      'RollerBearing',
      'RollerBody',
      'SensorPoint',
      'Segment',
      'SegmentGroup',
      'SupportPoint',
      'StrandGuide',
    ]

    return types.includes(type as CasterElementNames)
  }

  public static translateTypeToElementsHashesTypes (type: CasterElementNames) {
    switch (type) {
      case 'AirLoop':
        return [ 'AirLoop' ]
      case 'CoolingLoop':
        return [ 'CoolingLoop', 'CoolingLoopMountLog' ]
      case 'CoolingZone':
        return [ 'CoolingZone' ]
      case 'DataLine':
        return [ 'DataLine', 'DataLineMountLog' ]
      case 'Nozzle':
        return [ 'Nozzle', 'NozzleMountLog' ]
      case 'Roller':
        return [ 'Roller', 'RollerMountLog' ]
      case 'RollerBearing':
        return [ 'RollerBearing', 'RollerBearingMountLog' ]
      case 'RollerBody':
        return [ 'RollerBody', 'RollerBodyMountLog' ]
      case 'DataPoint': // TODO: do we need this?
        return [
          'DataPoint',
          'MoldFaceDataPointMountLog',
          'StrandDataPointsMountLog',
          'SegmentDataPointsMountLog',
          'RollerDataPointsMountLog',
          'RollerBodyDataPointsMountLog',
        ]
      case 'SensorPoint':
        return [
          'SensorPoint',
          'SegmentSensorPointsMountLog',
          'RollerSensorPointsMountLog',
          'RollerBodySensorPointsMountLog',
        ]
      case 'Segment':
        return [ 'Segment', 'SegmentMountLog' ]
      case 'SegmentGroup':
        return [ 'SegmentGroup', 'SegmentGroupMountLog' ]
      case 'SupportPoint':
        return [ 'SupportPoint', 'SupportPointMountLog' ]
      case 'StrandGuide':
        return [ 'StrandGuide', 'StrandGuideMountLog' ]
      default:
        return []
    }
  }

  public static readonly allNeededParentTypesPerType: Record<string, CasterElementNames[]> = {
    AirLoop: [],
    DataLine: [],
    Nozzle: [ 'Segment' ],
    Roller: [ 'Segment' ],
    RollerBearing: [ 'Roller', 'Segment' ],
    RollerBody: [ 'Roller', 'Segment' ],
    SensorPoint: [ 'Roller', 'RollerBody', 'Segment' ],
    Segment: [],
    SegmentGroup: [],
    SupportPoint: [ 'SegmentGroup' ],
  }

  public static normalizeCompareCasterInformation (
    currentData: Record<string, Record<string, any>>,
    data: Record<string, Record<string, any>>,
  ) {
    const newData: Record<string, Record<string, any>> = currentData

    for (const caseId in data) {
      // convert key to PascalCase and delete the last character if it is an 's'
      const hashes = data[caseId]

      if (!newData[caseId]) {
        newData[caseId] = {}
      }

      for (const hashKey in hashes) {
        let newHashKey = ''

        if (hashKey === 'rollerBodies') {
          newHashKey = 'RollerBody'
        }
        else {
          newHashKey = hashKey.charAt(0).toUpperCase() + hashKey.slice(1, -1)
        }

        newData[caseId][newHashKey] = hashes[hashKey]
      }
    }

    return newData
  }

  public static getFullCasterElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    element: Element,
    mountLog: MountLog,
    numericId: number,
  ): FullCasterElement<Element, MountLog> {
    // FIXME: this is a hack for Segment(Group) names in TreeView as the name is always stored in the mountLog ...
    const segmentLikeMountLog = mountLog as any
    const name = segmentLikeMountLog.segmentId || segmentLikeMountLog.segmentGroupId
      ? (mountLog as any).name
      : element.name

    return element.realDataUUID
      ? { ...element, ...mountLog, id: numericId, name }
      : { ...mountLog, ...element, id: numericId, name }
  }

  public static getCompleteFullCasterElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    element: Element,
    mountLog: MountLog,
  ) {
    const additionalData = element.realDataUUID
      ? { ...(mountLog?.additionalData ?? {}) }
      : { ...(element?.additionalData ?? {}) }

    const fullElement = element.realDataUUID
      ? { ...element, ...mountLog, ...additionalData }
      : { ...mountLog, ...element, ...additionalData }

    delete (fullElement as any).additionalData

    return fullElement
  }
}
