import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Input from '@/react/specific/Input'
import VisUtil from '@/react/visualization/VisUtil'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import { PlotConfig } from '@/types/visualization'

import ColorPicker from '../../ColorPicker'
import { CheckListHeader, FormWrapper, IButton, InputWrapper } from '../../Dialogs/DialogStyles'

const connector = connect(({ visualization, filter }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
  filterControlVariables: filter.filterControlVariables,
}), {
  savePlotConfig: VisualizationActions.savePlotConfig,
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

class VerticalTab extends Component<Props> {
  private readonly handleAddVerticalLine = (_event?: any) => {
    const { saveTileConfig, tileConfigs, currentTileId } = this.props

    if (!currentTileId) {
      return
    }

    const config = tileConfigs[currentTileId]
    const shapeIds = [ ...(config.shapeIds ?? []) ]
    const includesEmptyShapeId = shapeIds.find(shape => shape.id === '')

    if (!includesEmptyShapeId) {
      shapeIds.push({ id: '', type: 'line', thickness: 2 })
    }

    saveTileConfig({ id: currentTileId, shapeIds })
  }

  private readonly handleRemoveVerticalLine = (event: any) => {
    const { id } = event.target
    const { saveTileConfig, tileConfigs, currentTileId } = this.props

    if (!currentTileId) {
      return
    }

    const config = tileConfigs[currentTileId]
    const shapeIds = [ ...config.shapeIds ]

    shapeIds.splice(
      shapeIds.indexOf(shapeIds.filter(shape => shape.id === id)[0]),
      1,
    )

    saveTileConfig({ id: currentTileId, shapeIds })
  }

  private readonly handleVerticalLines = (event: any, id: string) => {
    const { name, value } = event.target
    const { saveTileConfig, tileConfigs, currentTileId } = this.props

    if (event.target.value.disabled) {
      return
    }

    if (!currentTileId) {
      return
    }

    const config = tileConfigs[currentTileId]
    const shapeIds = [ ...config.shapeIds ]

    shapeIds[shapeIds.indexOf(shapeIds.filter(shape => shape.id === id)[0])] = {
      ...shapeIds[shapeIds.indexOf(shapeIds.filter(shape => shape.id === id)[0])],
      [name]: value,
    }

    saveTileConfig({
      id: currentTileId,
      shapeIds,
    })
  }
  
  public override render () {
    const {
      plotConfigs,
      tileConfigs,
      currentTileId,
      fullscreen,
      t,
      data,
      filterControlVariables,
    } = this.props
    const { shapeIds, isDynamicData, isMergedDynamicData } = tileConfigs[currentTileId ?? ''] ?? {}

    const filteredPlotConfigs: Record<string, PlotConfig> = {}

    Object
      .values(plotConfigs)
      .forEach((config: any) => {
        if (config.isMergedDynamicDataSource) {
          return
        }

        filteredPlotConfigs[config.id] = config
      })

    const verticalLineSelectors = VisUtil
      .getConfigSelectors(data, filteredPlotConfigs)
      .filter(el => (el.group !== 'dynamicDataSource' || el.isVerticalLine))

    if (isDynamicData || isMergedDynamicData) {
      verticalLineSelectors.push({
        group: 'dynamicDataSource',
        key: 'passlineCoord',
        value: 'Current Passln Cord',
        isVerticalLine: true,
      })
      filterControlVariables.forEach(variable => {
        verticalLineSelectors.push({
          group: 'dynamicDataSource',
          key: variable,
          value: variable,
          isVerticalLine: true,
        })
      })
    }

    return (
      <FormWrapper>
        <CheckListHeader>{t('plotConfig.verticalLinesAndAreas')}</CheckListHeader>
        {
          shapeIds && shapeIds.map(({ id, type, color, thickness }, index) => {
            const selectors = [
              { key: '', value: t('plotConfig.defaultValue'), group: 'disabled', disabled: true },
              ...verticalLineSelectors.map(selector => {
                if (selector.key === id || !shapeIds.map(shapeId => shapeId.id).includes(selector.key)) {
                  return selector
                }

                return ({
                  ...selector,
                  disabled: true,
                })
              }),
            ]

            const types = [
              { key: 'line', value: 'Line' },
              { key: 'rect', value: 'Area' },
            ]

            const colors = [
              '#a2a6a9',
              ...VisUtil.TRACE_COLORS,
            ]

            const thicknesses = [
              { key: 1, value: 'Thin' },
              { key: 2, value: 'Medium' },
              { key: 3, value: 'Thick' },
            ] as Selector[]

            return (
              <div key={id}>
                <InputWrapper $fullscreen={fullscreen}>
                  <Input
                    id={id}
                    label={t('plotConfig.id.label')}
                    title={t('plotConfig.id.title')}
                    name='id'
                    type='select'
                    selectors={selectors}
                    value={id}
                    onChange={e => this.handleVerticalLines(e, id)}
                  />
                  <Input
                    id={id}
                    label={t('plotConfig.plotType.label')}
                    title={t('plotConfig.plotType.title')}
                    name='type'
                    type='select'
                    value={type}
                    selectors={types}
                    onChange={e => this.handleVerticalLines(e, id)}
                  />
                  <ColorPicker
                    labelSpacer
                    color={color ?? '#a2a6a9'}
                    colors={colors}
                    id={id}
                    name='color'
                    onChange={e => this.handleVerticalLines(e, id)}
                  />
                </InputWrapper>
                {
                  type === 'line' &&
                (
                  <InputWrapper>
                    <Input
                      id={id}
                      label={t('plotConfig.thickness.label')}
                      title={t('plotConfig.thickness.title')}
                      name='thickness'
                      type='select'
                      value={thickness}
                      selectors={thicknesses}
                      onChange={e => this.handleVerticalLines(e, id)}
                    />
                  </InputWrapper>
                )
                }
                <IButton
                  title={t('plotConfig.remove')}
                  className='pe-7s-less'
                  onClick={this.handleRemoveVerticalLine}
                  id={id}
                />
                {shapeIds.length - 1 > index && <hr />}
              </div>
            )
          })
        }
        {
          (!shapeIds || shapeIds.length < verticalLineSelectors.length) &&
          (
            <IButton
              title={t('plotConfig.add.title')}
              className='pe-7s-plus'
              onClick={this.handleAddVerticalLine}
            />
          )
        }
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(VerticalTab as any) as any) as any
