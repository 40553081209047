import React from 'react'
import styled, { css } from 'styled-components'

const TabPageView = styled.div<{ $fixedHeight?: number }>`${({ $fixedHeight }) =>
  css`
  ${$fixedHeight && `height: ${$fixedHeight}px`}
`}`

interface Props {
  title: any | string // used in TabView,
  tooltip?: string // used in TabView,
  fixedHeight?: number
  children?: any
  hide?: any
}

export default class TabPage extends React.Component<Props> {
  public override render () {
    const { children, fixedHeight, hide } = this.props

    if (hide) {
      return null
    }

    return (
      <TabPageView $fixedHeight={fixedHeight}>
        {children}
      </TabPageView>
    )
  }
}
