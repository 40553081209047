// TODO: fix types
export enum RollerBodySensorPointsMountLogActionsEnum {
  // eslint-disable-next-line max-len
  ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG =
    'RollerBodySensorPointsMountLog/ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG',
  DELETE = 'RollerBodySensorPointsMountLog/DELETE',
  DELETE_MULTIPLE = 'RollerBodySensorPointsMountLog/DELETE_MULTIPLE',
  UPDATE = 'RollerBodySensorPointsMountLog/UPDATE',
}

export function addRollerBodySensorPointsMountLog (dataLineHash: Record<number, RollerBodySensorPointsMountLog>) {
  return {
    type: RollerBodySensorPointsMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerBodySensorPointsMountLogReducers: Record<string, Function | undefined> = {
  [RollerBodySensorPointsMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [RollerBodySensorPointsMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerBodySensorPointsMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerBodySensorPointsMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = RollerBodySensorPointsMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
