export enum changeItemActionsEnum {
  ACTION_ADD_CHANGE_ITEM = 'ChangeItem/ACTION_ADD_CHANGE_ITEM',
  DELETE = 'ChangeItem/DELETE',
  DELETE_MULTIPLE = 'ChangeItem/DELETE_MULTIPLE',
  UPDATE = 'ChangeItem/UPDATE',
}

export function addChangeItems (changeItemHash: ChangeItemHash) {
  return {
    type: changeItemActionsEnum.ACTION_ADD_CHANGE_ITEM,
    changeItemHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const changeItemReducers: Record<string, Function | undefined> = {
  [changeItemActionsEnum.ACTION_ADD_CHANGE_ITEM]: (
    state: ChangeItemHash,
    action: { changeItemHash: ChangeItemHash },
  ) => ({
    ...state,
    ...action.changeItemHash,
  }),

  [changeItemActionsEnum.DELETE]: (state: ChangeItemHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [changeItemActionsEnum.DELETE_MULTIPLE]: (state: ChangeItemHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [changeItemActionsEnum.UPDATE]: (state: ChangeItemHash, action: { elements: ChangeItemHash }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (state: ChangeItemHash = {}, action: any) {
  const handler = changeItemReducers[action.type]

  return handler ? handler(state, action) : state
}
