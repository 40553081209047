import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import ApiClient from '@/store/apiClient'

type SteelGrades = {
  defaultSteelGrades: Array<any>
  customSteelGrades: Array<any>
}

type ValidationType = 'number' | 'text' // types used in OMSCommandList.csv

export default class CommandUtil {
  private static getValidatedValue (type: ValidationType, value: string): string {
    let newValue = String(value ?? '')

    switch (type) {
      case 'number':
        newValue = newValue
          .replace(',', '.')
          .replace(/^0*/, '')
          .replace(/[^\d.]/g, '')

        if (newValue.indexOf('.') >= 0) {
          newValue = newValue.replace(/\.(?=.*[.])/g, '')
        }

        return String(Number(newValue))
      case 'text':
      default:
        return newValue
    }
  }

  public static getSteelGrades (currentSimulationCaseId: string): Promise<SteelGrades> {
    return ApiClient.get(`${'Network.URI(deprecated)'}/visualization_command/steelgrades/${currentSimulationCaseId}`)
  }

  public static getCommandByName (name: string, commands: Array<any>): any {
    return commands.find(rC => rC['#OMSCommand'] === name)
  }

  public static getAssignments (assignments: string) {
    const rawAssignments = (assignments ?? '').substring(1).split('+')
    const _assignments: any[] = []

    rawAssignments.forEach(assignment => {
      const arrayStringAssignment: Array<string> = assignment.split(',')

      if (arrayStringAssignment.length <= 1) {
        _assignments.push([ { key: assignment } ])

        return
      }

      const val: Array<any> = arrayStringAssignment.map((a: string) => {
        const data: Array<string> = /([a-zA-Z]*)+(>|<*)/.exec(a) ?? []

        return ({ key: data[1], val: [ data[1], data[2] ] })
      })

      _assignments.push(val)
    })

    return _assignments
  }

  public static applySteelGrade (assignments: Array<any>, steelGrade: any): Array<Array<string>> {
    const presetsArray: Array<Array<string>> = new Array(assignments.length).fill(0).map(() => []) ?? []

    if (steelGrade) {
      assignments.forEach((assign, index) => {
        assign.forEach((a: any) => {
          if (a.val) {
            presetsArray[index].push(a.key)
          }

          presetsArray[index].push(steelGrade[a.key] ?? '0')
        })
      })
    }

    return presetsArray
  }

  public static applyParameterList (command: any, parameter: Array<string>) {
    const rawParameter = cloneDeep(parameter)

    const args = command['#NumberOfArguments'].substr(1).split('+').reduce((acc: any[], val: any) => {
      const [ num, typeList ] = val.split('*')
      const types = typeList.split(',')
      const fields = []

      for (let i = 0; i < num; i++) {
        fields.push(...types)
      }

      return [
        ...acc,
        fields,
      ]
    }, [])

    const newParameter: any[] = []

    args.forEach((element: any, index: number) => {
      newParameter.push([])

      element.forEach((type: any) => {
        const value = CommandUtil.getValidatedValue(type, rawParameter[0])

        newParameter[index].push(value)
        rawParameter.shift()
      })
    })

    return newParameter
  }

  private static getCompleteShortName (shortName: string) {
    const type = shortName[0]
    const index = Number(shortName.substr(1))
    const isDefault = type === 'D' || type === 'd'

    return {
      completeShortName: (isDefault ? 'default_' : 'custom_') + index,
      isDefault,
      index,
    }
  }

  public static getSteelGradeInfo (
    shortName: string,
    command: any,
    defaultSteelGrades: Array<any>,
    customSteelGrades: Array<any>,
  ) {
    const { completeShortName, isDefault, index } = CommandUtil.getCompleteShortName(shortName)
    const steelGrade = ((isDefault ? defaultSteelGrades : customSteelGrades) ?? [])[index]

    if (!steelGrade) {
      return null
    }

    const assignments = CommandUtil.getAssignments(command['#Assignments'])

    return {
      shortName: completeShortName,
      parameter: CommandUtil.applySteelGrade(assignments, steelGrade),
    }
  }

  public static updateProjectDataWithCommandData (project: Project, commandDataBySimulationCaseId: any) {
    const _project = cloneDeep(project)

    _project.simulationCases.forEach(simulationCase => {
      const commandData = commandDataBySimulationCaseId[simulationCase.id]

      if (commandData) {
        simulationCase.commandFiles[0].commands.forEach((command: any) => {
          const newCommand = commandData[command.command]

          if (newCommand) {
            command.parameter = newCommand.parameter

            if (command.command === 'init_material') {
              command.shortName = newCommand.shortName
            }
          }
        })
      }
    })

    return _project
  }

  public static hasChanges (
    assignments: Array<any>,
    steelGrade: any,
    inputValues: Array<Array<string>>,
  ): boolean {
    return !isEqual(CommandUtil.applySteelGrade(assignments, steelGrade), inputValues)
  }
}
