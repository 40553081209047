export enum SegmentGroupActionsEnum {
  ACTION_ADD_SEGMENT_GROUP = 'SegmentGroup/ACTION_ADD_SEGMENT_GROUP',
  DELETE = 'SegmentGroup/DELETE',
  DELETE_MULTIPLE = 'SegmentGroup/DELETE_MULTIPLE',
  UPDATE = 'SegmentGroup/UPDATE',
  RENAME = 'SegmentGroup/RENAME',
  RESET = 'SegmentGroup/RESET',
}

export function addSegmentGroup (segmentGroupHash: SegmentGroupHash) {
  return {
    type: SegmentGroupActionsEnum.ACTION_ADD_SEGMENT_GROUP,
    segmentGroupHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentGroupReducers: Record<string, Function | undefined> = {
  [SegmentGroupActionsEnum.ACTION_ADD_SEGMENT_GROUP]: (
    _state: SegmentGroupHash,
    action: { segmentGroupHash: SegmentGroupHash },
  ) => (
    action.segmentGroupHash
  ),

  [SegmentGroupActionsEnum.DELETE]: (state: SegmentGroupHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentGroupActionsEnum.DELETE_MULTIPLE]: (state: SegmentGroupHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentGroupActionsEnum.UPDATE]: (state: SegmentGroupHash, action: { elements: SegmentGroupHash }) => ({
    ...state,
    ...action.elements,
  }),

  [SegmentGroupActionsEnum.RENAME]: (state: SegmentHash, action: { name: string, id: number }) => ({
    ...state,
    [action.id]: {
      ...state[action.id],
      name: action.name,
    },
  }),

  [SegmentGroupActionsEnum.RESET]: () => ({}),
}

export default function (
  state: SegmentGroupHash = {},
  action: any,
) {
  const handler = SegmentGroupReducers[action.type]

  return handler ? handler(state, action) : state
}
