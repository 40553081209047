// TODO: fix types
export enum RollerBodyDataPointsMountLogActionsEnum {
  // eslint-disable-next-line max-len
  ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG =
    'RollerBodyDataPointsMountLog/ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG',
  DELETE = 'RollerBodyDataPointsMountLog/DELETE',
  DELETE_MULTIPLE = 'RollerBodyDataPointsMountLog/DELETE_MULTIPLE',
  UPDATE = 'RollerBodyDataPointsMountLog/UPDATE',
}

export function addRollerBodyDataPointsMountLog (dataLineHash: Record<number, RollerBodyDataPointsMountLog>) {
  return {
    type: RollerBodyDataPointsMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerBodyDataPointsMountLogReducers: Record<string, Function | undefined> = {
  [RollerBodyDataPointsMountLogActionsEnum.ACTION_ADD_ROLLER_BODY_DATA_POINTS_MOUNT_LOG]: (
    _state: any,
    action: { dataLineHash: any },
  ) => (
    action.dataLineHash
  ),

  [RollerBodyDataPointsMountLogActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerBodyDataPointsMountLogActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerBodyDataPointsMountLogActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = RollerBodyDataPointsMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
