/* eslint-env browser */

import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import Button from '@/react/components/Button/index'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  resetAllElements: ElementsActions.resetAllElements,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  resetReducer: DataActions.resetReducer,
  addMetadataToCurrentProjectCasesMetadata: ApplicationActions.addMetadataToCurrentProjectCasesMetadata,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  name: string
  description: string
  openNewCase: boolean
  loading: boolean
}

const T = 'createRealDataCaseDialog'

export class CreateRealDataCaseDialog extends Component<Props, State> {
  @Identifiable('CreateRealDataCaseDialog') public static readonly NAME: string

  public override state: State = {
    name: '',
    description: '',
    openNewCase: false,
    loading: false,
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)

    const { currentSimulationCase } = this.props

    this.setState({
      name: currentSimulationCase.name,
      description: currentSimulationCase.description,
    })
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CreateRealDataCaseDialog.NAME)
  }

  private readonly handleNameChange = (event: KeyboardEvent) => {
    const { value } = event.target as HTMLInputElement

    this.setState({ name: value ?? '' })
  }

  private readonly handleDescriptionChange = (event: KeyboardEvent) => {
    const { value } = event.target as HTMLInputElement

    this.setState({ description: value ?? '' })
  }

  private readonly handleOpenNewCaseChange = (event: MouseEvent) => {
    const { checked } = event.target as HTMLInputElement

    this.setState({ openNewCase: checked })
  }

  private readonly handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { currentSimulationCase, enqueueSnackbar, t } = this.props
    const { name, description, openNewCase } = this.state

    this.setState({ loading: true })

    let $case: SimulationCase | null = null

    try {
      // TODO: change request to new api/request function
      $case = await ApiClient.post(
        `${useConfig().apiBaseURL}/cases`,
        {
          data: {
            projectId: currentSimulationCase.projectId,
            name,
            description,
            fromCaseId: currentSimulationCase.id,
            isRealData: true,
          },
        },
      )

      enqueueSnackbar(t(`${T}.create.success`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error) {
      enqueueSnackbar(t(`${T}.create.error`), { autoHideDuration: 3000, variant: 'error' })

      return
    }
    finally {
      this.setState({ loading: false })
    }

    if ($case && openNewCase) {
      const {
        featureFlags,
        resetReducer, 
        resetAllElements,
        setVisualizationMetaInformation,
        setCurrentCatalogId,
        setCurrentSimulationCase,
        addMetadataToCurrentProjectCasesMetadata, 
      } = this.props

      delete $case.project

      const isSlimVersion = FeatureFlags.usesSlimVersion(featureFlags)

      resetReducer()
      resetAllElements()
      setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
      setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
      setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
      setVisualizationMetaInformation('config', '', AppState.Caster)
      setCurrentCatalogId()
      setCurrentSimulationCase($case, isSlimVersion)
      addMetadataToCurrentProjectCasesMetadata({ id: $case.id, createdAt: new Date($case.createdAt) })

      IpcManager.send('loadCurrentCaster', $case)
    }

    this.handleClose()
  }

  public override render () {
    const { t } = this.props
    const { name, description, openNewCase, loading } = this.state

    return (
      <BaseDialog
        id={DialogID.CreateRealDataCase.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>{t(`${T}.message`)}</Text>
          <Input
            id={DialogID.CreateRealDataCase.NameInput}
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            placeholder={t(`${T}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleNameChange}
            onKeyDown={this.handleKeyDown}
          />
          <Input
            id={DialogID.CreateRealDataCase.DescriptionInput}
            label={t(`${T}.description.label`)}
            title={t(`${T}.description.label`)}
            placeholder={t(`${T}.description.placeholder`)}
            name='description'
            type='text'
            rows={8}
            value={description}
            onChange={this.handleDescriptionChange}
            onKeyDown={this.handleKeyDown}
          />
          <Input
            id={DialogID.CreateRealDataCase.OpenNewCaseInput}
            name='openNewCase'
            type='checkbox'
            title={t(`${T}.openNewCase.title`)}
            value={openNewCase}
            onChange={this.handleOpenNewCaseChange}
          />
          <Button
            id={DialogID.CreateRealDataCase.CreateButton}
            onClick={this.handleSubmit}
            disabled={!name || !description}
            title={!name || !description ? t(`${T}.create.disabled`) : ''}
            loading={loading}
          >
            {t(`${T}.create.label`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(CreateRealDataCaseDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
