import styled, { css } from 'styled-components'

export const Content = styled.div`${() =>
  css`
  position: absolute;
  top: 60px;
  height: calc(80vh - 60px);
  width: 100%;
`}`

export const Title = styled.div`${({ theme }) =>
  css`
  color: ${theme.mainFontColor};
  font-weight: 600;
  font-size: 15px;
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`}`

export const DragNDrop = styled.div<{ $float?: string }>`${({ $float }) =>
  css`
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
  padding: 0 30px 25px;
  float: ${$float ?? 'left'};
`}`

export const DropContainer = styled.div<{ $editMode?: boolean }>`${({ theme, $editMode }) =>
  css`
  position: relative;
  height: calc(100% - 55px);
  background: ${$editMode ? theme.colors.swatch5 : theme.colors.swatch6};
  padding: 10px;
  width: calc(100% + 20px);
  margin-left: -20px;
  ${$editMode && css`border: solid 2px ${theme.colors.swatch6};`}
`}`

export const List = styled.div<{ $hasQuickSettings?: boolean }>`${({ $hasQuickSettings }) =>
  css`
  height: calc(100% - ${$hasQuickSettings ? 150 : 50}px);
  padding-right: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  user-select: none;
  ${$hasQuickSettings && css`margin-top: 100px;`}
`}`

export const ListItem = styled.div<{ $editMode?: boolean, $selected?: boolean }>`${({ theme, $editMode, $selected }) =>
  css`
  position: relative;
  height: 50px;
  width: 100%;
  margin: 2px auto;
  background: ${!$selected ? theme.colors.swatch6 : theme.colors.swatch5};
  border: solid 2px ${theme.borderColor};
  padding: 0 10px;
  cursor: ${!$editMode ? 'grab' : 'default'};
`}`

export const ListTitle = styled.div`${({ theme }) =>
  css`
  position: relative;
  height: 22px;
  width: 100%;
  margin: 2px auto;
  background: ${theme.primary.main};
  color: ${theme.primary.font};
  padding: 5px;
  font-size: 12px;
`}`

export const InnerText = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 50%;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate(0, -50%);
  color: ${theme.mainFontColor};
`}`

export const I = styled.i<{ $left?: boolean, $size?: string }>`${({ theme, $left, $size }) =>
  css`
  position: absolute;
  font-size: ${$size ?? '40px'};
  color: ${theme.colors.swatch9};
  ${$left ? 'left: 10px' : 'right: 10px'};
  top: 10px;
  cursor: pointer;
`}`

export const Dialog = styled.div`${({ theme }) =>
  css`
  position: relative;
  margin-top: 10vh;
  margin-left: calc(50% - 600px);
  width: 1200px;
  height: 80vh;
  background: ${theme.colors.swatch5 ?? '#2A3038'};
  border-radius: 10px;
  z-index: 200;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`}`

export const DialogBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  filter: blur(5px);
  transform: scale(1.1);
  z-index: 150;
`

export const Header = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  border-bottom: solid 1px ${theme.colors.swatch6 ?? '#373f49'};
`}`

export const HeaderTitle = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  font-size: 25px;
  font-weight: 500;
  color: ${theme.colors.swatch9};
`}`

export const PlotQuickConfig = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  padding: 10px;
  background: ${theme.colors.swatch5 ?? '#2A3038'};
`}`
