export default class Logic {
  private static validPointPosition (num: any) {
    if (typeof num !== 'string') {
      return false
    }

    const indexOfPoint = num.indexOf('.')

    if (indexOfPoint === -1) {
      return true
    }
    else if (indexOfPoint === num.length - 1) {
      return false
    }

    return true
  }

  public static numberValid (value: string, param: Parameter) {
    if (!String(value).length || value == null) {
      return false
    }

    const numberValue = Number(value)
    const isNumber = !isNaN(numberValue)

    if (!isNumber) {
      return false
    }

    const isBiggerOrEqualThanMin = param.numberMin === undefined || (param.numberMin <= numberValue)
    const isSmallerOrEqualThanMax = param.numberMax === undefined || (param.numberMax >= numberValue)

    return (isBiggerOrEqualThanMin && isSmallerOrEqualThanMax && (Logic.validPointPosition(String(value))))
  }
}
