export enum moldMountLogActionsEnum {
  ACTION_ADD_MOLD_MOUNT_LOG = 'MoldMountLog/ACTION_ADD_MOLD_MOUNT_LOG',
  DELETE = 'MoldMountLog/DELETE',
  RESET = 'MoldMountLog/RESET',
  UPDATE_WIDTH = 'MoldMountLog/UPDATE_WIDTH',
}

export function addMoldMountLog (moldMountLog: MoldMountLog) {
  return {
    type: moldMountLogActionsEnum.ACTION_ADD_MOLD_MOUNT_LOG,
    moldMountLog,
  }
}

export function updateMoldMountLogWidthById (moldMountLogId: string, width: number) {
  return {
    type: moldMountLogActionsEnum.UPDATE_WIDTH,
    moldMountLogId,
    width,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const moldMountLogReducers: Record<string, Function | undefined> = {
  [moldMountLogActionsEnum.ACTION_ADD_MOLD_MOUNT_LOG]: (
    _state: MoldMountLogHash,
    action: { moldMountLog: MoldMountLog },
  ) => action.moldMountLog,

  [moldMountLogActionsEnum.DELETE]: (_state: MoldMountLogHash) => null,

  [moldMountLogActionsEnum.RESET]: () => null,

  [moldMountLogActionsEnum.UPDATE_WIDTH]: (
    state: MoldMountLogHash,
    action: { moldMountLogId: string, width: number },
  ) => {
    const newState = { ...state }

    if (newState[action.moldMountLogId]) {
      newState[action.moldMountLogId].width = action.width
    }

    return state
  },
}

export default function (state: MoldMountLogHash | null = null, action: any) {
  const handler = moldMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
