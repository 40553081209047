import { VisualizationState } from '@/types/visualization'

import {
  initialState,
  VisualizationActionsEnum as VisActionsEnum,
} from '../consts'

const contextMenuReducers = {
  [VisActionsEnum.ACTION_VISUALIZATION_OPEN_CONTEXT_MENU]: (state: VisualizationState, { dType, data }: any) => ({
    ...state,
    contextMenu: {
      type: dType,
      data,
    },
  }),
  [VisActionsEnum.ACTION_VISUALIZATION_CLOSE_CONTEXT_MENU]: (state: VisualizationState) => ({
    ...state,
    contextMenu: initialState.contextMenu,
  }),
}

export default contextMenuReducers
