import cloneDeep from 'lodash/cloneDeep'

import Util from '@/logicHandlers/ServerLogic/actions/Util'
import ApiClient from '@/store/apiClient'

export const T = 'inputDataDialog'

export default class Logic {
  public static async handleUpload (
    ext: string,
    type: string,
    fileType: string,
    currentSimulationCase: SimulationCase,
    setCurrentSimulationCase: (simCase: SimulationCase) => void,
    uploadType: string,
  ) {
    try {
      const data = await Util.openUploadFileDialog(
        ext,
        `/caster_input_data/${type}/${fileType}`,
        'patch',
        (formData: FormData) => {
          formData.append('simulationCaseId', currentSimulationCase.id)
          formData.append('uploadType', uploadType)
        },
      )

      const simulationCase = cloneDeep(currentSimulationCase)

      simulationCase.inputData = data.inputData

      setCurrentSimulationCase(simulationCase)
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  public static async handleGenerateStrandGuide (simulationCaseId: string, uploadType: string): Promise<any> {
    try {
      const { data } = await ApiClient.post(`${'Network.URI(deprecated)'}/caster_generate/strandGuide`, {
        data: { simulationCaseId, uploadType },
      })

      return data
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return {}
  }

  public static async handleGenerateNozzles (simulationCaseId: string, uploadType: string): Promise<any> {
    try {
      const { data } = await ApiClient.post(`${'Network.URI(deprecated)'}/caster_generate/nozzles`, {
        data: { simulationCaseId, uploadType },
      })

      return data
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return {}
  }
}
