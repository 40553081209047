import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Delete from '@/react/specific/Delete'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import { CheckListHeader, FormWrapper, ListItem } from '../../Dialogs/DialogStyles'
import { InnerText } from '../Styles'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  visualizationMetaInformation: visualization.visualizationMetaInformation,
}), {
  setConfig: VisualizationActions.setConfig,
  savePlotConfig: VisualizationActions.savePlotConfig,
  removePlotConfig: VisualizationActions.removePlotConfig,
  showConfigDialog: VisualizationActions.showConfigDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  allowDeletion?: boolean
  fullscreen: boolean
  t: Translation
}

class MergedTab extends Component<Props> {
  private readonly handleRemoveDataSourceFromMergedPlot = (plotConfigId: string) => {
    const { editDialogConfigId, plotConfigs, removePlotConfig, savePlotConfig, showConfigDialog } = this.props

    const plotConfig = plotConfigs[editDialogConfigId]

    if (!plotConfig) {
      return
    }

    if (plotConfig.configIds.length === 1) {
      removePlotConfig(editDialogConfigId)
      showConfigDialog()

      return
    }

    plotConfig.configIds = plotConfig.configIds.filter(id => id !== plotConfigId)

    savePlotConfig(plotConfig)
  }
  
  public override render () {
    const {
      editDialogConfigId,
      plotConfigs,
      fullscreen,
      t,
      allowDeletion,
    } = this.props

    const plotConfig = plotConfigs[editDialogConfigId]

    if (!plotConfig) {
      return null
    }

    const { configIds } = plotConfig

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <CheckListHeader>{t('plotConfig.mergedIds')}</CheckListHeader>
        <div>
          {
            configIds && configIds.map((plotConfigId, i) => {
              const config = plotConfigs[plotConfigId]

              if (!config) {
                <ListItem key={i} title=''>No info available</ListItem>
              }

              return (
                <ListItem
                  style={
                    {
                      display: 'flex',
                      alignItems: 'flex-end',
                    }
                  }
                  key={plotConfigId}
                  title={plotConfigId}
                >
                  {
                    allowDeletion
                      ? (
                        <Delete
                          onClick={() => this.handleRemoveDataSourceFromMergedPlot(plotConfigId)}
                        />
                      )
                      : null
                  }
                  <InnerText style={{ position: 'static' }}>{`${config.name} - ${plotConfigId}`}</InnerText>
                </ListItem>
              )
            })
          }
        </div>
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(MergedTab as any) as any) as any
