import isEqual from 'lodash/isEqual'
import Tooltip from 'rc-tooltip'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Util from '@/logic/Util'
import Input from '@/react/specific/Input'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import TabsUtil from './TabsUtil'
import { FormWrapper, InputWrapper, Text } from '../../Dialogs/DialogStyles'
import DynamicFormulaInfo from '../DynamicFormulaInfo'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
}), {
  savePlotConfig: VisualizationActions.savePlotConfig,
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  tileConfig: any
  plotConfig: any
}

class DataTab extends Component<Props, State> {
  private tileConfigTimeout?: number

  private plotConfigTimeout?: number

  public constructor (props: Props) {
    super(props)

    const { tileConfigs, currentTileId, plotConfigs, editDialogConfigId } = props

    this.state = {
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId], id: currentTileId } : {},
      plotConfig: currentTileId ? plotConfigs[editDialogConfigId] : {},
    }
  }
  
  public override componentDidUpdate (prevProps: Props, prevState: State) {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.tileConfigTimeout)

      this.tileConfigTimeout = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        this.tileConfigTimeout = undefined
      }, 1000)
    }

    if (!isEqual(plotConfig, prevState.plotConfig)) {
      clearTimeout(this.plotConfigTimeout)

      this.plotConfigTimeout = window.setTimeout(() => {
        savePlotConfig(plotConfig)
        this.plotConfigTimeout = undefined
      }, 1000)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (this.tileConfigTimeout) {
      clearTimeout(this.tileConfigTimeout)
      saveTileConfig(tileConfig)
    }

    if (this.plotConfigTimeout) {
      clearTimeout(this.plotConfigTimeout)
      savePlotConfig(plotConfig)
    }
  }

  private readonly handleInputDataTile = (event: any) => {
    const { tileConfig } = this.state
    const { value, name } = event.target

    let val: any = value

    if (name === 'dynamicFormula') {
      val = Util.prepareFormula(val)

      if (tileConfig.dynamicFormula === val) {
        return
      }
    }

    this.setState({
      tileConfig: {
        ...tileConfig,
        [name]: val,
      },
    })
  }

  private readonly handleInput = (event: any) => {
    const { plotConfigs, editDialogConfigId } = this.props
    const { value, name } = event.target

    let val: any = value

    if (/^xRange/.test(name)) {
      val = plotConfigs[editDialogConfigId].xValues?.indexOf(Number(val)) ?? -1
    }

    this.setState(({ tileConfig }) => ({
      tileConfig: {
        ...tileConfig,
        [name]: val,
      },
    }))
  }
  
  public override render () {
    const { plotConfig, tileConfig } = this.state
    const { fullscreen, t } = this.props
    const { type } = tileConfig

    TabsUtil.setConfigs(tileConfig, plotConfig)

    const dynamicFormula = TabsUtil.getValue('dynamicFormula', '')
    const plotName = TabsUtil.getValue('name')
    const xRangeMin = TabsUtil.getValue('xRangeMin')
    const xRangeMax = TabsUtil.getValue('xRangeMax')
    const vRangeMin = TabsUtil.getValue('vRangeMin', type === 'gage' ? '' : undefined)
    const vRangeMax = TabsUtil.getValue('vRangeMax', type === 'gage' ? '' : undefined)
    const frequency = TabsUtil.getValue('frequency')
    const xValues = TabsUtil.getValue('xValues')

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <InputWrapper $fullscreen={fullscreen}>
          {
            fullscreen &&
            (
              <span>
                <Input
                  label={t('plotConfig.name.plotId')}
                  name='name'
                  type='text'
                  title={plotName}
                  value={plotName}
                  onChange={this.handleInput}
                />
                <hr />
              </span>
            )
          }
          {
            type !== 'gage' &&
            (
              <Input
                label={t('plotConfig.xRange.label')}
                title={t('plotConfig.xRange.title')}
                name='xRange'
                type='selectRange'
                min={xValues ? xValues[xRangeMin > xRangeMax ? xRangeMax : xRangeMin] : 0}
                max={
                  xValues
                    ? xValues[xRangeMin > xRangeMax ? xRangeMin : xRangeMax] || xValues[xValues.length - 1]
                    : 0
                }
                selectors={xValues}
                onChange={this.handleInput}
              />
            )
          }
          <Input
            label={t('plotConfig.vRange.label')}
            title={t('plotConfig.vRange.title')}
            name='vRange'
            type='range'
            min={vRangeMin}
            max={vRangeMax}
            onChange={this.handleInput}
          />
        </InputWrapper>
        {
          type === 'text' &&
          (
            <InputWrapper $fullscreen={fullscreen}>
              <div style={{ width: '100%' }}>
                <Tooltip
                  align={{ offset: [ 0, 30 ] }}
                  placement='top'
                  trigger={[ 'click' ]}
                  overlay={<DynamicFormulaInfo />}
                >
                  <Input
                    label={t('plotConfig.dynamicFormula.label')}
                    title={t('plotConfig.dynamicFormula.title')}
                    name='dynamicFormula'
                    type='text'
                    value={dynamicFormula}
                    onChange={this.handleInputDataTile}
                  />
                </Tooltip>
                <Text>
                  {
                    `
                  ${t('plotConfig.dynamicFormula.example')}
                  ${Util.testFormula(dynamicFormula, t('plotConfig.dynamicFormula.error'))}
                `
                  }
                </Text>
                <Text>{t('plotConfig.dynamicFormula.text')}</Text>
                <br />
              </div>
            </InputWrapper>
          )
        }
        <InputWrapper $fullscreen={fullscreen}>
          <Input
            half
            label={t('plotConfig.frequency.label')}
            title={t('plotConfig.frequency.title')}
            name='frequency'
            type='select'
            value={frequency}
            selectors={Array(10).fill(0).map((v, i) => i + 1)} // TODO: It was fill() but TS threw errors
            onChange={this.handleInput}
          />
        </InputWrapper>
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(DataTab as any) as any) as any
