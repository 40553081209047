import Button from '@material-ui/core/Button'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Icon from '@/react/specific/Icon'
import * as ApplicationActions from '@/store/application/main/actions'
import { Translation } from '@/types/translation'

import { DeleteDialog } from '../DeleteDialog'

const T = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isSelected: boolean
  allSelected: boolean
  isSelectedAndNotRunning: boolean
  t: Translation
}

class DeleteSelected extends Component<Props> {
  private readonly handleClick = () => {
    const { openDialog } = this.props

    openDialog(DeleteDialog.NAME)
  }

  private readonly getTitle = () => {
    const { isSelected, allSelected, isSelectedAndNotRunning, t } = this.props

    if (!isSelected) {
      return t(`${T}.titles.deleteSelected.nothingSelected`)
    }

    if (allSelected) {
      return t(`${T}.titles.deleteSelected.allSelected`)
    }

    if (!isSelectedAndNotRunning) {
      return t(`${T}.titles.deleteSelected.running`)
    }

    return t(`${T}.titles.deleteSelected.default`)
  }
  
  public override render () {
    const { isSelected, allSelected, isSelectedAndNotRunning, ...restProps } = this.props
    const disabled = !isSelected || allSelected || !isSelectedAndNotRunning

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <Icon icon='trash-alt' />
      </Button>
    )
  }
}

export default withNamespaces('application')(connector(DeleteSelected as any) as any) as any
