import MomentUtils from '@date-io/moment'
import { BaseDateTimePickerProps, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import type { Moment } from 'moment'
import styled, { css } from 'styled-components'

const StyledKeyboardDateTimePicker = styled(KeyboardDateTimePicker as any)<{ $half?: boolean }>`${({ $half }) =>
  css`
  width: ${$half ? 'calc(50% - 10px)' : '100%'} !important;
  margin-right: ${$half ? '10px' : '0'} !important;
`}`

type DateTimePickerProps = BaseDateTimePickerProps & {
  label: string
  value: string
  onChange: (moment: Moment) => void
  half?: boolean
}

export function DateTimePicker (props: DateTimePickerProps) {
  const { half, ...restProps } = props

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StyledKeyboardDateTimePicker
        variant='inline'
        format='yyyy-MM-DD HH:mm'
        margin='normal'
        ampm={false}
        $half={half}
        {...restProps as any}
      />
    </MuiPickersUtilsProvider>
  )
}
