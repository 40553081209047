/* eslint-env browser */

import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { useConfig } from '@/config'
import Button from '@/react/components/Button/index'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  selectedPaths: state.data.selectedPaths,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  copyCount: number
  useExistingUUIDs: boolean
  loading: boolean
}

const T = 'createRealDataDialog'

export class CreateRealDataDialog extends Component<Props, State> {
  @Identifiable('CreateRealDataDialog') public static readonly NAME: string

  public override state: State = {
    copyCount: 1,
    useExistingUUIDs: false,
    loading: false,
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CreateRealDataDialog.NAME)
  }
  
  private readonly handleCopyCountChange = (event: MouseEvent) => {
    const { value } = event.target as HTMLInputElement & { value: number }

    this.setState({ copyCount: value })
  }

  private readonly handleUseExistingUUIDsChange = (event: MouseEvent) => {
    const { checked } = event.target as HTMLInputElement & { checked: boolean }

    this.setState({ useExistingUUIDs: checked })
  }

  private readonly handleSubmit = async () => {
    const { currentSimulationCase, selectedPaths, enqueueSnackbar, t } = this.props
    const { copyCount, useExistingUUIDs } = this.state

    this.setState({ loading: true })

    try {
      // TODO: change request to new api/request function
      await ApiClient.post(
        `${useConfig().apiBaseURL}/real-data`,
        {
          data: {
            projectId: currentSimulationCase.projectId,
            casterId: currentSimulationCase.currentCasterId,
            mountLogIdsByType: this.getMountLogIdsByTypeFromPaths(selectedPaths),
            copyCount,
            useExistingUUIDs,
          },
        },
      )

      enqueueSnackbar(t(`${T}.create.success`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      enqueueSnackbar(t(`${T}.create.error`), { autoHideDuration: 3000, variant: 'error' })

      return
    }
    finally {
      this.setState({ loading: false })
    }

    this.handleClose()
  }

  private readonly getMountLogIdsByTypeFromPaths = (paths: Set<string>) => {
    const data = {} as Record<CasterElementNames, string[]>

    for (const path of paths) {
      const { id: numericId, type } = ThreeUtil.getElementInfo(path)
      const mountLogId = MainView.numericIdMountLogMaps[`${type}MountLog`][numericId] as string | undefined

      if (!mountLogId) {
        throw new Error(`Could not find id for path: ${path}`)
      }

      data[type] = data[type] ?? []

      data[type].push(mountLogId)
    }

    return data
  }

  public override render () {
    const { selectedPaths, t } = this.props
    const { copyCount, useExistingUUIDs, loading } = this.state

    const noCopyCount = !copyCount
    const noSelection = !selectedPaths.size
    const duplicates = useExistingUUIDs && copyCount > 1

    return (
      <BaseDialog
        id={DialogID.CreateRealData.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>{t(`${T}.message`)}</Text>
          <Text>{t(`${T}.selectedElements`, { elements: selectedPaths.size })}</Text>
          <Input
            id={DialogID.CreateRealData.CopyCountInput}
            name='copyCount'
            type='number'
            label={t(`${T}.copyCount.label`)}
            title={t(`${T}.copyCount.title`)}
            value={copyCount}
            step={1}
            min={1}
            onChange={this.handleCopyCountChange}
          />
          <Input
            id={DialogID.CreateRealData.UseExistingUUIDsInput}
            name='useExistingUUIDs'
            type='checkbox'
            label={t(`${T}.useExistingUUIDs.label`)}
            title={t(`${T}.useExistingUUIDs.title`)}
            value={useExistingUUIDs}
            onChange={this.handleUseExistingUUIDsChange}
          />
          <Text>{t(`${T}.useExistingUUIDs.message`)}</Text>
          <Button
            id={DialogID.CreateRealData.CreateButton}
            onClick={this.handleSubmit}
            disabled={noCopyCount || noSelection || duplicates}
            loading={loading}
            title={
              noSelection
                ? t(`${T}.disabled.noSelectedElements`)
                : duplicates
                  ? t(`${T}.disabled.duplicates`)
                  : noCopyCount
                    ? t(`${T}.disabled.noCopyCount`)
                    : ''
            }
          >
            {t(`${T}.create.label`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(CreateRealDataDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
