import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import IpcManager from '@/IpcManager'
import FeatureFlags from '@/react/FeatureFlags'
import Button from '@/react/specific/Button'
import SecondaryButton from '@/react/specific/SecondaryButton'
import DataActions from '@/store/data/actions'
import * as FilterActions from '@/store/filter/actions'
import { DefaultState } from '@/types/state'

import Section from '../Section'

export const NozzleCatalogSelect = styled.select`
  outline:       none;
  background:    #22282e;
  color:         #CCCCCC;
  border:        none;
  border-bottom: solid 1px #CCCCCC; 
  width:         120px;
  height:        27px;
  margin:        10px 9px;
  float:         left;
`

const UseButton = styled(SecondaryButton)`
  float: right;
`

const RedButton = styled(Button)`
  margin: 10px 0 0 0;
  width:  calc(100% - 10px);
`

const SelectBox = styled.div`
  display:    block;
  text-align: center;
`

const CatalogBlock = styled.div`
  min-height: 40px;
`

const connector = connect((state: DefaultState) => ({
  catalog: state.data.catalog,
  catalogElement: state.data.catalogElement,
  selectedPaths: state.data.selectedPaths,
  editValues: state.data.editValues,
  filterElement: state.filter.filterElement,
  hasEditChanges: state.data.hasEditChanges,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
}), {
  setElements: DataActions.setElements,
  setEditChanges: DataActions.setEditChanges,
  setEditValues: DataActions.setEditValues,
  setCatalogElement: DataActions.setCatalogElement,
  setFilterValues: FilterActions.setFilterValues,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  path: string
  type: string
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCatalogNozzle: string
  loadingCatalog: boolean
}

export class NozzleCatalog extends Component<Props, State> {
  public override state: State = {
    selectedCatalogNozzle: '',
    loadingCatalog: false,
  }

  private timeoutId: number | null = null

  public override componentDidMount () {
    const { setCatalogElement } = this.props

    IpcManager.both.on('loadingCatalog', this.handleLoadingCatalog)

    setCatalogElement()
  }

  public override componentWillUnmount () {
    IpcManager.both.removeListener('loadingCatalog', this.handleLoadingCatalog)

    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
    }
  }

  private readonly handleLoadingCatalog = (event: any, value: State) => {
    if (!value.loadingCatalog) {
      this.timeoutId = window.setTimeout(() => {
        this.setState({
          ...value,
        })
      }, 1000)
    }
    else {
      this.setState({
        ...value,
      })
    }
  }

  private readonly handleChange = (event: any) => {
    const { catalog, setCatalogElement } = this.props
    const selectedCatalogNozzle = event.target.value

    this.setState({ selectedCatalogNozzle })

    if (catalog?.[selectedCatalogNozzle]) {
      const selectedCatalog = catalog[selectedCatalogNozzle]
      let catalogElement = {}

      const createCatalogElement = (sourceKey: CatalogEntryKey, targetKey: string) => {
        if (selectedCatalog[sourceKey]) {
          catalogElement = {
            ...catalogElement,
            [targetKey]: selectedCatalog[sourceKey],
          }
        }
      }

      createCatalogElement('field5', 'name')
      createCatalogElement('field2', 'nozzleType')
      createCatalogElement('field9', 'angleWidth')
      createCatalogElement('field11', 'angleLength')

      setCatalogElement(catalogElement)
    }
  }

  private readonly handleCatalogOption = () => {
    const {
      catalog,
      selectedPaths,
      setElements,
      setFilterValues,
      path,
      filterElement,
      catalogElement,
      setEditChanges,
      type,
      setEditValues,
      editValues,
      featureFlags,
      hasEditChanges,
    } = this.props

    const { selectedCatalogNozzle } = this.state

    if (catalog && (catalog as any)[selectedCatalogNozzle] && catalogElement) {
      if (!path) {
        setFilterValues({
          ...filterElement,
          Nozzle: {
            ...filterElement.Nozzle,
            ...catalogElement,
          },
        })

        if (FeatureFlags.canEditNozzle(featureFlags)) {
          setEditValues({
            Nozzle: {
              ...editValues.Nozzle,
              ...catalogElement,
            },
          })
        }

        return
      }

      if (!(hasEditChanges as any)?.[type]) {
        setEditChanges(true, type)
      }

      // TODO: maybe the rest of the logic can change to using Set as well
      setElements(Array.from(selectedPaths), catalogElement)
    }
  }

  private readonly handleUploadCatalog = () => {
    IpcManager.both.send('openUploadCatalogDialog')
  }

  public override render () {
    const { catalog, featureFlags, isLoggedIn, t } = this.props
    const { selectedCatalogNozzle, loadingCatalog } = this.state

    return (
      <Section name={t('catalog.title')} closed>
        <SelectBox>
          {
            catalog?.length
              ? (
                <CatalogBlock>
                  <NozzleCatalogSelect
                    value={selectedCatalogNozzle ?? ''}
                    onChange={this.handleChange}
                  >
                    <option value='' disabled>{t('catalog.choose')}</option>
                    {
                      catalog?.map((entry, index) => (
                        <option value={index} key={index}>
                          {
                            Object.keys(entry).map((key, index) => String(
                              index < 7
                                ? String(entry[`field${index + 1}`] ? `${entry[`field${index + 1}`]} | ` : '- | ')
                                : '',
                            ))
                          }
                        </option>
                      ))
                    }
                  </NozzleCatalogSelect>
                  <UseButton
                    onClick={this.handleCatalogOption}
                    value={t('catalog.use')}
                    title={t('catalog.use')}
                    small
                    disabled={!catalog || !catalog.length || !selectedCatalogNozzle}
                  />
                  {
                    !isLoggedIn && FeatureFlags.canEditNozzle(featureFlags) && (
                      <RedButton
                        value={t('catalog.change')}
                        title={t('catalog.change')}
                        onClick={this.handleUploadCatalog}
                        loading={loadingCatalog}
                      />
                    )
                  }
                </CatalogBlock>
              )
              : !isLoggedIn && FeatureFlags.canEditNozzle(featureFlags)
                ? <RedButton value={t('catalog.open')} onClick={this.handleUploadCatalog} />
                : <RedButton value={t('catalog.found')} disabled />
          }
        </SelectBox>
      </Section>
    )
  }
}

export default withNamespaces('caster')(connector(NozzleCatalog as any) as any) as any
