export enum StrandGuideMountLogActionsEnum {
  ACTION_ADD_STRAND_GUIDE_MOUNT_LOG = 'StrandGuideMountLog/ACTION_ADD_STRAND_GUIDE_MOUNT_LOG',
  DELETE = 'StrandGuideMountLog/DELETE',
  UPDATE = 'StrandGuideMountLog/UPDATE',
  RESET = 'StrandGuideMountLog/RESET',
}

export function addStrandGuideMountLog (strandGuideHash: StrandGuideMountLogHash) {
  return {
    type: StrandGuideMountLogActionsEnum.ACTION_ADD_STRAND_GUIDE_MOUNT_LOG,
    strandGuideHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StrandGuideMountLogReducers: Record<string, Function | undefined> = {
  [StrandGuideMountLogActionsEnum.ACTION_ADD_STRAND_GUIDE_MOUNT_LOG]: (
    _state: StrandGuideMountLogHash,
    action: { strandGuideHash: StrandGuideMountLogHash },
  ) => (
    action.strandGuideHash
  ),

  [StrandGuideMountLogActionsEnum.UPDATE]: (
    state: StrandGuideMountLogHash,
    action: { elements: StrandGuideMountLogHash },
  ) => ({
    ...state,
    ...action.elements,
  }),

  [StrandGuideMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: StrandGuideMountLogHash = {},
  action: any,
) {
  const handler = StrandGuideMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
