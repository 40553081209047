import { DeleteDialogPlot, VisualizationState } from '@/types/visualization'

import { VisualizationActionsEnum as VisActionsEnum } from '../consts'

const dialogReducers = {
  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_CONFIG_DIALOG]: (
    state: VisualizationState,
    { editDialogConfigId, openConfigDialogWindow }: {
      editDialogConfigId: string
      openConfigDialogWindow: boolean
    },
  ) => ({
    ...state,
    editDialogConfigId,
    openConfigDialogWindow,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_DELETE_DIALOG]: (
    state: VisualizationState,
    { deleteDialogPlot, openDeleteDialogWindow }: {
      deleteDialogPlot: DeleteDialogPlot
      openDeleteDialogWindow: boolean
    },
  ) => ({
    ...state,
    deleteDialogPlot,
    openDeleteDialogWindow,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_EDIT_DASHBOARD_DIALOG]: (
    state: VisualizationState,
    { editDialogDashboardData, openDashboardWindow }: {
      editDialogDashboardData: any
      openDashboardWindow: boolean
    },
  ) => ({
    ...state,
    editDialogDashboardData: { ...editDialogDashboardData },
    openDashboardWindow,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_DERIVE_DIALOG]: (
    state: VisualizationState,
    { openDerivePlotDialog }: { openDerivePlotDialog: boolean },
  ) => ({
    ...state,
    openDerivePlotDialog,
    viewId: '',
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_ADD_PLOT_DIALOG]: (
    state: VisualizationState,
    { addPlotViewId, openAddPlotDialogWindow }: {
      addPlotViewId: string
      openAddPlotDialogWindow: boolean
    },
  ) => ({
    ...state,
    addPlotViewId,
    openAddPlotDialogWindow,
  }),

  [VisActionsEnum.ACTION_SHOW_DELETE_DASHBOARD_DIALOG]: (state: VisualizationState, action: any) => ({
    ...state,
    dashboardToDelete: action.deleteDashboardObject,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG]: (
    state: VisualizationState,
    { open }: { open: boolean },
  ) => ({
    ...state,
    dataSource: open || !state.dataSource,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG]: (
    state: VisualizationState,
    { open }: { open: boolean },
  ) => ({
    ...state,
    plotExport: open ?? true,
  }),
}

export default dialogReducers
