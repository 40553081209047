import cloneDeep from 'lodash/cloneDeep'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Button from '@/react/components/Button/index'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'

import Logic, { T as T_PARENT } from './Logic'
import { FileName } from './styles'

const T = `${T_PARENT}.nozzles`

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
  caseLocks: state.application.main.caseLocks,
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  uploadType: string
}

export class Nozzles extends Component<Props, State> {
  public override state: State = {
    uploadType: '',
  }
  
  public override componentDidMount () {
    this.handleData()
  }
  
  public override componentDidUpdate () {
    this.handleData()
  }

  private readonly handleData = () => {
    if (this.state.uploadType !== '') {
      return
    }

    const { currentSimulationCase: { inputData: { nozzles: { uploadType } } } } = this.props

    if (!uploadType) {
      return
    }

    this.setState({ uploadType })
  }

  private readonly handleChangeUploadType = (event: any) => this.setState({ uploadType: (event.target.value as any) })

  private readonly handleUploadNozzlesProjectFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.prj', 'nozzles', 'fileProject', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadNozzlesLooseSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileLooseSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadNozzlesFixedSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileFixedSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadNozzlesRightSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileRightSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadNozzlesLeftSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileLeftSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadNozzlesCatalogFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileCatalog', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleGenerate = async () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    const data = await Logic.handleGenerateNozzles(currentSimulationCase.id, uploadType)

    if (!Object.keys(data).length) {
      return
    }

    const simulationCase = cloneDeep(currentSimulationCase)

    simulationCase.inputData.nozzles = data

    setCurrentSimulationCase(simulationCase)
  }
  
  public override render () {
    const { uploadType } = this.state
    const { authenticationData, currentSimulationCase, caseLocks, t } = this.props
    const {
      inputData: { strandGuide, nozzles },
      simulationStartedAt,
      currentCasterId,
    } = currentSimulationCase
    const {
      fileProject,
      fileLooseSide,
      fileFixedSide,
      fileRightSide,
      fileLeftSide,
      fileCatalog,
      generateStartedBy,
    } = nozzles
    const { generateStartedBy: otherGenerateStartedBy } = strandGuide

    const uploadTypeSelectors = [
      { key: '4', value: t(`${T}.uploadType.4`) },
      { key: '5', value: t(`${T}.uploadType.5`) },
    ]

    const noFile = t(`${T_PARENT}.noFile`)

    const { isLocked: generalLocked } = FeatureFlags.getLockedStatus('General', authenticationData, caseLocks)
    const { isLocked: nozzlesLocked } = FeatureFlags.getLockedStatus('Nozzles', authenticationData, caseLocks)

    const isLocked = generalLocked || nozzlesLocked

    const disabled = !currentCasterId ||
      Boolean(simulationStartedAt) ||
      Boolean(generateStartedBy) ||
      Boolean(otherGenerateStartedBy) ||
      isLocked ||
      (uploadType === '4' && !fileProject) ||
      (uploadType === '5' && (!fileLooseSide || !fileFixedSide || !fileRightSide || !fileLeftSide || !fileCatalog))

    return (
      <div>
        <Input
          name='uploadType'
          type='select'
          label={t(`${T}.uploadType.label`)}
          title={t(`${T}.uploadType.title`)}
          value={uploadType}
          selectors={uploadTypeSelectors}
          onChange={this.handleChangeUploadType}
        />
        {
          uploadType === '4' &&
          (
            <div>
              <Button
                title={t(`${T}.uploadProjectFile.title`)}
                onClick={this.handleUploadNozzlesProjectFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadProjectFile.label`)}
              </Button>
              <FileName title={fileProject ?? noFile}>{fileProject ?? noFile}</FileName>
            </div>
          )
        }
        {
          uploadType === '5' &&
          (
            <div>
              <Button
                title={t(`${T}.uploadLooseSideFile.title`)}
                onClick={this.handleUploadNozzlesLooseSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadLooseSideFile.label`)}
              </Button>
              <FileName title={fileLooseSide ?? noFile}>{fileLooseSide ?? noFile}</FileName>
              <Button
                title={t(`${T}.uploadFixedSideFile.title`)}
                onClick={this.handleUploadNozzlesFixedSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadFixedSideFile.label`)}
              </Button>
              <FileName title={fileFixedSide ?? noFile}>{fileFixedSide ?? noFile}</FileName>
              <Button
                title={t(`${T}.uploadRightSideFile.title`)}
                onClick={this.handleUploadNozzlesRightSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadRightSideFile.label`)}
              </Button>
              <FileName title={fileRightSide ?? noFile}>{fileRightSide ?? noFile}</FileName>
              <Button
                title={t(`${T}.uploadLeftSideFile.title`)}
                onClick={this.handleUploadNozzlesLeftSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadLeftSideFile.label`)}
              </Button>
              <FileName title={fileLeftSide ?? noFile}>{fileLeftSide ?? noFile}</FileName>
              <Button
                title={t(`${T}.uploadCatalogFile.title`)}
                onClick={this.handleUploadNozzlesCatalogFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${T}.uploadCatalogFile.label`)}
              </Button>
              <FileName title={fileCatalog ?? noFile}>{fileCatalog ?? noFile}</FileName>
            </div>
          )
        }
        <Button
          title={
            isLocked
              ? t(`${T}.generate.titleLocked`)
              : (
                !currentCasterId
                  ? t(`${T}.generate.titleMissingCaster`)
                  : t(`${T}.generate.title`)
              )
          }
          onClick={this.handleGenerate}
          disabled={disabled}
          loading={generateStartedBy}
          noSuccessIcon
          type='tertiary'
          bold
        >
          {t(`${T}.generate.label`)}
        </Button>
      </div>
    )
  }
}

export default withNamespaces('application')(connector(Nozzles as any) as any) as any
