import 'normalize.css'
import 'typeface-roboto'
import 'react-grid-layout/css/styles.css'
import 'pixeden-stroke-7-icon'

import '@/rc-tooltip.css'
import '@/styles.css'

import { SnackbarProvider } from 'notistack'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import styled from 'styled-components'

import { ensureValidAccessToken } from '@/api/auth'
import { NetworkEvent } from '@/api/network-event'
import App from '@/App'
import { AuthDataStore } from '@/logic/auth-data-store'
import Index from '@/store'
import '@/translation/i18n'
import { SnackbarUtilsConfigurator } from '@/Util/SnackbarUtils'
import '@/TitleBar'

// import registerServiceWorker from './registerServiceWorker' //TODO: fix service worker

const win = window as any

win.ELECTRON_DISABLE_SECURITY_WARNINGS = true
win.meta = win.meta ?? {}
win.identifiableRegistry = []
win.isElectron = Boolean(win?.process?.type)

if (import.meta.hot) {
  // https://vitejs.dev/guide/api-hmr#hot-on-event-cb
  import.meta.hot.on('vite:beforeUpdate', () => {
    win.identifiableRegistry = [] 
  })
}

if (win.isElectron) {
  AuthDataStore.clear()
}

NetworkEvent.init()

document.addEventListener('dragover', function (event) {
  event.preventDefault()

  return false
}, false)

document.addEventListener('drop', function (event) {
  event.preventDefault()

  // console.log(event.dataTransfer.files[0])

  return false
}, false)

document.addEventListener('keydown', event => {
  if (event.ctrlKey === true && /^[+-=0]$/.test(event.key)) {
    event.preventDefault()
  }
}, true)

document.getElementById('root')!.addEventListener('wheel', event => {
  if (event.ctrlKey === true) {
    event.preventDefault()
  }
}, true)

document.getElementById('titlebar')!.addEventListener('wheel', event => {
  if (event.ctrlKey === true) {
    event.preventDefault()
  }
}, true)

const notistackRef = React.createRef()

const onClickDismiss = (key: number | string | undefined) => () => {
  (notistackRef.current as any)?.handleDismissSnack(key)
}

const Button = styled.span`
  cursor: pointer;
  margin-right: 10px;
  margin-top: 3px;

  :hover {
    text-decoration: underline;
  }

  i {
    font-size: 20px;
  }
`

setInterval(ensureValidAccessToken, 10000)

const container = document.getElementById('root')

if (!container) {
  throw new Error('No root element found')
}

const root = ReactDOMClient.createRoot(container)

const content = (
  <Provider store={Index.getStore()}>
    <SnackbarProvider
      ref={notistackRef}
      action={
        key => (
          <Button onClick={onClickDismiss(key)}>
            <i className='pe-7s-close-circle' />
          </Button>
        )
      }
      style={{ marginTop: '15px', whiteSpace: 'pre-line' }}
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'center',
        }
      }
    >
      <>
        <SnackbarUtilsConfigurator />
        <App />
      </>
    </SnackbarProvider>
  </Provider>
)

root.render(content)

// registerServiceWorker()
