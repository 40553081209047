import { useConfig } from '@/config'
import ApiClient, { HttpMethods, Options } from '@/store/apiClient'
import SnackbarUtils from '@/Util/SnackbarUtils'

export async function request<T> (
  method: HttpMethods,
  path: string,
  errorText: string,
  options?: Options,
  suppressError?: boolean,
): Promise<T | null> {
  try {
    const data = await ApiClient.request(method, `${useConfig().apiBaseURL}/${path}`, options)

    if (!data) {
      return null
    }

    return data as T
  }
  catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error)

    if (!suppressError) {
      // FIXME: translate message and error.message (which should be a translation key)
      SnackbarUtils.error(`${errorText}: ${error?.message ?? error?.body?.message ?? error}`)
    }

    return null
  }
}
