import Util from '@/logic/Util'
import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'

export class AllChildrenSelected {
  private static areAllChildrenSelected (
    elementType: CasterElementNames,
    elementMountLogId: string,
    parentPath: string,
    selectedPaths: Set<string>,
    allChildrenSelectedPaths: Set<string>,
    elementsHashes: ElementsHashes,
  ): boolean {
    const numericId = MainView.numericIdMountLogMaps[`${elementType}MountLog`][elementMountLogId]
    const path = `${(parentPath ? `${parentPath}/` : '') + elementType}:${numericId}`
    const childTypes = Util.getChildTypeForRealData(elementType)

    let allChildrenSelected = childTypes.length ? true : selectedPaths.has(path)

    for (const childType of childTypes) {
      const childMountLogIdsType = `${childType[0].toLocaleLowerCase() + childType.substring(1)}MountLogs`
      const childMountLogIds =
        elementsHashes[`${elementType}MountLog` as keyof ElementsHashes][elementMountLogId][childMountLogIdsType] ?? []

      let allChildrenSelectedForChildType = true

      for (const childMountLogId of childMountLogIds) {
        if (
          !AllChildrenSelected.areAllChildrenSelected(
            childType,
            childMountLogId,
            path,
            selectedPaths,
            allChildrenSelectedPaths,
            elementsHashes,
          )
        ) {
          allChildrenSelected = false
          allChildrenSelectedForChildType = false
        }
      }

      if (allChildrenSelectedForChildType) {
        allChildrenSelectedPaths.add(`${path}/${childType}-Selector`)
      }
    }

    if (allChildrenSelected) {
      allChildrenSelectedPaths.add(path)
    }

    return allChildrenSelected
  }

  public static generate (selectedPaths: Set<string>, elementsHashes: ElementsHashes) {
    const allChildrenSelectedPaths = new Set<string>()
    const { SegmentGroupMountLog } = elementsHashes

    for (const segmentGroupMountLogId in SegmentGroupMountLog) {
      AllChildrenSelected.areAllChildrenSelected(
        'SegmentGroup',
        segmentGroupMountLogId,
        '',
        selectedPaths,
        allChildrenSelectedPaths,
        elementsHashes,
      )
    }

    return allChildrenSelectedPaths
  }
}
