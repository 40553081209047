export enum SegmentActionsEnum {
  ACTION_ADD_SEGMENT = 'Segment/ACTION_ADD_SEGMENT',
  DELETE = 'Segment/DELETE',
  DELETE_MULTIPLE = 'Segment/DELETE_MULTIPLE',
  UPDATE = 'Segment/UPDATE',
  RENAME = 'Segment/RENAME',
  RESET = 'Segment/RESET',
}

export function addSegment (segmentHash: SegmentHash) {
  return {
    type: SegmentActionsEnum.ACTION_ADD_SEGMENT,
    segmentHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentReducers: Record<string, Function | undefined> = {
  [SegmentActionsEnum.ACTION_ADD_SEGMENT]: (_state: SegmentHash, action: { segmentHash: SegmentHash }) => (
    action.segmentHash
  ),

  [SegmentActionsEnum.DELETE]: (state: SegmentHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentActionsEnum.DELETE_MULTIPLE]: (state: SegmentHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentActionsEnum.UPDATE]: (state: SegmentHash, action: { elements: SegmentHash }) => ({
    ...state,
    ...action.elements,
  }),
  [SegmentActionsEnum.RENAME]: (state: SegmentHash, action: { name: string, id: number }) => ({
    ...state,
    [action.id]: {
      ...state[action.id],
      _name: action.name,
    },
  }),
}

export default function (
  state: SegmentHash = {},
  action: any,
) {
  const handler = SegmentReducers[action.type]

  return handler ? handler(state, action) : state
}
