import cloneDeep from 'lodash/cloneDeep'
import Plotly from 'plotly.js-dist-min'

import { getElementFromPath } from '@/store/elements/logic/index'
import FilterHandler from '@/three/logic/FilterHandler'
import MainView from '@/three/views/MainView'
import type { ElementsHashes } from '@/types/state'
import type { PlotConfig, TileConfig } from '@/types/visualization'

export class ViewLogic {
  private static readonly avoidedElementKeys = [
    'createdAt',
    'updatedAt',
    'blueprint',
    'caster',
    'user',
    'physicalChildren',
    'mountedAt',
    'removedAt',
    'project',
    'projectId',
  ]

  private static readonly avoidedElementKeysPerType: Record<string, string[]> = {
    SensorPoint: [ 'sensorPoint' ],
    DataPoint: [ 'dataPoint' ],
    DataLine: [ 'dataLine' ],
    Segment: [ 'segment' ],
    SegmentGroup: [ 'segmentGroup' ],
    Nozzle: [ 'nozzle' ],
    Roller: [ 'roller' ],
    RollerBody: [ 'rollerBody' ],
    RollerBearing: [ 'rollerBearing' ],
  }

  /** Function to clean the element from the keys that are not needed in the dynamicDataSource selector,
   * such as additionalData and mountLogs */
  public static cleanElementKeys (element: any, type: string) {
    delete element.additionalData
    ViewLogic.avoidedElementKeysPerType[type]?.forEach(key => delete element[key])
    ViewLogic.avoidedElementKeys.forEach(key => delete element[key])
    Object.keys(element).forEach(key => {
      if (key.toLowerCase().includes('mountlog')) {
        delete element[key]
      }
    })
  }

  public static getFilteredElements (elementsHashes: ElementsHashes, filter: string) {
    const filteredElements = FilterHandler
      .getFilteredElements(elementsHashes, filter, false) as Record<string, FilterableElementType>

    return Object
      .entries(filteredElements)
      .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
      .map(([ path ]) => path)
  }

  public static getDynamicElementsFromConfig (
    elementsHashes: ElementsHashes,
    config: PlotConfig,
    filteredElementCache: any,
    elementType: string,
  ) {
    const elementsType = !config.filter
      ? (config.elements?.length ? 'ConfigElements' : 'AllElements')
      : 'FilteredElements'

    let elements: string[]

    switch (elementsType) {
      case 'AllElements':
        elements = filteredElementCache[elementsType] ?? FilterHandler.getAllElementPaths(elementsHashes, elementType)

        filteredElementCache[elementsType] = elements
        break
      case 'FilteredElements':
        elements = filteredElementCache[config.filter] ?? ViewLogic.getFilteredElements(elementsHashes, config.filter)

        filteredElementCache[config.filter] = elements
        break
      case 'ConfigElements':
        elements = config.elements
        break
    }

    return elements
  }

  public static getElementKeyValue (element: any, key: string) {
    const el = element ?? {}

    return el[key] ?? el.additionalData?.[key]
  }

  public static getDynamicDataFromDataLines (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
    fallback = true,
    hasRef = false,
  ) {
    const rawData = elements.length > 0
      ? elements
        .filter(path => path.includes('DataLine'))
        .map((path: string) => getElementFromPath(path, elementsHashes))
      : (fallback ? Object.values((elementsHashes as any)[type] ?? {}) : [])

    const dynamicData = []

    for (const el of rawData) {
      const xValues = ViewLogic.getElementKeyValue(el, attrX)
      const yValues = ViewLogic.getElementKeyValue(el, attrY)

      const parsedXValues = attrX === 'xCoords' && typeof xValues !== 'string'
        ? xValues
        : typeof xValues === 'string'
        ? xValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : []

      const parsedYValues = attrY === 'xCoords'
        ? yValues
        : typeof yValues === 'string'
        ? yValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : []

      if (
        Array.isArray(parsedXValues) &&
        parsedXValues.length > 1 &&
        Array.isArray(parsedYValues) &&
        parsedYValues.length > 1
      ) {
        const line: { x: number, y: number }[] = []
        const amountOfPoints = Math.min(parsedXValues.length, parsedYValues.length)

        for (let i = 0; i < amountOfPoints; i++) {
          const x = parsedXValues[i]
          const y = parsedYValues[i]

          if (x !== undefined && y !== undefined) {
            line.push({ x, y })
          }
        }

        if (line.length) {
          if (hasRef) {
            return line
          }

          dynamicData.push(line)
        }
      }
    }

    return dynamicData
  }

  public static checkDynamicDataFromElements (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
  ) {
    const rawData = elements.length > 0
      ? elements.map((path: string) => getElementFromPath(path, elementsHashes))
      : Object.values((elementsHashes as any)[type])

    const hasX = Boolean(rawData.find((el: any) => (ViewLogic.getElementKeyValue(el, attrX) !== undefined)))
    const hasY = Boolean(rawData.find((el: any) => (ViewLogic.getElementKeyValue(el, attrY) !== undefined)))

    return { hasX, hasY }
  }

  public static getDynamicDataFromElements (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: CasterElementNames,
    attrX: string,
    attrY: string,
    fallback = true,
  ) {
    const rawData = elements.length > 0
      ? elements.map((path: string) => getElementFromPath(path, elementsHashes))
      : (fallback ? Object.values(elementsHashes[type]) : []) as BaseMountLog[]

    const dynamicData = []

    for (const el of rawData) {
      if (!el) {
        continue
      }

      let x = Number(ViewLogic.getElementKeyValue(el, attrX))
      let y = Number(ViewLogic.getElementKeyValue(el, attrY))

      if (attrX === 'id') {
        const mountLogId = el.id
        const typeMountLog = `${type}MountLog`
        const numericId = MainView.numericIdMountLogMaps[typeMountLog]?.[mountLogId]

        x = numericId
      }
      else if (attrY === 'id') {
        const mountLogId = el.id
        const typeMountLog = `${type}MountLog`
        const numericId = MainView.numericIdMountLogMaps[typeMountLog]?.[mountLogId]

        y = numericId
      }

      if (!isNaN(x) && !isNaN(y)) {
        dynamicData.push({ x, y })
      }
    }

    dynamicData.sort((a: any, b: any) => a.x - b.x)

    return dynamicData
  }

  public static getElementsFromPaths (elementsHashes: ElementsHashes, pathsObject: string[]) {
    const paths = Object.keys(pathsObject)
    const elements = []

    for (const path of paths) {
      const element = getElementFromPath(path, elementsHashes)

      if (element) {
        elements.push(element)
      }
    }

    return elements
  }

  public static getShapeDynamicData (elements: string[], elementsHashes: ElementsHashes, attrY: string) {
    const rawData = elements.map((path: string) => getElementFromPath(path, elementsHashes))

    const dynamicData = []

    for (const el of rawData) {
      if (!el) {
        continue
      }

      const y = 0
      const x = Number(el[attrY as keyof typeof el])

      if (!isNaN(x) && !isNaN(y)) {
        dynamicData.push({ x, y })
      }
    }

    dynamicData.sort((a, b) => a.x - b.x)

    return dynamicData
  }

  public static isDynamicDataSourceWithCurrentPassLnCoordInFilter (plotConfig: PlotConfig) {
    const { group, selectedSet, filter } = plotConfig ?? {}

    return group === 'dynamicDataSource' && selectedSet === 'currentFilter' && filter?.includes('CurrentPassLnCoord')
  }

  public static isDynamicDataSourceWithFilterControlVariableInFilter (
    plotConfig: PlotConfig,
    filterControlVariables: string[],
  ) {
    const { group, selectedSet, filter } = plotConfig ?? {}

    return (
      group === 'dynamicDataSource' &&
      selectedSet === 'currentFilter' &&
      Boolean(filter) &&
      filterControlVariables.some(variable => filter.includes(variable))
    )
  }

  public static isMergedDynamicDataSourceWithFilterControlVariableInFilter (
    plotConfig: PlotConfig,
    filterControlVariables: string[],
  ) {
    // when deleting plot it could lead to an error, this is a fallback
    if (!plotConfig) {
      return false
    }

    return plotConfig.isMergedDynamicDataSource &&
      plotConfig.configs.some((config: PlotConfig) =>
        ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(config, filterControlVariables)
      )
  }

  public static isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter (plotConfig: PlotConfig) {
    // when deleting plot it could lead to an error, this is a fallback
    if (!plotConfig) {
      return false
    }

    return plotConfig.isMergedDynamicDataSource &&
      plotConfig.configs.some((config: PlotConfig) =>
        ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(config)
      )
  }

  public static getDataLineRanges (dynamicData: [{ x: number, y: number }][]) {
    let tempMaxX = 0
    let tempMinX = Infinity
    let tempMaxY = 0
    let tempMinY = Infinity
    const amountOfLines = dynamicData?.length ?? 0

    for (let i = 0; i < amountOfLines; i++) {
      const amountOfPoints = dynamicData[i].length
      const line = dynamicData[i]

      for (let j = 0; j < amountOfPoints; j++) {
        const point: { x: number, y: number } = line[j]

        if (point.x > tempMaxX) {
          tempMaxX = point.x
        }

        if (point.x < tempMinX) {
          tempMinX = point.x
        }

        if (point.y > tempMaxY) {
          tempMaxY = point.y
        }

        if (point.y < tempMinY) {
          tempMinY = point.y
        }
      }
    }

    return { x: [ tempMinX, tempMaxX ], y: [ tempMinY, tempMaxY ] }
  }

  // xValues should be sorted
  public static getNearestValueSmallerThanX (
    x: number,
    xValues: number[],
    xIndex?: number,
  ): { value?: number, index?: number } {
    if (xIndex === undefined) {
      xIndex = xValues.length - 1
    }

    if (xIndex < 0) {
      return { value: undefined, index: undefined }
    }

    for (let i = xIndex; i >= 0; i--) {
      if (xValues[i] < x) {
        return { value: xValues[i], index: i }
      }
    }

    return { value: undefined, index: undefined }
  }

  public static getNearestValueBiggerThanX (
    x: number,
    xValues: number[],
    xIndex?: number,
  ): { value?: number, index?: number } {
    if (xIndex === undefined) {
      xIndex = 0
    }

    if (xIndex >= xValues.length) {
      return { value: undefined, index: undefined }
    }

    for (let i = xIndex; i < xValues.length; i++) {
      if (xValues[i] > x) {
        return { value: xValues[i], index: i }
      }
    }

    return { value: undefined, index: undefined }
  }

  public static clonePlotAndChangeFontColorToBlack (
    plot: any,
    tempDiv: any,
    imageHeight?: number,
    imageWidth?: number,
  ) {
    const plotData = cloneDeep(plot.data)
    const plotLayout = cloneDeep(plot.layout)

    if (imageHeight && imageWidth) {
      tempDiv.style.height = `${imageHeight}px`
      tempDiv.style.width = `${imageWidth}px`
    }

    // Modify the layout to set the background color to white
    // eslint-disable-next-line camelcase
    plotLayout.plot_bgcolor = 'white'
    // eslint-disable-next-line camelcase
    plotLayout.paper_bgcolor = 'white'

    // change the font color to black in every axis, and every legend
    for (const axis of [ 'xaxis', 'yaxis' ]) {
      if (plotLayout[axis]) {
        // eslint-disable-next-line camelcase
        plotLayout[axis].tickfont = { color: 'black' }
        // eslint-disable-next-line camelcase
        plotLayout[axis].titlefont = { color: 'black' }

        if (plotLayout[axis].title) {
          // eslint-disable-next-line camelcase
          plotLayout[axis].title.font = { color: 'black' }
        }
      }
    }

    // Create a new plotly chart with the copied data and modified layout
    return Plotly.newPlot(tempDiv, plotData, plotLayout)
  }

  public static getMultiLineMin = (dynamicData: any[][], coord: 'x' | 'y') => {
    const min = dynamicData.reduce((acc, curr) => {
      const currMin = Math.min(...curr?.map(d => d[coord] ?? Infinity) ?? [ Infinity ])

      return Math.min(acc, currMin)
    }, Infinity)

    return min === Infinity ? 0 : min
  }

  public static getMultiLineMax = (dynamicData: any[][], coord: 'x' | 'y') => {
    const max = dynamicData.reduce((acc, curr) => {
      const currMax = Math.max(...curr?.map(d => d[coord] ?? -Infinity) ?? [ -Infinity ])

      return Math.max(acc, currMax)
    }, -Infinity)

    return max === -Infinity ? 0 : max
  }

  public static getRealXDomainForMergedPlot = (
    xDomain: Domain,
    dynamicDataList: { x: number, y: number }[][][],
    tileConfig: TileConfig,
  ): [number, number] => {
    const { followPasslnCoord, numberOfPointsBeforeAndAfterPasslnCoord = 1 } = tileConfig

    if (!followPasslnCoord) {
      return xDomain
    }

    const currentPasslnCoord = (window as any).currentPasslnPosition ?? 0
    const nearestSmallerValues: number[] = []
    const nearestBiggerValues: number[] = []

    // treat merged plot as a single plot

    dynamicDataList.forEach(dynamicData => {
      dynamicData.forEach(line => {
        if (line.length === 0) {
          return
        }

        let lastIndex: number | undefined
        const xValues = line.map(point => point.x)
        const nearestSmallerValuesPerLine: number[] = []
        const nearestBiggerValuesPerLine: number[] = []

        for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
          if (i === 0) {
            const { value, index } = ViewLogic.getNearestValueSmallerThanX(currentPasslnCoord, xValues)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestSmallerValues.includes(value)) {
              nearestSmallerValuesPerLine.push(value)
              nearestSmallerValues.push(value)
            }
          }
          else {
            const { value, index } = ViewLogic
              .getNearestValueSmallerThanX(nearestSmallerValuesPerLine[i - 1], xValues, lastIndex)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestSmallerValues.includes(value)) {
              nearestSmallerValues.push(value)
              nearestSmallerValuesPerLine.push(value)
            }
          }
        }

        lastIndex = undefined

        for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
          if (i === 0) {
            const { value, index } = ViewLogic
              .getNearestValueBiggerThanX(currentPasslnCoord, xValues)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestBiggerValues.includes(value)) {
              nearestBiggerValues.push(value)
              nearestBiggerValuesPerLine.push(value)
            }
          }
          else {
            const { value, index } = ViewLogic
              .getNearestValueBiggerThanX(nearestBiggerValuesPerLine[i - 1], xValues, lastIndex)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestBiggerValues.includes(value)) {
              nearestBiggerValues.push(value)
              nearestBiggerValuesPerLine.push(value)
            }
          }
        }
      })
    })

    if (nearestSmallerValues.length === 0 && nearestBiggerValues.length === 0) {
      return xDomain
    }

    // sort and only account for the last numberOfPointsBeforeAndAfterPasslnCoord values
    nearestSmallerValues.sort((a, b) => b - a).splice(numberOfPointsBeforeAndAfterPasslnCoord)

    // sort and only account for the first numberOfPointsBeforeAndAfterPasslnCoord values
    nearestBiggerValues.sort((a, b) => a - b).splice(numberOfPointsBeforeAndAfterPasslnCoord)

    const min = Math.min(...nearestSmallerValues, currentPasslnCoord) ?? xDomain[0]
    const max = Math.max(currentPasslnCoord, ...nearestBiggerValues) ?? xDomain[1]

    return [ min, max ]
  }

  public static getRealYDomainForMergedPlot = (
    realXDomain: Domain,
    dynamicDataList: { x: number, y: number }[][][],
    tileConfig: TileConfig,
    yDomain: Domain,
  ): Domain => {
    const linesYDomains: number[][] = []

    dynamicDataList.forEach(dynamicData => {
      dynamicData.forEach(line => {
        if (line.length === 0) {
          return
        }

        const lineYDomain = this.getYDomain(realXDomain, yDomain, tileConfig, line)

        if (lineYDomain.length) {
          linesYDomains.push(lineYDomain)
        }
      })
    })

    const min = linesYDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity) ?? yDomain[0]
    const max = linesYDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity) ?? yDomain[1]

    return [ min, max ]
  }

  public static getXDomain (xDomain: number[], xValues: number[], tileConfig: TileConfig): number[] {
    if (!tileConfig.followPasslnCoord) {
      return xDomain
    }

    const { numberOfPointsBeforeAndAfterPasslnCoord = 1 } = tileConfig

    const currentPasslnCoord = (window as any).currentPasslnPosition ?? 0

    const nearestSmallerValues: number[] = []
    const nearestBiggerValues: number[] = []

    let lastIndex: number | undefined

    for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
      if (i === 0) {
        const { value, index } = ViewLogic.getNearestValueSmallerThanX(currentPasslnCoord, xValues)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestSmallerValues.push(value)
      }
      else {
        const { value, index } = ViewLogic.getNearestValueSmallerThanX(nearestSmallerValues[i - 1], xValues, lastIndex)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestSmallerValues.push(value)
      }
    }

    lastIndex = undefined

    for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
      if (i === 0) {
        const { value, index } = ViewLogic.getNearestValueBiggerThanX(currentPasslnCoord, xValues)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestBiggerValues.push(value)
      }
      else {
        const { value, index } = ViewLogic.getNearestValueBiggerThanX(nearestBiggerValues[i - 1], xValues, lastIndex)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestBiggerValues.push(value)
      }
    }

    if (nearestSmallerValues.length === 0 && nearestBiggerValues.length === 0) {
      return xDomain
    }

    const min = Math.min(...nearestSmallerValues, currentPasslnCoord)
    const max = Math.max(...nearestBiggerValues, currentPasslnCoord)

    return [ min, max ]
  }

  // find all points that have x in the range of xDomain, add them to an array, then find the min and max of the array
  public static getYDomain (
    xDomain: number[],
    yDomain: number[],
    tileConfig: TileConfig,
    dynamicData: any[],
  ): number[] {
    if (!dynamicData?.length) {
      return yDomain
    }

    const pointsComprehendedInXDomain = this.getPointsComprehendedInXDomain(xDomain, dynamicData)

    if (!pointsComprehendedInXDomain.length) {
      return []
    }

    const min = Math.min(...pointsComprehendedInXDomain.map(point => point.y))
    const max = Math.max(...pointsComprehendedInXDomain.map(point => point.y))

    return [ min, max ]
  }

  public static getPointsComprehendedInXDomain = (xDomain: number[], dynamicData: Coord[]): Coord[] => {
    const points: Coord[] = []

    for (const point of dynamicData) {
      if (point.x <= xDomain[1] && point.x >= xDomain[0]) {
        points.push(point)
      }

      if (point.x > xDomain[1]) {
        break
      }
    }

    return points
  }

  public static findPointIndexWithXValue = (
    pointsArray: Coord[],
    x: number,
    start: number,
    end: number,
  ): number => {
    if (end < start) {
      return -1
    }

    const mid = Math.floor((start + end) / 2)

    if (pointsArray[mid].x === x) {
      return mid
    }

    if (pointsArray[mid].x < x) {
      return this.findPointIndexWithXValue(pointsArray, x, mid + 1, end)
    }
    else {
      return this.findPointIndexWithXValue(pointsArray, x, start, mid - 1)
    }
  }
}
