export enum StrandGuideActionsEnum {
  ACTION_ADD_STRAND_GUIDE = 'StrandGuide/ACTION_ADD_STRAND_GUIDE',
  DELETE = 'StrandGuide/DELETE',
  UPDATE = 'StrandGuide/UPDATE',
  RESET = 'StrandGuide/RESET',
}

export function addStrandGuide (strandGuideHash: StrandGuideHash) {
  return {
    type: StrandGuideActionsEnum.ACTION_ADD_STRAND_GUIDE,
    strandGuideHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StrandGuideReducers: Record<string, Function | undefined> = {
  [StrandGuideActionsEnum.ACTION_ADD_STRAND_GUIDE]: (
    _state: StrandGuideHash,
    action: { strandGuideHash: StrandGuideHash },
  ) => (
    action.strandGuideHash
  ),

  [StrandGuideActionsEnum.RESET]: () => ({}),
}

export default function (
  state: StrandGuideHash = {},
  action: any,
) {
  const handler = StrandGuideReducers[action.type]

  return handler ? handler(state, action) : state
}
