import { NetworkEvent } from '@/api/network-event'

export class AuthDataStore {
  private static readonly storageKey = 'tokenData'

  public static set (tokenData: AuthResult) {
    localStorage.setItem(AuthDataStore.storageKey, JSON.stringify(tokenData))

    NetworkEvent.register()
  }

  public static get (): AuthResult | null {
    const tokenDataRaw = localStorage.getItem(AuthDataStore.storageKey)

    if (!tokenDataRaw) {
      return null
    }

    try {
      return JSON.parse(tokenDataRaw) as AuthResult
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      return null
    }
  }

  public static clear () {
    NetworkEvent.unregister()

    localStorage.removeItem(AuthDataStore.storageKey)
  }
}
