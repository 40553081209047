/* eslint-env browser */

import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { deleteNozzleCatalog, getNozzleCatalogContents, getNozzleCatalogs } from '@/api/nozzle-catalog'
import BaseDialog from '@/react/dialogs/BaseDialog'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import { Button, Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import DataActions from '@/store/data/actions'
import { DefaultState } from '@/types/state'
import { ArrayOfTranslations, Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from './Logic'

const T = 'projectDataDialog.caster'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  catalogList: state.data.catalogList,
}), {
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setLastLoadedCasterCatalogId: ApplicationActions.setLastLoadedCasterCatalogId,
  setError: ErrorActions.setError,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  saveCatalog: DataActions.saveCatalog,
  setCatalogList: DataActions.setCatalogList,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t: ArrayOfTranslations & Translation
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  closeSnackbar(key?: OptionsObject['key']): void
}

export class SelectCatalogDialog extends Component<Props> {
  @Identifiable('SelectCatalogDialog') public static readonly NAME: string

  public override async componentDidMount () {
    await this.handleGetCatalogs()
  }

  private readonly handleGetCatalogs = async () => {
    const { currentSimulationCase: { id }, enqueueSnackbar, setCatalogList } = this.props

    try {
      const catalogs = await getNozzleCatalogs(id)

      if (!catalogs?.length) {
        return
      }

      setCatalogList(catalogs)
    }
    catch (error: any) {
      enqueueSnackbar('Error fetching catalogs', { variant: 'error' })

      this.handleClose()
    }
  }

  private readonly handleAddCatalog = (catalog: CaseFile | 'default') => {
    const { setCatalogList, setCurrentCatalogId } = this.props

    const newCatalogList = [ ...this.props.catalogList ]

    if (catalog !== 'default') {
      newCatalogList.unshift(catalog)
    }

    setCurrentCatalogId(catalog === 'default' ? 'default' : catalog.id)
    setCatalogList(newCatalogList)
  }

  private readonly handleChangeCatalog = (event: any) => {
    const { setCurrentCatalogId, setError } = this.props
    const { name, value } = event.target

    setError(name)

    if (value === 'add') {
      return Logic.handleUploadCasterCatalog(this.handleAddCatalog, setError, this.props)
    }

    setCurrentCatalogId(value)
  }

  private readonly handleDeleteCatalog = async (_type: string, key: string) => {
    const { featureFlags, currentSimulationCase: { id, projectId }, catalogList, setCatalogList } = this.props

    if (!FeatureFlags.canDeleteNozzleCatalog(featureFlags)) {
      return
    }

    await deleteNozzleCatalog(projectId, id, key)

    setCatalogList(catalogList.filter(val => val.id !== key))
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectCatalogDialog.NAME)
  }

  private readonly handleLoadCatalog = async () => {
    const {
      currentCatalogId,
      closeDialog,
      saveCatalog,
      setLastLoadedCasterCatalogId,
      catalogList,
      currentSimulationCase,
    } = this.props
    let catalogId = currentCatalogId ?? ''

    if (!catalogId || catalogId === 'default') {
      catalogId = catalogList[0]?.id
    }

    if (!catalogId || catalogId === 'add') {
      return
    }

    const projectId = currentSimulationCase.projectId
    const caseId = currentSimulationCase.id

    const csvString = await getNozzleCatalogContents(projectId, caseId, catalogId)

    if (!csvString) {
      return
    }

    const catalogData = await Logic.getParsedCsv(csvString)

    saveCatalog(catalogData)
    setLastLoadedCasterCatalogId(catalogId)

    closeDialog(SelectCatalogDialog.NAME)
  }

  public override render () {
    const {
      t,
      currentSimulationCase,
      featureFlags,
      currentCatalogId,
      error,
      catalogList,
    } = this.props

    const {
      simulationStartedAt,
    } = currentSimulationCase

    const catalogSelectors = Logic.getSelectors<CaseFile>(
      catalogList,
      'id',
      'fileName',
      { value: t(`${T}.catalog.default`), disabled: true },
    )
    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, catalogList)

    return (
      <BaseDialog
        title={t(`${T}.catalog.load`)}
        icon='pe-7s-folder'
        header={t(`${T}.catalog.load`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='catalog'
            type='select'
            label={t(`${T}.catalog.label`)}
            title={
              error.catalog
                ? t([ `error.${error.catalog}`, 'error.default' ])
                : t(`${T}.catalog.title`)
            }
            error={error.catalog && t([ `error.${error.catalog}`, 'error.default' ])}
            value={selectedCatalog}
            selectors={
              FeatureFlags.canUploadNozzleCatalog(featureFlags)
                ? [
                  ...catalogSelectors,
                  {
                    key: 'add',
                    notRemovable: true,
                    value: t(`${T}.catalog.add`),
                  },
                ]
                : [ ...catalogSelectors ]
            }
            onChange={this.handleChangeCatalog}
            onDelete={this.handleDeleteCatalog}
            disabled={Boolean(simulationStartedAt)}
          />
          <Button
            value=''
            onClick={this.handleLoadCatalog}
            title={t(`${T}.catalog.load`)}
          >
            {t(`${T}.catalog.load`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

// eslint-disable-next-line max-len
export default withNamespaces('application')(
  withSnackbar((connector(SelectCatalogDialog as any) as any) as any) as any,
) as any
