import Util from '@/logic/Util'
import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'

export class AllPaths {
  private static areAllChildrenSelected (
    elementType: CasterElementNames,
    elementMountLogId: string,
    parentPath: string,
    allPaths: Set<string>,
    elementsHashes: ElementsHashes,
  ) {
    const numericId = MainView.numericIdMountLogMaps[`${elementType}MountLog`][elementMountLogId]
    const path = `${(parentPath ? `${parentPath}/` : '') + elementType}:${numericId}`
    const childTypes = Util.getChildTypeForRealData(elementType)

    for (const childType of childTypes) {
      const childMountLogIdsType = `${childType[0].toLocaleLowerCase() + childType.substring(1)}MountLogs`
      const childMountLogIds =
        elementsHashes[`${elementType}MountLog` as keyof ElementsHashes][elementMountLogId][childMountLogIdsType] ?? []

      for (const childMountLogId of childMountLogIds) {
        AllPaths.areAllChildrenSelected(
          childType,
          childMountLogId,
          path,
          allPaths,
          elementsHashes,
        )
      }
    }

    allPaths.add(path)
  }

  public static generate (elementsHashes: ElementsHashes) {
    const allPaths = new Set<string>()
    const { SegmentGroupMountLog } = elementsHashes

    for (const segmentGroupMountLogId in SegmentGroupMountLog) {
      AllPaths.areAllChildrenSelected(
        'SegmentGroup',
        segmentGroupMountLogId,
        '',
        allPaths,
        elementsHashes,
      )
    }

    return allPaths
  }
}
