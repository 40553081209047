import { NetworkStatus } from '@/api/network-event'
import { VisualizationMetaInformation, VisualizationState } from '@/types/visualization'

export enum VisualizationActionsEnum {
  ACTION_SET_SECONDARY_SIZE = 'dashboard/ACTION_SET_SECONDARY_SIZE',
  ACTION_VISUALIZATION_SET_META = 'visualization/ACTION_VISUALIZATION_SET_META',
  ACTION_VISUALIZATION_SET_CURRENT_DASHBOARD_WIDTH = 'visualization/ACTION_VISUALIZATION_SET_CURRENT_DASHBOARD_WIDTH',
  ACTION_VISUALIZATION_SET_CURRENT_CASTER_DIALOG_WIDTH =
    'visualization/ACTION_VISUALIZATION_SET_CURRENT_CASTER_DIALOG_WIDTH',
  ACTION_VISUALIZATION_SET_AMOUNT_OF_COMPARISON_CASTER_COLUMNS =
    'visualization/ACTION_VISUALIZATION_SET_AMOUNT_OF_COMPARISON_CASTER_COLUMNS',
  ACTION_VISUALIZATION_SET_DATASOURCE = 'visualization/ACTION_VISUALIZATION_SET_DATASOURCE',
  ACTION_VISUALIZATION_SET_DATA = 'visualization/ACTION_VISUALIZATION_SET_DATA',
  ACTION_VISUALIZATION_SHOW_PLOT_LIST = 'visualization/ACTION_VISUALIZATION_SHOW_PLOT_LIST',
  ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING = 'visualization/ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING',
  ACTION_VISUALIZATION_SET_EDIT_MODE = 'visualization/ACTION_VISUALIZATION_SET_EDIT_MODE',
  ACTION_VISUALIZATION_SHOW_CONFIG_DIALOG = 'visualization/ACTION_VISUALIZATION_SHOW_CONFIG_DIALOG',
  ACTION_VISUALIZATION_SHOW_ADD_PLOT_DIALOG = 'visualization/ACTION_VISUALIZATION_SHOW_ADD_PLOT_DIALOG',
  ACTION_VISUALIZATION_SHOW_DELETE_DIALOG = 'visualization/ACTION_VISUALIZATION_SHOW_DELETE_DIALOG',
  ACTION_VISUALIZATION_SHOW_EDIT_DASHBOARD_DIALOG = 'visualization/ACTION_VISUALIZATION_SHOW_EDIT_DASHBOARD_DIALOG',
  ACTION_VISUALIZATION_SHOW_DERIVE_DIALOG = 'visualization/ACTION_VISUALIZATION_SHOW_DERIVE_DIALOG',
  ACTION_VISUALIZATION_SAVE_PLOT_CONFIG = 'visualization/ACTION_VISUALIZATION_SAVE_PLOT_CONFIG',
  ACTION_VISUALIZATION_REMOVE_PLOT_CONFIG = 'visualization/ACTION_VISUALIZATION_REMOVE_PLOT_CONFIG',
  ACTION_VISUALIZATION_SET_LOADING_BUTTON_STATUS = 'visualization/ACTION_VISUALIZATION_SET_LOADING_BUTTON_STATUS',
  ACTION_VISUALIZATION_OPEN_CONTEXT_MENU = 'visualization/ACTION_VISUALIZATION_OPEN_CONTEXT_MENU',
  ACTION_VISUALIZATION_CLOSE_CONTEXT_MENU = 'visualization/ACTION_VISUALIZATION_CLOSE_CONTEXT_MENU',
  ACTION_VISUALIZATION_DERIVE_PLOT = 'visualization/ACTION_VISUALIZATION_DERIVE_PLOT',
  ACTION_VISUALIZATION_MERGE_PLOTS = 'visualization/ACTION_VISUALIZATION_MERGE_PLOTS',
  ACTION_VISUALIZATION_SET_CURRENT_TILE_ID = 'visualization/ACTION_VISUALIZATION_SET_CURRENT_TILE_ID',
  ACTION_SET_CONFIG = 'visualization/ACTION_SET_CONFIG',
  ACTION_UPDATE_DASHBOARD_REQUEST = 'visualization/ACTION_UPDATE_DASHBOARD_REQUEST',
  ACTION_UPDATE_DASHBOARD_SUCCESS = 'visualization/ACTION_UPDATE_DASHBOARD_SUCCESS',
  ACTION_UPDATE_DASHBOARD_ERROR = 'visualization/ACTION_UPDATE_DASHBOARD_ERROR',
  ACTION_UPDATE_COMPARISON_CASTERS_REQUEST = 'visualization/ACTION_UPDATE_COMPARISON_CASTERS_REQUEST',
  ACTION_UPDATE_COMPARISON_CASTERS_SUCCESS = 'visualization/ACTION_UPDATE_COMPARISON_CASTERS_SUCCESS',
  ACTION_UPDATE_COMPARISON_CASTERS_ERROR = 'visualization/ACTION_UPDATE_COMPARISON_CASTERS_ERROR',
  ACTION_SPLIT_VIEW = 'visualization/ACTION_SPLIT_VIEW',
  ACTION_ADD_PLOT_TILE = 'visualization/ACTION_ADD_PLOT_TILE',
  ACTION_DELETE_PLOT_TILE = 'visualization/ACTION_DELETE_PLOT_TILE',
  ACTION_DELETE_SPLIT_VIEW = 'visualization/ACTION_DELETE_SPLIT_VIEW',
  ACTION_SET_CURRENT_DASHBOARD = 'visualization/ACTION_SET_CURRENT_DASHBOARD',
  ACTION_DELETE_DASHBOARD = 'visualization/ACTION_DELETE_DASHBOARD',
  ACTION_SHOW_DELETE_DASHBOARD_DIALOG = 'visualization/ACTION_SHOW_DELETE_DASHBOARD_DIALOG',
  ACTION_CHANGE_TABS = 'visualization/ACTION_CHANGE_TABS',
  ACTION_SAVE_TILE_CONFIG = 'visualization/ACTION_SAVE_TILE_CONFIG',
  ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG = 'visualization/ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG',
  ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG = 'visualization/ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG',
  ACTION_ADD_HDF5_SCHEMA = 'visualization/ACTION_ADD_HDF5_SCHEMA',
  ACTION_SET_HDF5_SCHEMA = 'visualization/ACTION_SET_HDF5_SCHEMA',
  ACTION_REMOVE_HDF5_SCHEMA = 'visualization/ACTION_REMOVE_HDF5_SCHEMA',
  ACTION_VISUALIZATION_META_INFORMATION = 'visualization/ACTION_VISUALIZATION_META_INFORMATION',
  ACTION_UPDATE_COMMAND_TILE = 'visualization/ACTION_UPDATE_COMMAND_TILE',
  ACTION_RESET_VIS_DATA = 'visualization/ACTION_RESET_VIS_DATA',
  ACTION_RESET_VIEW_OBJECT = 'visualization/ACTION_RESET_VIEW_OBJECT',
  ACTION_RESET_VISUALIZATION = 'visualization/ACTION_RESET_VISUALIZATION',
  // Compare Casters
  ACTION_SET_SELECTED_COMPARISON_CASE_IDS = 'visualization/ACTION_SET_SELECTED_COMPARISON_CASE_IDS',
  ACTION_SET_COMPARISON_TIMESTAMPS = 'visualization/ACTION_SET_COMPARISON_TIMESTAMPS',
  ACTION_SET_COMPARE_CASTER_INFORMATION = 'visualization/ACTION_SET_COMPARE_CASTER_INFORMATION',
  // Compare Casters for Plots
  ACTION_SET_PLOTS_COMPARE_CASTER_INFORMATION = 'visualization/ACTION_SET_PLOTS_COMPARE_CASTER_INFORMATION',
}

export type VisualizationAction = {
  type: string
  mode: boolean
  status: NetworkStatus
  width: number
  amountOfComparisonCasterColumns?: number
  ids: string[]
  data: any
  idsAndTimestamps: string[]
}

export const initialState: VisualizationState = {
  data: {} as any,
  showPlotList: false,
  isEditModeOn: true,
  showConfigDialog: false,
  openConfigDialogWindow: false,
  openAddPlotDialogWindow: false,
  openDashboardWindow: false,
  dataSource: false,
  plotExport: false,
  isPlotListOpened: false,
  isCommandMappingOpen: false,
  addPlotViewId: '',
  editDialogConfigId: '',
  viewId: '',
  deleteDialogPlot: {} as any,
  plotConfigs: {},
  loadingStatus: {},
  contextMenu: {},
  viewsObject: {},
  currentDashboard: {},
  dashboardToDelete: {} as any,
  tileConfigs: {},
  editDialogDashboardData: {} as any,
  hdf5Schemas: [],
  hdf5Schema: 'default',
  visualizationMetaInformation: {} as VisualizationMetaInformation,
  updatableCommandTile: [],
  currentDashboardWidth: 500,
  currentCasterDialogWidth: 335,
  amountOfComparisonCasterColumns: 0,
  selectedComparisonCaseIds: [],
  comparisonTimestamps: [],
  compareCasterInformation: {},
  plotsCompareCasterInformation: {},
}
