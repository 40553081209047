import isEqual from 'lodash/isEqual'
import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import GlobalUtil from '@/logic/Util'
import FeatureFlags from '@/react/FeatureFlags'
import DataActions from '@/store/data/actions'
import { getElementsHashesObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { DEFINITION } from '@/store/type/consts'
import { getChangedPaths, getSupportPointPaths } from '@/store/type/SupportPointDefinition'
import { setSelectedComparisonCaseIds } from '@/store/visualization/actions'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { DisplayStrandSides } from '@/types/elements/enum'
import { DefaultState } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

import { CHILDREN, PARENT } from './consts'
import { SectionContainer, SectionContent } from './styles'
import DropDown from '../form/DropDown'
import FormBuilder from '../form/FormBuilder'
import Section from '../Section'

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  editElements: state.data.editElements,
  editElementsInitial: state.data.editElementsInitial,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  editValues: state.data.editValues,
  filterElement: state.filter.filterElement,
  term: state.filter.term,
  pendingDeleteList: state.data.pendingDeleteList,
  hasEditChanges: state.data.hasEditChanges,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  loopCounter: state.data.loopCounter,
  currentDashboard: state.visualization.currentDashboard,
  viewsObject: state.visualization.viewsObject,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  currentSimulationCase: state.application.main.currentSimulationCase,
  ...getElementsHashesObject(state),
}), {
  resetGapWarnings: DataActions.resetGapWarnings,
  saveElement: DataActions.saveElement,
  createElement: DataActions.createElement,
  validateInput: DataActions.validateInput,
  addDirtyPath: DataActions.addDirtyPath,
  setElements: DataActions.setElements,
  setParentPath: DataActions.setParentPath,
  addDirtyDeletePath: DataActions.addDirtyDeletePath,
  addPendingDeleteList: DataActions.addPendingDeleteList,
  copyElement: DataActions.copyElement,
  setEditChanges: DataActions.setEditChanges,
  setEditValues: DataActions.setEditValues,
  repeatElement: DataActions.repeatElement,
  undoChanges: DataActions.undoChanges,
  setAdditionalData: DataActions.setAdditionalData,
  setFilterValues: FilterActions.setFilterValues,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  paths: string[]
  allPaths?: string[]
  type: CasterDialogElementType
  hideActions?: boolean
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedTarget: string
  copyMode: string
  newNozzle: any
  selectedParentIds: Record<string, number>
  initialParentIds: any
  defaultValues: Record<string, Record<string, any> | undefined>
  multiParentValue: string[]
  offset: number
  gapOffset: number
  copies: number
  direction: string
  [key: string]: any
}

export class AllInOne extends PureComponent<Props, State> {
  private timeoutHandle?: NodeJS.Timeout

  public override state: State = {
    selectedTarget: '',
    copyMode: 'offset',
    newNozzle: {},
    selectedParentIds: {} as Record<string, number>,
    initialParentIds: {},
    defaultValues: {} as Record<string, Record<string, any>>,
    multiParentValue: new Array<string>(),
    offset: 0,
    gapOffset: 1,
    copies: 0,
    direction: 'positive',
  }

  public constructor (props: Props) {
    super(props)

    const {
      paths,
      dirtyDeletePaths,
      setEditChanges,
      type,
      filterElement,
      editValues,
      setFilterValues,
      setEditValues,
    } = props

    let selectedParentIds = {}

    if (!paths.length) {
      this.state = {
        ...this.state,
        selectedParentIds: {},
        initialParentIds: {},
        multiParentValue: [],
      }
    }
    else if (paths.length === 1) {
      const parents = paths[0].split('/')

      for (let i = 0; i < parents.length - 1; i++) {
        const [ type, id ] = parents[i].split(':')

        selectedParentIds = {
          ...selectedParentIds,
          [type]: Number(id),
        }
      }

      this.state = {
        ...this.state,
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue: [],
      }
    }
    else {
      const segmentGroupIds: any = []
      const segmentSideIds: any = []

      paths.forEach(path => {
        const pathArray = GlobalUtil.getPathArrayFromPath(path)

        if (!segmentGroupIds.includes(pathArray[1])) {
          segmentGroupIds.push(pathArray[1])
        }

        if (!segmentSideIds.includes(pathArray[3])) {
          segmentSideIds.push(pathArray[3])
        }
      })

      const multiParentValue = []

      if (segmentGroupIds.length !== 1) {
        multiParentValue.push('SegmentGroup')
        multiParentValue.push('Segment')
      }
      else if (segmentSideIds.length !== 1) {
        multiParentValue.push('Segment')
      }

      selectedParentIds = {
        ...selectedParentIds,
        SegmentGroup: segmentGroupIds[0],
        Segment: segmentSideIds[0],
      }

      this.state = {
        ...this.state,
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue,
      }
    }

    if (paths.some(path => dirtyDeletePaths.includes(path))) {
      setEditChanges(true, type)
    }

    const definition = DEFINITION[type]

    let defaultValues: Record<string, any> = {}

    Object.keys(definition.fields).forEach(key => {
      defaultValues = {
        ...defaultValues,
        [type]: {
          ...defaultValues[type],
          [key]: definition.fields[key].defaultValue,
        },
      }
    })

    this.state = {
      ...this.state,
      defaultValues,
    }

    const element = {
      ...filterElement,
      ...editValues,
      ...defaultValues,
    }

    const elementsHashes = getElementsHashesObject(this.props)

    const { moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(elementsHashes)

    if (type === 'General') {
      Object.keys(definition.fields).forEach(key => {
        if (!moldMountLog) {
          return
        }

        ;(element as any)[type][key] = (moldMountLog as any)[key]
      })
    }

    setFilterValues({ ...element })
    setEditValues({ ...element })
  }

  public override componentDidUpdate (prevProps: Props) {
    const { paths: _oldPaths, allPaths: oldAllPaths } = prevProps
    const {
      paths: newPaths,
      allPaths,
      editElements,
      dirtyDeletePaths,
      editElementsInitial,
      type,
      setEditChanges,
      filterElement,
      hasEditChanges,
    } = this.props
    const { copies, initialParentIds, selectedParentIds } = this.state

    if (allPaths && newPaths[0] !== allPaths[0]) {
      return
    }

    let elementsAreChanged = false

    const paths = allPaths ?? newPaths
    const oldPaths = oldAllPaths ?? _oldPaths

    const areSomePathsDirty = paths.some(path => dirtyDeletePaths.includes(path))
    const didParentsChange = !isEqual(initialParentIds, selectedParentIds)

    const realType = type === 'SegmentGroupSupportPoints' ? 'SupportPoint' : type
    // TODO: reset or going back to the initial state does not work!?

    for (const path in editElements) {
      if (paths.includes(path) && path.includes(realType)) {
        const initialElement = editElementsInitial[path]
        const initial = initialElement ? isEqual(initialElement, editElements[path]) : true

        if (!initial || areSomePathsDirty || copies || didParentsChange) {
          elementsAreChanged = true

          break
        }
      }
    }

    if (paths && oldPaths && typeof paths === 'object' && !isEqual(paths, oldPaths)) {
      this.handleSelectInitialParents(paths)
      this.setState({
        offset: 0,
        copies: 0,
      })

      elementsAreChanged = areSomePathsDirty
    }

    if ((!paths.length) && (filterElement[type] && Object.keys(filterElement[type]).length)) {
      elementsAreChanged = true
    }

    // TODO: refactor expression
    if (((hasEditChanges ?? {}) as Record<string, any>)[type] !== elementsAreChanged) {
      setEditChanges(elementsAreChanged, type)
    }
  }

  private readonly handleDeleteElement = () => {
    const {
      type,
      addDirtyPath,
      addDirtyDeletePath,
      editElements,
      paths,
      dirtyDeletePaths,
      setEditChanges,
      addPendingDeleteList,
      pendingDeleteList,
    } = this.props

    const elementsHashes = getElementsHashesObject(this.props)
    const dirtyPaths: string[] = [ ...paths ]
    const pendingList: string[] = []

    paths.forEach((path: string) => {
      if (!(pendingDeleteList.includes(path))) { // TODO: lists could be hashes to improve performance
        pendingList.push(path)
      }
    })

    if (type === 'RollerBody' || type === 'RollerBearing') {
      paths.forEach((path: string) => {
        const parentPath = ThreeUtil.getParentInfo(path).path
        const parentRoller = GlobalUtil.getElement<Roller, RollerMountLog>(parentPath, elementsHashes)
        const parentIndexOfDirtyDelete = dirtyDeletePaths.includes(parentPath)
        const parentIndexOfDirty = dirtyPaths.includes(parentPath)
        let allChildren = GlobalUtil.getRollerChildren(parentRoller, elementsHashes)

        if (type === 'RollerBody') {
          const rollerBody = GlobalUtil.getElement<RollerBody, RollerBodyMountLog>(path, elementsHashes)
          const sensorPointsInRollerBody = rollerBody?.sensorPointMountLogs?.filter((sensorPointMountLogId: any) =>
            Boolean(MainView.MountLogIdFullPathMap.SensorPoint[sensorPointMountLogId])) ?? []

          const sensorPointPaths = sensorPointsInRollerBody
            .map((sensorPointMountLogId) => MainView.MountLogIdFullPathMap.SensorPoint[sensorPointMountLogId])
            .filter(Boolean) as string[]

          if (sensorPointPaths.length) {
            dirtyPaths.push(...sensorPointPaths)
          }
        }

        allChildren = allChildren.filter((childPath: string) => {
          const indexOfDirtyDelete = dirtyDeletePaths.includes(childPath)
          const indexOfDirty = dirtyPaths.includes(childPath)

          if (indexOfDirtyDelete && indexOfDirty) {
            return true
          }

          return !(indexOfDirtyDelete || indexOfDirty)
        })

        if (!allChildren.length && !parentIndexOfDirtyDelete && !parentIndexOfDirty) {
          dirtyPaths.push(parentPath)
        }

        if (allChildren.length && parentIndexOfDirtyDelete) {
          dirtyPaths.push(parentPath)
        }
      })
    }

    if (type === 'Roller') {
      paths.forEach((path: string) => {
        const indexOfDirtyDelete = dirtyDeletePaths.includes(path)
        let allChildren = GlobalUtil.getRollerChildren(editElements[path], elementsHashes)

        allChildren = allChildren.filter((childPath) => {
          const childIndexOfDirtyDelete = dirtyDeletePaths.includes(childPath)

          if (indexOfDirtyDelete && childIndexOfDirtyDelete) {
            return true
          }

          return !indexOfDirtyDelete && !childIndexOfDirtyDelete
        })

        dirtyPaths.push(...allChildren)

        const roller = GlobalUtil.getElement<Roller, RollerMountLog>(path, elementsHashes)
        const sensorPointsInRoller = roller?.sensorPointMountLogs?.filter((sensorPointMountLogId: any) => 
          Boolean(MainView.MountLogIdFullPathMap.SensorPoint[sensorPointMountLogId])) ?? []

        const sensorPointPaths = sensorPointsInRoller
          .map((sensorPointMountLogId: any) => MainView.MountLogIdFullPathMap.SensorPoint[sensorPointMountLogId])
          .filter(Boolean) as string[]

        if (sensorPointPaths.length) {
          dirtyPaths.push(...sensorPointPaths)
        }
      })
    }

    addDirtyPath(dirtyPaths)
    addDirtyDeletePath(dirtyPaths)
    addPendingDeleteList(pendingList)
    setEditChanges(true, type)
  }

  private readonly handleSave = (commentsByTypeAndKey?: { [type: string]: { [key: string]: string } }) => {
    const {
      paths,
      allPaths,
      saveElement,
      setEditChanges,
      type,
      setEditValues,
      setAdditionalData,
      editValues,
      editElements,
      pendingDeleteList,
      hasEditChanges,
    } = this.props

    const target = this.getParentsArray()

    if (hasEditChanges && hasEditChanges[type as CasterElementNames]) {
      setEditChanges(false, type)
    }

    if (type === 'General') {
      setTimeout(() => {
        setAdditionalData({ Mold: editValues[type] })
      }, 1000)
    }
    else {
      const typeReg = new RegExp(type, 'g')
      const deleteList = pendingDeleteList.filter((deletePath: any) => typeReg.test(deletePath))

      const action = deleteList.length > 0 ? 'delete' : 'update'

      let pathsToSave = allPaths ?? paths

      if (type === 'SegmentGroupSupportPoints' && allPaths?.length) {
        pathsToSave = pathsToSave.filter((path: string) => editElements[path]?.shimPropose !== null)
      }

      saveElement(pathsToSave, target, action, type as CasterElementNames, commentsByTypeAndKey)
      setEditValues({ [type]: {} })
    }
  }

  private readonly handleInput = (event: any) => {
    const {
      setElements,
      paths,
      setFilterValues,
      filterElement,
      setTerm,
      term,
      type,
      setEditChanges,
      setEditValues,
      editValues,
      editElements,
      validateInput,
      featureFlags,
      loopCounter,
      hasEditChanges,
      currentSimulationCase,
      SupportPoint,
      SegmentGroupMountLog,
      SupportPointMountLog,
    } = this.props
    const { name, value } = event.target
    const path = paths[0]
    const massValues = paths.length > 1

    const typePart = (term ?? '').split(' ').filter((part: any) => part.includes(type)) ?? []
    const isFilterActive = type && typePart.length && typePart.join(' ').includes(String(name.substring(1)))

    if (isFilterActive) {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle)
      }

      this.timeoutHandle = setTimeout(() => {
        if (value) {
          setTerm(`${type}#${name.substring(1)}=${value}`, false, true)
        }
        else {
          // TODO: this is a workaround to prevent a bug! But it's also not a too bad way to handle this case
          setTerm(`${type}#${name.substring(1)}=`, false, true)
        }
      }, 250)
    }

    if (!path) {
      if (type === 'General') {
        setEditChanges(true, type)
      }

      let filterElementCopy = {
        ...filterElement[type],
        ...editValues[type],
      }

      filterElementCopy[name] = value

      if (name !== 'name') {
        if (value === 'conic' || parseInt(value, 10) === 0 || (typeof value === 'string' && !value.length)) {
          delete filterElementCopy[name]
        }
      }
      else if (!value.length) {
        delete filterElementCopy[name]
      }

      if (type === 'Nozzle' && FeatureFlags.canEditNozzle(featureFlags) && !currentSimulationCase.simulationStartedAt) {
        if (name === 'passlnCoord' && value !== 0) {
          filterElementCopy = {
            ...filterElementCopy,
            height: filterElementCopy.height || 200,
            angleLength: filterElementCopy.angleLength || 120,
            angleWidth: filterElementCopy.angleWidth || 120,
          }
        }

        if (name === 'loop') {
          const waterFluxFraction = loopCounter[Math.abs(Number(value))]
            ? (1 / (loopCounter[Math.abs(Number(value))] + 1) || 1)
            : 1

          filterElementCopy = {
            ...filterElementCopy,
            loop: Math.abs(Number(value)),
            // eslint-disable-next-line camelcase
            waterFluxFraction: waterFluxFraction.toFixed(4) ?? 0,
          }
        }
      }

      setFilterValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })

      setEditValues({
        [type]: {
          ...filterElementCopy,
        },
      })

      validateInput(type)

      return
    }

    if (!hasEditChanges[type]) {
      setEditChanges(true, type)
    }

    if (massValues) {
      setElements(paths, { [name]: value })
    }
    else {
      setElements(path, { [name]: value })
    }

    if (!massValues && path && type === 'SegmentGroupSupportPoints' && name === 'shimPropose') {
      const info = ThreeUtil.getElementInfo(path)
      const element = { id: info.id }
      const newEditElements = { ...editElements, [path]: { [name]: value } }
      const changedPaths = getChangedPaths(
        element,
        newEditElements,
        SegmentGroupMountLog,
        SupportPointMountLog,
        SupportPoint,
      )
      const supportPointPaths = getSupportPointPaths(element, SegmentGroupMountLog, SupportPointMountLog)

      if (changedPaths.length === supportPointPaths.length - 1) {
        for (const supportPointPath of supportPointPaths) {
          if (!changedPaths.includes(supportPointPath)) {
            // eslint-disable-next-line camelcase
            setElements(supportPointPath, { shimPropose: null })
          }
        }
      }
    }
  }

  private readonly handlePatternInput = (event: any) => {
    const { resetGapWarnings } = this.props
    const { name, value: originalValue } = event.target
    let value = originalValue

    if (name === 'gapOffset' && Number(value) < 1) {
      value = 1
    }

    this.setState({
      [name]: value,
    })

    resetGapWarnings()
  }

  private readonly handleUndo = (event: any) => {
    const {
      undoChanges,
      setFilterValues,
      setEditValues,
      filterElement,
      type,
      setEditChanges,
      hasEditChanges,
    } = this.props
    const { defaultValues } = this.state
    const name = event.target.value

    if (name) {
      const filterElementCopy = { ...filterElement[type] }

      filterElementCopy[name] = defaultValues[type]?.[name]

      undoChanges(name, type)
      setFilterValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })

      setEditValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })
    }
    else {
      undoChanges(null, type)

      setFilterValues({
        ...filterElement,
        [type]: {
          ...defaultValues[type],
        },
      })

      setEditValues({
        [type]: {
          ...defaultValues[type],
        },
      })

      this.setState({
        multiParentValue: [],
        selectedParentIds: {},
      })

      if (hasEditChanges[type]) {
        setEditChanges(false, type)
      }
    }
  }

  private readonly handlePatternUndo = (event: any) => {
    const { value } = event.target
    let val: number | string = 0

    switch (value) {
      case 'copyMode':
        val = 'offset'
        break
      case 'direction':
        val = 'positive'
        break
      case 'gapOffset':
        val = 1
        break
      default:
    }

    this.setState({
      [value]: val,
    })
  }

  private readonly handleMirrorElements = () => {
    const { paths, repeatElement, type } = this.props
    const target = this.getParentsArray()

    repeatElement(paths, target, type as CasterElementNames, 0, 0, 'mirror')
  }

  private readonly handleRepeatElements = () => {
    const { paths, repeatElement, type } = this.props
    const { copyMode, offset, gapOffset, copies, direction } = this.state
    const off = copyMode === 'offset' ? offset : gapOffset
    const offsetWithDirection = direction === 'positive' ? Math.abs(off) : -Math.abs(off)
    const target = this.getParentsArray()

    repeatElement(paths, target, type as CasterElementNames, copies, offsetWithDirection, copyMode)
  }

  private readonly handleCreateOrClone = () => {
    const { paths, copyElement, filterElement, createElement, type, setEditChanges } = this.props
    const { copies, selectedParentIds } = this.state
    const elementType = type as CasterElementNames
    const parentType = PARENT[elementType] as CasterElementNames
    const parentMountLogType = `${parentType}MountLog` as CasterElementNames
    const parentMountLogIdKey = `${parentMountLogType[0].toLowerCase()}${parentMountLogType.substring(1)}Id`
    const numericParentId = selectedParentIds[parentType]
    const parentMountLogId = MainView.numericIdMountLogMaps[parentMountLogType][numericParentId] as string | undefined

    if (!parentMountLogId) {
      // TODO: show error message

      // eslint-disable-next-line no-console
      console.error('Could not find parent mount log id')

      return
    }

    setEditChanges(false, type)

    if (paths && paths.length) {
      copyElement(paths, parentMountLogIdKey, parentMountLogId, elementType)

      if (copies) {
        this.handleRepeatElements()
      }
    }
    else {
      const element = {
        ...filterElement[type],
        [parentMountLogIdKey]: parentMountLogId,
      }

      createElement([ element ], elementType)
    }
  }

  private readonly handleSelectInitialParents = (paths: Array<any>) => {
    let selectedParentIds = {}

    if (!paths.length) {
      this.setState({
        selectedParentIds: {},
        initialParentIds: {},
        multiParentValue: [],
      })
    }

    if (paths.length === 1) {
      const parents = paths[0].split('/')

      for (let i = 0; i < parents.length - 1; i++) {
        const [ type, id ] = parents[i].split(':')

        selectedParentIds = {
          ...selectedParentIds,
          [type]: Number(id),
        }
      }

      this.setState({
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue: [],
      })
    }

    if (paths.length > 1) {
      const segmentGroupIds: any = []
      const segmentSideIds: any = []

      paths.forEach(path => {
        const pathArray = GlobalUtil.getPathArrayFromPath(path)

        if (!segmentGroupIds.includes(pathArray[1])) {
          segmentGroupIds.push(pathArray[1])
        }

        if (!segmentSideIds.includes(pathArray[3])) {
          segmentSideIds.push(pathArray[3])
        }
      })

      const multiParentValue = []

      if (segmentGroupIds.length !== 1) {
        multiParentValue.push('SegmentGroup')
        multiParentValue.push('Segment')
      }
      else if (segmentSideIds.length !== 1) {
        multiParentValue.push('Segment')
      }

      selectedParentIds = {
        ...selectedParentIds,
        SegmentGroup: segmentGroupIds[0],
        Segment: segmentSideIds[0],
      }

      this.setState({
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue,
      })
    }
  }

  private readonly handleChildren = (children: CasterElementNames[], selectedParentIds: string[]) => {
    children.forEach(child => {
      delete (selectedParentIds as any)[child]

      if (CHILDREN[child].length) {
        this.handleChildren(CHILDREN[child] as any, selectedParentIds)
      }
    })
  }

  private readonly handleParentChange = (event: any) => {
    const { setEditChanges, setParentPath, type, setEditValues, editValues, validateInput } = this.props
    const { selectedParentIds: stateSelectedParentIds, multiParentValue } = this.state

    const selectedParentIds = { ...stateSelectedParentIds }

    selectedParentIds[event.target.name] = Number(event.target.value)

    this.handleChildren((CHILDREN as any)[event.target.name], selectedParentIds as any)

    this.setState({
      selectedParentIds: {
        ...selectedParentIds,
      },
      multiParentValue: [
        ...multiParentValue.filter((parent: any) => !Object.keys(selectedParentIds).includes(parent)),
      ],
    })

    setParentPath(selectedParentIds)

    const editPath = {
      [type]: {
        ...editValues[type],
        parentPath: {
          ...selectedParentIds,
        },
      },
    }

    setEditValues(editPath)
    setEditChanges(true, type)
    validateInput(type)
  }

  private readonly getParentsArray = () => {
    const { selectedParentIds, multiParentValue } = this.state

    return Object.keys(selectedParentIds).reduce((list, key: string) => {
      if (!multiParentValue.includes(key)) {
        return [
          ...list,
          key,
          String(selectedParentIds[key]),
        ]
      }

      return [ ...list ]
    }, [] as string[])
  }

  private readonly getParents = () => {
    const { type } = this.props
    const elementsHashes = getElementsHashesObject(this.props)
    const { Caster, StrandGuideMountLog, SegmentMountLog, Segment } = elementsHashes
    const { selectedParentIds } = this.state

    const strandGuideMountLog =
      Object.values(StrandGuideMountLog ?? {}).find((mountLog) => mountLog.casterId === Caster?.id)

    if (!strandGuideMountLog) {
      // eslint-disable-next-line no-console
      console.warn('Could not find strand guide mount log')

      return []
    }

    const parents: Array<CasterElementNames> = []
    let currentType: CasterElementNames | null | undefined = type as CasterElementNames

    // build parents array from down to top, e.g. Roller -> Segment -> SegmentGroup
    while (currentType && PARENT[currentType]) {
      parents.push(PARENT[currentType] as CasterElementNames)
      currentType = PARENT[currentType]
    }

    let previousParentType: CasterElementNames = 'StrandGuide'
    let previousParentId = strandGuideMountLog.id

    const selectors: { type: CasterElementNames, ids: Selector[] }[] = []

    for (const parentType of parents.reverse()) {
      if (!previousParentId) {
        selectors.push({
          type: parentType,
          ids: [],
        })

        continue
      }

      const previousParentMountLogKey = `${previousParentType}MountLog` as CasterElementNames
      const previousParentMountLog = elementsHashes[previousParentMountLogKey]?.[previousParentId]
      const childMountLogKey = `${parentType[0].toLowerCase()}${parentType.substring(1)}MountLogs`
      const parentMountLogIds = previousParentMountLog?.[childMountLogKey] as string[] | undefined
      const typeMountLogKey = `${parentType}MountLog` as CasterElementNames
      const parentMountLogs = parentMountLogIds
        ?.map((id: string) => elementsHashes[typeMountLogKey]?.[id])
        ?.filter((mountLog) => mountLog)

      parentMountLogs?.sort((a, b) => parentType === 'Segment'
        ? 0 // don't sort segments
        : a.passLineCoord - b.passLineCoord)

      previousParentId = MainView.numericIdMountLogMaps[typeMountLogKey]?.[selectedParentIds[parentType]] as string

      selectors.push({
        type: parentType,
        ids: parentMountLogs?.map(({ id }) => {
          const numericId = MainView.numericIdMountLogMaps[typeMountLogKey][id]

          return {
            key: numericId,
            value: parentType === 'Segment'
              ? DisplayStrandSides[Segment[SegmentMountLog?.[id]?.segmentId ?? '']?.side as StrandSide]
              : `${parentType === 'SegmentGroup' ? 'SG' : parentType}:${numericId}`,
          } as Selector
        }) ?? [],
      })

      previousParentType = parentType
    }

    return selectors
  }

  public override render () {
    const { selectedParentIds, multiParentValue, direction, offset, copies, copyMode, gapOffset } = this.state
    const {
      type,
      paths,
      allPaths,
      hideActions,
      filterElement,
      editValues,
      featureFlags,
      t,
      amountOfComparisonCasterColumns,
    } = this.props

    const parents = this.getParents()
    const target = this.getParentsArray()
    const len = paths && paths.length

    // TODO: this does not work with selected Segments or SegmentGroups
    const segments = len
      ? [ ...Array.from(new Set(paths.map(path => path.split('/').slice(0, 2).join('/')))) ].length
      : 0
    const segmentGroups = len
      ? [ ...Array.from(new Set(paths.map(path => path.split('/').slice(0, 1).join('/')))) ].length
      : 0

    const elementType = len ? paths[0].split('/').slice(-1)[0].split(':')[0].toLowerCase() : ''
    const isComparingCasters = setSelectedComparisonCaseIds.length && amountOfComparisonCasterColumns > 0

    return (
      <div>
        {
          type !== 'General' && type !== 'SegmentGroupSupportPoints' && (
            <SectionContainer>
              <Section name={t('allInOne.selection.title')} title={t('allInOne.selection.tooltip')}>
                <SectionContent>
                  {
                    t('allInOne.selectedValues', {
                      selected: len ? `${len} ${type}${len > 1 ? 's' : ''}` : t('allInOne.nothing'),
                    })
                  }
                  {
                    len === 1
                      ? ` (${t(`allInOne.types.${elementType}`)}: ${paths[0].split('/').slice(-1)[0].split(':')[1]})`
                      : ''
                  }
                  {
                    len > 0 && type !== 'DataPoint' && type !== 'DataLine' && type !== 'SegmentGroup' && (
                      <div>
                        {
                          parents.length < 2 && t('allInOne.selectedInSG', {
                            segmentGroups,
                            segmentGroupS: segmentGroups > 1 ? 's' : '',
                            segments,
                            segmentS: segments > 1 ? 's' : '',
                          })
                        }
                        {
                          parents.length > 1 && t('allInOne.selected', {
                            segmentGroups,
                            segmentGroupS: segmentGroups > 1 ? 's' : '',
                            segments,
                            segmentS: segments > 1 ? 's' : '',
                          })
                        }
                      </div>
                    )
                  }
                </SectionContent>
              </Section>
              {
                ![
                  'SensorPoint',
                  'DataLine',
                  'DataPoint',
                  'Segment',
                  'SegmentGroup',
                  'SupportPoint',
                  'SegmentGroupSupportPoints',
                ]
                  .includes(type) &&
              (
                <Section name={t('allInOne.parents.title')} title={t('allInOne.parents.tooltip')}>
                  {
                    parents.map(parent => (
                      <DropDown
                        key={parent.type}
                        values={parent.ids}
                        value={
                          multiParentValue.includes(parent.type)
                            ? t('allInOne.multiValue')
                            : (selectedParentIds as any)[parent.type] // TODO: Fix type
                        }
                        label={parent.type}
                        onChange={this.handleParentChange}
                        multiValues={multiParentValue.includes(parent.type)}
                        disabled={
                          (
                            !FeatureFlags.canEditNozzle(featureFlags) &&
                        !FeatureFlags.canEditRoller(featureFlags) &&
                        paths[0]
                          ) ||
                        isComparingCasters
                        }
                      />
                    ))
                  }
                </Section>
              )
              }
            </SectionContainer>
          )
        }
        {
          type !== 'Segment' && (
            <FormBuilder
              type={type}
              path={paths[0]}
              paths={paths}
              allPaths={allPaths}
              hideActions={hideActions}
              target={target}
              // TODO: fix type
              filterValue={(editValues as any)[type] ?? (filterElement as any)[type] ?? {}}
              selectedParentIds={selectedParentIds}
              onSave={this.handleSave}
              onCreateOrCopy={this.handleCreateOrClone}
              massForm={paths.length > 1}
              onInput={this.handleInput}
              onDeleteElement={this.handleDeleteElement}
              onUndo={this.handleUndo}
              direction={direction}
              onPatternInput={this.handlePatternInput}
              onMirrorElements={this.handleMirrorElements}
              onPatternUndo={this.handlePatternUndo}
              copies={copies}
              copyMode={copyMode}
              offset={offset}
              gapOffset={gapOffset}
              onPatternApply={this.handleRepeatElements}
              isComparingCasters={isComparingCasters}
            />
          )
        }
      </div>
    )
  }
}

export default withNamespaces('caster')(connector(AllInOne as any) as any) as any
