import Util from '@/logic/Util'
import { getElementFromPath } from '@/store/elements/logic/getters'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import { CompareFilterHandler } from '@/three/logic/CompareFilterHandler'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'
import type { PlotConfig, TileConfig, Vector2D } from '@/types/visualization'
import { ElementsUtil } from '@/Util/ElementsUtil'

import { ViewLogic } from './ViewLogic'

export class ViewCompareLogic {
  public static getXDomainForDynamicPlotsWithComparisonCasters (
    xDomain: number[],
    tileConfig: TileConfig,
    dynamicData: Vector2D[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return xDomain
    }

    const linesXDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0 || !Array.isArray(line)) {
        return
      }

      if (!Array.isArray(line)) {
        return
      }

      linesXDomains.push(ViewLogic.getXDomain(xDomain, line.map(point => point.x), tileConfig))
    })

    const min = linesXDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity)
    const max = linesXDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity)

    return [ min, max ]
  }

  public static getYDomainForDynamicPlotsWithComparisonCasters (
    xDomain: number[],
    yDomain: number[],
    tileConfig: TileConfig,
    dynamicData: any[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return yDomain
    }

    const linesYDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0 || !Array.isArray(line)) {
        return
      }

      linesYDomains.push(ViewLogic.getYDomain(xDomain, yDomain, tileConfig, line))
    })

    const min = linesYDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity) ?? yDomain[0]
    const max = linesYDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity) ?? yDomain[1]

    return [ min, max ]
  }

  private static getCompareElementFromPath (
    path: string,
    elementsHashes: ElementsHashes,
    caseId: string,
  ) {
    if (!elementsHashes) {
      return undefined
    }

    const { type, id: numericId } = ThreeUtil.getElementInfo(path)
    const isSensorPoint = type === 'SensorPoint'

    const numericIdMountLogType = `${type}MountLog`
    const mountLogType = !isSensorPoint ? numericIdMountLogType : Util.getSensorPointMountLogTypeByPath(path)

    const id = ElementsUtil
      .compareNumericIdMountLogMaps[caseId]
      ?.[numericIdMountLogType as keyof NumericIdMountLogMaps]
      ?.[numericId]

    if (
      !id ||
      !type ||
      !(elementsHashes as any)[type] ||
      !(elementsHashes as any)[mountLogType] ||
      !(elementsHashes as any)[mountLogType][id]
    ) {
      return {}
    }

    // first letter to lower case
    const elementIdKey = `${type[0].toLowerCase() + type.substring(1)}Id`
    const elementMountLog = (elementsHashes as any)[mountLogType][id]
    const element = elementsHashes[type][elementMountLog[elementIdKey]]

    return ElementsUtil.getFullCasterElement(element, elementMountLog, numericId)
  }

  private static getElementsFromCompareDataLineWithFilter (
    elementsHashes: ElementsHashes,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    const caseHashes = ElementsUtil.comparePaths[caseId]
    const { filter } = plotConfig

    if (!caseHashes || !filter) {
      return []
    }

    const hitElements = CompareFilterHandler.getFilteredElementsPerCompareCaseId(
      elementsHashes,
      caseId,
      filter,
    )
    const returnedElements: any[] = []

    Object
      .entries(hitElements)
      .filter(([ _path, elType ]) => elType === 'DataLine')
      .forEach(([ path ]) => {
        const element = ViewCompareLogic.getCompareElementFromPath(
          path,
          elementsHashes,
          caseId,
        )

        if (!element) {
          return
        }

        returnedElements.push(element)
      })

    return returnedElements
  }

  private static getPointsFromComparisonCasterWithFilter (
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    const caseHashes = ElementsUtil.comparePaths[caseId]
    const { filter } = plotConfig

    if (!caseHashes || !filter) {
      return []
    }

    const points: Vector2D[] = []

    const hitElements = CompareFilterHandler.getFilteredElementsPerCompareCaseId(
      elementsHashes,
      caseId,
      filter,
    )

    Object
      .entries(hitElements)
      .filter(([ _path, elType ]) => elType === type)
      .forEach(([ path ]) => {
        const element = ViewCompareLogic.getCompareElementFromPath(
          path,
          elementsHashes,
          caseId,
        )

        if (!element) {
          return
        }

        const numberedAttrX = Number(element[attrX] ?? element?.additionalData?.[attrX])
        const numberedAttrY = Number(element[attrY] ?? element?.additionalData?.[attrY])

        if (!isNaN(numberedAttrX) && !isNaN(numberedAttrY)) {
          points.push({ x: numberedAttrX, y: numberedAttrY })
        }
      })

    return points.sort((a, b) => a.x - b.x)
  }

  public static getPointsFromComparisonCaster (
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    if (plotConfig.filter) {
      return this.getPointsFromComparisonCasterWithFilter(
        elementsHashes,
        type,
        attrX,
        attrY,
        plotConfig,
        caseId,
      )
    }

    if (type === 'SensorPoint') {
      const mountLogs = [
        ...Object.values(elementsHashes.SegmentSensorPointsMountLog ?? {}),
        ...Object.values(elementsHashes.RollerSensorPointsMountLog ?? {}),
        ...Object.values(elementsHashes.RollerBodySensorPointsMountLog ?? {}),
      ]

      const points: Vector2D[] = []

      mountLogs.forEach((mountLog) => {
        const { id } = elementsHashes.SensorPoint?.[mountLog.sensorPointId ?? ''] ?? {}
        const elementInfo = elementsHashes.SensorPoint?.[id] ?? {}
        const element = ElementsUtil.getCompleteFullCasterElement(elementInfo, mountLog)

        const numericIdsHash = (ElementsUtil.compareNumericIdMountLogMaps[caseId] as any)?.[`${type}MountLog`]
        const numericId = Number(numericIdsHash?.[mountLog.id])

        const numberedAttrX = attrX !== 'id'
          ? Number((element as any)[attrX])
          : numericId
        const numberedAttrY = attrY !== 'id'
          ? Number((element as any)[attrY])
          : numericId

        if (!isNaN(numberedAttrX) && !isNaN(numberedAttrY)) {
          points.push({ x: numberedAttrX, y: numberedAttrY })
        }
      })

      return points.sort((a, b) => a.x - b.x)
    }
    else if (type === 'DataPoint') {
      const mountLogs = [
        ...Object.values(elementsHashes.MoldFaceDataPointsMountLog ?? {}),
        ...Object.values(elementsHashes.StrandDataPointsMountLog ?? {}),
        ...Object.values(elementsHashes.SegmentDataPointsMountLog ?? {}),
        ...Object.values(elementsHashes.RollerDataPointsMountLog ?? {}),
        ...Object.values(elementsHashes.RollerBodyDataPointsMountLog ?? {}),
      ]

      const points: Vector2D[] = []

      mountLogs.forEach((mountLog) => {
        const elementInfo = elementsHashes.DataPoint?.[mountLog.dataPointId ?? ''] ?? {}
        const element = ElementsUtil.getCompleteFullCasterElement(elementInfo, mountLog)

        const numericIdsHash = (ElementsUtil.compareNumericIdMountLogMaps[caseId] as any)?.[`${type}MountLog`]
        const numericId = Number(numericIdsHash?.[mountLog.id])

        const numberedAttrX = attrX !== 'id'
          ? Number((element as any)[attrX])
          : numericId
        const numberedAttrY = attrY !== 'id'
          ? Number((element as any)[attrY])
          : numericId

        if (!isNaN(numberedAttrX) && !isNaN(numberedAttrY)) {
          points.push({ x: numberedAttrX, y: numberedAttrY })
        }
      })

      return points.sort((a: any, b: any) => a.x - b.x)
    }
    else {
      const mountLogs = Object.values((elementsHashes as any)[`${type}MountLog`] ?? {})

      const points: Vector2D[] = []

      mountLogs.forEach((mountLog: any) => {
        const elementIdKey = `${type[0].toLowerCase() + type.substring(1)}Id`
        const elementInfo = (elementsHashes as any)[type]?.[mountLog[elementIdKey]] ?? {}
        const element = ElementsUtil.getCompleteFullCasterElement(elementInfo, mountLog)
        const numericIdsHash = (ElementsUtil.compareNumericIdMountLogMaps[caseId] as any)?.[`${type}MountLog`]
        const numericId = Number(numericIdsHash?.[mountLog.id])

        const numberedAttrX = attrX !== 'id'
          ? Number(element[attrX])
          : numericId
        const numberedAttrY = attrY !== 'id'
          ? Number(element[attrY])
          : numericId

        if (!isNaN(numberedAttrX) && !isNaN(numberedAttrY)) {
          points.push({ x: numberedAttrX, y: numberedAttrY })
        }
      })

      return points.sort((a: any, b: any) => a.x - b.x)
    }
  }

  public static getPointsFromComparisonDataLine (
    elementsHashes: ElementsHashes,
    attrX: string,
    attrY: string,
    caseId: string,
    plotConfig: PlotConfig,
    hasRef = false,
  ) {
    const { filter } = plotConfig
    const elements: any[] = []

    if (!elementsHashes) {
      return []
    }

    if (filter) {
      elements.push(...this.getElementsFromCompareDataLineWithFilter(
        elementsHashes,
        plotConfig,
        caseId,
      ))
    }
    else {
      const mountLogs = Object.values(elementsHashes.DataLineMountLog ?? {})

      mountLogs.forEach((mountLog) => {
        const elementInfo = elementsHashes.DataLine?.[mountLog.dataLineId ?? ''] ?? {}
        const element = ElementsUtil.getCompleteFullCasterElement(elementInfo, mountLog)

        elements.push(element)
      })
    }

    const lines: Array<Vector2D[]> = []

    for (const el of elements) {
      const xValues = ViewLogic.getElementKeyValue(el, attrX)
      const yValues = ViewLogic.getElementKeyValue(el, attrY)
      const parsedXValues = typeof xValues === 'string'
        ? xValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : xValues
      const parsedYValues = typeof yValues === 'string'
        ? yValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : yValues

      if (
        Array.isArray(parsedXValues) &&
        parsedXValues.length > 1 &&
        Array.isArray(parsedYValues) &&
        parsedYValues.length > 1
      ) {
        const line: Vector2D[] = []
        const amountOfPoints = Math.min(parsedXValues.length, parsedYValues.length)

        for (let i = 0; i < amountOfPoints; i++) {
          const x = parsedXValues[i]
          const y = parsedYValues[i]

          if (x !== undefined && y !== undefined) {
            line.push({ x, y })
          }
        }

        if (line.length) {
          if (hasRef) {
            return line
          }

          lines.push(line)
        }
      }
    }

    return lines
  }

  public static buildComparePaths (
    elementsHashes: ElementsHashes,
    caseId: string,
  ) {
    const numericIdMaps = ElementsUtil.compareNumericIdMountLogMaps[caseId]
    const paths: Record<CasterElementNames, Record<string, string>> = {} as any

    if (!numericIdMaps) {
      return
    }

    // SegmentGroups
    const segmentGroupMountLogs = Object.values(elementsHashes.SegmentGroupMountLog ?? {})

    paths.SegmentGroup = {}

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const numericId = numericIdMaps.SegmentGroupMountLog?.[segmentGroupMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        return
      }

      const path = `SegmentGroup:${numericId}`

      paths.SegmentGroup[segmentGroupMountLog.id] = path
    })

    // Segments
    const segmentMountLogHash = elementsHashes.SegmentMountLog ?? {}
    const segmentMountLogs = Object.values(segmentMountLogHash)

    paths.Segment = {}

    for (const segmentMountLog of segmentMountLogs) {
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = paths.SegmentGroup[segmentGroupMountLogId ?? '']
      const numericId = numericIdMaps.SegmentMountLog[segmentMountLog.id]
      const path = parentPath ? `${parentPath}/Segment:${numericId}` : `Segment:${numericId}`

      paths.Segment[segmentMountLog.id] = path
    }

    // Nozzles
    const nozzleMountLogHash = elementsHashes.NozzleMountLog ?? {}
    const nozzleMountLogs = Object.values(nozzleMountLogHash)

    paths.Nozzle = {}

    for (const nozzleMountLog of nozzleMountLogs) {
      const numericId = numericIdMaps.NozzleMountLog[nozzleMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Segment[nozzleMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/Nozzle:${numericId}` : `Nozzle:${numericId}`

      paths.Nozzle[nozzleMountLog.id] = path
    }

    // Rollers
    const rollerMountLogHash = elementsHashes.RollerMountLog ?? {}
    const rollerMountLogs = Object.values(rollerMountLogHash)

    paths.Roller = {}

    for (const rollerMountLog of rollerMountLogs) {
      const numericId = numericIdMaps.RollerMountLog[rollerMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Segment[rollerMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/Roller:${numericId}` : `Roller:${numericId}`

      paths.Roller[rollerMountLog.id] = path
    }

    // RollerBodies
    const rollerBodyMountLogHash = elementsHashes.RollerBodyMountLog ?? {}
    const rollerBodyMountLogs = Object.values(rollerBodyMountLogHash)

    paths.RollerBody = {}

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const numericId = numericIdMaps.RollerBodyMountLog[rollerBodyMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Roller[rollerBodyMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/RollerBody:${numericId}` : `RollerBody:${numericId}`

      paths.RollerBody[rollerBodyMountLog.id] = path
    }

    // RollerBearings
    const rollerBearingMountLogHash = elementsHashes.RollerBearingMountLog ?? {}
    const rollerBearingMountLogs = Object.values(rollerBearingMountLogHash)

    paths.RollerBearing = paths.RollerBearing ?? {}

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const numericId = numericIdMaps.RollerBearingMountLog[rollerBearingMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Roller[rollerBearingMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = parentPath ? `${parentPath}/RollerBearing:${numericId}` : `RollerBearing:${numericId}`

      paths.RollerBearing[rollerBearingMountLog.id] = path
    }

    // DataPoints in Rollers
    const rollerDataPointsMountLogHash = elementsHashes.RollerDataPointsMountLog ?? {}
    const rollerDataPointsMountLogs = Object.values(rollerDataPointsMountLogHash)

    paths.DataPoint = paths.DataPoint ?? {}

    for (const rollerDataPointsMountLog of rollerDataPointsMountLogs) {
      const numericId = numericIdMaps.DataPointMountLog[rollerDataPointsMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Roller[rollerDataPointsMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/DataPoint:${numericId}` : `DataPoint:${numericId}`

      paths.DataPoint[rollerDataPointsMountLog.id] = path
    }

    // SensorPoints in Rollers
    const rollerSensorPointsMountLogHash = elementsHashes.RollerSensorPointsMountLog ?? {}
    const rollerSensorPointsMountLogs = Object.values(rollerSensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const rollerSensorPointsMountLog of rollerSensorPointsMountLogs) {
      const numericId = numericIdMaps.SensorPointMountLog[rollerSensorPointsMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Roller[rollerSensorPointsMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      paths.SensorPoint[rollerSensorPointsMountLog.id] = path
    }

    // DataPoints in Roller Bodies
    const rollerBodyDataPointsMountLogHash = elementsHashes.RollerBodyDataPointsMountLog ?? {}
    const rollerBodyDataPointsMountLogs = Object.values(rollerBodyDataPointsMountLogHash)

    for (const rollerBodyDataPointsMountLog of rollerBodyDataPointsMountLogs) {
      const numericId = numericIdMaps.DataPointMountLog[rollerBodyDataPointsMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.RollerBody[rollerBodyDataPointsMountLog.rollerBodyMountLogId ?? '']
      const path = parentPath ? `${parentPath}/DataPoint:${numericId}` : `DataPoint:${numericId}`

      paths.DataPoint[rollerBodyDataPointsMountLog.id] = path
    }

    // sensor points in roller bodies
    // eslint-disable-next-line max-len
    const rollerBodySensorPointsMountLogHash = elementsHashes.RollerBodySensorPointsMountLog ?? {}
    const rollerBodySensorPointsMountLogs = Object.values(rollerBodySensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const rollerBodySensorPointsMountLog of rollerBodySensorPointsMountLogs) {
      const numericId = numericIdMaps.SensorPointMountLog[rollerBodySensorPointsMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.RollerBody[rollerBodySensorPointsMountLog.rollerBodyMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      paths.SensorPoint[rollerBodySensorPointsMountLog.id] = path
    }

    // sensor points in segments
    const segmentSensorPointsMountLogHash = elementsHashes.SegmentSensorPointsMountLog ?? {}
    const segmentSensorPointsMountLogs = Object.values(segmentSensorPointsMountLogHash)

    paths.SensorPoint = paths.SensorPoint ?? {}

    for (const segmentSensorPointsMountLog of segmentSensorPointsMountLogs) {
      const numericId = numericIdMaps.SensorPointMountLog[segmentSensorPointsMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.Segment[segmentSensorPointsMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      paths.SensorPoint[segmentSensorPointsMountLog.id] = path
    }

    // SupportPoints
    const supportPointMountLogHash = elementsHashes.SupportPointMountLog ?? {}
    const supportPointMountLogs = Object.values(supportPointMountLogHash)

    paths.SupportPoint = paths.SupportPoint ?? {}

    for (const supportPointMountLog of supportPointMountLogs) {
      const numericId = numericIdMaps.SupportPointMountLog[supportPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || isNaN(numericId)) {
        continue
      }

      const parentPath = paths.SegmentGroup[supportPointMountLog.segmentGroupMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SupportPoint:${numericId}` : `SupportPoint:${numericId}`

      paths.SupportPoint[supportPointMountLog.id] = path
    }

    ElementsUtil.comparePaths[caseId] = paths
  }

  public static getPointsFromDataServerData (
    type: string,
    elementPaths: string[],
    elementsHashes: ElementsHashes,
    attrX: string,
    attrY: string,
    timestamp: number,
    mountLogToKeyUUIDsMap: MountLogToKeyUUIDsMap,
    casterDataServer: CasterDataServerState,
  ) {
    const filteredPaths = elementPaths.filter(path => {
      const { type: pathType } = ThreeUtil.getElementInfo(path)

      return pathType === type
    })
    const points: Vector2D[] = []

    if (!filteredPaths.length) {
      return []
    }

    const timestampData = casterDataServer.timestampData[timestamp]

    if (!timestampData) {
      return []
    }

    const rawData = filteredPaths.map(path => getElementFromPath(path, elementsHashes))

    for (const el of rawData) {
      if (!el) {
        continue
      }

      let x = Number(ViewLogic.getElementKeyValue(el, attrX))
      let y = Number(ViewLogic.getElementKeyValue(el, attrY))

      const attrXUUID = mountLogToKeyUUIDsMap[type as FilterableElementType]?.[attrX]?.[el.id]
      const attrYUUID = mountLogToKeyUUIDsMap[type as FilterableElementType]?.[attrY]?.[el.id]

      if (!attrXUUID && !attrYUUID) {
        continue
      }

      const dataSourceX = casterDataServer.timestampData[timestamp]?.[attrXUUID]
      const dataSourceY = casterDataServer.timestampData[timestamp]?.[attrYUUID]

      if (isNaN(dataSourceX) && isNaN(dataSourceY)) {
        continue
      }

      if (attrX === 'id') {
        const mountLogId = el.id
        const typeMountLog = `${type}MountLog`
        const numericId = MainView.numericIdMountLogMaps[typeMountLog]?.[mountLogId]

        x = numericId
      }
      else if (attrY === 'id') {
        const mountLogId = el.id
        const typeMountLog = `${type}MountLog`
        const numericId = MainView.numericIdMountLogMaps[typeMountLog]?.[mountLogId]

        y = numericId
      }

      const xValue = dataSourceX ?? x
      const yValue = dataSourceY ?? y

      if (!isNaN(xValue) && !isNaN(yValue)) {
        points.push({ x: xValue, y: yValue })
      }
    }

    points.sort((a: any, b: any) => a.x - b.x)

    return points
  }
}
