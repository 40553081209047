import hotkeys from 'hotkeys-js'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Button from '@/react/components/Button/index'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { FixedButton } from '@/react/dialogs/DialogStyles'
import { Form, List, Text, TextBody } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import * as MatrixActions from '@/store/matrix/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

const T = 'projectMatrixDialog.duplicateSimulationCaseDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string
  loading: boolean
  duplicateLoading: boolean
  duplicateError: string
}

export class DuplicateSimulationCaseDialog extends Component<Props, State> {
  @Identifiable('DuplicateSimulationCaseDialog') public static readonly NAME: string

  public override state: State = {
    error: '',
    loading: false,
    duplicateLoading: false,
    duplicateError: '',
  }
  
  public override componentDidMount () {
    hotkeys('Enter', this.handleCloneSubmit)
    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleCloneSubmit)
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DuplicateSimulationCaseDialog.NAME)
  }

  private readonly handleCloneSubmit = () => {
    const { selections, setCurrentProject, setLoadingStatus, setSelections, closeDialog } = this.props
    const simulationCaseIds = Object.keys(selections).filter(id => selections[id])
    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })

    setLoadingStatus(true)
    this.setState({ duplicateLoading: true, duplicateError: '' })
    closeDialog(DuplicateSimulationCaseDialog.NAME)
    setSelections(deselect)

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/duplicate`, { data: { simulationCaseIds } })
      .then(({ project }) => {
        if (project && project.id) {
          setCurrentProject(project)
        }

        this.setState({ duplicateLoading: false })
        setLoadingStatus(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ duplicateLoading: false, duplicateError: 'ERROR' })
      })
  }
  
  public override render () {
    const { error, loading, duplicateLoading } = this.state
    const { currentProject, selections, t } = this.props

    const simulationCases = currentProject
      .simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => selections[simulationCase.id])

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-albums'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${T}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                simulationCases.map(({ num, simulationCase }) => (
                  <li key={simulationCase.id}>{num}. {simulationCase.name}</li>
                ))
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleCloneSubmit}
              error={error}
              loading={loading}
              disabled={duplicateLoading}
              icon='pe-7s-albums'
              isRef
            >
              {t(`${T}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(DuplicateSimulationCaseDialog as any) as any) as any
