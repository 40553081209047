import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import FeatureFlags from '@/react/FeatureFlags/index'
import Icon from '@/react/specific/Icon'
import { DefaultState } from '@/types/state'

import { Header, IconBox, Lock, NameBox, Trigger } from './TypeHeaderStyles'

const connector = connect((state: DefaultState) => ({
  selectedPaths: state.data.selectedPaths,
  currentSimulationCase: state.application.main.currentSimulationCase,
  authenticationData: state.application.main.authenticationData,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  caseLocks: state.application.main.caseLocks,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  title: string
  type: string
  isExpanded: boolean
  isSelected: boolean
  onClick: (e: any) => void
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  icons: any
}

export class TypeHeader extends Component<Props, State> {
  public override state: State = {
    icons: {
      General: 'map-marker',
      Elements: 'list',
      SegmentGroup: 'square',
      Segment: 'square',
      SupportPoint: 'square-full',
      Nozzle: 'caret-down',
      Roller: 'circle',
      RollerBody: 'circle',
      RollerBearing: 'bullseye',
      SensorPoint: 'square-full',
      DataPoint: 'square-full',
      DataLine: 'gripLines',
      ConsistencyCheck: 'edit',
      Modules: 'objectGroup',
    },
  }

  public override render () {
    const { icons } = this.state
    const {
      type,
      isExpanded,
      isSelected,
      onClick: handleClick,
      title,
      currentSimulationCase,
      authenticationData,
      featureFlags,
      caseLocks,
      t,
    } = this.props
    const realFeatureFlags = FeatureFlags.getRealFeatureFlags({
      application: {
        main: {
          authenticationData: { featureFlags },
          currentSimulationCase,
        },
      },
    })
    const canEdit = FeatureFlags.canEditElement(type, realFeatureFlags)
    const { isLocked } = FeatureFlags.getLockedStatus(type, authenticationData, caseLocks)

    const lockedInfo = t('header.noPermissions', { type })
    // FIXME: if needed get username and show it
    // const lockedInfo = isLocked
    //   ? lockedBy
    //     ? t('header.lockedBy', { type, user: lockedBy })
    //     : t('header.noPermissions', { type })
    //   : t('header.noPermissions', { type })

    return (
      <Header $isSelected={isSelected} title={(!canEdit || isLocked) ? lockedInfo : title || type}>
        <IconBox $isChild={![ 'General', 'Elements', 'Modules', 'ConsistencyCheck' ].includes(type)}>
          <Icon
            icon={(icons as any)[type]}
            type={type === 'RollerBody' ? 'fas' : undefined}
          />
        </IconBox>
        <NameBox $pad={20} onClick={handleClick}>
          {type}
        </NameBox>
        {
          (!canEdit || isLocked) && type !== 'Modules' && type !== 'Elements' && (
            <Lock title={lockedInfo}>
              <i className='pe-7s-lock' />
            </Lock>
          )
        }
        <Trigger onClick={handleClick}>
          <i className={`pe-7s-angle-${isExpanded ? 'down' : 'right'}`} />
        </Trigger>
      </Header>
    )
  }
}

export default withNamespaces('caster')(connector(TypeHeader as any) as any) as any
