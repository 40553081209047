import { DriverManager, DriverType } from '@/react/driver/DriverManager'
import FeatureFlags from '@/react/FeatureFlags'
import Util from '@/three/logic/Util'
import ThreeBase from '@/three/ThreeBase'
import ThreeManager from '@/three/ThreeManager'

import MainView from '.'

export default class EventHandlers {
  public static handleKeyCode (view: MainView, event: any) {
    switch (event.keyCode) {
      case 81: // Q
        // CTRL + Q
        if (!event.shiftKey && event.ctrlKey) {
          DriverManager.init(DriverType.SliderTest)
        }

        break
      case 65: // A
        // TODO: if dialog open or focus in input (filter/edit), do not select all elements
        // CTRL + A
        if (view.amountOfComparisonCasterColumns) {
          event.preventDefault()

          return
        }

        if (!event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          const selectableTypes = [
            'Nozzle',
            'Roller',
            'RollerBody',
            'RollerBearing',
            'DataPoint',
            'SensorPoint',
            'DataLine',
          ]

          const paths = view
            .clickableObjects
            .filter(object => Util.isVisible(object) && selectableTypes.includes(object.userData.type))
            .map(object => object.userData.path)

          view.setSelectedElementPaths?.(paths, true, false, true)
        }

        // CTRL + SHIFT + A
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          view.setSelectedElementPaths?.()
        }

        break
      case 70: // F
        // CTRL + F
        if (!event.shiftKey && event.ctrlKey) {
          if (!FeatureFlags.canCenter3DPosition(view.featureFlags)) {
            return
          }

          event.preventDefault()

          view.jumpToFiltered()
        }

        // CTRL + SHIFT + F
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          view.setTerm('')

          view.setSelectedElementPaths?.()
        }

        break
      case 67: // C
        // CTRL + SHIFT + C
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          view.controls.enabled = true
          view.controls.enableRotate = true
        }

        break
      case 191: // #
        // CTRL + #
        if (event.ctrlKey) {
          event.preventDefault()

          view.controls.autoRotate = !view.controls.autoRotate

          ThreeManager.base.setFpsTarget(view.controls.autoRotate ? ThreeBase.FullFPS : ThreeBase.LowFPS)

          view.controls.update()
        }

        break
      default:
    }
  }
}
