import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import { ContextMenuData } from '@/types/visualization'

import { ContextMenuItem, ContextMenuWrapper } from './ContextMenu'

const connector = connect((state: DefaultState) => ({
  contextMenu: state.visualization.contextMenu,
}), {
  closeContextMenu: VisualizationActions.closeContextMenu,
  derivePlot: VisualizationActions.derivePlot,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  data: ContextMenuData
  t: Translation
}

class PlotContextMenu extends Component<Props> {
  private readonly handleDeriveClick = () => {
    const { data, contextMenu, derivePlot, closeContextMenu } = this.props

    const viewId = contextMenu?.data?.viewId ?? ''

    derivePlot(data.configId ?? '', data.xIndex, viewId, data.x)
    closeContextMenu()
  }
  
  public override render () {
    const { data, t } = this.props

    return (
      <ContextMenuWrapper data-contextmenu $position={data.mousePos}>
        <ContextMenuItem data-contextmenu onClick={this.handleDeriveClick}>
          {t('plotContextMenu.label', { x: data.x.toFixed(2) })}
        </ContextMenuItem>
      </ContextMenuWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(PlotContextMenu as any) as any) as any
