import React, { Component } from 'react'
import styled from 'styled-components'

const CommentStyledInput = styled.textarea`
outline:       none;
padding:      4px 10px;
background:    #22282e;
border:        0;
color:         #CCCCCC;
border: solid 1px #8e8e8e;
border-radius: 5px;
font-size:     13px;
width:        80%;
height:        50px;
margin: 5px 0;
resize: none
`

type Props = {
  placeholder?: string
  onChange: (type: string, key: string, comment: string) => void
  type: string
  elementKey: string
}

export default class CommentInput extends Component<Props> {
  public override render () {
    const { placeholder, onChange, type, elementKey } = this.props

    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CommentStyledInput
          placeholder={placeholder}
          onChange={(e) => onChange(type, elementKey, e.target.value ?? '')}
        />
      </div>
    )
  }
}
