import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import sizeMe from 'react-sizeme'

import Util from '@/logic/Util'
import { DefaultState } from '@/types/state'
import { Definition } from '@/types/visualization'

import logic from './logic'
import { Label, TextContainer, Value, Wrapper } from './styles'

const connector = connect(({ visualization, application }: DefaultState) => ({
  tileConfigs: visualization.tileConfigs,
  plotConfigs: visualization.plotConfigs,
  data: visualization.data,
  networkStatus: application.main.networkStatus,
}), {
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  width: number
  height: number
  dynamicData: Array<any>
  tileId: string
  /**
   * PlotConfig ID
   */
  configId: string
  viewId: string
  xLabel: string
  yLabel: string
  type: 'area' | 'bar' | 'contour' | 'gage' | 'line' | 'pie' | 'text'
  configIds: string[]
  yDomain: number[]
  xDomain: number[]
  xRange: number[]
  valueRange: number[]
  shapeIds?: number[]
  radiusDomain?: any[]
  radius0?: number
  radius?: number
  frequency: number
  flipAxes: boolean
  isDynamicData: boolean
}

type State = {
  data: any[]
}

class TextWrapper extends Component<Props, State> {
  private wrapperRef?: HTMLElement

  public override state: State = {
    data: [],
  }
  
  public override componentDidUpdate (prevProps: Props) {
    const { data } = this.state

    const forceReload = data && data.length === 0

    logic.connect(prevProps, this.props, forceReload, this.setData)

    this.handleResize()
  }
  
  private readonly handleMouseDown = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const offset = event.pageY - this.wrapperRef.getBoundingClientRect().top
    const containerHeight = this.wrapperRef.getBoundingClientRect().height

    if (event.button === 0) {
      if (offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: -10,
        })
      }

      if (containerHeight - offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: 10,
        })
      }
    }
  }

  private readonly handleWheel = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const target = this.wrapperRef

    target.scrollTop += event.deltaY

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }
  }

  private readonly handleWrapperRef = (ref: any) => {
    if (ref) {
      this.wrapperRef = ref
    }
  }

  private readonly handleResize = () => {
    this.handleWheel({
      target: this.wrapperRef,
      deltaY: 0,
    })
  }

  private readonly setData = ({ tileId, definitions }: { tileId: string, definitions: Definition[] }) => {
    const { data: stateData } = this.state
    const { tileConfigs, isDynamicData, dynamicData, configId } = this.props

    const defaultData = logic.getData(this.props)

    if (isDynamicData) {
      defaultData.forEach((data, index) => {
        defaultData[index].name = configId
        defaultData[index].x = dynamicData.map(el => el.x)
        defaultData[index].y = dynamicData.map(el => el.y)
      })

      this.setState({
        data: defaultData,
      })

      return
    }

    const data = []

    for (let i = 0; i < definitions.length; i++) {
      const dataConfigId = definitions[i].id
      const rawValues = definitions[i].data

      data.push({
        ...defaultData[0],
        y: rawValues?.map((value: any) => Util.handleFormula(value, tileConfigs[tileId].dynamicFormula ?? '')) ?? [],
        name: dataConfigId,
      })
    }

    if (!isEqual(data, stateData)) {
      this.setState({
        data,
      })
    }
  }
  
  public override render () {
    const { data } = this.state
    const { plotConfigs, configId, tileConfigs, tileId, isDynamicData } = this.props

    const tConf = tileConfigs[tileId]
    const conf = plotConfigs[configId]
    const defaultLabels: string[] = []

    data.forEach(val => {
      if ((conf && conf.key) || (plotConfigs[val.name] && plotConfigs[val.name].key)) {
        defaultLabels.push((conf.key ? conf : plotConfigs[val.name]).key.split('_').join(' '))
      }
    })

    return (
      <Wrapper
        id={`text_${tileId}`}
        ref={this.handleWrapperRef}
        onWheel={this.handleWheel}
        onResize={this.handleResize}
        onMouseDown={this.handleMouseDown}
      >
        {
          Boolean(data) && data.map((val, i) => {
            const label = isDynamicData
              ? (tConf.label ?? {} as any)[val.name] ?? val.x[0]
              : ((tConf.label ?? {} as any)[val.name] ?? defaultLabels[i] ?? '').replace('[C]', '[°C]')

            const value = /^[0-9]/.test(val.y[0]) ? Number(val.y[0]).toFixed(2) : val.y[0] ?? ''

            return (
              <TextContainer key={i} className='data-export' data-export-label={label} data-export-value={value}>
                <Label title={label}>{label}</Label>
                <Value>{value}</Value>
              </TextContainer>
            )
          })
        }
      </Wrapper>
    )
  }
}

export default sizeMe()(connector(TextWrapper as any) as any) as any
