type RecentlyUsedInfo = {
  projectId?: string
  simulationCaseId?: string
  casterDashboardConfigId?: string
}

export class Info {
  private static readonly recentlyUsedInfoKey = 'recentlyUsedInfo'

  public static getRecentlyUsedInfo (): RecentlyUsedInfo {
    return JSON.parse(localStorage.getItem(this.recentlyUsedInfoKey) ?? '{}')
  }

  public static setRecentlyUsedInfo (info: Partial<RecentlyUsedInfo>) {
    localStorage.setItem(this.recentlyUsedInfoKey, JSON.stringify({ ...this.getRecentlyUsedInfo(), ...info }))
  }

  public static removeRecentlyUsedInfo () {
    localStorage.removeItem(this.recentlyUsedInfoKey)
  }
}
