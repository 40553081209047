// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import deepMerge from 'deepmerge'
import { applyMiddleware, compose, createStore } from 'redux'

import { NetworkEvent } from '@/api/network-event'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info } from '@/logic/Info'
import { DefaultState } from '@/types/state'

import ApiClient from './apiClient'
import createMiddleware from './asyncMiddleware'
import makeRootReducer, { injectReducer } from './reducers'

window.meta = window.meta ?? {}

export default (initialState = {}) => {
  const middleware = [
    createMiddleware(ApiClient),
  ]

  let composeEnhancers = typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

  if (window.meta.DEV) {
    // eslint-disable-next-line no-underscore-dangle
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension
    }
  }

  const hor = (reducers) => (state: DefaultState = {} as DefaultState, action: BaseAction) => {
    const initialStateHOR = reducers({}, {})

    switch (action.type) {
      case 'UPDATE_WHOLE_STATE':
        if (action.data.caster) {
          NetworkEvent.setClientData({ casterId: action.data.caster.id ?? null })
        }

        return {
          ...state,
          ...action.data ?? {},
        }
      case 'ACTION_SWITCH_PROJECT':
        return {
          ...initialStateHOR,
          util: state.util,
          application: {
            ...initialStateHOR.application,
            main: {
              ...initialStateHOR.application.main,
              authenticationData: state.application.main.authenticationData,
              appState: state.application.main.appState,
              networkStatus: state.application.main.networkStatus,
            },
          },
          visualization: {
            ...initialStateHOR.visualization,
          },
        }
      case 'ACTION_RESET_STORE':
        // logout
        AuthDataStore.clear()

        Info.removeRecentlyUsedInfo()

        return {
          ...initialStateHOR,
          util: state.util,
        }
      default:
        return reducers(state, action)
    }
  }

  const reducers = makeRootReducer()

  const store = createStore(
    hor(makeRootReducer()),
    // deepMerge seems to be faster than lodash.merge, does it work the same?
    // https://measurethat.net/Benchmarks/Show/5136/0/lodash-merge-vs-deepmerge
    deepMerge(reducers({}, {}), initialState),
    composeEnhancers(applyMiddleware(...middleware)),
  )

  store.asyncReducers = {}

  store.injectReducer = params => {
    injectReducer(store, params)
  }

  // FIXME: webpack HMR is not working in Vite!
  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     // eslint-disable-next-line @typescript-eslint/no-var-requires
  //     const reducers = require('./reducers').default

  //     store.replaceReducer(reducers(store.asyncReducers))
  //   })
  // }

  return store
}
