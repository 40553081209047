import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Teleport from '@/Teleport'
import type { ElementsHashes } from '@/types/state'
import { Vector2D } from '@/types/visualization'

import TreeViewContextMenu from './tree/TreeViewContextMenu'
import { TreeViewNodes } from './TreeViewNodes'

const Tree = styled.div`
  height: calc(100vh - 50px - 16px);
`

type Props = {
  openDialogs: string[]
  elementsHashes: ElementsHashes
  name: string
  selectedPaths: Set<string>
  setTerm: (term: string, isCtrl?: boolean, isFilterClick?: boolean, isElementClick?: boolean) => void
  term: string
  target: string
  onSelect: (e: any, elementPath: string) => void
  setTarget: (target: string) => void
  setSelectedElementPaths: (elementPath?: string, ctrlKey?: boolean, shiftKey?: boolean) => void
  referenceTimestamp: Date
}

const TreeView: FC<Props> = ({
  onSelect,
  elementsHashes,
  selectedPaths,
  setTerm,
  setSelectedElementPaths,
  term,
  target,
  setTarget,
  openDialogs,
  referenceTimestamp,
}) => {
  const segmentGroups = elementsHashes.SegmentGroup

  if (!segmentGroups && Object.keys(segmentGroups).length) {
    return <Tree />
  }

  const [ mousePosition, setMousePosition ] = useState<Vector2D | null>(null)
  const tree = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleContextMenu (event: MouseEvent) {
      event.preventDefault()
      event.stopPropagation()

      setMousePosition({ x: event.clientX, y: event.clientY })
    }

    if (!tree.current) {
      return
    }

    tree.current.addEventListener('contextmenu', handleContextMenu, false)

    return function cleanup () {
      tree.current?.removeEventListener('contextmenu', handleContextMenu, false)
    }
  }, [])

  return (
    <Tree ref={tree}>
      <TreeViewNodes
        elementsHashes={elementsHashes}
        selectedPaths={selectedPaths}
        setTerm={setTerm}
        setSelectedElementPaths={setSelectedElementPaths}
        term={term}
        setTarget={setTarget}
        target={target}
        onSelect={onSelect}
        openDialogs={openDialogs}
        referenceTimestamp={referenceTimestamp}
      />
      <Teleport to='body'>
        <TreeViewContextMenu mousePosition={mousePosition} onClose={() => setMousePosition(null)} />
      </Teleport>
    </Tree>
  )
}

export default TreeView
