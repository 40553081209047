export enum CoolingLoopMountLogActionsEnum {
  ACTION_ADD_COOLING_LOOP_MOUNT_LOG = 'CoolingLoopMountLog/ACTION_ADD_COOLING_LOOP_MOUNT_LOG',
  UPDATE = 'CoolingLoopMountLog/UPDATE',
  RESET = 'CoolingLoopMountLog/RESET',
}

export function addCoolingLoopMountLog (coolingLoopMountLogHash: Record<number, CoolingLoopMountLog>) {
  return {
    type: CoolingLoopMountLogActionsEnum.ACTION_ADD_COOLING_LOOP_MOUNT_LOG,
    coolingLoopMountLogHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CoolingLoopMountLogReducers: Record<string, Function | undefined> = {
  [CoolingLoopMountLogActionsEnum.ACTION_ADD_COOLING_LOOP_MOUNT_LOG]: (
    _state: CoolingLoopMountLogHash,
    action: { coolingLoopMountLogHash: CoolingLoopMountLogHash },
  ) => (
    action.coolingLoopMountLogHash
  ),

  [CoolingLoopMountLogActionsEnum.UPDATE]: (
    state: CoolingLoopMountLogHash,
    action: { elements: CoolingLoopMountLogHash },
  ) => ({
    ...state,
    ...action.elements,
  }),

  [CoolingLoopMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: CoolingLoopMountLogHash = {},
  action: any,
) {
  const handler = CoolingLoopMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
