import { getElementFromPath } from '@/store/elements/logic/getters'
import ThreeUtil from '@/three/logic/Util'
import { StrandSides } from '@/types/elements/enum'

import SectionView from '.'
import MainView from '../MainView'
export default class CalculationUtil {
  public static getButtonCoordinates (x: number | null, y: number | null, name: string, buttons: any) {
    const buttonCoordinates: any = {}

    buttonCoordinates.x = x ?? buttons[name]?.position?.x ?? 0
    buttonCoordinates.y = y ?? buttons[name]?.position?.y ?? 0

    return buttonCoordinates
  }

  public static calcSectionPlane (
    base: number,
    large1: number,
    wide1: number,
    large2: number,
    wide2: number,
    margin: number,
  ) {
    return Math.max(
      base + large1 * 2,
      wide1 * 2,
      base + large2 * 2,
      wide2 * 2,
    ) + margin
  }

  public static calcCameraZoom (height: number, width: number, min: Coord, max: Coord) {
    return Math.min(width / (max.x - min.x), height / (max.y + 0.5 - min.y)) / 50 * 0.85
  }

  public static calcMousePosition (mouseValueOnCanvas: number, axisValue: number, dimension: number, isY = false) {
    const valueToAdd = isY ? 1 : -1
    let firstExpression = (mouseValueOnCanvas - axisValue) / dimension

    if (isY) {
      firstExpression = -firstExpression
    }

    return (firstExpression * 2 + valueToAdd)
  }

  public static getActualSegmentGroup (view: SectionView, currentPsslnPosition: number) {
    if (!currentPsslnPosition || view.jumpOver === null) {
      const id = MainView.numericIdMountLogMaps.SegmentGroupMountLog[0]
      const firstSGName = view.elementsHashes.SegmentGroupMountLog[id]?.name ?? ''

      return {
        id: 0,
        name: firstSGName,
      }
    }

    if (view.jumpOver === 'DataPoint') {
      const numericId = this.getActualSegmentGroupDataPointCase(view, currentPsslnPosition)
      const id = MainView.numericIdMountLogMaps.SegmentGroupMountLog[numericId]
      const name = view.elementsHashes.SegmentGroupMountLog[id]?.name ?? ''

      return {
        id,
        name,
      }
    }

    const elementTypeMountLogHash = view.elementsHashes[`${view.jumpOver}MountLog`]
    const mountLogs = Object.values(elementTypeMountLogHash ?? {})

    if (!mountLogs.length) {
      return {
        id: 0,
        name: '',
      }
    }

    const currentPsslnPositionMountLog = mountLogs.find(element => element.passlineCoord === currentPsslnPosition)
    // eslint-disable-next-line max-len
    const currentPath = (MainView.MountLogIdFullPathMap as any)[view.jumpOver]?.[currentPsslnPositionMountLog?.id]

    if (!currentPath) {
      return {
        id: 0,
        name: '',
      }
    }

    const { path: segmentPath } = ThreeUtil.getParentInfo(currentPath)
    const { id: segmentGroupId, path: segmentGroupPath } = ThreeUtil.getParentInfo(segmentPath)
    const segmentGroup = getElementFromPath(segmentGroupPath, view.elementsHashes)

    if (!segmentGroup || segmentGroupId === undefined) {
      return {
        id: 0,
        name: '',
      }
    }

    const segmentGroupName = segmentGroup.name ?? ''

    return {
      id: segmentGroupId,
      name: segmentGroupName,
    }
  }

  public static getCurrentFixedSideRollerNumber (view: SectionView, currentPsslnPosition: number) {
    const { SegmentMountLog, Segment, RollerMountLog } = view.elementsHashes
    const mountLogsInPasslineCoord = Object.values(RollerMountLog).filter(
      (element: any) => element.passlineCoord === currentPsslnPosition,
    )

    const fixedSideMountLog = mountLogsInPasslineCoord.find(rollerMountLog => {
      const segmentMountLog = SegmentMountLog[rollerMountLog.segmentMountLogId ?? '']
      const segment = Segment[segmentMountLog?.segmentId ?? '']

      if (!segment) {
        return false
      }

      const { side } = segment

      return side === StrandSides.Fixed
    })

    if (!fixedSideMountLog) {
      return SectionView.currentFixedSideRollerNumber
    }

    const { id } = fixedSideMountLog

    return MainView.numericIdMountLogMaps.RollerMountLog[id] ?? SectionView.currentFixedSideRollerNumber
  }

  // calculate get actual segment group when jumping over data points

  private static getActualSegmentGroupDataPointCase (view: SectionView, currentPsslnPosition: number): number {
    // search for the nearest nozzle or roller to the current pssln position
    const nozzles = Object.values(view.elementsHashes.Nozzle)
    const rollers = Object.values(view.elementsHashes.Roller)
    const segmentGroups = Object.values(view.elementsHashes.SegmentGroup)

    const { element: nozzle, difference: d1 } = CalculationUtil.findNearestElementToPassLn(
      nozzles,
      currentPsslnPosition,
    )
    const { element: roller, difference: d2 } = CalculationUtil.findNearestElementToPassLn(
      rollers,
      currentPsslnPosition,
    )
    const { element: SegmentGroup, difference: d3 } = CalculationUtil.findNearestElementToPassLn(
      segmentGroups,
      currentPsslnPosition,
    )

    if (!nozzle && !roller && !SegmentGroup) {
      return 0
    }

    if (d3 < d2 && d3 < d1 && SegmentGroup) {
      return SegmentGroup._id
    }

    if (d1 < d2 && nozzle) {
      return 0
      // return view.elementsHashes.Segment[segmentId]?.['#parent']?.id ?? 1 // FIXME
    }

    if (!roller) {
      return 0
    }

    return 0
    // return view.elementsHashes.Segment[segmentId]?.['#parent']?.id ?? 1 // FIXME
  }

  private static findNearestElementToPassLn (elements: any[], currentPasslnPosition: number) {
    let minDistance = Infinity
    let index = 0
    const elementCount = elements.length

    for (let i = 0; i < elementCount; i++) {
      const element = elements[i]
      const distance = Math.abs(currentPasslnPosition - element.passlineCoord)

      if (distance < minDistance) {
        minDistance = distance
        index = i
      }
    }

    return { element: elements[index] || null, difference: minDistance }
  }
}
