import hotkeys from 'hotkeys-js'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { setVisualizationConfig } from '@/api/visualization-config'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import PlotContent from './PlotContent'
import { Dialog, DialogBackground, Header, HeaderTitle, I } from './Styles'

const connector = connect(({ visualization, application }: DefaultState) => ({
  appState: application.main.appState,
  ...visualization, // TODO: connect the single values don't just use spread!
}), {
  showPlotList: VisualizationActions.showPlotList,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

class PlotConfigurator extends Component<Props> {
  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const {
      appState,
      visualizationMetaInformation,
      plotConfigs,
      tileConfigs,
      viewsObject,
      showPlotList,
      setConfig,
    } = this.props

    if (showPlotList) {
      showPlotList()
    }

    const data = { viewsObject, plotConfigs, tileConfigs }

    setVisualizationConfig(visualizationMetaInformation?.[appState]?.config, data)
      .then(() => {
        setConfig(data)
      })
  }

  public override render () {
    const { t } = this.props

    return (
      <div>
        <DialogBackground />
        <Dialog>
          <div>
            <Header title={t('plotConfigurator.title')}>
              <I className='pe-7s-graph2' $left />
              <HeaderTitle>{t('plotConfigurator.label')}</HeaderTitle>
              <I className='pe-7s-close' onClick={this.handleClose} title={t('plotConfigurator.close')} />
            </Header>
            <PlotContent />
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(PlotConfigurator as any) as any) as any
