export enum passlineActionsEnum {
  ACTION_ADD_PASSLINE = 'Passline/ACTION_ADD_PASSLINE ',
  DELETE = 'Passline/DELETE',
  RESET = 'Passline/RESET',
}

export function addPassline (passline: Passline) {
  return {
    type: passlineActionsEnum.ACTION_ADD_PASSLINE,
    passline,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const passlineReducers: Record<string, Function | undefined> = {
  [passlineActionsEnum.ACTION_ADD_PASSLINE]: (_state: Passline, action: { passline: Passline }) => action.passline,

  [passlineActionsEnum.DELETE]: (_state: Passline) => null,

  [passlineActionsEnum.RESET]: () => null,
}

export default function (state: Passline | null = null, action: any) {
  const handler = passlineReducers[action.type]

  return handler ? handler(state, action) : state
}
