/* eslint-env browser */

import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { setDefaultDashboard } from '@/api/case'
import {
  getGroupVisualizationConfigFile,
  getVisualizationConfig,
  getVisualizationConfigs,
  uploadVisualizationConfig,
} from '@/api/visualization-config'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { Info } from '@/logic/Info'
import Button from '@/react/components/Button/index'
import Logic from '@/react/dialogs/project/ProjectDataDialog/Logic'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as FilterActions from '@/store/filter/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { EditConfigDialog } from './EditConfigDialog'
import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  darkTheme: state.application.main.darkTheme,
  authenticationData: state.application.main.authenticationData,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  openDialog: ApplicationActions.openDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditConfigId: ApplicationActions.setEditConfigId,
  closeDialog: ApplicationActions.closeDialog,
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setAppState: ApplicationActions.setAppState,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  setDefaultCasterDashboardConfig: ApplicationActions.setDefaultCasterDashboardConfig,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  resetViewObject: VisualizationActions.resetViewObject,
  resetVisualization: VisualizationActions.resetVisualization,
  setError: ErrorActions.setError,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar(message: React.ReactNode | string, options?: OptionsObject): OptionsObject['key'] | null
  t(key: string, params?: Record<string, unknown>): string
}

type VisualizationConfigWithGroupFlag = VisualizationConfig & { isGroupDashboard?: boolean }

type State = {
  loading: any
  selectedConfig: string
  configs: VisualizationConfigWithGroupFlag[]
}

const T = 'selectDashboardDialog'

export class SelectDashboardDialog extends Component<Props, State> {
  @Identifiable('SelectDashboardDialog') public static readonly NAME: string

  public constructor (props: Props) {
    super(props)

    Logic.init(this)
  }

  public override state: State = {
    loading: {
      openVis: false,
    },
    selectedConfig: 'default',
    configs: [] as VisualizationConfigWithGroupFlag[],
  }

  public override async componentDidMount () {
    hotkeys('Escape', this.handleClose)

    const { visualizationMetaInformation, currentSimulationCase } = this.props
    const projectId = currentSimulationCase.projectId
    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    const configs = await getVisualizationConfigs(projectId, AppState.Caster, { nameOnly: true }) ?? []

    this.setState({
      selectedConfig: config,
      configs,
    })
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectDashboardDialog.NAME)
  }

  private readonly handleChangeConfig = (event: any) => {
    const { setError } = this.props
    const { value } = event.target

    if (value === 'add') {
      try {
        Logic.handleUploadVisualizationConfig(
          (type: string, data: any, _appState: any) => {
            this.setState({
              selectedConfig: data.id,
              configs: [ ...this.state.configs, { name: data.name, id: data.id } as VisualizationConfig ],
            })
          },
          setError,
          AppState.Caster,
        )
      }
      catch (e) {}

      return
    }

    this.setState({
      selectedConfig: value,
    })
  }

  private readonly handleLoadConfig = async () => {
    const {
      setTerm,
      currentSimulationCase,
      setConfig,
      setDataSources,
      resetViewObject,
      setAppState,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      enqueueSnackbar,
    } = this.props

    const { configs } = this.state
    let dashboardConfigId = this.getSelectedConfig()

    this.setState({ loading: { openVis: true } })

    if (!dashboardConfigId || dashboardConfigId === 'default') {
      const fileName = `config_${Date.now()}.json`
      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }
      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      const visualizationConfig = await uploadVisualizationConfig(
        file,
        currentSimulationCase.projectId,
        AppState.Caster,
      )

      if (!visualizationConfig) {
        // setError('openDashboard', error.status) // FIXME: error.status is undefined

        this.setState({ loading: { openVis: false } })

        return
      }

      dashboardConfigId = visualizationConfig.id
    }

    const configObject = configs.find(({ id }) => id === dashboardConfigId)
    const isGroupDashboard = configObject?.isGroupDashboard

    resetViewObject()
    this.handleChangeConfig({ target: { value: dashboardConfigId } })
    setVisualizationMetaInformation('config', dashboardConfigId, AppState.Caster)
    setAppState(AppState.Caster)

    Info.setRecentlyUsedInfo({ casterDashboardConfigId: dashboardConfigId })

    if (isGroupDashboard) {
      const visualizationConfig = await getGroupVisualizationConfigFile(configObject.id)

      if (!visualizationConfig) {
        // setError('openDashboard', error.status) // FIXME: error.status is undefined

        this.setState({ loading: { openVis: false } })

        return
      }

      const { viewsObject, plotConfigs, tileConfigs } = visualizationConfig as any

      if (!viewsObject || !plotConfigs || !tileConfigs) {
        enqueueSnackbar(
          'Error opening dashboard, file could be malformed',
          { autoHideDuration: 3000, variant: 'error' },
        )

        return
      }

      setConfig({
        viewsObject,
        plotConfigs,
        tileConfigs,
      })

      setDataSources(visualizationConfig.dataSources ?? [])
    }
    else {
      const visualizationConfig = await getVisualizationConfig(dashboardConfigId, { emptySelectedComparisonCasters: 1 })

      if (!visualizationConfig) {
        // setError('openDashboard', error.status) // FIXME: error.status is undefined

        this.setState({ loading: { openVis: false } })

        return
      }

      if (!visualizationConfig.data?.viewsObject) {
        enqueueSnackbar(
          'Error opening dashboard, file could be malformed',
          { autoHideDuration: 3000, variant: 'error' },
        )

        return
      }

      setConfig(visualizationConfig.data)
      setDataSources(visualizationConfig.dataSources ?? [])
    }

    setTerm('')

    this.handleClose()

    setSimpleDashboardTabIndex(1)
  }

  private readonly handleDelete = (type: string, id: string) => {
    const {
      enqueueSnackbar,
      visualizationMetaInformation,
      resetVisualization,
      setSimpleDashboardTabIndex,
      currentSimulationCase,
    } = this.props
    const { config: currentConfigId } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    if (currentSimulationCase.defaultVisualizationConfigId === id) {
      this.handleSetDefault(id)
    }

    ApiClient
      .del(`${useConfig().apiBaseURL}/visualization-configs/${id}`)
      .then(() => {
        // this.handleClose()
        if (currentConfigId === id) {
          // FIXME
          resetVisualization()
          setSimpleDashboardTabIndex(0)
        }

        this.setState({
          configs: this.state.configs.filter(config => config.id !== id),
        })
        // handle when it is the current config
      })
      .catch((error) => {
        enqueueSnackbar('Error deleting visualization config', { autoHideDuration: 3000, variant: 'error' })
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  private readonly handleDownloadConfig = async (key: string) => {
    const { darkTheme } = this.props

    const visualizationConfig = await getVisualizationConfig(key)

    if (!visualizationConfig) {
      return
    }

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme, dataSources: visualizationConfig.dataSources }),
      visualizationConfig.name,
    )
  }

  private readonly handleEditConfig = (key: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  }

  private readonly handleSetDefault = async (key: string) => {
    const { setDefaultCasterDashboardConfig, currentSimulationCase } = this.props
    const setUndefined = currentSimulationCase.defaultVisualizationConfigId === key

    await setDefaultDashboard(currentSimulationCase.id, setUndefined ? undefined : key)

    setDefaultCasterDashboardConfig(setUndefined ? undefined : key)
  }

  private readonly getSelectedConfig = () => {
    const { selectedConfig, configs } = this.state
    const {
      authenticationData: { casterDashboardConfigs },
    } = this.props

    if (casterDashboardConfigs) {
      casterDashboardConfigs.forEach(config => {
        if (!configs.find(({ id }) => id === config.id)) {
          configs.push({ id: config.id, name: config.fileName, isGroupDashboard: true } as any)
        }
      })
    }

    return Logic.getSelectedConfig(selectedConfig, configs)
  }

  public override render () {
    const {
      t,
      currentSimulationCase: { defaultVisualizationConfigId },
      visualizationMetaInformation,
      authenticationData,
      featureFlags,
    } = this.props

    const { configs } = this.state

    const canUploadConfig = FeatureFlags.canUploadCasterDashboardConfig(featureFlags)
    const canCreateConfig = FeatureFlags.canCreateCasterDashboardConfig(featureFlags)
    const canEditConfig = FeatureFlags.canEditCasterDashboardConfig(featureFlags)
    const canDeleteConfig = FeatureFlags.canDeleteCasterDashboardConfig(featureFlags)
    const canDownloadConfig = FeatureFlags.canDownloadCasterDashboard(featureFlags)
    const defaultConfigSelector = canCreateConfig
      ? {
        value: t(`${T}.config.default`),
        disabled: false,
        hideActions: true,
      }
      : null

    const configSelectors = Logic.getSelectors(configs, 'id', 'name', defaultConfigSelector)

    if (authenticationData.casterDashboardConfigs) {
      authenticationData.casterDashboardConfigs.forEach(config => {
        if (!configSelectors.find(({ key }) => key === config.id)) {
          configSelectors.push({
            key: config.id,
            value: config.fileName,
            notRemovable: true,
          } as any)
        }
        else {
          const selector = configSelectors.find(({ key }) => key === config.id)

          if (selector) {
            selector.notRemovable = true
          }
        }
      })
    }

    if (canUploadConfig) {
      configSelectors.push({
        key: 'add',
        value: t(`${T}.config.add`),
        notRemovable: true,
        hideActions: true,
      })
    }

    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}
    const currentConfig = configSelectors.find(({ key }) => key === config)

    if (currentConfig) {
      currentConfig.value = <span>{currentConfig.value} (current)</span>
    }

    Logic.markDefaultDashboardConfig(
      configSelectors,
      defaultVisualizationConfigId,
      authenticationData.defaultCasterDashboardConfig?.id,
    )

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-server'
        header={t(`${T}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
        contentMinHeight={100}
      >
        <Form $minHeight={100}>
          <Input
            name='config'
            type='select'
            title={FeatureFlags.canViewCasterDashboard(featureFlags) ? t(`${T}.config.title`) : 'Unauthorized'}
            value={this.getSelectedConfig()}
            selectors={configSelectors}
            noDeleteCurrent
            onChange={this.handleChangeConfig}
            onDelete={canDeleteConfig && this.handleDelete}
            onDownload={canDownloadConfig && this.handleDownloadConfig}
            onEdit={canEditConfig && this.handleEditConfig}
            disabled={!FeatureFlags.canViewCasterDashboard(featureFlags)}
            onSetDefault={this.handleSetDefault}
          />
          <Button id={DialogID.SelectDashboard.LoadConfigButton} onClick={this.handleLoadConfig}>
            {t(`${T}.config.btn`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(SelectDashboardDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
