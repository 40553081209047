export enum loopAssignmentActionsEnum {
  ACTION_ADD_LOOP_ASSIGNAMENT = 'LoopAssignment/ACTION_ADD_LOOP_ASSIGNAMENT',
  DELETE = 'LoopAssignment/DELETE',
  DELETE_MULTIPLE = 'LoopAssignment/DELETE_MULTIPLE',
  UPDATE = 'LoopAssignment/UPDATE',
  RESET = 'LoopAssignment/RESET',
}

export function addLoopAssignment (loopAssignmentHash: Record<number, LoopAssignment>) {
  return {
    type: loopAssignmentActionsEnum.ACTION_ADD_LOOP_ASSIGNAMENT,
    loopAssignmentHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const loopAssignmentReducers: Record<string, Function | undefined> = {
  [loopAssignmentActionsEnum.ACTION_ADD_LOOP_ASSIGNAMENT]: (_state: any, action: { loopAssignmentHash: any }) => (
    action.loopAssignmentHash
  ),

  [loopAssignmentActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [loopAssignmentActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [loopAssignmentActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),

  [loopAssignmentActionsEnum.RESET]: () => ({}),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = loopAssignmentReducers[action.type]

  return handler ? handler(state, action) : state
}
