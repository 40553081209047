import CircularProgress from '@material-ui/core/CircularProgress'
import isEqual from 'lodash/isEqual'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import SplitterLayout from 'react-splitter-layout'
import styled, { ThemeProvider } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { NetworkStatus } from '@/api/network-event'
import { getVisualizationConfig, setVisualizationConfig } from '@/api/visualization-config'
import IpcManager from '@/IpcManager'
import { OpenProjectDialog } from '@/react/dialogs/project/OpenProjectDialog'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import FeatureFlags from '@/react/FeatureFlags/index'
import Icon from '@/react/specific/Icon'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

import StatusBar from './Statusbar'
import { DashboardWrapper, NetworkStatusDisplay, NoData } from './styles'
import StyleConfig from '../config/StyleConfig'
import ConfigDialogContent from '../ConfigDialogContent'
import ContextMenu from '../ContextMenu'
import AddDerivedPlotDialog from '../Dialogs/AddDerivedPlotDialog'
import AddPlotDialog from '../Dialogs/AddPlotDialog'
import DeleteDashboardDialog from '../Dialogs/DeleteDashboardDialog'
import DeletePlotDialog from '../Dialogs/DeletePlotDialog'
import { Dialog, DialogBackground } from '../Dialogs/DialogStyles'
import EditDashboardDialog from '../Dialogs/EditDashboardDialog'
import PlotExportDialog from '../Dialogs/PlotExportDialog'
import SelectSourceDialog from '../Dialogs/SelectSourceDialog'
import View from '../View'

const Spinner = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 48px - 20px);
  left: calc(50% - 20px);
`

const connector = connect((state: DefaultState) => ({
  viewsObject: state.visualization.viewsObject,
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  openConfigDialogWindow: state.visualization.openConfigDialogWindow,
  openDeleteDialogWindow: state.visualization.openDeleteDialogWindow,
  openAddPlotDialogWindow: state.visualization.openAddPlotDialogWindow,
  openDashboardWindow: state.visualization.openDashboardWindow,
  openDerivePlotDialog: state.visualization.openDerivePlotDialog,
  dashboardToDelete: state.visualization.dashboardToDelete,
  data: state.visualization.data,
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  loadingStatus: state.visualization.loadingStatus,
  dataSource: state.visualization.dataSource,
  plotExport: state.visualization.plotExport,
  isEditModeOn: state.visualization.isEditModeOn,
  networkStatus: state.application.main.networkStatus,
  darkTheme: state.application.main.darkTheme,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
}), {
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setSecondarySize: VisualizationActions.setSecondarySize,
  setLoadingButtonStatus: VisualizationActions.setLoadingButtonStatus,
  openSelectSourceDialog: VisualizationActions.openSelectSourceDialog,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  setAppState: ApplicationActions.setAppState,
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  currentMainView: string | null | undefined
  error: number | string | null
  loading: boolean
}

class Dashboard extends Component<Props, State> {
  private setTimeout?: number

  public override state: State = {
    currentMainView: null,
    error: null,
    loading: false,
  }
  
  public override componentDidMount () {
    const { viewsObject, setLoadingButtonStatus } = this.props
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if (viewsObjectKeys.length) {
      this.setState({
        currentMainView: viewsObjectKeys[0],
      })
    }

    if (!viewsObjectKeys.length) {
      const viewId = `view_${uuid()}`

      this.setState({
        currentMainView: viewId,
      })
    }

    IpcManager.both.on('setLoading', (event: any, loadingStatus: boolean, type: string) => {
      setLoadingButtonStatus(loadingStatus, type)
    })

    this.handleAppStateChange({ appState: AppState.Caster })
  }
  
  public override componentDidUpdate (prevProps: Props, prevState: State) {
    const { viewsObject, featureFlags, setAppState, tileConfigs, appState } = this.props

    const { currentMainView } = prevState
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if (!viewsObjectKeys.length && !currentMainView) {
      const viewId = `view_${uuid()}`

      this.setState({
        currentMainView: viewId,
      })
    }

    if (!isEqual(prevProps.viewsObject, viewsObject) && Object.keys(tileConfigs ?? {}).length) {
      clearTimeout(this.setTimeout)

      const { appState, visualizationMetaInformation, plotConfigs, viewsObject } = this.props

      this.setTimeout = window.setTimeout(async () => {
        const dashboardConfigId = visualizationMetaInformation?.[appState]?.config
        const data = { viewsObject, plotConfigs, tileConfigs }

        await setVisualizationConfig(dashboardConfigId, data)
      }, 1000)
    }

    if (
      (appState === AppState.ParamDashboard && !FeatureFlags.canViewParameterDashboard(featureFlags)) ||
      (appState === AppState.ResultDashboard && !FeatureFlags.canViewResultDashboard(featureFlags))
    ) {
      setAppState(AppState.Caster)
    }

    if (
      (typeof currentMainView === 'string' && !viewsObjectKeys.includes(currentMainView)) &&
      (viewsObjectKeys.length)
    ) {
      this.setState({
        currentMainView: viewsObjectKeys[0],
      })
    }

    this.handleAppStateChange(prevProps)
  }

  private readonly handleAppStateChange = async (prevProps: any) => {
    const {
      visualizationMetaInformation,
      openDialog,
      currentProject,
      currentSimulationCase,
      appState,
      isLoggedIn,
    } = this.props

    const { data, config } = visualizationMetaInformation?.[appState] ?? {}

    if (isLoggedIn && prevProps.appState !== appState) {
      if (currentProject?.id && currentSimulationCase?.id) {
        if (
          (appState === AppState.ResultDashboard && (!data || data === 'default' || !config)) ||
          (appState === AppState.ParamDashboard && !config)
        ) {
          openDialog(ProjectDataDialog.NAME)
        }
        else {
          await this.handleOpenVisualization()
        }
      }
      else {
        openDialog(OpenProjectDialog.NAME)
      }
    }
  }

  private readonly handleOpenVisualization = async () => {
    const { appState, visualizationMetaInformation, setConfig, setDataSources, currentSimulationCase } = this.props
    const { config, data } = visualizationMetaInformation?.[appState] ?? {}

    this.setState({
      error: null,
      loading: true,
    })

    const visualizationConfig = await getVisualizationConfig(config)

    if (!visualizationConfig) {
      this.setState({
        // error: error.status, // FIXME: error.status is undefined
        loading: false,
      })

      return
    }

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources ?? [])

    const { dataId } = currentSimulationCase.visualizationDataList?.[0] ?? {}

    if (Boolean(data) || Boolean(dataId)) {
      const { visualizationData } = await ApiClient
        .get(`${'Network.URI(deprecated)'}/visualization_data/${data ?? dataId}`)
        .catch((error) => {
          this.setState({
            error: error.status,
            loading: false,
          })

          return {}
        })

      if (!visualizationData) {
        this.setState({
          loading: false,
        })

        return
      }

      IpcManager.internal.send('VisualizationData', null, visualizationData)
    }

    this.setState({
      loading: false,
    })
  }

  private readonly handleSecondaryPaneSizeChange = (viewId: string, size: number) => {
    const { setSecondarySize, isEditModeOn } = this.props

    if (isEditModeOn) {
      setSecondarySize(viewId, size)
    }
  }

  private readonly handleCloseDataSourceDialog = () => {
    const { openSelectSourceDialog } = this.props

    openSelectSourceDialog(false)
  }

  private readonly handleClosePlotExportDialog = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog(false)
  }

  private readonly handleOpenProjectDialog = () => {
    const { openDialog } = this.props

    openDialog(ProjectDataDialog.NAME)
  }

  private readonly handleFixData = () => {
    ApiClient.get(`${'Network.URI(deprecated)'}/visualization_fix/data`)
  }

  private readonly getSplitLayout = (currentId: string) => {
    const { viewsObject, isEditModeOn } = this.props
    const currentSplitData = viewsObject[currentId]?.split

    if (!currentSplitData) {
      return (
        <div data-id={currentId} className={isEditModeOn ? 'editable' : ''}>
          <View viewId={currentId} view={viewsObject[currentId]} />
        </div>
      )
    }

    if (currentSplitData.vertical) {
      return (
        <SplitterLayout
          vertical
          secondaryInitialSize={currentSplitData.size}
          onSecondaryPaneSizeChange={this.handleSecondaryPaneSizeChange.bind(this, currentId)}
          customClassName={isEditModeOn ? 'editable' : ''}
          primaryMinSize={15}
          secondaryMinSize={15}
          percentage
        >
          {this.getSplitLayout(currentSplitData.vertical[0])}
          {this.getSplitLayout(currentSplitData.vertical[1])}
        </SplitterLayout>
      )
    }

    if (currentSplitData.horizontal) {
      return (
        <SplitterLayout
          secondaryInitialSize={currentSplitData.size}
          onSecondaryPaneSizeChange={this.handleSecondaryPaneSizeChange.bind(this, currentId)}
          customClassName={isEditModeOn ? 'editable' : ''}
          primaryMinSize={15}
          secondaryMinSize={15}
          percentage
        >
          {this.getSplitLayout(currentSplitData.horizontal[0])}
          {this.getSplitLayout(currentSplitData.horizontal[1])}
        </SplitterLayout>
      )
    }
  }
  
  public override render () {
    const {
      openConfigDialogWindow,
      openDeleteDialogWindow,
      openAddPlotDialogWindow,
      openDerivePlotDialog,
      dashboardToDelete,
      loadingStatus,
      dataSource,
      plotExport,
      openDashboardWindow,
      networkStatus,
      darkTheme,
      plotConfigs,
      t,
      isLoggedIn,
      appState,
      visualizationMetaInformation,
    } = this.props
    const { currentMainView, loading } = this.state

    let icon

    switch (networkStatus) {
      case NetworkStatus.Connected:
        icon = <Icon fixedWidth icon='check' />
        break
      case NetworkStatus.Connecting:
        icon = <Icon fixedWidth icon='sync-alt' spin />
        break
      case NetworkStatus.Disconnected:
        icon = <Icon fixedWidth icon='times' />
        break
      default:
        icon = <Icon fixedWidth icon='jedi' title='!sith' />
        break
    }

    if (loading) {
      return (
        <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
          <div>
            <StatusBar />
            <DashboardWrapper>
              <Spinner disableShrink />
              <NetworkStatusDisplay title={networkStatus} className={networkStatus}>
                {icon}
              </NetworkStatusDisplay>
            </DashboardWrapper>
          </div>
        </ThemeProvider>
      )
    }

    const isParamDB = appState === AppState.ParamDashboard
    const isResultDB = appState === AppState.ResultDashboard
    const { config } = visualizationMetaInformation?.[appState] ?? {}
    const noData = plotConfigs && Object.keys(plotConfigs).length === 0

    if (isLoggedIn && ((noData && isResultDB) || (!config && isParamDB))) {
      return (
        <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
          <div>
            <StatusBar />
            <DashboardWrapper>
              <NoData onClick={this.handleOpenProjectDialog}>
                {dataSource && <SelectSourceDialog onCloseDataSourceDialog={this.handleCloseDataSourceDialog} />}
                {t('dashboard.noData.heading')}
                <br />
                {t('dashboard.noData.secondHeading')}
              </NoData>
            </DashboardWrapper>
          </div>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <StatusBar />
          <DashboardWrapper>
            {dataSource && <SelectSourceDialog onCloseDataSourceDialog={this.handleCloseDataSourceDialog} />}
            {plotExport && <PlotExportDialog onClosePlotExportDialog={this.handleClosePlotExportDialog} />}
            {
              openConfigDialogWindow &&
              (
                <div>
                  <DialogBackground />
                  {/* TODO: Adjust width */}
                  <Dialog $width='850px' $height='450px' $noBottomBorder>
                    <ConfigDialogContent />
                  </Dialog>
                </div>
              )
            }
            {openDeleteDialogWindow && <DeletePlotDialog />}
            {openAddPlotDialogWindow && <AddPlotDialog />}
            {openDerivePlotDialog && <AddDerivedPlotDialog />}
            {openDashboardWindow && <EditDashboardDialog />}
            {Object.keys(dashboardToDelete ?? {}).length > 0 && <DeleteDashboardDialog />}
            {!loadingStatus.openVisualizationConfig && currentMainView && this.getSplitLayout(currentMainView)}
            <ContextMenu />
            <NetworkStatusDisplay title={networkStatus} className={networkStatus} onClick={this.handleFixData}>
              {icon}
            </NetworkStatusDisplay>
          </DashboardWrapper>
        </div>
      </ThemeProvider>
    )
  }
}

export default withNamespaces('visualization')(connector(Dashboard as any) as any) as any
