export enum SupportPointMountLogActionsEnum {
  ACTION_ADD_SUPPORT_POINT_MOUNT_LOG = 'SupportPointMountLog/ACTION_ADD_SUPPORT_POINT_MOUNT_LOG',
  UPDATE = 'SupportPointMountLog/UPDATE',
  RESET = 'SupportPointMountLog/RESET',
}

export function addSupportPointMountLog (supportPointHash: SupportPointMountLogHash) {
  return {
    type: SupportPointMountLogActionsEnum.ACTION_ADD_SUPPORT_POINT_MOUNT_LOG,
    supportPointHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SupportPointMountLogReducers: Record<string, Function | undefined> = {
  [SupportPointMountLogActionsEnum.ACTION_ADD_SUPPORT_POINT_MOUNT_LOG]: (
    _state: SupportPointMountLogHash,
    action: { supportPointHash: SupportPointMountLogHash },
  ) => (
    action.supportPointHash
  ),

  [SupportPointMountLogActionsEnum.UPDATE]: (
    state: SupportPointMountLogHash,
    action: { elements: SupportPointMountLogHash },
  ) => ({
    ...state,
    ...action.elements,
  }),

  [SupportPointMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: SupportPointMountLogHash = {},
  action: any,
) {
  const handler = SupportPointMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
