import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import Icon from '@/react/specific/Icon'
import { getElementsHashesObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { DefaultState } from '@/types/state'

import { Filter, NameBoxForModules } from './Module'
import { Trigger } from '../type/TypeHeaderStyles'

const StyledModuleType = styled.div`${() =>
  css`
width: 100%;
color: #CCCCCC;
border-top: solid 1px #000000;
border-bottom: solid 1px #000000;
margin-bottom: -1px;
position: relative;
background-color: #4f4f4f;
`}`

const StyledElement = styled.div`${() =>
  css`
width: 100%;
color: #CCCCCC;
border-top: solid 1px #000000;
border-bottom: solid 1px #000000;
margin-bottom: -1px;
position: relative;
background-color: #636363;
`}`

const connector = connect((state: DefaultState) => ({
  moduleMap: state.modules,
  term: state.filter.term,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  elementsHashes: getElementsHashesObject(state),
}), {
  setTerm: FilterActions.setTerm,
  // setSelectedElementPaths: DataActions.setSelectedElementPaths, // FIXME
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: string
  module: string
}

type State = {
  isOpen: boolean
}

export class ModuleType extends Component<Props, State> {
  public override state: State = { isOpen: false }

  private readonly handleFilter = (newTerm: string) => {
    const { setTerm, term } = this.props

    if (term === newTerm) {
      setTerm('')
    }
    else {
      setTerm(newTerm)
    }

    // FIXME: @c.bentele setSelectedElementPaths works now, please check if this logic can be used!
    // setSelectedElementPaths()
  }

  private readonly handleFilterPerType = () => {
    const { type, module } = this.props

    this.handleFilter(`${type}#module=${module}`)
  }

  private readonly handleToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  private getElement (id: string) {
    const { type, elementsHashes } = this.props

    return (elementsHashes as any)[type][id]
  }

  private getElementComponent (element: any) {
    const { type, term } = this.props
    const filterActive = term === `${type}:${element._id}`
    const elementTypeAndId = `${type}:${element._id}`

    return (
      <StyledElement key={`${type}_${element._id}`} title={element._name || elementTypeAndId}>
        <NameBoxForModules $pad={60} onClick={() => false}>
          <span>{element._name || elementTypeAndId}</span>
        </NameBoxForModules>
        <Filter onClick={() => this.handleFilter(`${type}:${element._id}`)} $active={filterActive}>
          <Icon icon='filter' title='Filter Element' />
        </Filter>
      </StyledElement>
    )
  }

  private readonly getIsFilterActive = () => {
    const { module, type, term } = this.props

    return term === `${type}#module=${module}`
  }

  public override render () {
    const { type, module, moduleMap, featureFlags } = this.props
    // https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    const elements = Object
      .keys(moduleMap[module][type])
      .map(id => this.getElement(id))
      .sort((a, b) => collator.compare(a._name ?? '', b._name ?? ''))
    const { isOpen } = this.state
    const isFilterActive = this.getIsFilterActive()
    const canFilterPerModuleType = FeatureFlags.canFilterPerModuleElementType(featureFlags)

    return (
      <div>
        <StyledModuleType title={type}>
          <NameBoxForModules $pad={40} onClick={this.handleToggleOpen}>
            {type}
          </NameBoxForModules>
          {
            canFilterPerModuleType && (
              <Filter onClick={this.handleFilterPerType} $active={isFilterActive}>
                <Icon icon='filter' title='Filter Element Type' />
              </Filter>
            )
          }
          <Trigger onClick={this.handleToggleOpen}>
            <i className={`pe-7s-angle-${isOpen ? 'down' : 'right'}`} />
          </Trigger>
        </StyledModuleType>
        {isOpen && elements.map(element => (this.getElementComponent(element)))}
      </div>
    )
  }
}

export default connector(ModuleType as any) as any
