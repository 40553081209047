import { type ChangeEventHandler, useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import styled, { css } from 'styled-components'

import DataActions from '@/store/data/actions'
import ThreeUtil from '@/three/logic/Util'
import { DefaultState } from '@/types/state'
import { SetUtil } from '@/Util/SetUtil'

const ChildrenSelectorWrapper = styled.div<{ $checked: boolean }>`${({ $checked }) => css`
  display: ${$checked ? 'inline-flex' : 'none'};
  width: 25px;
  text-align: center;
  place-content: center;
`}`

const connector = connect(
  (state: DefaultState) => ({
    selectedPaths: state.data.selectedPaths,
    allPaths: state.data.allPaths,
    allChildrenSelectedPaths: state.data.allChildrenSelectedPaths,
  }),
  {
    setSelectedElementPaths: DataActions.setSelectedElementPaths,
  },
)

type Props = ConnectedProps<typeof connector> & {
  path: string
  selectType?: CasterElementNames
}

const ChildrenSelector: React.FC<Props> = (props: Props) => {
  const { path, selectType, allChildrenSelectedPaths, allPaths, selectedPaths, setSelectedElementPaths } = props
  const { type } = ThreeUtil.getElementInfo(path)

  if (type === 'SensorPoint' || selectType === 'SensorPoint') {
    return null
  }

  const selfAndChildPaths = useMemo(
    () => {
      const term = path + (selectType ? `/${selectType}:` : '/')
      const set = SetUtil.filter(allPaths, (path) => path.startsWith(term))

      if (!selectType) {
        set.add(path)
      }

      return set
    },
    [ allPaths, path, selectType ],
  )

  const pathsToSelect = useMemo(
    () => SetUtil.filter(selfAndChildPaths, (path) => !selectedPaths.has(path)),
    [ selfAndChildPaths, selectedPaths ],
  )

  const isChecked = allChildrenSelectedPaths.has(path + (selectType ? `/${selectType}-Selector` : ''))

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation()

    const paths = !isChecked ? pathsToSelect : selfAndChildPaths

    setSelectedElementPaths(Array.from(paths), true)
  }

  return (
    <ChildrenSelectorWrapper className='children-selector' $checked={isChecked}>
      <input type='checkbox' onChange={handleChange} checked={isChecked} />
    </ChildrenSelectorWrapper>
  )
}

export default connector(ChildrenSelector)
