import { ElementsHashes } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

import FilterHandler, { type Filter, type FilterFunction } from './FilterHandler'
import Util from './Util'

export class CompareFilterHandler {
  private static filterCache: Record<string, { timestamp: number, data: Record<string, FilterableElementType> }> = {}

  private static filteredElementsByCaseId: {
    [caseId: string]: {
      [term: string]: {
        [path: string]: FilterableElementType
      }
    }
  } = {}

  private static handleCompareElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    term: string,
    filters: Filter<Element, MountLog> | Filter<Element, MountLog>[],
    path: string,
    type: FilterableElementType,
    element: any,
    caseId: string,
  ) {
    let hit = false

    if (term.includes('@') && CompareFilterHandler.filteredElementsByCaseId[caseId]?.[term]?.[path]) {
      hit = true
    }

    const parentInfo = path ? Util.getParentInfo(path) : null
    // eslint-disable-next-line max-len
    const isParentVisible = Boolean(
      CompareFilterHandler.filteredElementsByCaseId[caseId]?.[term]?.[parentInfo?.path ?? ''],
    )
    const arrayOfFilters = filters instanceof Array ? filters : [ filters ]

    arrayOfFilters.forEach((filterElement, index) => {
      const allowed = index && filters instanceof Array
        ? filters[index - 1].type !== type
        : true
      const isChild = index && filters instanceof Array
        ? (FilterHandler.CHILDREN as any)[filters[index - 1].type]?.includes(type)
        : false

      if (isParentVisible && allowed && isChild && filterElement.type === '*') {
        hit = true

        return
      }

      if (filterElement.type !== type) {
        return
      }

      if (isParentVisible || index === 0) {
        hit = FilterHandler.handleLevel(filterElement, path, element)
      }
    })

    if (hit) {
      if (!CompareFilterHandler.filteredElementsByCaseId[caseId]) {
        CompareFilterHandler.filteredElementsByCaseId[caseId] = {}
      }

      if (!CompareFilterHandler.filteredElementsByCaseId[caseId][term]) {
        CompareFilterHandler.filteredElementsByCaseId[caseId][term] = {}
      }

      CompareFilterHandler.filteredElementsByCaseId[caseId][term][path] = type
    }

    return hit
  }

  private static applyFiltersToCompareElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
    hitElements: Record<string, FilterableElementType>,
  ) {
    if (!CompareFilterHandler.applyTokensToElement(tokens, path, type, element)) {
      return
    }

    hitElements[path] = type
  }

  private static applyTokensToElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
  ) {
    const stack: FilterFunction[] = []

    tokens.forEach(token => {
      if (token === '&&') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) => filter1(path, type, element) && filter2(path, type, element))
      }
      else if (token === '||') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) => filter1(path, type, element) || filter2(path, type, element))
      }
      else {
        if (typeof token === 'string') {
          return
        }

        // is a filter
        stack.push(token)
      }
    })

    return stack[0](path, type, element)
  }

  private static prepareFilters (
    term: string,
    caseId: string,
  ): (FilterFunction | string)[] {
    // e.g. Nozzle#passln_coord=[CurrentPassLnCoord+1-1]
    let preparedTerm = term.replace(FilterHandler.variableRegEx, FilterHandler.handleVariables)

    preparedTerm = preparedTerm.replace(/(\[[^\]]{3,}\])/g, FilterHandler.handleCalculate)

    return CompareFilterHandler.tokenizeExpression(preparedTerm, caseId)
  }

  private static tokenizeExpression (expression: string, caseId: string) {
    const precedence = {
      '(': 1,
      ')': 1,
      '||': 2,
      '&&': 3,
    }

    const filters: (FilterFunction | string)[] = []
    const filterStack: string[] = []

    // eslint-disable-next-line no-useless-escape
    const tokens = FilterHandler.getTokens(expression)

    if (!tokens) {
      return []
    }

    tokens.forEach(token => {
      if (token === '||' || token === '&&') {
        while (
          filterStack.length > 0 &&
          (precedence as any)[filterStack[filterStack.length - 1]] >= precedence[token]
        ) {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.push(token)
      }
      else if (token === '(') {
        filterStack.push(token)
      }
      else if (token === ')') {
        while (filterStack[filterStack.length - 1] !== '(') {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.pop()
      }
      else {
        const deconstructedFilters = FilterHandler.getFilter(token)

        filters.push((path: string, type: FilterableElementType, element: any) =>
          CompareFilterHandler.handleCompareElement(token, deconstructedFilters as any, path, type, element, caseId)
        )
      }
    })

    while (filterStack.length > 0) {
      const nextFilter = filterStack.pop()

      if (nextFilter) {
        filters.push(nextFilter)
      }
    }

    return filters
  }

  public static getFilteredElementsPerCompareCaseId = (
    elementsHashes: ElementsHashes,
    caseId: string,
    term: string,
  ) => {
    if (term && !FilterHandler.isValidFilterTerm(term)) {
      return {}
    }

    // TODO: this does not always work, we need to include filter variables in the cache key
    const filterKey = term ?? 'AllElements'

    // potential cache hit
    if (CompareFilterHandler.filterCache[filterKey]) {
      const { timestamp, data } = CompareFilterHandler.filterCache[filterKey]

      // cache hit
      if (Date.now() - timestamp < 100) {
        return data
      }

      // cache too old - delete the cache
      delete CompareFilterHandler.filterCache[filterKey]
    }

    // cache miss - calculate the filters

    const hitElements: Record<string, FilterableElementType> = {}
    const filters = term ? CompareFilterHandler.prepareFilters(term, caseId) : []
    const numericMap = ElementsUtil.compareNumericIdMountLogMaps[caseId]
    const mountLogIdPathMap = ElementsUtil.comparePaths[caseId]

    if (!filters.length || !numericMap || !mountLogIdPathMap) {
      return {}
    }

    const filterTypes = term ? FilterHandler.getFilterElementTypes(term) : []

    const currentMountLogHashesPerType: Record<string, any[]> = {}

    FilterHandler.elementTypeInfoArray.forEach(elementTypeInfo => {
      const { type } = elementTypeInfo

      if (!filterTypes.includes(type) && !filterTypes.includes('*')) {
        return
      }

      const currentMountLogHashes = elementsHashes[`${type}MountLog` as keyof ElementsHashes] ?? {}

      currentMountLogHashesPerType[type] = Object.values(currentMountLogHashes)

      const mountLogs = Object.values(currentMountLogHashes)

      mountLogs.forEach((mountLog: any) => {
        const numericId = numericMap[`${type}MountLog` as keyof NumericIdMountLogMaps]?.[mountLog.id] as
          | number
          | undefined
        const path = mountLogIdPathMap[type]?.[mountLog.id]

        if (numericId === undefined || !path) {
          return
        }

        // type with first letter in lower case
        const elementIdKey = `${type[0].toLowerCase()}${type.substring(1)}Id`
        const elementInfoId = mountLog[elementIdKey]

        if (!elementInfoId) {
          return
        }

        const elementInfo = (elementsHashes as any)[type][elementInfoId]
        const element = ElementsUtil.getFullCasterElement(elementInfo, mountLog, numericId)

        CompareFilterHandler.applyFiltersToCompareElement(
          filters,
          path,
          type as FilterableElementType,
          element,
          hitElements,
        )
      })
    })

    // sensor points
    FilterHandler.sensorPointInfoArray.forEach(sensorPointInfo => {
      if (!filterTypes.includes('SensorPoint')) {
        return
      }

      const currentMountLogHashes = elementsHashes[sensorPointInfo.mountLogType] ?? {}
      const mountLogs = Object.values(currentMountLogHashes) as any[] // TODO: use some SensorPointMountLog[] type

      mountLogs.forEach((mountLog) => {
        const numericId = numericMap.SensorPointMountLog?.[mountLog.id] as number | undefined
        const path = mountLogIdPathMap.SensorPoint?.[mountLog.id]

        if (numericId === undefined || !path) {
          return
        }

        const elementInfo = elementsHashes.SensorPoint?.[mountLog.sensorPointId] ?? {}
        const element = ElementsUtil.getFullCasterElement(elementInfo, mountLog, numericId)

        CompareFilterHandler.applyFiltersToCompareElement(
          filters,
          path,
          'SensorPoint',
          element,
          hitElements,
        )
      })
    })

    // TODO: no data points here?

    // data lines, handle like with the sensor points
    const mountLogs = Object.values(elementsHashes.DataLineMountLog ?? {})

    mountLogs.forEach((mountLog) => {
      const numericId = numericMap.DataLineMountLog?.[mountLog.id] as number | undefined

      if (numericId === undefined) {
        return
      }

      const elementInfo = elementsHashes.DataLine[mountLog.dataLineId ?? '']

      if (!elementInfo) {
        return
      }

      const element = ElementsUtil.getFullCasterElement(elementInfo, mountLog, numericId)

      const path = `DataLine:${numericId}`

      CompareFilterHandler.applyFiltersToCompareElement(
        filters,
        path,
        'DataLine',
        element,
        hitElements,
      )
    })

    // TODO: no coolingLoop here?
    // TODO: no airLoop here?

    CompareFilterHandler.filterCache[filterKey] = {
      timestamp: Date.now(),
      data: hitElements,
    }

    return hitElements
  }
}
