export enum CasterActionsEnum {
  ACTION_ADD_CASTER = 'Caster/ACTION_ADD_CASTER',
  DELETE = 'Caster/DELETE',
  RESET = 'Caster/RESET',
}

export function addCaster (Caster: Caster) {
  return {
    type: CasterActionsEnum.ACTION_ADD_CASTER,
    Caster,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CasterReducers: Record<string, Function | undefined> = {
  [CasterActionsEnum.ACTION_ADD_CASTER]: (_state: Caster, action: { Caster: Caster }) => action.Caster,

  [CasterActionsEnum.DELETE]: (_state: Caster) => null,

  [CasterActionsEnum.RESET]: () => null,
}

export default function (state: Caster | null = null, action: any) {
  const handler = CasterReducers[action.type]

  return handler ? handler(state, action) : state
}
