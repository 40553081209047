import isEqual from 'lodash/isEqual'
import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { useConfig } from '@/config'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import { getElementTypeURL } from '@/store/data/logic'
import { getElementsHashesObject } from '@/store/elements/logic'
import { getElementFromPath } from '@/store/elements/logic/getters'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { DefaultState } from '@/types/state'

import AllInOne from '../AllInOne'
import { SectionContainer, SectionContent } from '../AllInOne/styles'
import Section from '../Section'

const connector = connect((state: DefaultState) => ({
  elementsHashes: getElementsHashesObject(state),
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  timestamps: state.timestamps,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  compareCasterInformation: state.visualization.compareCasterInformation,
  Caster: state.Caster,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  // no actions yet
  setCompareCasterInformation: VisualizationActions.setCompareCasterInformation,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  paths: string[]
}

type State = void

export class SegmentGroup extends PureComponent<Props, State> {
  private readonly supportPointNameOrder = [ 'INL', 'INR', 'OUTL', 'OUTR' ]

  public override componentDidMount (): void {
    const { paths, selectedComparisonCaseIds } = this.props

    if (paths.length && selectedComparisonCaseIds.length) {
      this.updateSupportPointsCompareInformation()
    }
  }

  public override componentDidUpdate (prevProps: Readonly<Props>): void {
    const { paths, selectedComparisonCaseIds } = this.props

    if (
      paths.length &&
      selectedComparisonCaseIds.length &&
      (!isEqual(prevProps.paths, paths) || !isEqual(prevProps.selectedComparisonCaseIds, selectedComparisonCaseIds))
    ) {
      this.updateSupportPointsCompareInformation()
    }
  }

  private readonly updateSupportPointsCompareInformation = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      timestamps,
      setCompareCasterInformation,
      elementsHashes,
      currentSimulationCase,
      Caster,
    } = this.props

    const supportPointPaths = this.getSupportPointPaths().filter((path) => path)
    const supportPointNames = supportPointPaths
      .map((path) => getElementFromPath(path, elementsHashes)?.name)
      .filter(Boolean) as string[]
    const segmentGroupElement = getElementFromPath(paths[0], elementsHashes)

    if (!supportPointNames.length || !segmentGroupElement) {
      return
    }

    const { passlineCoord } = segmentGroupElement
    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      for (const name of supportPointNames) {
        const key = `${name}_${passlineCoord}`

        const element = compareCasterInformation[caseId]?.SupportPoint?.[key]

        if (!element) {
          elementsToBeRequestedPerCase[caseId] = elementsToBeRequestedPerCase[caseId] ?? []
          elementsToBeRequestedPerCase[caseId].push(key)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const caseId = currentSimulationCase?.id
      const casterId = Caster?.id
      const data = await ApiClient
        .post(`${useConfig().apiBaseURL}/${getElementTypeURL('SupportPoint')}/${caseId}/${casterId}/compare`, {
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      if (!data || !Object.keys(data).length) {
        return
      }

      const newCompareCasterInformation = { ...compareCasterInformation }
      const caseIds = Object.keys(data)

      for (const caseId of caseIds) {
        if (!newCompareCasterInformation[caseId]) {
          newCompareCasterInformation[caseId] = {} as any
        }

        if (!newCompareCasterInformation[caseId]?.SupportPoint) {
          newCompareCasterInformation[caseId].SupportPoint = {}
        }

        newCompareCasterInformation[caseId].SupportPoint = {
          ...newCompareCasterInformation[caseId].SupportPoint,
          ...data[caseId],
        }
      }

      setCompareCasterInformation(newCompareCasterInformation)
    }
  }

  private readonly getSegmentGroupMountLog = () => {
    const { paths, elementsHashes } = this.props
    const path = paths?.[0] ?? ''
    const { id } = ThreeUtil.getElementInfo(path)
    const segmentGroupMountLogUUID = MainView.numericIdMountLogMaps.SegmentGroupMountLog[id]

    return elementsHashes.SegmentGroupMountLog[segmentGroupMountLogUUID ?? '']
  }

  private readonly getSupportPointPaths = () => {
    const { paths, elementsHashes } = this.props

    // only use the first path
    const path = paths?.[0] ?? ''
    const segmentGroupMountLog = this.getSegmentGroupMountLog()

    // FIXME: this filter should not be necessary, why does the SegmentGroupMountLog have old supportPointMountLogs?
    const filteredSupportPointMountLogs = segmentGroupMountLog
      ?.supportPointMountLogs
      ?.filter((id) => elementsHashes.SupportPointMountLog[id]) ?? []

    filteredSupportPointMountLogs.sort((idA, idB) => {
      const supportPointMountLogA = elementsHashes.SupportPointMountLog[idA]
      const supportPointA = elementsHashes.SupportPoint[supportPointMountLogA?.supportPointId ?? '']
      const supportPointMountLogB = elementsHashes.SupportPointMountLog[idB]
      const supportPointB = elementsHashes.SupportPoint[supportPointMountLogB?.supportPointId ?? '']

      return (
        this.supportPointNameOrder.indexOf(supportPointA?.name ?? '') -
        this.supportPointNameOrder.indexOf(supportPointB?.name ?? '')
      )
    })

    return filteredSupportPointMountLogs?.map((supportPointMountLogUUID) => {
      const supportPointId = MainView.numericIdMountLogMaps.SupportPointMountLog[supportPointMountLogUUID]

      return `${path}/SupportPoint:${supportPointId}`
    }) ?? []
  }

  public override render () {
    const { paths, elementsHashes } = this.props

    if (paths?.length === 0 || paths?.length > 1) {
      return (
        <SectionContainer>
          {/* TODO: translate */}
          <Section name='Selection'>
            <SectionContent>
              {
                paths.length !== 0
                  ? `${paths.length} SegmentGroups selected`
                  : 'Nothing selected'
              }
            </SectionContent>
          </Section>
        </SectionContainer>
      )
    }

    const path = paths?.[0] ?? ''
    const supportPointPaths = this.getSupportPointPaths()
    const segmentGroupMountLog = this.getSegmentGroupMountLog()
    const segmentGroup = elementsHashes.SegmentGroup[segmentGroupMountLog?.segmentGroupId ?? '']
    const name = segmentGroupMountLog?.name ?? segmentGroup?.name ?? path

    return (
      <div>
        <SectionContainer>
          {/* TODO: translate */}
          <Section name='SupportPoints'>
            <SectionContent>
              {
                supportPointPaths.length
                  ? `${supportPointPaths.length} SupportPoints in ${name} selected`
                  : `No SupportPoints in ${name}`
              }
            </SectionContent>
          </Section>
        </SectionContainer>
        {
          supportPointPaths.map((supportPointPath: any, index: number) => (
            <div key={supportPointPath}>
              <AllInOne
                paths={[ supportPointPath ]}
                allPaths={supportPointPaths}
                type='SegmentGroupSupportPoints'
                hideActions={index !== supportPointPaths.length - 1}
              />
            </div>
          ))
        }
      </div>
    )
  }
}

export default connector(SegmentGroup as any) as any
