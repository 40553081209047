import Util from '@/logic/Util'
import { MatchHashElement, NodeElement } from '@/react/TreeViewNodes'
import MainView from '@/three/views/MainView'
import { ElementsHashes } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

type Context = {
  elementsHashes: ElementsHashes
  elementsArray: NodeElement[]
  shownPaths: string[]
  matchHash: Record<string, MatchHashElement>
  showAll: boolean
}

type ParentInfo = {
  path: string
  mountLogId: string
  type: string
  numericId: number
}

export class TreeViewBuilder {
  public static buildTree (
    elementsHashes: ElementsHashes,
    elementsArray: NodeElement[],
    shownPaths: string[],
    matchHash: Record<string, MatchHashElement>,
    showAll = false,
  ) {
    const context: Context = { elementsHashes, elementsArray, shownPaths, matchHash, showAll }

    TreeViewBuilder.buildSegmentGroup(context, 0)
  }

  // Private methods

  private static comparePasslineCoord<T extends BaseMountLog> (a: T, b: T): number {
    return (a.passlineCoord ?? 0) - (b.passlineCoord ?? 0)
  }

  private static buildType (
    parentType: CasterElementNames,
    parentInfo: ParentInfo,
    type: CasterElementNames,
    context: Context,
    depth: number,
  ): void {
    switch (type) {
      case 'Segment':
        TreeViewBuilder.buildSegment(parentInfo, context, depth)
        break
      case 'Nozzle':
        TreeViewBuilder.buildNozzle(parentInfo, context, depth)
        break
      case 'Roller':
        TreeViewBuilder.buildRoller(parentInfo, context, depth)
        break
      case 'RollerBody':
        TreeViewBuilder.buildRollerBody(parentInfo, context, depth)
        break
      case 'RollerBearing':
        TreeViewBuilder.buildRollerBearing(parentInfo, context, depth)
        break
      case 'SensorPoint':
        TreeViewBuilder.buildSensorPoint(parentType, parentInfo, context, depth)
        break
    }
  }

  private static getMountLogMapByType<T extends BaseMountLog> (type: string, context: Context) {
    return context.elementsHashes[`${type}MountLog` as keyof ElementsHashes] as Record<string, T>
  }

  private static buildChildren (
    type: CasterElementNames,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ): void {
    const childTypes = Util.getChildType(type)

    for (const childType of childTypes) {
      TreeViewBuilder.buildType(type, parentInfo, childType, context, depth + 1)
    }
  }

  private static hasChildren<T extends BaseMountLog> (type: CasterElementNames, mountLog: T): boolean {
    const childTypes = Util.getChildType(type)

    for (const childType of childTypes) {
      const childIdsKey = `${childType[0].toLocaleLowerCase() + childType.substring(1)}MountLogs` as keyof T

      if ((mountLog[childIdsKey] as string[])?.length) {
        return true
      }
    }

    return false
  }

  private static getSortedMountLogs<T extends BaseMountLog> (
    type: keyof ElementsHashes,
    parentInfo: ParentInfo | null,
    context: Context,
  ): T[] {
    const mountLogs = TreeViewBuilder.getMountLogMapByType<T>(type, context)

    let filteredMountLogs = null

    if (parentInfo) {
      const parentMountLogs = TreeViewBuilder.getMountLogMapByType(parentInfo.type, context)
      const parentMountLog = parentMountLogs[parentInfo.mountLogId]
      const childIdsKey = type.includes('SensorPoints')
        ? 'sensorPointMountLogs'
        : `${type[0].toLocaleLowerCase() + type.substring(1)}MountLogs`

      filteredMountLogs = (parentMountLog[childIdsKey as keyof BaseMountLog] as any as string[])
        ?.map(id => mountLogs[id]) ?? []
    }
    else {
      filteredMountLogs = Object.values(mountLogs)
    }

    return filteredMountLogs.toSorted(TreeViewBuilder.comparePasslineCoord)
  }

  private static getElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    type: CasterElementNames,
    mountLog: MountLog,
    context: Context,
  ): FullCasterElement<Element, MountLog> | null {
    const numericId = MainView.numericIdMountLogMaps[`${type}MountLog`]?.[mountLog.id] ?? null

    if (numericId === null) {
      return null
    }

    const childIdKey = `${type[0].toLocaleLowerCase() + type.substring(1)}Id` as keyof MountLog
    const elementInfo = context.elementsHashes[type as keyof ElementsHashes][mountLog[childIdKey]] as Element

    return ElementsUtil.getFullCasterElement<Element, MountLog>(elementInfo, mountLog, numericId)
  }

  private static prepareBuildContainer (
    type: CasterElementNames,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ): void {
    const sortedMountLogs = TreeViewBuilder.getSortedMountLogs(type, parentInfo, context)

    TreeViewBuilder.buildContainer(type, parentInfo, sortedMountLogs.length, context, depth)

    for (const mountLog of sortedMountLogs) {
      TreeViewBuilder.buildElement(type, mountLog, parentInfo, context, depth + 1)
    }
  }

  private static buildContainer (
    type: CasterElementNames,
    parentInfo: ParentInfo,
    childCount: number,
    context: Context,
    depth: number,
  ) {
    if (!childCount) {
      return
    }

    const parentNameAndId = `${parentInfo.type}:${parentInfo.numericId}`
    const selectorPath = `${type}Selector-${parentNameAndId}`
    const shouldShow = depth < 2 || context.showAll

    context.matchHash[parentNameAndId].children.push(selectorPath)

    context.elementsArray.push({
      name: 'selector',
      nameAndId: selectorPath,
      element: {},
      depth,
      hasChildren: true,
      fullPath: parentInfo.path,
      amountOfChildren: childCount,
    })

    context.matchHash[selectorPath] = {
      children: [],
      parentTypeAndId: parentNameAndId,
      selected: false,
      show: shouldShow,
      showChildren: type === 'Segment' ? shouldShow : false,
    }

    if (shouldShow) {
      context.shownPaths.push(selectorPath)
    }
  }

  private static buildElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
    type: CasterElementNames,
    mountLog: MountLog,
    parentInfo: ParentInfo | null,
    context: Context,
    depth: number,
  ): void {
    const element = TreeViewBuilder.getElement<Element, MountLog>(type, mountLog, context)

    if (element === null) {
      return
    }

    const nameAndId = `${type}:${element.id}`
    const path = parentInfo ? `${parentInfo.path}/${nameAndId}` : nameAndId
    const parentNameAndId = parentInfo ? `${parentInfo.type}:${parentInfo.numericId}` : ''
    const selectorPath = `${type}Selector-${parentNameAndId}`
    const hasChildren = TreeViewBuilder.hasChildren(type, mountLog)
    const shouldShow = hasChildren && (depth < 3 || context.showAll)

    context.elementsArray.push({
      name: type === 'SegmentGroup' || type === 'Segment' || type === 'SensorPoint'
        ? element.name ?? nameAndId
        : nameAndId,
      nameAndId,
      element,
      depth,
      fullPath: path,
      hasChildren,
    })

    context.matchHash[selectorPath]?.children?.push(nameAndId)

    context.matchHash[nameAndId] = {
      children: [], // children will be added later
      parentTypeAndId: selectorPath,
      selected: false,
      show: shouldShow,
      showChildren: type === 'SensorPoint' ? false : (hasChildren && (depth < 2 || context.showAll)),
    }

    if (shouldShow) {
      context.shownPaths.push(nameAndId)
    }

    if (!hasChildren) {
      return
    }

    const newParentInfo: ParentInfo = { path, type, numericId: element.id, mountLogId: mountLog.id }

    TreeViewBuilder.buildChildren(type, newParentInfo, context, depth)
  }

  private static buildSegmentGroup (context: Context, depth: number): void {
    const sortedSegmentGroupMountLogs = TreeViewBuilder.getSortedMountLogs<SegmentGroupMountLog>(
      'SegmentGroup',
      null,
      context,
    )

    for (const segmentGroupMountLog of sortedSegmentGroupMountLogs) {
      TreeViewBuilder.buildElement('SegmentGroup', segmentGroupMountLog, null, context, depth)
    }
  }

  private static readonly buildSegment = TreeViewBuilder.prepareBuildContainer.bind(null, 'Segment')

  private static readonly buildNozzle = TreeViewBuilder.prepareBuildContainer.bind(null, 'Nozzle')

  private static readonly buildRoller = TreeViewBuilder.prepareBuildContainer.bind(null, 'Roller')

  private static readonly buildRollerBody = TreeViewBuilder.prepareBuildContainer.bind(null, 'RollerBody')

  private static readonly buildRollerBearing = TreeViewBuilder.prepareBuildContainer.bind(null, 'RollerBearing')

  private static buildSensorPoint (
    parentType: CasterElementNames,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ) {
    const sortedMountLogs = TreeViewBuilder.getSortedMountLogs(
      `${parentType}SensorPoints` as keyof ElementsHashes,
      parentInfo,
      context,
    )

    TreeViewBuilder.buildContainer('SensorPoint', parentInfo, sortedMountLogs.length, context, depth)

    for (const sensorPointMountLog of sortedMountLogs) {
      TreeViewBuilder.buildElement('SensorPoint', sensorPointMountLog, parentInfo, context, depth + 1)
    }
  }
}
