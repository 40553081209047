import * as THREE from 'three'
import { Vector3 } from 'three'

import BaseObject, { BaseObjects } from './BaseObject'
import Mold from './Mold'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  line?: THREE.Line
  strand?: THREE.Mesh
}

export default class PasslineCurve extends BaseObject<null, null, unknown, Objects> {
  private static readonly x = new THREE.Vector3(1, 0, 0)

  private static curve: any

  private static straight: any

  public static plHeight: number

  public static shouldBeStraight = false

  private clickableObjects: any

  public static getCurve () {
    return PasslineCurve.shouldBeStraight ? PasslineCurve.straight : PasslineCurve.curve
  }

  private static getAngle (v1: Vector3, v2: Vector3) {
    return Math.acos((v1.x * v2.x + v1.y * v2.y) / (v1.length() * v2.length()))
  }

  public static getInfoAtPlCoord (plCoord: number, straight?: boolean, isDataPoint = false) {
    const position = new THREE.Vector3(0, PasslineCurve.plHeight - plCoord, 0)

    if (isNaN(plCoord) || straight === true || !PasslineCurve.curve) {
      if (isNaN(plCoord)) {
        // eslint-disable-next-line no-console
        console.log('plCoord is a NaN value')
      }

      return {
        position,
        normal: new THREE.Vector3(0, 0, -1),
        tangent: new THREE.Vector3(0, -1, 0),
        angleX: 0,
      }
    }

    const autoCurve = straight === undefined
    const curve = autoCurve ? PasslineCurve.getCurve() : PasslineCurve.curve

    const originalValue = plCoord / PasslineCurve.plHeight
    let value = originalValue

    if (value < 0) {
      value = 0
    }
    else if (value > 1) {
      value = 1
    }

    const tangent = new THREE.Vector3(0, 0, 0).add(curve.getTangentAt(value))

    tangent.z = tangent.z ?? 0

    const normal = tangent.clone().cross(new THREE.Vector3(0, 0, -1))
    const point: Vector3 = curve.getPointAt(value)

    if (originalValue < 0 && isDataPoint) {
      point.sub(new Vector3(0, plCoord + PasslineCurve.plHeight / 1000, 0))
    }

    const angleX = PasslineCurve.getAngle(PasslineCurve.x, normal)

    return {
      position: new THREE.Vector3(0, PasslineCurve.plHeight + point.y, -point.x),
      normal: new THREE.Vector3(-normal.z, normal.y, -normal.x),
      tangent: new THREE.Vector3(-tangent.z, tangent.y, -tangent.x),
      angleX,
    }
  }

  public setData (passlineSectionArray: PasslineSection[], clickableObjects: any, applyCurve: boolean) {
    const plHeight = BaseObject.getPasslineHeight(passlineSectionArray)

    this.clickableObjects = clickableObjects

    PasslineCurve.plHeight = plHeight
    PasslineCurve.shouldBeStraight = !applyCurve

    this.setValuesCurved(passlineSectionArray)
    this.setValuesStraight(passlineSectionArray)
  }

  private setValuesCurved (passlineSections: PasslineSection[]) {
    let angleSum = 0
    let prevLength = 0
    let prevRadius = 0
    const prevPos = new THREE.Vector2(0, 0)
    const prevEnd = new THREE.Vector2(0, 0)
    const prevDir = new THREE.Vector2(1, 0)
    const prevTangent = new THREE.Vector2(0, -1)
    const points: Vector3[] = []
    const pointPrecision = PasslineCurve.plHeight * 10 // at least times 10 otherwise equi-spaced points won't work

    // eslint-disable-next-line camelcase
    passlineSections.forEach(({ passlineCoord: plCoord, radius: rawRadius }) => {
      const length = (plCoord ?? 0) / 1000 - prevLength
      const radius = (rawRadius ?? 0) / 1000
      const pointCount = Math.ceil((length / PasslineCurve.plHeight) * pointPrecision) || 1

      if (radius > 0) {
        const circumference = 2 * Math.PI * radius
        const part = length / circumference
        const angle = 2 * Math.PI * part

        const newPos = prevDir.clone().setLength(prevRadius - radius).add(prevPos)

        const curve = new THREE.EllipseCurve(newPos.x, newPos.y, radius, radius, 0, -angle, true, -angleSum)

        const newPoints = curve.getSpacedPoints(pointCount)

        if (points.length) {
          newPoints.splice(0, 1)
        }

        newPoints.forEach(point => points.push(Util.getV3(point)))

        const end = curve.getPointAt(1)

        prevDir.copy(end.clone().sub(newPos))
        prevPos.copy(newPos)
        prevTangent.copy(curve.getTangentAt(1))
        prevEnd.copy(end)
        angleSum += angle
        prevRadius = radius
      }
      else {
        const linePoints = []
        const step = length / pointCount
        const endPos = prevTangent.clone().setLength(length)

        // subtracting 0.00001 from the length prevents the last two points to be too close for the curve to handle
        for (let i = 0; i < length - 0.00001; i += step) {
          const point = prevEnd.clone().add(prevTangent.clone().setLength(i))

          linePoints.push(Util.getV3(point))
        }

        const point = prevEnd.clone().add(endPos)

        linePoints.push(Util.getV3(point))

        if (points.length) {
          linePoints.splice(0, 1)
        }

        points.push(...linePoints)

        prevPos.add(endPos)
        prevEnd.add(endPos)
      }

      prevLength += length
    })

    if (!PasslineCurve.shouldBeStraight) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      const material = new THREE.LineBasicMaterial({ color: '#00b8ff' })

      geometry.translate(0, PasslineCurve.plHeight, 0)

      const line = new THREE.Line(geometry, material)

      line.name = 'PasslineCurve'
      Util.addOrReplace(this.container, line)

      this.objects.line = line
    }

    PasslineCurve.curve = new THREE.CatmullRomCurve3(points)
  }

  private setValuesStraight (passlineSections: PasslineSection[]) {
    let prevLength = 0
    const prevPos = new THREE.Vector2(0, 0)
    const prevEnd = new THREE.Vector2(0, 0)
    const prevTangent = new THREE.Vector2(0, -1)
    const points: Vector3[] = []
    const pointPrecision = PasslineCurve.plHeight * 10 // at least times 10 otherwise equi-spaced points won't work

    // eslint-disable-next-line camelcase
    passlineSections.forEach(({ passlineCoord: plCoord }) => {
      const length = (plCoord ?? 0) / 1000 - prevLength
      const pointCount = Math.ceil((length / PasslineCurve.plHeight) * pointPrecision) || 1

      const linePoints = []
      const step = length / pointCount
      const endPos = prevTangent.clone().setLength(length)

      // subtracting 0.00001 from the length prevents the last two points to be too close for the curve to handle
      for (let i = 0; i < length - 0.00001; i += step) {
        const point = prevEnd.clone().add(prevTangent.clone().setLength(i))

        linePoints.push(Util.getV3(point))
      }

      const point = prevEnd.clone().add(endPos)

      linePoints.push(Util.getV3(point))

      if (points.length) {
        linePoints.splice(0, 1)
      }

      points.push(...linePoints)

      prevPos.add(endPos)
      prevEnd.add(endPos)

      prevLength += length
    })

    if (PasslineCurve.shouldBeStraight) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      const material = new THREE.LineBasicMaterial({ color: '#00b8ff' })

      geometry.translate(0, PasslineCurve.plHeight, 0)

      const line = new THREE.Line(geometry, material)

      line.name = 'PasslineCurve'
      Util.addOrReplace(this.container, line)

      this.objects.line = line
    }

    PasslineCurve.straight = new THREE.CatmullRomCurve3(points)
  }

  public drawStrand () {
    // TODO: this hides the ruler header lines inside the mold, but it's a dirty hack
    const steps = Math.ceil(PasslineCurve.plHeight) * 4
    const points = PasslineCurve.getCurve().getSpacedPoints(steps - 1)
    const start = points[0].clone()

    start.y += 0.02
    points.unshift(start)

    if (points.length < 2) {
      // eslint-disable-next-line no-console
      console.error('Cannot build StrandGuide, not enough points in curve!')
    }

    const curve = new THREE.CatmullRomCurve3(points)

    const extrudeSettings = {
      steps,
      bevelEnabled: false,
      extrudePath: curve,
    }

    if (!Mold.innerShape) {
      // eslint-disable-next-line no-console
      console.error('Cannot build StrandGuide, Mold shape does not exist!')

      return
    }

    const geometry = new THREE.ExtrudeGeometry(Mold.innerShape, extrudeSettings)
    const material = new THREE.MeshLambertMaterial({ color: '#d0ac70' })

    geometry.translate(0, PasslineCurve.plHeight, 0)

    const strand = new THREE.Mesh(geometry, material)

    strand.name = 'CurvedStrand'
    strand.userData.type = 'Strand'

    Util.addOrReplace(this.container, strand, this.clickableObjects)

    this.objects.strand = strand
  }

  public setPosition (position: Vector3) {
    this.objects.line?.position?.copy(position)
  }

  public hideStrand () {
    if (!this.objects.strand) {
      return
    }

    this.objects.strand.visible = false
  }

  public showStrand () {
    if (!this.objects.strand) {
      return
    }

    this.objects.strand.visible = true
  }
}
