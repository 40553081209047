import { Checkbox } from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import { withNamespaces } from 'react-i18next'
import { type ConnectedProps, connect } from 'react-redux'
import { FixedSizeList as List } from 'react-window'
import styled, { css } from 'styled-components'

import { openDialog } from '@/store/application/main/actions'
import { getElementsHashesObject } from '@/store/elements/logic'
import { DefaultState } from '@/types/state'

import RealDataElementDetailsDialog from './dialogs/project/RealDataElementDetailsDialog'
import { PartsWarehouseLogic as Logic } from './PartsWarehouseLogic'
import Icon from './specific/Icon'
import { Counter, Spacer } from './tree/ElementGroupStyles'

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`

const FilterInput = styled.input`
  width: calc(100% - 20px);
  margin: 0;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: #333a44;
  padding: 0 30px 0 10px;
  outline: none;
  color: #FFFFFF;
  margin-top: 9px;
  margin-left: 10px;

  &::placeholder{
    font-style: italic;
    user-select: none;
  }
`

const CheckboxWrapper = styled.div`
  margin-top: 4px;
`

const Group = styled.div<{ $disabled?: boolean }>`${({ $disabled }) => css`
  display: flex;
  cursor: pointer;
  align-items: center;

  ${$disabled && css`
    color: #AAAAAA;
    pointer-events: none;
  `}

  &:hover {
    background: #6b6d71;
  }

  ${Spacer} {
    width: 20px;
    margin-left: 7px;
    margin-bottom: 4px;
  }
`}`

export const IconButton = styled.button`
  display: inline-block;
  float: right;
  text-align: center;
  color: #CCCCCC;
  border: none;
  background-color: inherit;
  cursor: pointer;

  &:disabled {
    color: #333a44;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #FFFFFF;
  }
`

const Element = styled.div`
  display: flex;
  padding-left: 24px;
  justify-content: space-between;
  align-items: center;

  ${IconButton} {
    opacity: 0;
  }

  &:hover {
    background: #6b6d71;

    ${IconButton} {
      opacity: 1;
    }
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.9em;
`

const connector = connect((state: DefaultState) => ({
  elementsHashes: getElementsHashesObject(state), // TODO: get real data elements map
}), {
  openDialog,
})

type Props = ConnectedProps<typeof connector> & {
  t: (key: string) => string
}

const T = 'partsWarehouse'

const PartsWarehouse: React.FC<Props> = (props) => {
  const { elementsHashes, openDialog, t } = props

  const [ filter, setFilter ] = useState('')
  const [ expandedElements, setExpandedElements ] = useState({} as Record<CasterElementNames, boolean | undefined>)
  const [ includeMounted, setIncludeMounted ] = useState(false)

  const filteredElements = useMemo(
    () => Logic.getElements(elementsHashes, expandedElements),
    [ filter, includeMounted, expandedElements, elementsHashes ],
  )

  return (
    <div>
      <Top>
        <FilterInput
          type='text'
          name='filter'
          value={filter}
          onChange={event => setFilter(event.target.value)}
          placeholder='Filter Parts Warehouse'
        />
        <CheckboxWrapper>
          <Checkbox
            onChange={() => setIncludeMounted(!includeMounted)}
            color='default'
            checked={includeMounted}
            name='includeMounted'
            title='Include Mounted Elements'
          />
        </CheckboxWrapper>
        {/* TODO: use DropDown -> All, Partially-Mounted, Mounted. Partially: mounted but some parent is not */}
      </Top>
      <List
        key='PartsWarehouseList'
        height={window.innerHeight - 84} // TODO: make this autosize
        itemSize={25}
        itemCount={filteredElements.length}
        width={335}
        itemData={filteredElements}
        overscanCount={10}
        itemKey={
          (index, data) => {
            const element = data[index]

            return element.listType === 'group' ? element.name : element.data.id
          }
        }
      >
        {
          ({ index, style }) => {
            const element = filteredElements[index]

            if (element.listType === 'group') {
              return (
                <Group
                  style={style} 
                  onClick={() => setExpandedElements(Logic.toggleExpanded(expandedElements, element.name))}
                  $disabled={element.childCount === 0}
                >
                  {element.childCount > 0 && element.expanded ? <Spacer>&#9662;</Spacer> : <Spacer>&#9656;</Spacer>}
                  <Name>{element.name}</Name>
                  <Counter>{element.childCount}</Counter>
                </Group>
              )
            }

            return (
              <Element style={style}>
                <Name>{element.data.name ? element.data.name : element.elementType}</Name>
                <IconButton>
                  <Icon
                    icon='table'
                    title={t(`${T}.showHistoricalDetails`)}
                    onClick={() => openDialog(RealDataElementDetailsDialog.NAME, element)}
                  />
                </IconButton>
              </Element>
            )
          }
        }
      </List>
    </div>
  )
}

export default withNamespaces('caster')(connector(PartsWarehouse))
