import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import LogicUtil from '@/logic/Util'
import DataActions from '@/store/data/actions'
import Util from '@/three/logic/Util'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import Button from './Button'
import { Buttons, CardBody, CardContainer, CardHeader, DialogWrapper } from './Dialog'
import SecondaryButton from './SecondaryButton'

const ListElement = styled.div`
  margin: 2px 0;
`

const connector = connect(({
  data,
  Roller,
  RollerBearing,
  RollerBody,
  timestamps,
  RollerBearingMountLog,
  RollerMountLog,
  RollerBodyMountLog,
}: DefaultState) => (
  {
    RollerBearing,
    RollerBody,
    Roller,
    pendingDeleteList: data.pendingDeleteList,
    hidePaths: data.hidePaths,
    dirtyDeletePaths: data.dirtyDeletePaths,
    timestamps,
    RollerBearingMountLog,
    RollerMountLog,
    RollerBodyMountLog,
  }
), {
  addPendingDeleteList: DataActions.addPendingDeleteList,
  addDirtyPath: DataActions.addDirtyPath,
  addDirtyDeletePath: DataActions.addDirtyDeletePath,
  saveElement: DataActions.saveElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}
export class DeleteDialog extends Component<Props> {
  private readonly handleDeletePendingElements = () => {
    const { saveElement, pendingDeleteList, addPendingDeleteList } = this.props

    addPendingDeleteList()

    const { type } = Util.getElementInfo(pendingDeleteList[0])

    saveElement(pendingDeleteList, [], 'delete', type) // TODO: needs to send elementType too
  }

  private readonly handleDeletePendingList = () => {
    const { addPendingDeleteList, pendingDeleteList, addDirtyPath, addDirtyDeletePath } = this.props
    const allChildren = [ ...pendingDeleteList ]
    const elementsHashes: any = {
      Roller: this.props.Roller,
      RollerBody: this.props.RollerBody,
      RollerBearing: this.props.RollerBearing,
      RollerMountLog: this.props.RollerMountLog,
      RollerBodyMountLog: this.props.RollerBodyMountLog,
      RollerBearingMountLog: this.props.RollerBearingMountLog,
    }

    pendingDeleteList.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'Roller') {
        const element = LogicUtil.getElement<Roller, RollerMountLog>(path, {
          Roller: this.props.Roller,
        } as any)

        allChildren.push(...LogicUtil.getRollerChildren(element, elementsHashes))
      }

      if (type === 'RollerBody' || type === 'RollerBearing') {
        const parentPath = Util.getParentInfo(path).path
        const parentElement = LogicUtil.getElement<Roller, RollerMountLog>(parentPath, {
          RollerBody: this.props.RollerBody,
          RollerBearing: this.props.RollerBearing,
          Roller: this.props.Roller,
        } as any)

        const rollerChildren = LogicUtil.getRollerChildren(parentElement, elementsHashes)

        if (rollerChildren.length === 1 && rollerChildren[0] === path) { // if this element is the only roller child
          allChildren.push(parentPath) // because parent isn't in pending delete list, only in dirtyDeleteList
        }
      }
    })

    addDirtyPath(allChildren)
    addDirtyDeletePath(allChildren) // if path is already in array it removes it from the array
    addPendingDeleteList()
  }
  
  public override render () {
    const { pendingDeleteList, t } = this.props

    return (
      <DialogWrapper>
        <CardHeader>
          <h3>{t('specific.deleteDialog.title')}</h3>
        </CardHeader>
        <CardContainer>
          <CardBody>
            {
              pendingDeleteList.map(path => (
                <ListElement key={path}>
                  {path}
                </ListElement>
              ))
            }
          </CardBody>
        </CardContainer>
        <Buttons>
          <Button
            onClick={this.handleDeletePendingElements}
            value={t('specific.deleteDialog.button')}
            small
          />
          <SecondaryButton
            onClick={this.handleDeletePendingList}
            value={t('specific.deleteDialog.secondaryButton')}
            small
          />
        </Buttons>
      </DialogWrapper>
    )
  }
}

export default withNamespaces('caster')(connector(DeleteDialog as any)) as any
