import isEqual from 'lodash/isEqual'
import React, { Component, ReactNode } from 'react'
import type { KeyboardEvent } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import { getCurrentDashboardEntry } from '@/App/util'
import DataActions from '@/store/data/actions'
import { getElementsHashesObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

import CasterOptions from './CasterOptions'
import CasterTreeContent from './CasterTreeContent'
import FeatureFlags from './FeatureFlags'
import ScrollBar from './specific/ScrollBar'
import TreePlaceholder from './specific/TreePlaceholder'
import TreeView from './TreeView'

export const ResizeHandle = styled.div<{ $width: number }>`${({ $width }) =>
  css`
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: ${$width}px;
  height: 30px;
  width: 30px;
  background: #474b4e;
  display: flex;
  cursor: ew-resize;
  display:flex;
  align-items: center;
  justify-content: center;
  color: #CCCCCC;
  font-size: 20px;
  border-top-right-radius: 7px;
`}`

export const CurrentDashboardWidth = styled.div<{ $visible: boolean }>`${({ $visible }) =>
  css`
  display: ${$visible ? 'unset' : 'none'};
  position: absolute;
  bottom: 7px;
  left: 10px;
  z-index: 100;
  color: #CCCCCC;
  user-select: none;
`}`

const CasterTreeView = styled.div<{
  children: ReactNode
  $dashboardWidth: number
  $isBig: boolean
  $isOpen: boolean
  $canResize: boolean
  $maxDashboardWidth: number
  $dragging: boolean
}>`${({
  $isOpen,
  $isBig,
  $canResize,
  $dashboardWidth,
  $maxDashboardWidth,
  $dragging,
}: any) =>
  css`
  position:   absolute;
  top:        0;
  bottom:     0;
  left:       ${$isOpen ? 0 : ($isBig ? `-${$dashboardWidth}px` : '-280px')};
  width:      ${$isBig ? `${$dashboardWidth}px` : '280px'};
  min-width:  ${$isBig ? '500px' : '280px'};
  max-width: ${$maxDashboardWidth}px;
  background: #22282e;
  font-size:  .9em;
  
  &::after {
    display:  ${$canResize && $isBig && $isOpen ? 'unset' : 'none'};
    opacity: ${$dragging ? '1' : 'var(--dragging)'};
    transition: opacity .1s ease-out;;
    content: '';
    background-color: #ccc;
    position: absolute;
    right: -4px;
    width: 4px;
    height: 100%;
    cursor: ew-resize;
  }
`}`

const Scroll = styled(ScrollBar)`
  position:   absolute;
  width:      100%;
  padding:    0 8px;
  height:     calc(100vh - 50px - 16px);
  top:        50px;
  overflow-y: auto;
  overflow-x: hidden;
`

const NoFile = styled.div`
  position:     absolute;
  top:          50%;
  left:         50%;
  transform:    translate(-50%, -50%);
  color:        #cccccc;
  font-size:    14px;
  font-weight:  bold;
  white-space:  nowrap;
  user-select:  none;
`

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  currentProject: state.application.main.currentProject,
  openDialogs: state.application.main.openDialogs,
  loadingStatus: state.application.main.loadingStatus,
  selectedPaths: state.data.selectedPaths,
  term: state.filter.term,
  target: state.filter.target,
  viewsObject: state.visualization.viewsObject,
  currentDashboard: state.visualization.currentDashboard,
  currentDashboardWidth: state.visualization.currentDashboardWidth,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  appState: state.application.main.appState,
  timestamps: state.timestamps,
  ...getElementsHashesObject(state),
}), {
  setTarget: FilterActions.setTarget,
  setTerm: FilterActions.setTerm,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setDashboardObject: VisualizationActions.setDashboardObject,
  setCurrentDashboardWidth: VisualizationActions.setCurrentDashboardWidth,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type State = {
  initialPosition: number
  initialSize: number
  currentPanelWidth: number
  mousePosition: number
  dragging: boolean
}

interface Props extends PropsFromRedux {
  isOpen: boolean
  t(key: string, params?: Record<string, unknown>): string
  onResizeCasterTree: (width: number) => void
}

export class CasterTree extends Component<Props, State> {
  public override state: State = {
    initialPosition: 0,
    initialSize: 0,
    currentPanelWidth: 0,
    mousePosition: 0,
    dragging: false,
  }

  public override shouldComponentUpdate (nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state.dragging, nextState.dragging)
  }

  public override componentDidUpdate (prevProps: Props) {
    const {
      featureFlags,
      visualizationMetaInformation,
      appState,
      currentDashboard,
      viewsObject,
      currentSimpleDashboardTabIndex,
      currentDashboardWidth,
    } = this.props
    const isTreeViewActive = currentSimpleDashboardTabIndex === 0

    if (isTreeViewActive && currentDashboardWidth !== 280) {
      this.handleSetWidth(280)
    }

    // TODO: this needs to be reworked, !== doesnt work for objects, it will always be true, but for now it works
    // leaving this so because the resize functionality will be changed soon, then we could change this also
    const dashboardChanged = this.props.currentDashboard !== prevProps.currentDashboard
    const tabChanged = this.props.currentSimpleDashboardTabIndex !== prevProps.currentSimpleDashboardTabIndex

    if (dashboardChanged || tabChanged) {
      if (isTreeViewActive) {
        return
      }

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
          const viewObject = viewsObject[viewId as string]

          this.handleSetWidth(viewObject?.dashboards?.[dashboardId]?.width ?? 500)
        })
      })

      if (!FeatureFlags.canResizeCasterDashboards(featureFlags, visualizationMetaInformation, appState)) {
        return
      }

      const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

      if (panel1) {
        document.addEventListener('pointerover', this.handleHoverPseudoElement, false)
        panel1.addEventListener('pointerdown', this.handleSetResize, false)
      }
    }
  }

  private readonly handleHoverPseudoElement = (event: any) => {
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (!panel1) {
      return
    }

    const dragging = Number(panel1.style.getPropertyValue('--dragging'))

    // only hover on pseudo element width
    if (Math.abs(panel1.offsetWidth - event.offsetX) <= 4) {
      panel1.style.setProperty(
        '--dragging',
        '1',
      )
    }
    else if (dragging) {
      panel1.style.setProperty('--dragging', '0')
    }
  }

  private readonly handleSetResize = (event: any) => {
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (Math.abs(panel1.offsetWidth - event.offsetX) <= 4) {
      this.setState({ mousePosition: event.x })

      document.addEventListener('pointermove', this.handleResize, false)
      document.addEventListener('pointerup', this.handleMouseUp, false)
    }
  }

  private readonly handleSetWidth = (width: number) => {
    const { setCurrentDashboardWidth, onResizeCasterTree: handleResizeCasterTree } = this.props
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (panel) {
      panel.style.width = `${width}px`
    }

    if (panel1) {
      panel1.style.width = `${width}px`
    }

    handleResizeCasterTree(width)
    setCurrentDashboardWidth(width)
  }

  private readonly handleResize = (event: any) => {
    // TODO: only resizable if mousedown and mpusemove?
    const { onResizeCasterTree: handleResizeCasterTree } = this.props
    const maxDashboardWidth = window.innerWidth - 390
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (panel && panel1) {
      const isInRange = event.x >= 500 && event.x <= maxDashboardWidth
      const isNotInRange = event.x <= 500 || event.x >= maxDashboardWidth
      const isUnderRange = event.x <= 500

      const dashboardWidth = isInRange
        ? parseInt(getComputedStyle(panel1, '').width) - (this.state.mousePosition - event.x)
        : isUnderRange
          ? 500
          : maxDashboardWidth

      panel.style.width = `${dashboardWidth}px`
      panel1.style.width = `${dashboardWidth}px`
      handleResizeCasterTree(dashboardWidth)

      if (isNotInRange) {
        this.setState({ currentPanelWidth: this.convertToMultipleOf16(dashboardWidth), dragging: true })

        return
      }

      this.setState({
        currentPanelWidth: this.convertToMultipleOf16(dashboardWidth),
        mousePosition: event.x,
        dragging: true,
      })
    }
  }

  private readonly handleMouseUp = () => {
    if (!this.state.dragging) {
      return
    }

    document.removeEventListener('pointermove', this.handleResize, false)

    const { currentDashboard, viewsObject, setDashboardObject, setCurrentDashboardWidth } = this.props
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    this.setState({ dragging: false })

    panel.style.width = `${this.state.currentPanelWidth}px`
    panel1.style.width = `${this.state.currentPanelWidth}px`
    setCurrentDashboardWidth(this.state.currentPanelWidth)

    document.removeEventListener('pointerup', this.handleMouseUp, false)

    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]

    if (!viewObject) {
      return
    }

    setDashboardObject(
      viewId as string,
      {
        ...viewObject.dashboards[dashboardId],
        width: this.state.currentPanelWidth,
      },
      dashboardId,
    )
  }

  private readonly handleSelect = (event: KeyboardEvent<EventTarget>, elementPath: string) => {
    const { setSelectedElementPaths } = this.props ?? {}

    setSelectedElementPaths(elementPath, event.ctrlKey || event.metaKey, event.shiftKey)
  }

  private readonly handleScrollToTop = () => {
    const element: any = document.getElementById('scroll')

    element.scrollTop = 0
  }

  private convertToMultipleOf16 (value: number) {
    const newValue = Math.round(value / 16) * 16

    if (newValue < 500) {
      return 500
    }

    return newValue
  }

  public override render () {
    const {
      openDialogs,
      loadingStatus,
      t,
      currentSimpleDashboardTabIndex,
      selectedPaths,
      setSelectedElementPaths,
      term,
      setTerm,
      setTarget,
      target,
      currentDashboardWidth,
      featureFlags,
      visualizationMetaInformation,
      appState,
      timestamps,
    } = this.props

    const elementsHashes = getElementsHashesObject(this.props)

    const isOpen = openDialogs.includes('CasterTree')
    const isBig = currentSimpleDashboardTabIndex > 0
    const maxDashboardWidth = window.innerWidth - 390
    const canResize = FeatureFlags.canResizeCasterDashboards(featureFlags, visualizationMetaInformation, appState)

    let content = null

    if (loadingStatus) {
      content = <TreePlaceholder />
    }
    else if (!elementsHashes.Caster) {
      content = (
        <NoFile>
          {t('treeView.openCaster')}
        </NoFile>
      )
    }
    else if (!FeatureFlags.canViewTreeView(featureFlags)) {
      content = (
        <NoFile>
          Please load a dashboard
        </NoFile>
      )
    }
    else {
      content = (
        <Scroll id='scroll'>
          <TreeView
            selectedPaths={selectedPaths}
            elementsHashes={elementsHashes}
            name='SegmentGroup'
            onSelect={this.handleSelect}
            setTerm={setTerm}
            setSelectedElementPaths={setSelectedElementPaths}
            term={term}
            setTarget={setTarget}
            target={target}
            openDialogs={openDialogs}
            referenceTimestamp={getReferenceDate(timestamps)}
          />
        </Scroll>
      )
    }

    return (
      <CasterTreeView
        $isOpen={isOpen}
        $isBig={isBig}
        $canResize={canResize}
        id='CustomResizableElement'
        $dashboardWidth={currentDashboardWidth}
        $maxDashboardWidth={maxDashboardWidth}
        $dragging={this.state.dragging}
      >
        <CasterOptions onScrollToTop={this.handleScrollToTop} />
        <CasterTreeContent defaultContent={content} />
        <CurrentDashboardWidth $visible={this.state.dragging}>
          {this.state.currentPanelWidth}
        </CurrentDashboardWidth>
      </CasterTreeView>
    )
  }
}

export default withNamespaces('caster')(connector(CasterTree as any) as any) as any
