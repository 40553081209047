export enum RollerBearingActionsEnum {
  ACTION_ADD_ROLLER_BEARING = 'RollerBearing/ACTION_ADD_ROLLER_BEARING',
  DELETE = 'RollerBearing/DELETE',
  DELETE_MULTIPLE = 'RollerBearing/DELETE_MULTIPLE',
  UPDATE = 'RollerBearing/UPDATE',
  RESET = 'RollerBearing/RESET',
}

export function addRollerBearing (rollerBearingHash: Record<number, RollerBearing>) {
  return {
    type: RollerBearingActionsEnum.ACTION_ADD_ROLLER_BEARING,
    rollerBearingHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerBearingReducers: Record<string, Function | undefined> = {
  [RollerBearingActionsEnum.ACTION_ADD_ROLLER_BEARING]: (
    _state: RollerBearingHash,
    action: { rollerBearingHash: RollerBearingHash },
  ) => (
    action.rollerBearingHash
  ),

  [RollerBearingActionsEnum.DELETE]: (state: RollerBearingHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerBearingActionsEnum.DELETE_MULTIPLE]: (state: RollerBearingHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerBearingActionsEnum.UPDATE]: (state: RollerBearingHash, action: { elements: RollerBearingHash }) => ({
    ...state,
    ...action.elements,
  }),

  [RollerBearingActionsEnum.RESET]: () => ({}),
}

export default function (
  state: RollerBearingHash = {},
  action: any,
) {
  const handler = RollerBearingReducers[action.type]

  return handler ? handler(state, action) : state
}
