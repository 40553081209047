import { v4 as uuid } from 'uuid'

import { VisualizationState } from '@/types/visualization'

import { initialState, VisualizationActionsEnum as VisActionsEnum } from '../consts'

const viewReducers = {
  [VisActionsEnum.ACTION_SPLIT_VIEW]: (
    state: VisualizationState,
    { key, direction }: { key: string, direction: 'horizontal' | 'vertical' },
  ) => {
    const viewsObjectCopy: any = { ...state.viewsObject[key] }
    const splitData = viewsObjectCopy.split ?? {}
    const splitOneId = `${key}_split_1_${uuid()}`
    const splitTwoId = `${key}_split_2_${uuid()}`
    const splitTwoDashboardId = `dashboard_${uuid()}`
    const splitOneData = {
      [splitOneId]: {
        dashboards: { ...viewsObjectCopy.dashboards },
        parent: key,
      },
    }

    delete viewsObjectCopy.dashboards
    splitData[direction] = splitData[direction] ?? []
    splitData[direction].push(splitOneId, splitTwoId)

    const currentDashboard = {
      ...state.currentDashboard,
      [splitOneId]: state.currentDashboard[key],
      [splitTwoId]: splitTwoDashboardId,
    }

    delete currentDashboard[key]

    return {
      ...state,
      viewsObject: {
        ...state.viewsObject,
        [key]: {
          ...state.viewsObject[key],
          split: splitData,
        },
        ...splitOneData,
        [splitTwoId]: {
          dashboards: {
            [splitTwoDashboardId]: {
              name: 'New Dashboard',
            },
          },
          parent: key,
        },
      },
      currentDashboard,
    }
  },

  [VisActionsEnum.ACTION_DELETE_SPLIT_VIEW]: (state: VisualizationState, action: any) => {
    const viewsObjectCopy = { ...state.viewsObject }
    const splitParentId = viewsObjectCopy[action.key].parent

    if (!splitParentId) {
      return
    }

    const splitParentCopy = { ...viewsObjectCopy[splitParentId] }
    const deletedChildren = splitParentCopy.split.vertical || splitParentCopy.split.horizontal
    const deletedChildrenCopy = [ ...deletedChildren ]
    let dashboards: any = {}

    while (deletedChildren.length) {
      const child = deletedChildren.shift()

      if (viewsObjectCopy[child].split) {
        const splits = viewsObjectCopy[child].split.vertical || viewsObjectCopy[child].split.horizontal

        deletedChildren.push(...splits)
      }
      else {
        dashboards = Object.assign(viewsObjectCopy[child].dashboards, dashboards)
      }

      delete viewsObjectCopy[child]
    }

    viewsObjectCopy[splitParentId].dashboards = {
      ...dashboards,
    }

    delete viewsObjectCopy[splitParentId].split

    const currentDashboard = {
      ...state.currentDashboard,
      [splitParentId]: state.currentDashboard[deletedChildrenCopy[0]],
    }

    delete currentDashboard[deletedChildrenCopy[0]]
    delete currentDashboard[deletedChildrenCopy[1]]

    return {
      ...state,
      viewsObject: {
        ...viewsObjectCopy,
      },
      currentDashboard,
    }
  },
  [VisActionsEnum.ACTION_SET_SECONDARY_SIZE]: (
    state: VisualizationState,
    { viewId, size }: { viewId: string, size: number },
  ) => ({
    ...state,
    viewsObject: {
      ...state.viewsObject,
      [viewId]: {
        ...state.viewsObject[viewId],
        split: {
          ...state.viewsObject[viewId].split,
          size,
        },
      },
    },
  }),

  [VisActionsEnum.ACTION_RESET_VIEW_OBJECT]: (state: VisualizationState) => ({
    ...state,
    viewsObject: initialState.viewsObject,
  }),
}

export default viewReducers
