import { io, Socket } from 'socket.io-client'

import { useConfig } from '@/config'
import { AuthDataStore } from '@/logic/auth-data-store'

type IncomingEventPayload = {
  event: string
  body: unknown
}

type ClientData = {
  projectId?: string | null
  caseId?: string | null
  casterId?: string | null
  liveMode?: boolean
}

export enum NetworkStatus {
  Connected = 'connected',
  Connecting = 'connecting',
  Disconnected = 'disconnected',
}

export class NetworkEvent {
  private static socket: Socket

  private static firstConnect = true

  public static readonly ConnectionStatusChanged = 'network-event-connection-status-changed'

  public static init () {
    if (NetworkEvent.socket) {
      // eslint-disable-next-line no-console
      console.warn('EventManager.init: already initialized')

      return
    }

    // eslint-disable-next-line no-console
    console.log('EventManager.init')

    const socket = NetworkEvent.socket = io(useConfig().apiBaseURL, { transports: [ 'websocket' ] })

    socket.on('connect', NetworkEvent.handleConnected)
    socket.io.on('reconnect_attempt', NetworkEvent.handleReconnectAttempt)
    socket.on('disconnect', NetworkEvent.handleDisconnected)
    socket.on('event', NetworkEvent.handleEvent)
  }

  public static send (event: string, body: unknown) {
    NetworkEvent.emit('event', { data: { event, body } })
  }

  public static subscribe (event: string, callback: (payload: any) => void) {
    // TODO: using an arrow function prevents us from unsubscribing later, but it's needed to get the detail property

    window.addEventListener(event, (customEvent) => callback((customEvent as CustomEvent).detail))
  }

  public static register () {
    NetworkEvent.emit('register')
  }

  public static unregister () {
    NetworkEvent.emit('unregister', {}, false)
  }

  public static setClientData (data: ClientData) {
    NetworkEvent.emit('set-client-data', data)
  }

  private static emit (event: string, data: Record<string, unknown> = {}, sendToken = true) {
    if (!NetworkEvent.socket?.connected) {
      // eslint-disable-next-line no-console
      return console.warn('EventManager.emit: not connected')
    }

    data.token = sendToken ? AuthDataStore.get()?.accessToken : null

    NetworkEvent.socket.emit(event, data)
  }

  private static dispatchEvent (event: string, body: unknown) {
    window.dispatchEvent(new CustomEvent(event, { detail: body }))
  }

  private static handleConnected () {
    NetworkEvent.register()

    if (NetworkEvent.firstConnect) {
      NetworkEvent.firstConnect = false

      NetworkEvent.setClientData({ projectId: null, caseId: null, casterId: null })
    }

    NetworkEvent.dispatchEvent(NetworkEvent.ConnectionStatusChanged, NetworkStatus.Connected)
  }

  private static handleReconnectAttempt () {
    NetworkEvent.dispatchEvent(NetworkEvent.ConnectionStatusChanged, NetworkStatus.Connecting)
  }

  private static handleDisconnected () {
    NetworkEvent.dispatchEvent(NetworkEvent.ConnectionStatusChanged, NetworkStatus.Disconnected)
  }

  private static handleEvent (payload: IncomingEventPayload) {
    NetworkEvent.dispatchEvent(payload.event, payload.body)
  }
}
