import cloneDeep from 'lodash/cloneDeep'

import Util from '@/logic/Util'
import { PARENT } from '@/react/context/AllInOne/consts'
import CalculateHelperFunctions from '@/react/context/form/functions/CalculateHelperFunctions'
import ThreeUtil from '@/three/logic/Util'
import MainView from '@/three/views/MainView'
import { StrandSides } from '@/types/elements/enum'
import type { ElementsHashes } from '@/types/state'

import { deleteElement } from './deleters'
import { getChildrenArrayByIds, getChildRollerBodyMountLogsByIds } from '../logic'

export function getPathByTypeAndId (type: CasterElementNames, id: number, elementsHashes: ElementsHashes) {
  const path = []
  let currentType: CasterElementNames | null | undefined = type
  let currentId = id

  do {
    path.push(`${currentType}:${currentId}`)
    currentId = elementsHashes[currentType]?.[currentId]
    currentType = PARENT[currentType]
  }
  while (currentType)

  return path.reverse().join('/')
}

export function getNewIdForType (xmlGenParams: XMLGenParams, type: string): number {
  const key = `lastTypeId_${type}` as XMLGenParamsIDKey

  xmlGenParams[key] = String(Number(xmlGenParams[key] ?? '0') + 1)

  return Number(xmlGenParams[key])
}

export function buildChangeItemHash (xmlData: XMLData, changeItemHash: ChangeItemHash) {
  let changeItems = xmlData.root.EditsBy3DCC?.ChangeItem

  if (!changeItems) {
    return
  }

  if (!Array.isArray(changeItems)) {
    changeItems = [ changeItems ]
  }

  for (const changeItem of changeItems) {
    changeItemHash[changeItem.uuid] = changeItem
  }
}

export function buildCCElementHash (xmlData: XMLData, ccElementHash: CCElementHash) {
  let ccElements = xmlData.root.CCResult?.CCElement

  if (!ccElements) {
    return
  }

  if (!Array.isArray(ccElements)) {
    ccElements = [ ccElements ]
  }

  for (const ccElement of ccElements) {
    ccElementHash[ccElement.uuid] = ccElement
  }
}

export function updateSidesListWithRoller (
  sidesList: any[],
  side: any,
  path: string,
  element: any,
  previousElement: any,
) {
  sidesList[side] = sidesList[side] ?? {}

  sidesList[side][path] = {
    element,
    previousElement,
  }
}

export function parsePasslineData (element: any) {
  const keys = Object.keys(element)

  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'section') {
      element[keys[i]] = element[keys[i]] instanceof Array ? element[keys[i]] : [ element[keys[i]] ]
    }
  }
}

function getElementByDataLineRef (ref: string, elementsHashes: any) {
  const refInfo = ref.split(':')

  if (refInfo.length !== 2) {
    return null
  }

  const [ type, refId ] = refInfo

  const elementsTypeHash = elementsHashes[type]
  const elementMountLogType = `${type}MountLog`
  const mountLogsHash = elementsHashes[elementMountLogType]

  if (!elementsTypeHash || !mountLogsHash || !type) {
    return null
  }

  // type's first letter lowercased
  const elementIdKey = `${type[0].toLowerCase()}${type.substring(1)}Id`
  const mountLogs = Object.values(mountLogsHash)

  for (let i = 0; i < mountLogs.length; i++) {
    const mountLog = mountLogs[i] as any
    const elementInfoId = mountLog[elementIdKey]
    const element = elementsTypeHash[elementInfoId]

    if (element?.additionalData?.refId === refId) {
      return element
    }
  }

  return null
}

export function linkDataLinesWithElements (
  elementsHashes: ElementsHashes,
  dataLines: DataLine[],
) {
  Object.values(dataLines).forEach((dataLine: any) => {
    if (!dataLine || !dataLine.ref) {
      return
    }

    const { ref } = dataLine

    const element = getElementByDataLineRef(ref, elementsHashes)
    const referencedXCoord = Util.fromSnakeToCamelCase(dataLine.xCoords ?? '')
    const referencedYValues = Util.fromSnakeToCamelCase(dataLine.yValues ?? '')
    const elementReferencedXCoords = element?.[referencedXCoord] ?? element?.additionalData?.[referencedXCoord]
    const elementReferencedYValues = element?.[referencedYValues] ?? element?.additionalData?.[referencedYValues]

    if (
      !element ||
      typeof referencedXCoord !== 'string' ||
      typeof referencedYValues !== 'string' ||
      !elementReferencedXCoords ||
      !elementReferencedYValues
    ) {
      return
    }

    dataLine[String(referencedXCoord)] = elementReferencedXCoords
    dataLine[String(referencedYValues)] = elementReferencedYValues
  })
}

export function checkPositivePropertyAndInKeyList (keys: string[], element: any, properties: string[]) {
  for (let i = 0; i < properties.length; i++) {
    if (!(keys.includes(properties[i]) && element[properties[i]] > 0)) {
      return false
    }
  }

  return true
}

export function positivePropertiesAndInKeyList (editValues: any, keys: string[], elementType: CasterElementNames) {
  let createValid = false

  switch (elementType) {
    case 'Nozzle':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Nozzle, [ 'height', 'angleWidth', 'angleLength' ])
      ) {
        createValid = true
      }

      break
    case 'Roller':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Roller, [ 'diameter', 'rollWidth' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBody':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBody, [ 'diameter', 'width' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBearing':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBearing, [ 'width' ])
      ) {
        createValid = true
      }

      break
    default:
      createValid = false
  }

  return createValid
}

export function getElementFromPath<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
  path: string,
  elementsHashes: ElementsHashes,
  copy?: boolean,
) {
  if (!elementsHashes) {
    return null
  }

  return Util.getElement<Element, MountLog>(path, elementsHashes, copy)
}

export function getDifferenceElement<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
  paths: string[],
  editElements: any,
  elementsHashes: ElementsHashes,
) {
  let highestElement: any = {}
  let highestElementOriginal = { passlineCoord: Infinity } as FullCasterElement<Element, MountLog>

  paths.forEach(path => {
    const element = getElementFromPath<Element, MountLog>(path, elementsHashes, true)

    if (element && (highestElementOriginal.passlineCoord ?? 0) > (element.passlineCoord ?? 0)) {
      highestElementOriginal = element
      highestElement = { ...editElements[path] }
    }
  })

  const differenceElement: any = { ...highestElement }

  differenceElement.passlineCoord -= highestElementOriginal.passlineCoord ?? 0
  differenceElement.widthCoord -= highestElementOriginal.widthCoord ?? 0

  return differenceElement
}

export function getPathArrayFromPath (path: string) {
  return path.split('/').reduce((list: any, part) =>
    (parts => [
      ...list,
      parts[0],
      Number(parts[1]),
    ])(part.split(':')), []) as (number | string)[]
}

export function getSegmentByPasslnAndSide (
  segmentMountLogHash: SegmentMountLogHash,
  segmentHash: SegmentHash,
  passlineCoord: number,
  side: StrandSide,
) {
  const segmentMountLogHashCopy = { ...segmentMountLogHash }

  return Object
    .values(segmentMountLogHashCopy)
    .filter((segmentMountLog) => (
      (segmentMountLog.passlineCoord ?? 0) <= passlineCoord &&
      segmentHash[segmentMountLog.segmentId ?? '']?.side === side
    ))
    .sort((a, b) => Number(b.passlineCoord) - Number(a.passlineCoord))[0]
}

// export function getSegmentGroupMountLogByPassln (SegmentGroupMountLogHash: SegmentGroupMountLogHash, passln: number){
//   const segmentGroupMountLogHashCopy = { ...SegmentGroupMountLogHash }

//   return Object.values(segmentGroupMountLogHashCopy)
//     .filter((segGroup) => segGroup.passlineCoord <= passln)
//     .sort((a, b) => b.passlineCoord - a.passlineCoord)[0]
// }

export function getSegmentByRollerPassln (rollerMountLogs: RollerMountLog[], passlineCoord: number) {
  const mountLogs = rollerMountLogs
    .filter(rollerMountLog => (rollerMountLog.passlineCoord ?? 0) > passlineCoord)
    .sort(CalculateHelperFunctions.byPasslineCoordDesc)

  return mountLogs[mountLogs.length - 1]
}

export function getDefaultElement (element: any, elementType: string) {
  let newElement = { ...element }

  if (elementType === 'Roller') {
    newElement = {
      ...newElement,
      passlineCoord: newElement.passlineCoord ?? 0,
      RollerBearing: [], // FIXME: does this work, or do we need to add these via API?
      RollerBody: [], // FIXME: does this work, or do we need to add these via API?
    } as Roller & RollerMountLog

    const { rollWidth, diameter, passlineCoord } = newElement
    const RollerBody = {
      width: rollWidth,
      widthCoord: (rollWidth / 2) * -1,
      name: 'DSCRollerBody',
      diameter,
      passlineCoord,
    } as RollerBody & RollerBodyMountLog

    const RollerBearing = [
      {
        width: 100,
        widthCoord: rollWidth / 2,
        name: '',
        passlineCoord,
      },
      {
        width: 100,
        widthCoord: -(rollWidth / 2) - 100,
        name: '',
        passlineCoord,
      },
    ] as (RollerBearing & RollerBearingMountLog)[]

    newElement.RollerBody.push(RollerBody) // TODO: make sure new elements get new ids
    newElement.RollerBearing.push(...RollerBearing) // TODO: make sure new elements get new ids
  }

  return newElement
}

export function getGapPositions (
  elementsHashes: ElementsHashes,
  element: any,
  targetArray: any[],
  offset: number,
  amount: number,
) {
  const gapPasslineCoords: number[] = []
  const segmentMountLogId = MainView.numericIdMountLogMaps.SegmentMountLog[targetArray[3]]
  const segmentMountLog = elementsHashes.SegmentMountLog[segmentMountLogId]
  const segmentSide = elementsHashes.Segment[segmentMountLog.segmentId ?? '']?.side ?? ''

  const segmentGroupMountLogId = MainView.numericIdMountLogMaps.SegmentGroupMountLog[targetArray[1]]

  const rollerList = CalculateHelperFunctions
    .getRollerList(elementsHashes, segmentGroupMountLogId, segmentSide, [])
    .sort(CalculateHelperFunctions.byPasslineCoordAsc)

  const startCoord = Number(element.passlineCoord)
  let rollerMountLogs = []

  if (offset > 0) {
    rollerMountLogs = rollerList.filter(val => (val.passlineCoord ?? 0) > startCoord)
  }
  else {
    rollerMountLogs = rollerList.filter(val => (val.passlineCoord ?? 0) < startCoord).reverse()
  }

  for (let i = Math.abs(offset) - 1; i < rollerMountLogs.length - 1; i += Math.abs(offset)) {
    const roller = elementsHashes.Roller[rollerMountLogs[i].rollerId ?? '']
    const roller2 = elementsHashes.Roller[rollerMountLogs[i + 1].rollerId ?? '']

    if (offset > 0) {
      const a = Number(rollerMountLogs[i].passlineCoord) + Number(roller?.diameter ?? 0) / 2
      const b = Number(rollerMountLogs[i + 1].passlineCoord) - Number(roller2?.diameter ?? 0) / 2

      gapPasslineCoords.push(a + ((b - a) / 2))
    }
    else {
      const a = Number(rollerMountLogs[i].passlineCoord) - Number(roller?.diameter ?? 0) / 2
      const b = Number(rollerMountLogs[i + 1].passlineCoord) + Number(roller2?.diameter ?? 0) / 2

      gapPasslineCoords.push(a - ((a - b) / 2))
    }

    if (gapPasslineCoords.length === amount) {
      break
    }
  }

  return { gapPasslineCoords, rollerMountLogs }
}

export function getAddedObject (
  addedObject: any,
  // eslint-disable-next-line camelcase
  XML_Generation_Parameters: XMLGenParams,
  elementType: CasterElementNames,
) {
  const object = addedObject

  object._numericId = getNewIdForType(XML_Generation_Parameters, elementType)
  object._id = object._numericId

  return object
}

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case StrandSides.Fixed:
      distance2passline = radius
      break
    case StrandSides.Loose:
      distance2passline = radius + thickness
      break
    case StrandSides.Left:
    case StrandSides.Right:
      distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}

export function getAddedElementPathWithoutNewParent (
  parentPathArray: string[],
  elementType: CasterElementNames,
  counter: number,
) {
  const newParentPath = Util.getPathFromPathArray(parentPathArray)

  return `${newParentPath}/${elementType}:-${counter}`
}

export function handleWaterFluxFraction (data: any) {
  const { Mold } = data.root.Caster
  const { SegmentGroup } = data.root.Caster.StrandGuide
  const coolingLoopAssignments: any = {}

  ThreeUtil.runPerElement(
    'SegmentGroup',
    'SegmentGroup',
    SegmentGroup,
    null,
    '',
    (path: string, type: CasterElementNames, element: any) => {
      if (type === 'Nozzle') {
        coolingLoopAssignments[Number(element._loop)] = (coolingLoopAssignments[Number(element._loop)] ?? 0) + 1
      }
    },
  )

  ThreeUtil.runPerElement(
    'SegmentGroup',
    'SegmentGroup',
    SegmentGroup,
    null,
    '',
    (path: string, type: CasterElementNames, element: any, previous: any) => {
      if (type === 'Nozzle') {
        // eslint-disable-next-line camelcase
        element._water_flux_fraction = (1 / coolingLoopAssignments[Number(element._loop)]).toFixed(4)

        return
      }

      if (type === 'Roller') {
        const { _thickness, _width } = Mold
        const { side } = previous
        const radius = Number(element._diameter) / 2

        const distanceToPassline = getDistanceToPassLine(
          -1,
          side,
          radius,
          Number(_thickness),
          Number(_width),
        )

        element._dist2passline = distanceToPassline
      }
    },
  )

  return coolingLoopAssignments
}

export function saveRollerElements<Element extends RollerBody, MountLog extends RollerBodyMountLog> (
  type: string,
  newElement: any, // TODO: maybe use FullCasterElement<Element, MountLog> instead of any?
  path: string,
  elementsHashes: ElementsHashes,
  rollerChildrenPath: string[],
) {
  newElement[type].forEach((element: any) => {
    const rollerElementPath = `${path}/${type}:${element._id}`
    const originalRollerBody = getElementFromPath<Element, MountLog>(rollerElementPath, elementsHashes)

    if (!originalRollerBody) {
      return
    }

    if (type === 'body') {
      originalRollerBody.diameter = newElement.diameter
    }

    originalRollerBody.passlineCoord = newElement.passlineCoord

    rollerChildrenPath.push(rollerElementPath)
  })
}

// FIXME: is this even used/called?
export function completeTargetPathArray (
  rawPath: string[] | string,
  targetPath: string[] | string,
  elementsHashes: ElementsHashes,
) {
  const path = rawPath instanceof Array ? Util.getPathFromPathArray(rawPath) : rawPath
  const targetArray = targetPath instanceof Array ? targetPath : getPathArrayFromPath(targetPath)

  const targetArrayComplete = [ ...targetArray ]

  // when only SegmentGroup is selected (e.g. SegmentGroup:1) we need to add the Segment to the path
  // this only works for elements that are direct children of Segment (e.g. Nozzle, Roller)
  if (targetArrayComplete.length === 2) {
    const currentParentPath = path.substring(0, path.lastIndexOf('/'))
    const segment = getElementFromPath<Segment, SegmentMountLog>(currentParentPath, elementsHashes)
    const [ sg, sgId ] = targetArrayComplete
    const segmentGroupPath = `${sg}:${sgId}`
    const segmentGroup = getElementFromPath<SegmentGroup, SegmentGroupMountLog>(segmentGroupPath, elementsHashes)

    // TODO: should we throw an error here?
    if (!segment || !segmentGroup) {
      return targetArrayComplete
    }

    const { segmentMountLogs } = segmentGroup
    const { side } = segment

    let numericId = -1

    for (const segmentMountLogId of segmentMountLogs) {
      const segmentMountLog = elementsHashes.SegmentMountLog[segmentMountLogId]
      const segment = elementsHashes.Segment[segmentMountLog?.segmentId ?? '']

      if (segment?.side === side) {
        numericId = MainView.numericIdMountLogMaps.Segment[segment.id]

        break
      }
    }

    // TODO: should we throw an error if the numericId is -1?
    if (numericId !== -1) {
      targetArrayComplete.push('Segment')
      targetArrayComplete.push(numericId)
    }
  }

  return targetArrayComplete
}

export function setElement (
  elementsHashes: ElementsHashes,
  type: CasterElementNames,
  element: any,
  parentType: CasterElementNames,
  parentId: number,
  pushToList = true,
) {
  if (!elementsHashes[type]) {
    elementsHashes[type] = {}
  }

  if (!elementsHashes[parentType]) {
    elementsHashes[parentType] = {}
  }

  element['#parent'] = {
    type: parentType,
    id: parentId,
  }
  elementsHashes[type][element.id] = element
  // elementsHashes[parentType][element._id] = element

  if (pushToList) {
    if (!elementsHashes[parentType][parentId][`#${type}Ids`]) {
      elementsHashes[parentType][parentId][`#${type}Ids`] = []
    }

    elementsHashes[parentType][parentId][`#${type}Ids`].push(element.id)
  }
}

export function save (
  {
    elementsHashes,
    dirtyPaths,
    editElements,
    loopCounter,
  }: {
    elementsHashes: ElementsHashes
    dirtyPaths: string[]
    editElements: Record<string, any>
    loopCounter: LoopCounter
  },
  path: string,
  isRoller: boolean,
  targetArray?: any[],
) {
  const pathArray = getPathArrayFromPath(path)

  const originalElement = getElementFromPath(path, elementsHashes)

  if (!originalElement) {
    return
  }

  const newElement = editElements[path]
  const [ type, id ] = pathArray.slice(-2) as [CasterElementNames, number]

  if (type === 'Nozzle') {
    const oldLoop = (originalElement as FullCasterElement<Nozzle, NozzleMountLog>).coolLoopIndex
    const newLoop = newElement.coolLoopIndex

    if (oldLoop && newLoop && oldLoop !== newLoop) {
      loopCounter[oldLoop] -= 1
      loopCounter[newLoop] = (loopCounter[newLoop] ?? 0) + 1
    }
  }

  if (isRoller) {
    const rollerChildrenPath: string[] = []

    if (newElement.RollerBearing && newElement.RollerBearing.length) {
      saveRollerElements('RollerBearing', newElement, path, elementsHashes, rollerChildrenPath)
    }

    if (newElement.RollerBody && newElement.RollerBody.length) {
      saveRollerElements('RollerBody', newElement, path, elementsHashes, rollerChildrenPath)
    }

    dirtyPaths.push(...rollerChildrenPath)
  }

  if (!targetArray) {
    // TODO: this should be outside this if, but is causes the value like passln_coord to jump back
    Object.keys(newElement).forEach(key => {
      ;(originalElement as any)[key] = newElement[key] // FIXME: fix type, maybe rework function
    })

    return
  }

  const targetArrayComplete = completeTargetPathArray(path, targetArray, elementsHashes)
  const [ parentType, parentId ] = targetArrayComplete.slice(-2) as [CasterElementNames, number]

  setElement(elementsHashes, type, newElement, parentType, parentId)

  const { type: oldParentType, id: oldParentId } = ThreeUtil.getParentInfo(path)
  const oldParentListIds = (elementsHashes as any)[oldParentType][oldParentId][`#${type}Ids`]

  oldParentListIds.splice(oldParentListIds.findIndex((oldId: number) => oldId === id), 1)

  const currentParentPath = Util.getPathFromPathArray(targetArrayComplete)

  return `${currentParentPath}/${type}:${newElement.id}`
}

export function handleSuccessfullySavedPaths (
  paths: string[],
  actionType: string,
  parentPath: string | null,
  targetArray: any[],
  dirtyPaths: string[],
  dirtyDeletePaths: string[],
  selectedPaths: Set<string>,
  hidePaths: string[],
  editElements: any,
  elementsHashes: ElementsHashes,
  loopCounter: LoopCounter,
) {
  const args = {
    elementsHashes,
    dirtyPaths,
    editElements,
    loopCounter,
  }

  paths.forEach(path => {
    const pathArray = getPathArrayFromPath(path)

    if (actionType !== 'delete') {
      if (!parentPath || path.indexOf(parentPath) === 0) {
        save(args, path, pathArray.slice(-2)[0] === 'Roller')
      }
      else {
        const newPath = save(args, path, pathArray.slice(-2)[0] === 'Roller', targetArray)

        if (!newPath) {
          return
        }

        dirtyPaths.push(newPath)
        dirtyDeletePaths.push(path)
        selectedPaths.delete(path)
        selectedPaths.add(newPath)
      }

      if (dirtyDeletePaths.includes(path) && !hidePaths.includes(path)) {
        hidePaths.push(path)
        selectedPaths.delete(path)
        delete editElements[path]
      }
    }
    else {
      const [ type, id ] = pathArray.slice(-2) as [CasterElementNames, number]

      if (type === 'Nozzle') {
        const element = getElementFromPath<Nozzle, NozzleMountLog>(path, elementsHashes)

        if (element?.coolLoopIndex) {
          loopCounter[element.coolLoopIndex] -= 1
        }
      }

      deleteElement(type, id, elementsHashes)

      const index = dirtyDeletePaths.indexOf(path)

      if (~index) {
        dirtyDeletePaths.splice(index, 1)
      }
    }

    dirtyPaths.push(path)
  })

  return selectedPaths
}

export function setRollerBodyData (
  object: any,
  // eslint-disable-next-line camelcase
  XML_Generation_Parameters: XMLGenParams,
  elementsHashes: ElementsHashes,
  path: string,
  rollerChildrenPath: string[],
) {
  object.RollerBody.forEach((rollerBody: any) => {
    // eslint-disable-next-line camelcase
    rollerBody.passlineCoord = object.passlineCoord
    rollerBody.diameter = object.diameter

    rollerBody.numericId = getNewIdForType(XML_Generation_Parameters, 'RollerBody')
    rollerBody.id = rollerBody.numericId

    setElement(elementsHashes, 'RollerBody', rollerBody, 'Roller', object.id, false)

    const rollerBodyPath = `${path}/RollerBody:${rollerBody.id}`

    rollerChildrenPath.push(rollerBodyPath)
  })
}

export function compareAndUpdateElementKeys (data: any, element: any) {
  Object.keys(data).forEach(key => {
    if (!/^_(parent|numeric|original)?id$/i.test(key)) {
      element[key] = data[key]
    }
  })
}

export function emptyObjectByReference (object: any) {
  for (const property in object) {
    delete object[property]
  }
}

export function handleRepeatPaths (
  repeatPath: string[],
  targetArray: any[],
  elementsHashes: ElementsHashes,
  mode: string,
  addedElements: any[],
  editElements: any,
  elementType: CasterElementNames,
  amount: number,
  offset: number,
) {
  let gapWarnings = 0

  repeatPath.forEach(rawPath => {
    const targetPath = targetArray.length > 3
      ? Util.getPathFromPathArray(targetArray)
      : ThreeUtil.getParentInfo(rawPath).path

    const element = getElementFromPath(rawPath, elementsHashes, true)

    if (!element) {
      return
    }

    const { type: parentType, id: numericParentId } = ThreeUtil.getElementInfo(targetPath)
    const parentMountLogType = `${parentType}MountLog` as CasterElementNames
    const parentMountLogIdTypeKey = `${parentMountLogType[0].toLowerCase() + parentMountLogType.substring(1)}Id`
    const parentMountLogId = MainView.numericIdMountLogMaps[parentMountLogType][numericParentId] as string | undefined

    switch (mode) {
      case 'mirror':
        addedElements.push({
          ...element,
          ...editElements[rawPath],
          widthCoord: -Number(element.widthCoord),
          [parentMountLogIdTypeKey]: parentMountLogId,
        })

        break
      case 'offset':
        for (let i = 1; i <= amount; i++) {
          addedElements.push({
            ...element,
            ...editElements[rawPath],
            passlineCoord: Number(element.passlineCoord) + (Number(offset) * i),
            [parentMountLogIdTypeKey]: parentMountLogId,
          })
        }

        break
      case 'gap offset': // Nozzle only
        {
          const newTargetPath = getPathArrayFromPath(rawPath)
          const { gapPasslineCoords, rollerMountLogs } = getGapPositions(
            elementsHashes,
            element,
            newTargetPath,
            offset,
            amount,
          )

          const parentPathArray = getPathArrayFromPath(targetPath)
          const originalSegmentMountLogId = MainView.numericIdMountLogMaps.SegmentMountLog[Number(parentPathArray[3])]
          const originalSegmentMountLog = elementsHashes.SegmentMountLog[originalSegmentMountLogId]
          const originalSegment = elementsHashes.Segment[originalSegmentMountLog.segmentId ?? '']

          for (let i = 0; i < amount; i++) {
            if (i >= gapPasslineCoords.length) {
              gapWarnings++

              continue
            }

            const rollerMountLog = getSegmentByRollerPassln(rollerMountLogs, gapPasslineCoords[i])
            const segmentMountLog = getSegmentByPasslnAndSide(
              elementsHashes.SegmentMountLog,
              elementsHashes.Segment,
              rollerMountLog.passlineCoord ?? 0,
              originalSegment?.side ?? '' as StrandSide,
            )

            if (!segmentMountLog) {
              gapWarnings++

              continue
            }

            addedElements.push({
              ...getElementFromPath(rawPath, elementsHashes, true),
              ...editElements[rawPath],
              passlineCoord: gapPasslineCoords[i],
              segmentMountLog: segmentMountLog.id,
            })
          }
        }

        break
      default:
        break
    }
  })

  return { gapWarnings }
}

export function handleCopyPaths (
  copyPath: string[],
  elementsHashes: ElementsHashes,
  elementType: CasterElementNames,
  editElements: any,
  diffPasslnCoord: number,
  diffWidthCoord: number,
  parentMountLogTypeKey: string,
  parentMountLogId: string,
) {
  const addedElements: any[] = []

  copyPath.forEach((rawPath) => {
    const origElement = getElementFromPath(rawPath, elementsHashes, true)

    if (!origElement) {
      return
    }

    const { passlineCoord: orgPasslnCoord, widthCoord: orgWidthCoord } = origElement
    const element = { ...origElement, ...editElements[rawPath] }

    // FIXME: what do we need to do here?
    if (elementType === 'Roller') {
      // deleteChildrenId(elementType, element)
    }

    if (orgPasslnCoord) {
      element.passlineCoord = Number(orgPasslnCoord) + Number(diffPasslnCoord)
    }

    if (orgWidthCoord) {
      element.widthCoord = Number(orgWidthCoord) + Number(diffWidthCoord)
    }

    element[parentMountLogTypeKey] = parentMountLogId

    addedElements.push(element)
  })

  return addedElements
}

// TODO: fix bug, sometimes parentRoller is undefined
export function setRollerDiameter (
  editElements: any,
  paths: string[],
  elementsHashes: ElementsHashes,
  rollerChildrenPaths: string[],
) {
  const data = cloneDeep(editElements)

  paths.forEach(path => {
    const parentRoller = getElementFromPath<Roller, RollerMountLog>(path, elementsHashes)

    if (!parentRoller) {
      return
    }

    // check if diameters are different
    if (Number(editElements[path].diameter) !== Number(parentRoller.diameter)) {
      // update rollerBody's diameter
      const childRollerBodyMountLogs = getChildRollerBodyMountLogsByIds(
        elementsHashes,
        parentRoller.rollerBodyMountLogs,
      )

      for (const rollerBodyMountLog of childRollerBodyMountLogs) {
        const rollerBody = elementsHashes.RollerBody[rollerBodyMountLog.rollerBodyId ?? '']

        if (!rollerBody) {
          continue
        }

        const numericId = MainView.numericIdMountLogMaps.RollerBodyMountLog[rollerBodyMountLog.id]

        if (isNaN(numericId)) {
          continue
        }

        if (Number(editElements[path].diameter) !== Number(rollerBody.diameter)) {
          const rollerBodyPath = `${path}/RollerBody:${numericId}`

          if (!data[rollerBodyPath]) {
            data[rollerBodyPath] = {
              ...rollerBody,
            }
          }

          data[rollerBodyPath].diameter = Number(editElements[path].diameter)

          if (!rollerChildrenPaths.includes(rollerBodyPath)) {
            rollerChildrenPaths.push(rollerBodyPath)
          }
        }
      }
    }
  })

  return data
}

export function setRollerPasslnCoord (
  editElements: any,
  paths: string[],
  elementsHashes: ElementsHashes,
  rollerChildrenPaths: string[],
) {
  const data = cloneDeep(editElements)

  paths.forEach(path => {
    const parentRoller = getElementFromPath<Roller, RollerMountLog>(path, elementsHashes)

    if (!parentRoller) {
      return
    }

    // if roller's passLnCoord changed
    if (Number(editElements[path].passlineCoord) !== Number(parentRoller.passlineCoord)) {
      // update rollerBodies
      getChildrenArrayByIds(elementsHashes, 'RollerBody', editElements[path]['#RollerBodyIds'])
        .forEach((rollerBody: any) => {
          const rollerBodyPath = `${path}/RollerBody:${rollerBody._id}`

          if (!data[rollerBodyPath]) {
            data[rollerBodyPath] = {
              ...rollerBody,
            }
          }

          data[rollerBodyPath].passlineCoord = Number(editElements[path].passlineCoord)

          if (!rollerChildrenPaths.includes(rollerBodyPath)) {
            rollerChildrenPaths.push(rollerBodyPath)
          }
        })

      // update rollerBearings
      getChildrenArrayByIds(elementsHashes, 'RollerBearing', editElements[path]['#RollerBearingIds'])
        .forEach((rollerBearing: any) => {
          const rollerBearingPath = `${path}/RollerBearing:${rollerBearing._id}`

          if (!data[rollerBearingPath]) {
            data[rollerBearingPath] = {
              ...rollerBearing,
            }
          }

          data[rollerBearingPath].passlineCoord = Number(editElements[path].passlineCoord)

          if (!rollerChildrenPaths.includes(rollerBearingPath)) {
            rollerChildrenPaths.push(rollerBearingPath)
          }
        })
    }
  })

  return data
}
