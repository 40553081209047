import hotkeys from 'hotkeys-js'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { allVerified } from '@/logic/case-verification'
import Button from '@/react/components/Button/index'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { FixedButton } from '@/react/dialogs/DialogStyles'
import FeatureFlags from '@/react/FeatureFlags'
import { Form, List, Text, TextBody } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import * as MatrixActions from '@/store/matrix/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from '../Logic'

const T = 'projectMatrixDialog.simulateDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  caseVerifications: state.application.main.caseVerifications,
}), {
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  all: boolean
  t: Translation
}

type State = {
  error: string
  loading: boolean
}

export class SimulateDialog extends Component<Props, State> {
  @Identifiable('SimulateDialog') public static readonly NAME: string

  public static readonly ALLNAME = 'SimulateDialogAll'

  public override state: State = {
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    hotkeys('Enter', this.handleStartSimulation)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleStartSimulation)
  }

  private readonly handleClose = () => {
    const { closeDialog, all } = this.props

    closeDialog(all ? SimulateDialog.ALLNAME : SimulateDialog.NAME)
  }

  private readonly handleStartSimulation = () => {
    const { selections, setSelections } = this.props
    const verifiedSimulationCases = this.handleGetVerified()
    const ids = verifiedSimulationCases.map(({ simulationCase }) => simulationCase.id)

    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })
    setSelections(deselect)

    Logic.handleSimulateMultiple(ids)
    this.handleClose()
  }

  private readonly handleGetVerified = () => {
    const { currentProject, selections, all, caseVerifications } = this.props

    return currentProject
      .simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => all || selections[simulationCase.id])
      .filter(({ simulationCase }) => {
        const {
          simulationStartedAt,
        } = simulationCase

        // FIXME: get selected catalog from the store
        const selectedCatalog = 'default'

        // FIXME: this is missing verifications for all cases
        return !simulationStartedAt && allVerified(simulationCase.id, caseVerifications, selectedCatalog)
      })
  }
  
  public override render () {
    const { error, loading } = this.state
    const { t, featureFlags } = this.props

    const verifiedSimulationCases = this.handleGetVerified()
    const canStartSimulation = FeatureFlags.canStartSimulation(featureFlags)

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-display1'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${T}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                verifiedSimulationCases.map(({ num, simulationCase }) => (
                  <li key={simulationCase.id}>{num}. {simulationCase.name}</li>
                ))
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleStartSimulation}
              error={error}
              loading={loading}
              icon='pe-7s-display1'
              isRef
              disabled={!canStartSimulation}
            >
              {t(`${T}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(SimulateDialog as any) as any) as any
