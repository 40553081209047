export default class LogicInterface {
  public on () {
    // eslint-disable-next-line no-console
    console.log('error on')
  }

  public send () {
    // eslint-disable-next-line no-console
    console.log('error send')
  }

  public removeListener () {
    // eslint-disable-next-line no-console
    console.log('error removeListener')
  }

  public has (_functionName: string): boolean {
    return false
    // eslint-disable-next-line no-console
    console.log('error has')
  }
}
