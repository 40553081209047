import type { PlotConfig, VisualizationData } from '@/types/visualization'

export default class VisUtil {
  public static readonly GROUP_MERGED = 'Merged Plots'

  public static readonly GROUP_DERIVED = 'Derived Plots'

  public static readonly TRACE_COLORS = [
    '#1f77b4', // muted blue
    '#ff7f0e', // safety orange
    '#2ca02c', // cooked asparagus green
    '#d62728', // brick red
    '#9467bd', // muted purple
    '#8c564b', // chestnut brown
    '#e377c2', // raspberry yogurt pink
    '#7f7f7f', // middle gray
    '#bcbd22', // curry yellow-green
    '#17becf', // blue-teal
  ]

  public static getConfigInfo (name: string, key: string, plotConfigId: string, rawGroup: string, config?: PlotConfig) {
    let group = ''
    let value = ''
    let title = ''
    const isVerticalLine = Boolean(config && config.isVerticalLine)

    if (/_derived_/.test(plotConfigId)) {
      value = `${name} (Plot derived at x = ${
        Number(plotConfigId.split('_').splice(-1)[0].replace('🍓', '.')).toFixed(2)
      })`
      group = VisUtil.GROUP_DERIVED
    }
    else if (rawGroup === 'dynamicDataSource') {
      let detail = ''

      if (!config?.filter && !config?.elements?.length) {
        detail = 'All Elements'
      }
      else if (!config.filter) {
        detail = 'Selection'
      }
      else {
        detail = config.filter.length > 50 ? `${config.filter.substring(0, 50)}...` : config.filter
      }

      value = `${name} (${detail})`
      group = rawGroup
      title = `${name} (${config?.filter ? config?.filter : 'All Elements'})`
    }
    else if (rawGroup === 'merged') {
      value = `${name} (Various Plots)`
      group = VisUtil.GROUP_MERGED
    }
    else if (rawGroup) {
      value = `${name} (${key})`
      group = rawGroup
    }

    return {
      title,
      group,
      value,
      isVerticalLine,
    }
  }

  public static getConfigSelectors (data: VisualizationData, configs: Record<string, PlotConfig>, list?: any[]) {
    return (list ?? Object.keys(configs ?? {}))
      .filter(plotConfigId => (
        !/^xCoordinates_/.test(configs[plotConfigId].key ?? '') &&
        !/^yCoordinates_/.test(configs[plotConfigId].key ?? '')
      ))
      .map(plotConfigId => {
        const { name, key, group: rawGroup } = configs[plotConfigId]
        const { value, group, isVerticalLine, title } = VisUtil.getConfigInfo(
          name,
          key,
          plotConfigId,
          rawGroup,
          configs[plotConfigId],
        )

        const result: {
          key: string
          value: string
          group: string
          isVerticalLine: boolean
          badge?: string
          title?: string
        } = {
          key: plotConfigId,
          group,
          value,
          isVerticalLine,
          title,
        }

        if (data && data[group] && data[group][key]) {
          result.badge = 'schema'
        }

        return result
      })
      .filter(config => config.key && config.group)
  }

  public static getEditableFileSelectors (editableFiles: any[]) {
    return editableFiles.map(editableFile => ({
      key: editableFile,
      value: editableFile,
      group: 'Editable Files',
    }))
  }

  public static getViewableFileSelectors (simulationCase: SimulationCase, catalogList: CaseFile[]) {
    const { currentCaster, commandFiles } = simulationCase ?? {}
    const viewableFiles = []

    // FIXME: this is supposed to be the caster file name not the caster name
    const casterName = currentCaster?.name ?? 'n/a'

    // Caster
    if (casterName) {
      viewableFiles.push(`SCI/Input-OMS/PlantData/${casterName}`)
    }

    // Catalogs
    if (catalogList && catalogList.length) {
      viewableFiles.push(...catalogList.map(catalog => `SCI/Input-DSC/Nozzles/${catalog.fileName}`))
    }

    // CommandFiles
    if (commandFiles && commandFiles.length) {
      viewableFiles.push(...commandFiles.map(commandFile => `SCI/Input-OMS/ProcessData/${commandFile.name}.txt`))
    }

    return viewableFiles.map(viewableFile => ({
      key: viewableFile,
      value: viewableFile,
      group: 'Viewable Files',
    }))
  }

  public static getTypeSelectors (group?: string) {
    let typeSelectors = []

    switch (group) {
      case '2DData_MidThickPlane':
        typeSelectors = [
          { key: 'contour', value: 'Contour' },
        ]
        break
      case 'LengthWiseQuantities':
      case 'WidthWiseTemperatures':
      case 'ProcessParameters':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'pie', value: 'Pie' },
          { key: 'message', value: 'Message' },
        ]
        break
      case 'MachineData':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
        ]
        break
      case 'PostProcessedQuantities':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
        ]
        break
      case 'derived':
      case 'ProcessParameters_ActualMinMaxAimminAimmax':
        typeSelectors = [
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
        ]
        break
      case 'Editable Files':
      case 'Viewable Files':
        typeSelectors = [
          { key: 'edit_box', value: 'Edit Box' },
        ]
        break
      default:
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'pie', value: 'Pie' },
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
          { key: 'message', value: 'Message' },
          { key: 'radar', value: 'Radar' },
        ]
    }

    return typeSelectors
  }

  public static sortStringsASC (a: any, b: any) {
    if (a.value > b.value) {
      return 1
    }

    if (a.value < b.value) {
      return -1
    }

    return 0
  }
}
