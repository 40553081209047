export enum NozzleMountLogActionsEnum {
  ACTION_ADD_NOZZLE_MOUNT_LOG = 'NozzleMountLog/ACTION_ADD_NOZZLE_MOUNT_LOG',
  DELETE = 'NozzleMountLog/DELETE',
  DELETE_MULTIPLE = 'NozzleMountLog/DELETE_MULTIPLE',
  UPDATE = 'NozzleMountLog/UPDATE',
  RESET = 'NozzleMountLog/RESET',
}

export function addNozzleMountLog (nozzleMountLogHash: Record<number, NozzleMountLog>) {
  return {
    type: NozzleMountLogActionsEnum.ACTION_ADD_NOZZLE_MOUNT_LOG,
    nozzleMountLogHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const NozzleMountLogReducers: Record<string, Function | undefined> = {
  [NozzleMountLogActionsEnum.ACTION_ADD_NOZZLE_MOUNT_LOG]: (
    _state: NozzleMountLogHash,
    action: { nozzleMountLogHash: NozzleMountLogHash },
  ) => (
    action.nozzleMountLogHash
  ),

  [NozzleMountLogActionsEnum.DELETE]: (state: NozzleMountLogHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [NozzleMountLogActionsEnum.DELETE_MULTIPLE]: (state: NozzleMountLogHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [NozzleMountLogActionsEnum.UPDATE]: (state: NozzleMountLogHash, action: { elements: NozzleMountLogHash }) => ({
    ...state,
    ...action.elements,
  }),

  [NozzleMountLogActionsEnum.RESET]: () => ({}),
}

export default function (
  state: NozzleMountLogHash = {},
  action: any,
) {
  const handler = NozzleMountLogReducers[action.type]

  return handler ? handler(state, action) : state
}
