import { StrandSides } from '@/types/elements/enum'
import type { DefaultState, ElementsHashes } from '@/types/state'

// some object containing ElementsHashes
export type ElementsHashesLike = unknown & (DefaultState | ElementsHashes)

export function getElementsHashesObject (state: ElementsHashesLike): ElementsHashes {
  return {
    Caster: state.Caster,
    Passline: state.Passline,
    PasslineMountLog: state.PasslineMountLog,
    AirLoop: state.AirLoop,
    AirLoopMountLog: state.AirLoopMountLog,
    CoolingLoop: state.CoolingLoop,
    CoolingZone: state.CoolingZone,
    LoopAssignment: state.LoopAssignment,
    SegmentGroup: state.SegmentGroup,
    SegmentGroupMountLog: state.SegmentGroupMountLog,
    Segment: state.Segment,
    SegmentMountLog: state.SegmentMountLog,
    SegmentGroupSupportPoints: state.SupportPoint,
    SupportPoint: state.SupportPoint,
    SupportPointMountLog: state.SupportPointMountLog,
    RollerBody: state.RollerBody,
    RollerBodyMountLog: state.RollerBodyMountLog,
    RollerBearing: state.RollerBearing,
    RollerBearingMountLog: state.RollerBearingMountLog,
    Nozzle: state.Nozzle,
    NozzleMountLog: state.NozzleMountLog,
    CoolingLoopMountLog: state.CoolingLoopMountLog,
    Roller: state.Roller,
    RollerMountLog: state.RollerMountLog,
    SensorPoint: state.SensorPoint,
    SegmentSensorPointsMountLog: state.SegmentSensorPointsMountLog,
    RollerSensorPointsMountLog: state.RollerSensorPointsMountLog,
    RollerBodySensorPointsMountLog: state.RollerBodySensorPointsMountLog,
    DataPoint: state.DataPoint,
    RollerDataPointsMountLog: state.RollerDataPointsMountLog,
    MoldFaceDataPointsMountLog: state.MoldFaceDataPointsMountLog,
    RollerBodyDataPointsMountLog: state.RollerBodyDataPointsMountLog,
    SegmentDataPointsMountLog: state.SegmentDataPointsMountLog,
    StrandDataPointsMountLog: state.StrandDataPointsMountLog,
    DataLine: state.DataLine,
    DataLineMountLog: state.DataLineMountLog,
    StrandGuide: state.StrandGuide,
    StrandGuideMountLog: state.StrandGuideMountLog,
    Mold: state.Mold,
    MoldMountLog: state.MoldMountLog,
    MoldFace: state.MoldFace,
  }
}

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case StrandSides.Fixed:
      distance2passline = radius
      break
    case StrandSides.Loose:
      distance2passline = radius + thickness
      break
    case StrandSides.Left:
    case StrandSides.Right:
      distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}

export function getNewIdForType (data: XMLData, type: string): number {
  const key = `lastTypeId_${type}` as XMLGenParamsIDKey
  const { XML_Generation_Parameters: parameters } = data.root

  parameters[key] = String(Number(parameters[key] ?? '0') + 1)

  return Number(parameters[key])
}

export function getChildrenArrayByIds<Type> (elementsHashes: ElementsHashes, type: CasterElementNames, ids: string[]) {
  if (!elementsHashes[type] || !ids) {
    return []
  }

  const elements: Type[] = []

  ids.forEach(id => {
    const element = elementsHashes[type][id]

    if (elementsHashes[type][id]) {
      elements.push(element)
    }
  })

  return elements
}

export function getChildRollerBodyMountLogsByIds (elementsHashes: ElementsHashes, ids: string[]) {
  const { RollerBodyMountLog } = elementsHashes

  const mountLogs: RollerBodyMountLog[] = []

  for (const id of ids) {
    if (!RollerBodyMountLog[id]) {
      continue
    }

    mountLogs.push(RollerBodyMountLog[id])
  }

  return mountLogs
}

export function getChildRollerBearingMountLogsByIds (elementsHashes: ElementsHashes, ids: string[]) {
  const { RollerBearingMountLog } = elementsHashes

  const mountLogs: RollerBearingMountLog[] = []

  for (const id of ids) {
    if (!RollerBearingMountLog[id]) {
      continue
    }

    mountLogs.push(RollerBearingMountLog[id])
  }

  return mountLogs
}
