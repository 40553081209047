import * as THREE from 'three'

import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import ThreeRoller from './Roller'
import LodUtil from '../logic/LodUtil'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  rollerBearingPlane: THREE.Mesh
  rollerGroupText: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  rollerGroupBacksideText: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  rollerBearingLine: THREE.Line
  [LodUtil.LOD_ROLLER_BEARING]: THREE.LOD
}

export default class ThreeRollerBearing extends BaseObject<RollerBearing, RollerBearingMountLog, ThreeRoller, Objects> {
  private static Geometry3DCache: Record<string, THREE.CylinderGeometry> = {}

  private static LineGeometry2DCache: Record<string, THREE.BufferGeometry> = {}

  private static PlaneGeometry2DCache: Record<string, THREE.PlaneGeometry> = {}

  private static readonly defaultPlaneSectionView = new THREE.MeshBasicMaterial({
    color: '#383838',
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  })

  private static readonly defaultLineSectionView = new THREE.LineBasicMaterial({ color: '#6a4dff' })

  private static readonly defaultMaterial = new THREE.MeshStandardMaterial({
    color: '#383838',
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly phantomMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.4,
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly selectedMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    roughness: 0.5,
    metalness: 0.5,
  }) // , transparent: true, opacity: 0.75

  // eslint-disable-next-line max-len
  private static readonly deletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da131b',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly selectedDeletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly DetailDistance = [ 0, 3, 8 ]

  private static get3DGeometry (radius: number, width: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${radius}_${width}_${radial}`
    let geometry = ThreeRollerBearing.Geometry3DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(radius, radius, width, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD)

      geometry = Util.getSmoothedGeometry(geometry, 80)

      ThreeRollerBearing.Geometry3DCache[geometryKey] = geometry
    }

    return geometry
  }

  private static get2DGeometry (bodyWidthStart: number, bodyWidth: number, diameter: number) {
    const lineGeometryKey = `${bodyWidthStart}_${bodyWidth}_${diameter}`
    const planeGeometryKey = `${bodyWidth}_${diameter}`

    let lineGeometry = ThreeRollerBearing.LineGeometry2DCache[lineGeometryKey]
    let planeGeometry = ThreeRollerBearing.PlaneGeometry2DCache[planeGeometryKey]

    if (!lineGeometry) {
      lineGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      ])

      // TODO: remove old code
      // lineGeometry = new (THREE as any).Geometry()

      // ;(lineGeometry as any).vertices.push(
      //   new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      //   new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
      //   new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
      //   new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
      //   new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      // )

      ThreeRollerBearing.LineGeometry2DCache[lineGeometryKey] = lineGeometry
    }

    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneGeometry(bodyWidth, diameter)

      ThreeRollerBearing.PlaneGeometry2DCache[planeGeometryKey] = planeGeometry
    }

    return { lineGeometry, planeGeometry }
  }

  private clickableObjects: any

  private readonly parentContainer: any

  private isPhantom?: boolean

  private sectionDetail?: any

  private width?: number

  private startWidth?: number

  private radius?: number

  public constructor (container: any, parent: any, clickableObjects: any, phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.parentContainer = phantomGroup ?? parent.objects.roller

    if (phantomGroup) {
      const parentRoller = this.parent.objects.roller
      const parentPosition = parentRoller.position
      const parentRotation = parentRoller.rotation

      this.parentContainer.position.copy(parentPosition)
      this.parentContainer.rotation.copy(parentRotation)
    }
  }

  protected override internalSetValues (data: SetValuesData<RollerBearing, RollerBearingMountLog>) {
    super.internalSetValues(data)

    const { elementData, view } = data

    this.isPhantom = data.isPhantom
    this.sectionDetail = view.sectionDetail

    if (this.sectionDetail) {
      this.renderSectionDetail(elementData, data.path)
    }
    else {
      this.renderNormal(elementData, data.path, data.isDeleted, data.isHidden)
    }

    this.setSelected((this.objects[LodUtil.LOD_ROLLER_BEARING] || this.objects.rollerBearingPlane).userData.selected)
  }

  private disposeChildren (parent: any): void {
    for (const child of parent.children) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      parent.remove(child)
    }
  }

  public override dispose (): void {
    // const name = `${this.path}_Phantom`
    super.dispose()

    if (this.container) {
      for (const child of this.container.children) {
        if (child.children.length) {
          this.disposeChildren(child)
        }
      }

      this.disposeChildren(this.container)
    }

    this.objects = {} as Objects
    this.clickableObjects = []
    this.container.clear()
  }

  public override setSelected (isSelected: boolean) {
    const { deleted } = this.parentContainer.userData

    if (Boolean(this.isPhantom) || (!this.objects[LodUtil.LOD_ROLLER_BEARING] && !this.objects.rollerBearingPlane)) {
      return
    }

    if (this.sectionDetail) {
      const material = isSelected
        ? (deleted ? ThreeRollerBearing.selectedDeletedMaterial : ThreeRollerBearing.selectedMaterial)
        : (deleted ? ThreeRollerBearing.deletedMaterial : ThreeRollerBearing.defaultPlaneSectionView)

      this.objects.rollerBearingPlane.material = material.clone()

      return
    }

    const element = this.objects[LodUtil.LOD_ROLLER_BEARING] || this.objects.rollerBearingPlane

    element.userData.selected = isSelected

    element.children.forEach((child: any) => {
      if (child.name.includes('Roller_Bearing')) {
        return
      }

      const visible = child.material.visible

      const material = isSelected
        ? (deleted ? ThreeRollerBearing.selectedDeletedMaterial : ThreeRollerBearing.selectedMaterial)
        : (deleted
          ? ThreeRollerBearing.deletedMaterial
          : ThreeRollerBearing.defaultMaterial)

      child.material = material.clone()
      child.material.visible = visible
    })
  }

  private internalSetVisibility (visible: boolean) {
    const {
      [LodUtil.LOD_ROLLER_BEARING]: lod,
      rollerGroupText,
      rollerGroupBacksideText,
      rollerBearingLine,
      rollerBearingPlane,
    } = this.objects

    if (!this.isPhantom && !this.sectionDetail) {
      rollerGroupText.material.visible = visible
      rollerGroupBacksideText.material.visible = visible
    }

    if (lod) {
      lod.visible = visible
    }

    if (rollerBearingLine && rollerBearingPlane) {
      rollerBearingLine.visible = visible
      rollerBearingPlane.visible = visible
    }
  }

  public setVisibility (displayType: number) {
    if (this.isHidden) {
      return
    }
    else if (this.sectionDetail) {
      displayType = ThreeRoller.DisplayTypes.RollerChildren
    }

    switch (displayType) {
      case ThreeRoller.DisplayTypes.All:
        this.internalSetVisibility(true)
        break
      case ThreeRoller.DisplayTypes.Roller:
        this.internalSetVisibility(false)
        break
      case ThreeRoller.DisplayTypes.RollerChildren:
        this.internalSetVisibility(true)
        break
      default:
    }
  }

  public override show () {
    if (this.isHidden) {
      return
    }

    super.show()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = true
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = true
      }
    })
  }

  public override hide () {
    super.hide()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => { // TODO: review
          child.material.visible = false
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = false
      }
    })
  }

  private renderNormal (rollerBearingData: any, path: string, isDeleted: boolean, isHidden: boolean) {
    const { width, widthCoord, id: bearingId } = rollerBearingData

    this.width = width / 1000
    this.startWidth = widthCoord / 1000
    this.radius = this.parent.radius * 0.8

    const lod = new THREE.LOD()

    // if (this.isPhantom) {
    //   const lodPos = this.parent.objects.roller.position

    //   lod.position.set(lodPos.x, lodPos.y, lodPos.z)
    // }

    for (let i = 0; i < 3; i++) {
      const geometry = ThreeRollerBearing.get3DGeometry(this.radius, this.width, i, this.isPhantom)

      const material = !this.isPhantom
        ? ThreeRollerBearing.defaultMaterial
        : ThreeRollerBearing.phantomMaterial

      const mesh = new THREE.Mesh(geometry, material.clone())

      mesh.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
      mesh.userData.type = 'RollerBearing'
      mesh.userData.path = path

      lod.addLevel(mesh, ThreeRollerBearing.DetailDistance[i])

      const oldElement = this.objects[LodUtil.LOD_ROLLER_BEARING]
        ? this.objects[LodUtil.LOD_ROLLER_BEARING].getObjectByName(mesh.name)
        : null

      // Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      if (oldElement) {
        for (const oldMesh of oldElement.children) {
          Util.addOrReplaceInList(oldMesh, mesh, this.clickableObjects)
        }
      }
      else {
        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerBearingLoD = lod

    rollerBearingLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    rollerBearingLoD.userData.type = 'RollerBearing'
    rollerBearingLoD.userData.path = path

    rollerBearingLoD.userData.deleted = isDeleted
    rollerBearingLoD.userData.hidden = isHidden
    rollerBearingLoD.userData.selected = this.objects[LodUtil.LOD_ROLLER_BEARING]?.userData?.selected

    this.objects[LodUtil.LOD_ROLLER_BEARING] = rollerBearingLoD

    const rollWidth = this.parent.rollerData.rollWidth / 1000 / 2
    const side = this.parent.container.userData.side

    switch (side) {
      case 'right':
        rollerBearingLoD.position.x = -(this.startWidth + this.width / 2 - rollWidth)
        break
      case 'left':
        rollerBearingLoD.position.x = this.startWidth + this.width / 2 - rollWidth
        break
      default:
        rollerBearingLoD.position.x = this.startWidth + this.width / 2
    }

    if (!this.isPhantom) {
      const { number } = this.parent.objects.rollerGroupText.userData

      const side = this.parent.container.userData.side
      const textSize = side === StrandSides.Loose ? 0.03 : 0.06

      const rollerGroupText = Util.getText(number, textSize, false, true)

      if (!rollerGroupText) {
        // eslint-disable-next-line no-console
        console.warn('Roller group text not found')

        return
      }

      const rollerGroupBacksideText = rollerGroupText.clone()

      rollerGroupText.name = `Roller_Bearing_Number_${number}_${bearingId}`
      rollerGroupBacksideText.name = `Roller_Bearing_Number_Backside_${number}_${bearingId}`

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.position.set(rollerBearingLoD.position.x + this.width / 2 + 0.0001, 0, 0)
      rollerGroupBacksideText.rotateY(-Util.RAD90)
      rollerGroupBacksideText.position.set(rollerBearingLoD.position.x - (this.width / 2 + 0.0001), 0, 0)

      this.objects.rollerGroupText = rollerGroupText
      this.objects.rollerGroupBacksideText = rollerGroupBacksideText

      Util.addOrReplace(this.parentContainer, rollerGroupText)
      Util.addOrReplace(this.parentContainer, rollerGroupBacksideText)
    }

    Util.addOrReplace(this.parentContainer, rollerBearingLoD)
  }

  private renderSectionDetail (rollerBearing: any, path: string) {
    const bodyWidthStart = rollerBearing.widthCoord / 1000
    const bodyWidth = rollerBearing.width / 1000
    const bearingDiameter = this.parent.radius * 2 * 0.9

    const { lineGeometry, planeGeometry } = ThreeRollerBearing.get2DGeometry(bodyWidthStart, bodyWidth, bearingDiameter)

    const line = new THREE.Line(lineGeometry, ThreeRollerBearing.defaultLineSectionView)

    line.name = `RollerBearingLine_${path}`

    const plane = new THREE.Mesh(
      planeGeometry,
      !this.isPhantom ? ThreeRollerBearing.defaultPlaneSectionView : ThreeRollerBearing.phantomMaterial,
    )

    plane.name = `RollerBearingPlane_${path}`
    plane.userData.type = 'RollerBearing'
    plane.userData.path = path

    const rollWidth = this.parent.rollerData.rollWidth / 1000 / 2
    const side = this.parent.container.userData.side

    // if (this.isPhantom) {
    //   line.position.copy(this.parent.objects.roller.position)
    //   plane.position.copy(this.parent.objects.roller.position)
    //   line.rotation.copy(this.parent.objects.roller.rotation)
    //   plane.rotation.copy(this.parent.objects.roller.rotation)
    // }

    switch (side) {
      case 'right':
        line.rotation.z += Util.RAD180
        line.position.x = rollWidth
        plane.position.x = -(bodyWidth / 2 + bodyWidthStart - rollWidth)
        break
      case 'left':
        line.position.x = -rollWidth
        plane.position.x = bodyWidth / 2 + bodyWidthStart - rollWidth
        break
      case 'loose':
        plane.rotation.x += Util.RAD180
        plane.position.x = bodyWidth / 2 + bodyWidthStart
        break
      default:
        plane.position.x = bodyWidth / 2 + bodyWidthStart
    }

    this.objects.rollerBearingLine = line
    this.objects.rollerBearingPlane = plane

    Util.addOrReplace(this.parentContainer, line)
    Util.addOrReplace(this.parentContainer, plane, this.clickableObjects)
  }
}
