// TODO: fix types
export enum DataLineActionsEnum {
  ACTION_ADD_DATA_POINT = 'DataLine/ACTION_ADD_DATA_POINT',
  DELETE = 'DataLine/DELETE',
  DELETE_MULTIPLE = 'DataLine/DELETE_MULTIPLE',
  UPDATE = 'DataLine/UPDATE',
  RESET = 'DataLine/RESET',
}

export function addDataLine (dataLineHash: Record<number, DataLine>) {
  return {
    type: DataLineActionsEnum.ACTION_ADD_DATA_POINT,
    dataLineHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DataLineReducers: Record<string, Function | undefined> = {
  [DataLineActionsEnum.ACTION_ADD_DATA_POINT]: (_state: any, action: { dataLineHash: any }) => (
    action.dataLineHash
  ),

  [DataLineActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [DataLineActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [DataLineActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),

  [DataLineActionsEnum.RESET]: () => ({}),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = DataLineReducers[action.type]

  return handler ? handler(state, action) : state
}
