import { request } from '@/api'

const PATH = 'temporal-data'

export type TemporalDataResponse = Array<Array<any>>

// the function will receive an array of strings, these strings contain the ids of the temporal data that we want to get
export function getTemporalData (neededData: string[]) {
  const errorText = 'Failed to get temporal data' // TODO: translate message

  return request<TemporalDataResponse | null>('get', String(PATH), errorText, { params: { neededData } })
}
