export enum RollerActionsEnum {
  ACTION_ADD_ROLLER = 'Roller/ACTION_ADD_ROLLER',
  DELETE = 'Roller/DELETE',
  DELETE_MULTIPLE = 'Roller/DELETE_MULTIPLE',
  UPDATE = 'Roller/UPDATE',
  RESET = 'Roller/RESET',
}

export function addRoller (rollerHash: RollerHash) {
  return {
    type: RollerActionsEnum.ACTION_ADD_ROLLER,
    rollerHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerReducers: Record<string, Function | undefined> = {
  [RollerActionsEnum.ACTION_ADD_ROLLER]: (_state: RollerHash, action: { rollerHash: RollerHash }) => (
    action.rollerHash
  ),

  [RollerActionsEnum.DELETE]: (state: RollerHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerActionsEnum.DELETE_MULTIPLE]: (state: RollerHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerActionsEnum.UPDATE]: (state: RollerHash, action: { elements: RollerHash }) => ({
    ...state,
    ...action.elements,
  }),

  [RollerActionsEnum.RESET]: () => ({}),
}

export default function (
  state: RollerHash = {},
  action: any,
) {
  const handler = RollerReducers[action.type]

  return handler ? handler(state, action) : state
}
