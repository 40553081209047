import { Group } from 'three'

import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import ApiClient from '@/store/apiClient'
import MainView from '@/three/views/MainView'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import ThreeSegment from './Segment'

type Objects = BaseObjects

export default class ThreeSegmentGroup extends BaseObject<SegmentGroup, SegmentGroupMountLog, unknown, Objects> {
  public constructor (container: Group, parent: any) {
    super(container, parent)
  }

  protected override internalSetValues (data: SetValuesData<SegmentGroup, SegmentGroupMountLog>) {
    super.internalSetValues(data)
  }

  public override hide () {
    // don't hide because if it's hidden sensor points don't show up
    // super.hide()

    // this.container.visible = false

    // this.container.children.forEach((child: any) => {
    //   child.visible = false
    // })
  }

  public static async updateShimMarker (view: MainView, path: string) {
    const numericId = Number(path.substring(path.indexOf(':') + 1))
    const segmentGroupMountLogId = MainView.numericIdMountLogMaps.SegmentGroupMountLog[numericId]
    const segmentGroupMountLog = view.elementsHashes.SegmentGroupMountLog[segmentGroupMountLogId]
    const supportPointCount = segmentGroupMountLog
      ?.supportPointMountLogs
      ?.filter((id) => view.elementsHashes.SupportPointMountLog[id])
      ?.length ?? 0

    if (ThreeSegment.getShimCheckboxDisabled(view.elementsHashes, supportPointCount)) {
      // eslint-disable-next-line no-console
      console.info('SegmentGroup.updateShimMarker: shim checkbox is disabled')

      return
    }

    const anyShimApplied = Object.keys(view.elementsHashes.SegmentGroupMountLog).some((key) => (
      view.elementsHashes.SegmentGroupMountLog[key].shimApplied
    ))

    if (!segmentGroupMountLog || anyShimApplied) {
      return
    }

    const shimMarker = !segmentGroupMountLog.shimMarker

    // TODO: remove this once the updateElementsHashes function has been made faster
    for (const segmentPath in view.elementList.Segment) {
      if (segmentPath.includes(path)) {
        const segment = view.elementList.Segment[segmentPath]

        segment.setShimMarker(shimMarker)
      }
    }

    try {
      await ApiClient.patch(
        `${useConfig().apiBaseURL}/segment-groups/${segmentGroupMountLogId}/mark-for-shimming`,
        { data: { shimMarker } },
      )
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      // TODO: remove this once the updateElementsHashes function has been made faster
      for (const segmentPath in view.elementList.Segment) {
        if (segmentPath.includes(path)) {
          const segment = view.elementList.Segment[segmentPath]

          segment.setShimMarker(!shimMarker)
        }
      }

      return
    }

    IpcManager.send('updateSegmentGroupMountLogs', { [segmentGroupMountLogId]: { shimMarker } })
  }
}
