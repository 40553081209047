import * as THREE from 'three'
import { Object3D, OrthographicCamera, PerspectiveCamera, Vector2 } from 'three'

import Util from '@/three/logic/Util'
import { Views } from '@/three/ThreeBase'

import ConditionUtil from './ConditionUtil'
import Getters from './Getters'

interface Viewport {
  x: number
  y: number
  width: number
  height: number
}

export default abstract class BaseView {
  public static staticClassName: string

  public className: string

  public renderer: THREE.WebGLRenderer

  public views: Views

  public viewport: Viewport

  public clickableObjects: Object3D[]

  public tooltipObjects: Object3D[]

  public raycaster: THREE.Raycaster

  public camera: OrthographicCamera | PerspectiveCamera | null

  public scene: THREE.Scene

  public downMousePosition: Vector2

  public constructor (renderer: THREE.WebGLRenderer, views: Views) {
    BaseView.staticClassName = 'BaseView'
    this.className = 'BaseView'
    this.renderer = renderer
    this.views = views
    this.viewport = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
    this.clickableObjects = []
    this.tooltipObjects = []
    this.raycaster = new THREE.Raycaster()
    this.camera = null
    this.scene = new THREE.Scene()
    this.downMousePosition = new THREE.Vector2()
  }

  public reset () {
    this.tooltipObjects = []
    this.clickableObjects = []
  }

  public resize (_width: number, _height: number) {
    // empty
  }

  public animate (_elapsed: number) {
    // empty
  }

  public render () {
    this.renderer.clearDepth()
    this.renderer.setViewport(this.viewport.x, this.viewport.y, this.viewport.width, this.viewport.height)

    if (this.camera) {
      // console.log(`${this.className} - ${this.renderer.info.render.calls} calls`)
      this.renderer.render(this.scene, this.camera)

      // if (!(window as any).scenes) {
      //   (window as any).scenes = {}
      // }

      // if (!(window as any).scenes[this.className]) {
      //   (window as any).scenes[this.className] = this.scene
      // }
      // if (this.className === 'MainView') {
      //   if (this.renderer.info.memory.geometries !== BaseView.nGeometries) {
      //     BaseView.nGeometries = this.renderer.info.memory.geometries
      //     console.log(`geometries: ${this.renderer.info.memory.geometries}`)
      //   }

      //   let nChildren = 0

      //   this.scene.traverse((child: any) => {
      //     nChildren++
      //   })
      //   console.log(`${nChildren}`)
      // }

      // if (this.className === 'MainView') {
      //   if (this.renderer.info.memory.textures !== BaseView.nTextures) {
      //     BaseView.nTextures = this.renderer.info.memory.textures
      //     console.log(`textures: ${this.renderer.info.memory.textures}`)
      //   }
      // }
    }
  }

  public handleMouseDown (event: any, mouseOnCanvas: Vector2) {
    if (
      ConditionUtil.mouseNotOnCanvasOrIgnoredIntersect(event, mouseOnCanvas)
    ) {
      return []
    }

    this.downMousePosition = mouseOnCanvas

    const { x, y, width, height } = this.viewport

    if (ConditionUtil.mouseOutsideOfView(width, height, x, y, mouseOnCanvas)) {
      return []
    }

    const mouse = Getters.getMouse(mouseOnCanvas, x, y, width, height)

    if (this.camera) {
      this.raycaster.setFromCamera(mouse, this.camera)
    }

    const intersects = Getters.getIntersects(this.clickableObjects, this.raycaster)

    if (!intersects.length) {
      return []
    }

    return intersects
  }

  public handleMouseUp (event: any, mouseOnCanvas: Vector2) {
    if (
      ConditionUtil.mouseNotOnCanvasOrIgnoredIntersect(event, mouseOnCanvas)
    ) {
      return []
    }

    if (
      !this.clickableObjects.length ||
      !this.downMousePosition ||
      !mouseOnCanvas ||
      ConditionUtil.differentMouseDownAndUpPositions(this.downMousePosition, mouseOnCanvas)
    ) {
      return []
    }

    const { x, y, width, height } = this.viewport

    if (ConditionUtil.mouseOutsideOfView(width, height, x, y, mouseOnCanvas)) {
      return []
    }

    const mouse = Getters.getMouse(mouseOnCanvas, x, y, width, height)

    if (this.camera) {
      this.raycaster.setFromCamera(mouse, this.camera)
    }

    const clickableObjects = this.clickableObjects.filter(object => Util.isVisible(object))

    const intersects = this.raycaster.intersectObjects(clickableObjects, false)

    if (!intersects.length) {
      return []
    }

    return intersects
  }

  public handleMouseMove (_event: any, _mouseOnCanvas: Vector2) {
    // empty
  }

  public handleKeyDown (_event: any) {
    // empty
  }

  public handleKeyUp (_event: any) {
    // empty
  }

  public unmount () {
    this.clearThree(this.scene)
    this.renderer.renderLists.dispose()
  }

  private clearThree (obj: any) {
    while (obj.children.length > 0) {
      this.clearThree(obj.children[0])
      obj.remove(obj.children[0])
    }

    if (obj.geometry) {
      obj.geometry.dispose()
    }

    if (obj.material) {
      // in case of map, bumpMap, normalMap, envMap ...
      Object.keys(obj.material).forEach(prop => {
        if (!obj.material[prop]) {
          return
        }

        if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') {
          obj.material[prop].dispose()
        }
      })

      if (obj.material.dispose) {
        obj.material.dispose()
      }
    }
  }
}
