import { combineReducers } from 'redux'

import application from './application'
import casterDataServer from './casterDataServer'
import CCElement from './consistencyCheck/ccElements'
import ChangeItem from './consistencyCheck/edits'
import data from './data'
import AirLoop from './elements/airLoop'
import AirLoopMountLog from './elements/airLoopMountLog'
import Caster from './elements/caster'
import CoolingLoop from './elements/coolingLoop'
import CoolingLoopMountLog from './elements/coolingLoopMountLog'
import CoolingZone from './elements/coolingZone'
import DataLine from './elements/dataLine'
import DataLineMountLog from './elements/dataLineMountLog'
import DataPoint from './elements/dataPoint'
import LoopAssignment from './elements/loopAssignment'
import Mold from './elements/mold'
import MoldFace from './elements/moldFace'
import MoldFaceDataPointsMountLog from './elements/moldFaceDataPointsMountLog'
import MoldMountLog from './elements/moldMountLog'
import Nozzle from './elements/nozzle'
import NozzleMountLog from './elements/nozzleMountLog'
import Passline from './elements/passline'
import PasslineMountLog from './elements/passlineMountLog'
import Roller from './elements/roller'
import RollerBearing from './elements/rollerBearing'
import RollerBearingMountLog from './elements/rollerBearingMountLog'
import RollerBody from './elements/rollerBody'
import RollerBodyDataPointsMountLog from './elements/rollerBodyDataPointsMountLog'
import RollerBodyMountLog from './elements/rollerBodyMountLog'
import RollerBodySensorPointsMountLog from './elements/rollerBodySensorPointsMountLog'
import RollerDataPointsMountLog from './elements/rollerDataPointsMountLog'
import RollerMountLog from './elements/rollerMountLog'
import RollerSensorPointsMountLog from './elements/rollerSensorPointsMountLog'
import Segment from './elements/segment'
import SegmentDataPointsMountLog from './elements/segmentDataPointsMountLog'
import SegmentGroup from './elements/segmentGroup'
import SegmentGroupMountLog from './elements/segmentGroupMountLog'
import SegmentMountLog from './elements/segmentMountLog'
import SegmentSensorPointsMountLog from './elements/segmentSensorPointsMountLog'
import SensorPoint from './elements/sensorPoint'
import StrandDataPointsMountLog from './elements/strandDataPointsMountLog'
import StrandGuide from './elements/strandGuide'
import StrandGuideMountLog from './elements/strandGuideMountLog'
import SupportPoint from './elements/supportPoint'
import SupportPointMountLog from './elements/supportPointMountLog'
import filter from './filter'
import loading from './LoadingStore'
import matrix from './matrix'
import modules from './modules'
import mountLogToKeyUUIDsMap from './mountLogToKeyUUIDs'
import temporalData from './temporalData'
import tileWarnings from './tileWarnings'
import timestamps from './timestamps'
import util from './util'
import visualization from './visualization'

export const makeRootReducer = (asyncReducers?: any) =>
  combineReducers({
    application,
    data,
    matrix,
    filter,
    loading,
    util,
    visualization,
    AirLoop,
    AirLoopMountLog,
    Caster,
    CoolingLoop,
    CoolingZone,
    LoopAssignment,
    Mold,
    MoldMountLog,
    MoldFace,
    Nozzle,
    NozzleMountLog,
    CoolingLoopMountLog,
    Passline,
    PasslineMountLog,
    Roller,
    RollerMountLog,
    RollerBody,
    RollerBodyMountLog,
    RollerBearing,
    RollerBearingMountLog,
    SupportPoint,
    SupportPointMountLog,
    Segment,
    SegmentMountLog,
    SegmentGroup,
    SegmentGroupMountLog,
    SensorPoint,
    StrandGuide,
    StrandGuideMountLog,
    DataPoint,
    DataLine,
    DataLineMountLog,
    MoldFaceDataPointsMountLog,
    RollerBodyDataPointsMountLog,
    RollerDataPointsMountLog,
    SegmentDataPointsMountLog,
    StrandDataPointsMountLog,
    RollerSensorPointsMountLog,
    RollerBodySensorPointsMountLog,
    SegmentSensorPointsMountLog,
    ChangeItem,
    CCElement,
    tileWarnings,
    modules,
    timestamps,
    casterDataServer,
    mountLogToKeyUUIDsMap,
    temporalData,
    ...asyncReducers,
  })

export const injectReducer = (store: any, { key, reducer }: any) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return
  }

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
