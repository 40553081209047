import React, { Component } from 'react'
import styled from 'styled-components'

import ScrollBar from './ScrollBar'

const BasePlaceholder = styled.div`
  height:           20px;
  background-color: rgba(204, 204, 204, 0.05);
  position:         relative;
  margin-top:       3px;
`

const ParentPlaceholder = styled(BasePlaceholder)`
  width: 258px;
`

const OpenParentPlaceholder = styled(BasePlaceholder)`
  width: 248px;
  left:  10px;
`

const ChildPlaceholder = styled(BasePlaceholder)`
  width: 238px;
  left:  20px;
`

const Scroll = styled(ScrollBar)`
  position:   absolute;
  width:      100%;
  top:        45px;
  overflow:   hidden;
  padding:    8px;
`

export default class TreePlaceholder extends Component {
  public override render () {
    return (
      <Scroll>
        {
          new Array(100).fill(0).map((v, i) => {
            switch (i % 6) {
              case 0:
                return <ParentPlaceholder key={i} />
              case 1:
                return <OpenParentPlaceholder key={i} />
              default:
                return <ChildPlaceholder key={i} />
            }
          })
        }
      </Scroll>
    )
  }
}
