import isEqual from 'lodash/isEqual'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import type { PassedData } from '@/react/Caster'
import FeatureFlags from '@/react/FeatureFlags'
import FilterHandler from '@/three/logic/FilterHandler'
import Util from '@/three/logic/Util'
import { ElementCacheKey } from '@/three/objects'
import { SetValuesData } from '@/three/objects/BaseObject'
import ThreeMold from '@/three/objects/Mold'
import PasslineCurve from '@/three/objects/PasslineCurve'
import ThreeSegmentGroup from '@/three/objects/SegmentGroup'
import type { Views } from '@/three/ThreeBase'
import { StrandSides } from '@/types/elements/enum'
import type { ElementsHashes } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { dispatchTestingEvent } from '@/Util/testingUtil'

import MainView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'
import DrawHandlers from './DrawHandlers'
import UpdateTransformHandler from './UpdateTransformHandler'

export default class MainUtil {
  public static updateCasterData (view: MainView, data: PassedData) {
    const { elementsHashes } = view
    const { Caster } = elementsHashes

    const { mold, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(elementsHashes)
    const sections = ElementsUtil.getPasslineSectionsByDate(elementsHashes, view.referenceCasterDate)
    const moldFaces = ElementsUtil.getMoldFacesByMoldId(elementsHashes, mold?.id)
    const moldWidthChanged = view.additionalData?.Mold?.width !== data.additionalData?.Mold?.width

    if (!Caster || !sections?.length || !mold || !view.views) {
      return
    }

    if (!isEqual(data.dirtyDeletePaths, view.deleteList)) {
      MainUtil.handleDeleteListUpdate(view, data)
    }

    view.plHeight = CalculationUtil.plHeightCalculator(sections) / 1000

    const dirtyElements = view.dirtyList && view.dirtyList.length > 0

    if (!view.caster && mold && moldMountLog) {
      MainUtil.handleNoCaster(view, data, mold, moldMountLog)
    }
    else if (Boolean(dirtyElements) || data.newCopiedElementsToDraw) {
      DrawHandlers.drawStrandGuide(view)

      if (view.className === 'MainView' && view.views.uiView && view.views.sectionView) {
        view.views.sectionView.updateMinAndMaxPasslineCoordinates()
      }

      if (dirtyElements && view.clearDirtyList) {
        view.clearDirtyList()
      }

      if (data.newCopiedElementsToDraw) {
        data.setNewCopiedElementsToDraw(false)
      }
    }

    if (moldWidthChanged) {
      MainUtil.updateAdditionalData(view, data)
    }

    if (!isEqual(data.selectedPaths, view.selectedElements)) {
      MainUtil.handleNewSelection(view.selectedElements, data, view.phantomElementList, view.elementList)
    }

    MainUtil.handleElementsOrParentsPathDifference(view, data)

    if (!view.sectionDetail && (Boolean(view.dataChanged) || moldWidthChanged)) {
      DrawHandlers.redrawSectionPlane(view, Caster)
    }

    view.selectedElements = data.selectedPaths

    view.setTerm = data.setTerm

    if (data.term !== view.term || data.newCopiedElementsToDraw) {
      view.term = data.term
      MainUtil.handleFilter(
        data.term,
        view.elementsHashes.SegmentGroup,
        view.elementList,
        data.elementsHashes,
        view.views,
        data.rollerChildren === 1 && view.className === 'MainView',
        view.referenceCasterDate,
        moldFaces,
      )
    }
  }

  private static updateAdditionalData (view: MainView, data: PassedData) {
    view.additionalData = data.additionalData

    const { mold, moldMountLog } = ElementsUtil
      .getMoldAndMoldMountLogByDate(view.elementsHashes)

    if (!mold || !moldMountLog) {
      return
    }

    const caster = view.elementsHashes.Caster
    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementsHashes, view.referenceCasterDate)

    if (!mold || !caster || !sections) {
      return
    }

    view.passlineCurve?.setData(sections, view.clickableObjects, view.applyCurve)

    const moldData = ElementsUtil.getFullCasterElement(mold, moldMountLog, 1)

    view.mold?.setValues({
      elementData: moldData as SetValuesData<Mold, MoldMountLog>['elementData'],
      path: '',
      isDeleted: false,
      isHidden: false,
      isPhantom: false,
      view,
    })

    view.passlineCurve?.drawStrand()

    view.views?.sectionView?.updateTransform(true)

    UpdateTransformHandler.updateTransformElements(view.elementList, data)

    const moldFaces = ElementsUtil.getMoldFacesByMoldId(view.elementsHashes, mold.id)

    moldFaces.forEach((moldFace) => {
      for (const dataPointId of moldFace.dataPointMountLogs) {
        // TODO: is this path correct? (Mold/DataPoint:UUID)
        const dataPointElement = view.elementList.DataPoint?.[`Mold/DataPoint:${dataPointId}`]

        if (dataPointElement) {
          dataPointElement.updateTransform()
        }
      }
    })
    UpdateTransformHandler.updateTransformSegments(
      view.containerList.Segment,
      view.elementList.Segment,
    )

    if (view.sectionDetail) {
      return
    }

    for (const side of Util.wideSides) {
      view.coordinate?.generateCoordinateAxes(caster, sections, mold, moldMountLog, side)
      view.coordinateStraight?.generateCoordinateAxes(caster, sections, mold, moldMountLog, side)
    }
  }

  // this gets called twice 1 for main view 1 for section view so thats normal!
  private static handleParentMismatch (view: MainView) {
    const pathsToRemove: string[] = []

    view.deleteList.forEach(path => {
      // element
      const { type: rawType, id } = Util.getElementInfo(path)
      const { id: parentId, type: parentType } = Util.getParentInfo(path)
      const type = rawType as ElementCacheKey
      const numericIdMountLogName = `${type}MountLog` as keyof ElementsHashes

      const elementsHashesMountLogName = rawType !== 'SensorPoint'
        ? `${type}MountLog` as keyof ElementsHashes
        : `${parentType}SensorPointsMountLog` as keyof ElementsHashes

      const mountLogId = MainView.numericIdMountLogMaps[numericIdMountLogName]?.[id]
      const mountLog = view.elementsHashes[elementsHashesMountLogName]?.[mountLogId]

      // parent
      const parentMountLogType = `${parentType}MountLog` as keyof ElementsHashes
      // change parentMountLogType to have the first letter lowercase
      const parentMountLogIdKey = `${parentMountLogType[0].toLowerCase() + parentMountLogType.substring(1)}Id`
      const parentMountLogId = mountLog[parentMountLogIdKey]
      const parentMountLog = view.elementsHashes[parentMountLogType]?.[parentMountLogId]
      const parentMountLogMappedByNumericId = MainView.numericIdMountLogMaps[parentMountLogType]?.[parentId]

      if (!mountLogId || !view.elementsHashes[elementsHashesMountLogName]?.[mountLogId]) {
        pathsToRemove.push(path)
      }
      else if (view.elementList?.[type] && (!parentMountLog || parentMountLog.id !== parentMountLogMappedByNumericId)) {
        pathsToRemove.push(path)

        const element = view.elementList[type]?.[path]
        const phantom = view.phantomElementList[type]?.[path]

        if (element) {
          Object.values(element.objects).forEach((object: any) => {
            view.clickableObjects.splice(view.clickableObjects.findIndex(obj => obj.uuid === object.uuid), 1)

            if (object.geometry) {
              object.geometry.dispose()
            }

            element.container.remove(object)
          })

          delete view.elementList[type]?.[path]

          if (phantom) {
            Object.values(phantom.objects).forEach((object: any) => {
              if (object.geometry) {
                object.geometry.dispose()
              }

              phantom.container.remove(object)
            })
          }

          delete view.phantomElementList[type]?.[path]
        }
      }
    })

    if (pathsToRemove.length && view.removeDeletePaths) {
      view.removeDeletePaths(pathsToRemove)
    }
  }

  // Work around for roller body behavior
  // TODO: find another way
  public static reloadFilteredElements (view?: MainView) {
    if (!view) {
      return
    }

    const { mold } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementsHashes)

    if (!mold) {
      return
    }

    const moldFaces = ElementsUtil.getMoldFacesByMoldId(view.elementsHashes, mold.id)

    MainUtil.handleFilter(
      view.term,
      view.elementsHashes.SegmentGroup,
      view.elementList,
      view.elementsHashes,
      view.views,
      false,
      view.referenceCasterDate,
      moldFaces,
    )
  }

  private static handleFilter (
    term: string | undefined,
    segmentGroupHash: SegmentGroupHash,
    elementList: any,
    elementsHashes: ElementsHashes,
    views: Views,
    skipRollerChildren: boolean,
    date: Date | undefined,
    mold?: Record<number, MoldFace>,
  ) {
    let newTerm = term
    const lowerNewTerm = newTerm?.toLowerCase()

    // TODO: this should not happen outside of the FilterHandler!
    if (lowerNewTerm === 'r' || lowerNewTerm === 'roller') {
      newTerm = 'r/*'
    }

    const filteredElements = date
      ? FilterHandler.getFilteredElements(
        elementsHashes,
        newTerm,
        skipRollerChildren,
        mold,
        true,
      )
      : {}

    FilterHandler.applyFilterToElements(
      segmentGroupHash,
      filteredElements,
      elementList,
      views,
      elementsHashes,
      skipRollerChildren,
    )
  }

  private static handleNoCaster (view: MainView, data: any, mold: Mold, moldMountLog: MoldMountLog) {
    view.caster = new THREE.Group()
    view.caster.name = 'Caster'

    view.passlineCurve = new PasslineCurve(view.caster, null)
    view.mold = new ThreeMold(view.caster, null)

    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementsHashes, view.referenceCasterDate)

    MainUtil.buildMountLogNumericIdsMap(view.elementsHashes, MainView.numericIdMountLogMaps)

    if (!sections) {
      // eslint-disable-next-line no-console
      console.error('no sections')

      return
    }

    // as long as they are not editable they can stay here
    view.passlineCurve.setData(sections, view.clickableObjects, view.applyCurve)

    const moldData = ElementsUtil.getFullCasterElement(mold, moldMountLog, 1)

    view.mold?.setValues({
      elementData: moldData as SetValuesData<Mold, MoldMountLog>['elementData'],
      path: '',
      isDeleted: false,
      isHidden: false,
      isPhantom: false,
      view: {
        ...view,
        additionalData: data.additionalData,
      } as MainView,
    })
    view.passlineCurve.drawStrand()

    const moldFaces = new THREE.Group()

    moldFaces.name = 'moldFaces'
    ;(view as any).moldFaces = moldFaces
    Util.addOrReplace(view.caster, moldFaces)

    if (view.sectionDetail) {
      view.caster.rotation.y = Util.RAD180
      view.caster.position.x = -(mold.thickness ?? 0) / 1000
    }

    view.phantoms = new THREE.Group()
    view.phantoms.name = 'Phantoms'
    view.phantoms.rotation.y = Util.RAD270
    DrawHandlers.drawStrandGuide(view)
    DrawHandlers.drawCaster(view)

    Util.addOrReplace(view.scene, view.caster)
    Util.addOrReplace(view.caster, view.phantoms)

    // TODO: maybe wait for the section view to finish!?
    if (!view.sectionDetail && view.setLoadingStatus) {
      view.setLoadingStatus(false)

      requestAnimationFrame(() => {
        dispatchTestingEvent('casterLoaded')
      })
    }

    view.sceneReady = true
    view.isRedrawing = false
  }

  private static handleSelectSegmentGroup (segmentGroupPath: string, elementList: any, isSelected: boolean) {
    const segmentGroup = elementList.SegmentGroup[segmentGroupPath]

    if (!segmentGroup) {
      // eslint-disable-next-line no-console
      console.log('segmentGroup not found', segmentGroupPath)

      return
    }

    for (const child of segmentGroup.container?.children ?? []) {
      if (!Util.wideSides.includes(child.userData.side)) {
        continue
      }

      const { path } = child.userData

      elementList.Segment[path]?.setSegmentGroupSelected(isSelected)
    }
  }

  public static buildMountLogNumericIdsMap (
    elementsHashes: ElementsHashes,
    mountLogNumericIdsMap: NumericIdMountLogMaps,
  ) {
    const {
      AirLoopMountLog = {},
      CoolingLoopMountLog = {},
      NozzleMountLog = {},
      RollerMountLog = {},
      RollerBodyMountLog = {},
      RollerBearingMountLog = {},
      SegmentMountLog = {},
      SupportPointMountLog = {},
      SegmentGroupMountLog = {},
      RollerDataPointsMountLog = {},
      RollerBodyDataPointsMountLog = {},
      SegmentDataPointsMountLog = {},
      StrandDataPointsMountLog = {},
      MoldFaceDataPointsMountLog = {},
      RollerSensorPointsMountLog = {},
      RollerBodySensorPointsMountLog = {},
      SegmentSensorPointsMountLog = {},
      DataLineMountLog = {},
    } = elementsHashes

    mountLogNumericIdsMap.AirLoopMountLog = {}
    mountLogNumericIdsMap.CoolingLoopMountLog = {}
    mountLogNumericIdsMap.NozzleMountLog = {}
    mountLogNumericIdsMap.RollerMountLog = {}
    mountLogNumericIdsMap.RollerBodyMountLog = {}
    mountLogNumericIdsMap.RollerBearingMountLog = {}
    mountLogNumericIdsMap.SegmentMountLog = {}
    mountLogNumericIdsMap.SupportPointMountLog = {}
    mountLogNumericIdsMap.SegmentGroupMountLog = {}
    mountLogNumericIdsMap.DataPointMountLog = {}
    mountLogNumericIdsMap.SensorPointMountLog = {}
    mountLogNumericIdsMap.DataLineMountLog = {}

    const hashes = {
      AirLoopMountLog,
      CoolingLoopMountLog,
      NozzleMountLog,
      RollerMountLog,
      RollerBodyMountLog,
      RollerBearingMountLog,
      SegmentMountLog,
      SupportPointMountLog,
      SegmentGroupMountLog,
      RollerDataPointsMountLog,
      RollerBodyDataPointsMountLog,
      SegmentDataPointsMountLog,
      StrandDataPointsMountLog,
      MoldFaceDataPointsMountLog,
      RollerSensorPointsMountLog,
      RollerBodySensorPointsMountLog,
      SegmentSensorPointsMountLog,
      DataLineMountLog,
    } as ElementsHashes

    let dataPointIdCounter = 0
    let sensorPointIdCounter = 0

    Object.keys(hashes).forEach(hashType => {
      if (hashType === 'RollerMountLog') {
        MainUtil.handleBuildRollerMountLogNumericIdsMap(elementsHashes, mountLogNumericIdsMap)

        return
      }

      const mountLogHash = (hashes as any)[hashType]
      const sortedMountLogs = Object
        .values(mountLogHash)
        .filter((val) => typeof val !== 'boolean') // to filter out hasChanges
        .sort((a: any, b: any) => a.passlineCoord - b.passlineCoord)

      const DataPointMountLogTypes = [
        'RollerDataPointsMountLog',
        'RollerBodyDataPointsMountLog',
        'SegmentDataPointsMountLog',
        'StrandDataPointsMountLog',
        'MoldFaceDataPointsMountLog',
      ]

      const SensorPointMountLogTypes = [
        'RollerSensorPointsMountLog',
        'RollerBodySensorPointsMountLog',
        'SegmentSensorPointsMountLog',
      ]

      if (DataPointMountLogTypes.includes(hashType)) {
        if (!mountLogNumericIdsMap.DataPointMountLog) {
          mountLogNumericIdsMap.DataPointMountLog = {}
        }

        Object.keys(mountLogHash).forEach((id: string) => {
          mountLogNumericIdsMap.DataPointMountLog[id] = dataPointIdCounter
          mountLogNumericIdsMap.DataPointMountLog[dataPointIdCounter] = id
          dataPointIdCounter++
        })

        return
      }

      if (SensorPointMountLogTypes.includes(hashType)) {
        if (!mountLogNumericIdsMap.SensorPointMountLog) {
          mountLogNumericIdsMap.SensorPointMountLog = {}
        }

        Object.keys(mountLogHash).forEach((id: string) => {
          mountLogNumericIdsMap.SensorPointMountLog[id] = sensorPointIdCounter
          mountLogNumericIdsMap.SensorPointMountLog[sensorPointIdCounter] = id
          sensorPointIdCounter++
        })

        return
      }

      sortedMountLogs.forEach((mountLog: any, index: number) => {
        mountLogNumericIdsMap[hashType as NumericIdMountLogMapsTypes][mountLog.id] = index
        mountLogNumericIdsMap[hashType as NumericIdMountLogMapsTypes][index] = mountLog.id
      })
    })
  }

  // order by passlineCoord but first comes the fixed side, then the loose side, then left, then right
  private static handleBuildRollerMountLogNumericIdsMap (
    elementsHashes: ElementsHashes,
    mountLogNumericIdsMap: NumericIdMountLogMaps,
  ) {
    const { RollerMountLog, SegmentMountLog, Segment } = elementsHashes
    const sideOrder = [
      StrandSides.Fixed,
      StrandSides.Loose,
      StrandSides.Left,
      StrandSides.Right,
    ]
    const sidePerRollerMountLog: Record<string, StrandSide | null> = {}
    const sortedRollerMountLogs = Object
      .values(RollerMountLog ?? {})
      .sort((a: any, b: any) => {
        const comparedMountLogs = [ a, b ]

        for (const mountLog of comparedMountLogs) {
          if (sidePerRollerMountLog[mountLog.id] === undefined) {
            const segmentMountLog = SegmentMountLog[mountLog.segmentMountLogId]

            if (!segmentMountLog) {
              return 0
            }

            const segmentInfo = Segment[segmentMountLog?.segmentId ?? '']

            if (!segmentInfo) {
              return 0
            }

            sidePerRollerMountLog[mountLog.id] = segmentInfo.side
          }
        }

        const sideA = sidePerRollerMountLog[a.id]
        const sideB = sidePerRollerMountLog[b.id]
        const sideAIndex = sideA === null ? -1 : sideOrder.indexOf(sideA)
        const sideBIndex = sideB === null ? -1 : sideOrder.indexOf(sideB)

        if (sideAIndex === sideBIndex) {
          return a.passlineCoord - b.passlineCoord
        }

        return sideAIndex - sideBIndex
      })

    sortedRollerMountLogs.forEach((mountLog: any, index: number) => {
      mountLogNumericIdsMap.RollerMountLog[mountLog.id] = index
      mountLogNumericIdsMap.RollerMountLog[index] = mountLog.id
    })
  }

  private static handleNewSelection (selectedElements: any[], data: any, phantomElementList: any, elementList: any) {
    selectedElements.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, false)

        return
      }

      if (
        !data.selectedPaths.has(path) &&
        elementList[type] &&
        elementList[type][path]
      ) {
        elementList[type][path].setSelected(false)

        if (phantomElementList[type] && phantomElementList[type][path]) {
          phantomElementList[type][path].hide()
        }
      }
    })

    data.selectedPaths.forEach((path: any) => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, true)

        return
      }

      if (elementList[type] && elementList[type][path]) {
        elementList[type][path].setSelected(true)

        if (phantomElementList[type] && phantomElementList[type][path]) {
          phantomElementList[type][path].show()
        }
      }
    })
  }

  private static handleElementsOrParentsPathDifference (view: MainView, data: any) {
    if (!ConditionUtil.editElementsOrParentPathAreDifferent(view, data)) {
      return
    }

    const { featureFlags, sectionDetail, phantomElementList } = view
    const { editElements } = data

    const editElementPath = Object.keys(data.editElements)

    view.editElements = data.editElements
    view.parentPath = data.parentPath
    editElementPath.forEach(path => {
      const { type: rawType } = Util.getElementInfo(path)
      const type = rawType as ElementCacheKey
      const phantomElement = phantomElementList[type]?.[path]

      if (!Util.isPhantom(type as CasterElementNames, sectionDetail) || !phantomElement) {
        return
      }

      if (FeatureFlags.canEditElement(type, featureFlags)) {
        let parentInfo: any = {}

        while (parentInfo.type !== 'Segment') {
          parentInfo = Util.getParentInfo(parentInfo.path || path)
        }

        const side = view.elementList.Segment?.[parentInfo.path]?.container?.userData?.side

        phantomElement.container.userData.side = side

        phantomElement.setValues({
          elementData: editElements[path] as never, // TODO: fix the never type
          path,
          isDeleted: false,
          isHidden: view.hideList?.includes(path) ?? false,
          isPhantom: true,
          view,
        })
      }
    })
  }

  public static resetSegments (containerList: any) {
    Object.values(containerList.Segment).forEach((segment: any) => {
      segment.position.x = 0
      segment.position.z = 0
      segment.rotation.y = Util.RAD270
    })
  }

  private static handleDeleteListUpdate (view: MainView, data: PassedData) {
    const { mold } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementsHashes)
    const moldFaces = ElementsUtil.getMoldFacesByMoldId(view.elementsHashes, mold?.id)

    if (!view.views) {
      return
    }

    view.deleteList = data.dirtyDeletePaths

    MainUtil.handleParentMismatch(view)

    MainUtil.handleFilter(
      undefined,
      view.elementsHashes.SegmentGroup,
      view.elementList,
      data.elementsHashes,
      view.views,
      false,
      view.referenceCasterDate,
      moldFaces,
    )
    view.term = undefined
    view.updateRoller(Util.RollerMode)
  }

  public static handleNoSectionDetail (view: MainView) {
    if (!view.coordinate || !view.coordinateStraight) {
      return
    }

    DrawHandlers.drawGridHelper(view)

    if (view.applyCurve) {
      view.coordinate.show()
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
      view.coordinateStraight.show()
    }
  }

  public static handleIntersects (view: MainView, intersects: any[]) {
    for (let i = 0; i < intersects.length; i++) {
      const { userData } = intersects[i].object
      const { type, path } = userData

      if (userData.disabled) {
        return
      }

      // needed for selection (setSelected())
      if (
        type === 'Nozzle' ||
        (type === 'Roller' && view.rollerChildren !== 2) ||
        (type === 'RollerBody' && view.rollerChildren !== 1) ||
        (type === 'RollerBearing' && view.rollerChildren !== 1) ||
        type === 'SensorPoint' ||
        type === 'DataPoint' ||
        type === 'DataLine' ||
        type === 'SegmentGroupDetails' ||
        type === 'SupportPoint'
      ) {
        if (
          (type === 'Nozzle' && FeatureFlags.canSelectNozzle(view.featureFlags)) ||
          (type === 'Roller' && view.rollerChildren !== 2 && FeatureFlags.canSelectRoller(view.featureFlags)) ||
          (type === 'RollerBody' && view.rollerChildren !== 1 && FeatureFlags.canSelectRollerBody(view.featureFlags)) ||
          (
            type === 'RollerBearing' &&
            view.rollerChildren !== 1 &&
            FeatureFlags.canSelectRollerBearing(view.featureFlags)
          ) ||
          // TODO: add feature flag for sensor point
          (type === 'SensorPoint') || //  && FeatureFlags.canSelectSensorPoint(view.featureFlags)
          (type === 'DataPoint' && FeatureFlags.canSelectDataPoint(view.featureFlags)) ||
          (type === 'DataLine' && FeatureFlags.canSelectDataLine(view.featureFlags)) ||
          (type === 'SegmentGroupDetails' && FeatureFlags.canSelectSupportPoint(view.featureFlags)) ||
          (type === 'SupportPoint' && FeatureFlags.canSelectSupportPoint(view.featureFlags))
        ) {
          view.selection.push(path)
        }

        return
      }

      if (type === 'SegmentGroupShimMarker') {
        ThreeSegmentGroup
          .updateShimMarker(view, path)
          .then(() => {})
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error)
          })

        return
      }

      if (userData.filter) {
        if (!view.setTerm) {
          return
        }

        const { filter } = userData

        if (view.term?.includes(filter)) {
          view.setTerm(filter, false, false, true)
          view.jumpToFiltered()
        }
        else {
          view.setTerm(filter, true, false, true)
          view.jumpToFilter(filter, () => {
            setTimeout(() => {
              view.setTerm(filter, false, false, false)
            }, 1)
          })
        }

        view.setSelectedElementPaths?.()

        return
      }

      if (type === 'Strand') {
        return
      }
    }
  }

  public static setupPerspectiveControls (view: any) {
    view.perspectiveControls = new (OrbitControls as any)(view.perspectiveCamera, view.renderer.domElement)
    view.perspectiveControls.enabled = false
    view.perspectiveControls.enableKeys = false
    view.perspectiveControls.enableRotate = true
    view.perspectiveControls.update()
  }

  public static setupOrthographicControls (view: any) {
    view.orthographicControls = new (OrbitControls as any)(view.orthographicCamera, view.renderer.domElement)
    view.orthographicControls.enabled = false
    view.orthographicControls.enableKeys = false
    view.orthographicControls.enableRotate = false
    view.orthographicControls.update()
  }
}
