/* eslint-disable camelcase */
import Util from '@/logic/Util'
import { PARENT } from '@/react/context/AllInOne/consts'
import { StrandSides } from '@/types/elements/enum'
import type { ElementsHashes } from '@/types/state'

export function getNewIdForType (data: XMLData, type: string): number {
  const key = `lastTypeId_${type}` as XMLGenParamsIDKey
  const { XML_Generation_Parameters: parameters } = data.root

  parameters[key] = String(Number(parameters[key] ?? '0') + 1)

  return Number(parameters[key])
}

export function getElementFromPath<Element extends CasterElementBaseEntity, MountLog extends BaseMountLog> (
  path: string[] | string,
  elementsHashes: ElementsHashes,
  copy?: boolean,
) {
  if (!elementsHashes) {
    return null
  }

  const pathString = path instanceof Array ? Util.getPathFromPathArray(path) : path

  return Util.getElement<Element, MountLog>(pathString, elementsHashes, copy)
}

export function getPathArrayFromPath (path: string) {
  return path.split('/').reduce((list: any, part) =>
    (parts => [
      ...list,
      parts[0],
      Number(parts[1]),
    ])(part.split(':')), [])
}

export function getSegmentByPassln (data: any, passln: number) {
  const xmlData = { ...data }

  return xmlData
    .SegmentGroup
    .filter((SegmentGp: any) => Number(SegmentGp.passlineCoord) <= Number(passln))
    .sort((a: any, b: any) => Number(b.passlineCoord) - Number(a.passlineCoord))[0]
}

export function getSegmentByRollerPassln (rollerList: Array<any>, passln: number) {
  const rollers = rollerList
    .filter(roller => roller.passlineCoord > passln)
    .sort((a, b) => b.passlineCoord - a.passlineCoord)

  return rollers[rollers.length - 1]
}

export function getDefaultElement (element: any, elementType: string) {
  let newElement = { ...element[elementType] }

  if (elementType !== 'Roller') {
    return newElement
  }

  newElement = {
    ...newElement,
    passlineCoord: newElement.passlineCoord ?? 0,
    RollerBearing: [],
    RollerBody: [],
  }

  const { rollerWidth, diameter, passlineCoord } = newElement
  const RollerBody = {
    width: rollerWidth,
    widthCoord: (rollerWidth / 2) * -1,
    name: 'DSCRollerBody',
    diameter,
    passlineCoord,
  }

  const RollerBearing = [
    {
      width: 100,
      widthCoord: rollerWidth / 2,
      name: '',
      passlineCoord,
    },
    {
      width: 100,
      widthCoord: -(rollerWidth / 2) - 100,
      name: '',
      passlineCoord,
    },
  ]

  newElement.RollerBody.push(RollerBody) // TODO: make sure new elements get new ids
  newElement.RollerBearing.push(...RollerBearing) // TODO: make sure new elements get new ids
}

export function getPathByTypeAndId (type: CasterElementNames, id: number, elementsHashes: ElementsHashes) {
  const path = []
  let currentType: CasterElementNames | null | undefined = type
  let currentId = id

  do {
    path.push(`${currentType}:${currentId}`)
    currentId = elementsHashes[currentType]?.[currentId]
    currentType = PARENT[currentType]
  }
  while (currentType)

  return path.reverse().join('/')
}

export function getAddedObject (addedObject: any, xmlData: XMLData, elementType: CasterElementNames) {
  const object = addedObject

  object.numericId = getNewIdForType(xmlData, elementType)
  object.id = object.numericId

  return object
}

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case StrandSides.Fixed:
      distance2passline = radius
      break
    case StrandSides.Loose:
      distance2passline = radius + thickness
      break
    case StrandSides.Left:
    case StrandSides.Right:
      distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}
