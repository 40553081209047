import { getTemporalData, TemporalDataResponse } from '@/api/temporal-data'
import { getCurrentDashboardEntry } from '@/App/util'

import { Props } from './index'
export default class TemporalDataHandler {
  public static async getTemporalData (props: Props) {
    const {
      plotConfigs,
      setTemporalData,
      temporalData,
    } = props

    const plotIds = this.getPlotIds(props)

    if (!plotIds) {
      return
    }

    const neededData = this.getNeededDataArray(plotConfigs, plotIds, temporalData)

    if (!neededData.length) {
      return
    }

    const mountLogsTypeArray = await getTemporalData(neededData)

    if (!mountLogsTypeArray) {
      return
    }

    const data = TemporalDataHandler.mapMountLogsToTemporalData(mountLogsTypeArray)

    setTemporalData(data)
  }

  private static mapMountLogsToTemporalData (mountLogsTypeArray: TemporalDataResponse) {
    const data = {} as any

    const mountLogsPerRealDataUUID: Record<string, any[]> = {}

    for (const mountLogType of mountLogsTypeArray) {
      for (const mountLog of mountLogType) {
        const realDataUUID = mountLog.realDataUUID

        if (!realDataUUID) {
          continue
        }

        if (!mountLogsPerRealDataUUID[realDataUUID]) {
          mountLogsPerRealDataUUID[realDataUUID] = []
        }

        mountLogsPerRealDataUUID[realDataUUID].push(mountLog)
      }
    }

    Object.entries(mountLogsPerRealDataUUID).forEach(([ realDataUUID, mountLogs ]) => {
      data[realDataUUID] = mountLogs
    })

    return data
  }

  private static getPlotIds (props: Props) {
    const { currentDashboard, viewsObject, tileConfigs } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return
    }

    const tileIds: string[] = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds

    if (!tileIds?.length) {
      return
    }

    return tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean)
  }

  private static getNeededDataArray (plotConfigs: any, plotIds: string[], temporalData: TemporalDataState) {
    const neededData: string[] = []

    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId]

      const { filter, selectedY, selectedX } = plotConfig

      if (selectedX?.includes('dataOverTime') && filter?.includes('realDataUUID')) {
        const [ type, attrY ] = selectedY.split('|')
        const realDataUUID = filter.split('realDataUUID=')[1]

        if (temporalData[realDataUUID]) {
          continue
        }

        const info = `${type}|${realDataUUID}|${attrY}`

        neededData.push(info)
      }
    }

    return neededData
  }
}
