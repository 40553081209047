import isEqual from 'lodash/isEqual'
import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import { useConfig } from '@/config'
import FeatureFlags from '@/react/FeatureFlags'
import FeatureFlagsUtil from '@/react/FeatureFlags/util'
import Button from '@/react/specific/Button'
import Icon from '@/react/specific/Icon'
import ApiClient from '@/store/apiClient'
import DataActions from '@/store/data/actions'
import { getElementTypeURL } from '@/store/data/logic'
import { getElementsHashesObject } from '@/store/elements/logic'
import { getElementFromPath } from '@/store/elements/logic/getters'
import { getReferenceDate } from '@/store/timestamps'
import { DEFINITION } from '@/store/type/consts'
import { setCompareCasterInformation } from '@/store/visualization/actions'
import Util from '@/three/logic/Util'
import { DefaultState, ElementsHashes } from '@/types/state'
import { CompareCasterInformation } from '@/types/visualization'
import { ElementsUtil } from '@/Util/ElementsUtil'

import CalculateHelperFunctions from './functions/CalculateHelperFunctions'
import Input from './Input'
import CommentInput from './Input/CommentInput'
import CompareCasterInput from './Input/CompareCasterInput'
import LabelWithSuggestion from './LabelWithSuggestion'
import MirrorAndRepeat from './MirrorAndRepeat'
import NozzleCatalog from './NozzleCatalog'
import Section from '../Section'

const ErrorMessage = styled.p`${() =>
  css`
  color: #FF0;
  margin: 5px auto;
  padding-left: 24px;
`}`

const Action = styled.div<{ $disabled?: boolean, $viewer?: boolean }>`${({ $disabled, $viewer }) =>
  css`
background:  ${$disabled ? '#474b4e' : '#BB1B1B'};
color:       ${$disabled ? '#999999' : '#FFFFFF'};
  position:    relative;
  min-height:  25px;
  font-size:   1em;
  margin:      ${$viewer ? '0 5px 15px' : '10px 5px'};
  padding:     0.25em 1em;
  border:      2px solid ${$disabled ? '#474b4e' : '#BB1B1B'};
  outline:     none;
  cursor:      ${$disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  transition:  .2s;
  flex:        1;

  svg {
    position:  absolute;
    top:       50%;
    left:      50%;
    transform: translate(-50%,-50%);
  }
  &:hover {
    transition: .2s;
    border:     ${$disabled ? '2px solid #474b4e' : '2px solid #A71A1A'};
    background: ${$disabled ? '' : '#A71A1A'};
  }
`}`

const Form = styled.div`
  padding: 10px 10px 0 10px;
`

const ButtonsBox = styled.div`
  display:         flex;
  flex-wrap:       nowrap;
  flex-direction:  row;
  justify-content: space-between;

  > *:only-child > button {
    width: 100%;
  }
`

const InfoIcon = styled(Icon)`
  cursor:     pointer;
  font-size:  12px;

  &:hover {
    color: #E00A24;
  }
`

const FlexDiv = styled.div`
  flex: 25;
  display: flex;
`

const ComparisonCasterTitle = styled.div`
  display: flex;
  justify-content: center;
  min-width: 77px;
`

const ComparisonCasterTitlesContainer = styled.div`
  display: flex;
  margin-left: 150px;
`

const connector = connect((state: DefaultState) => ({
  editElements: state.data.editElements,
  editElementsInitial: state.data.editElementsInitial,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  hidePaths: state.data.hidePaths,
  catalogElement: state.data.catalogElement,
  rootData: state.data.rootData,
  hasEditChanges: state.data.hasEditChanges,
  editValues: state.data.editValues,
  createValid: state.data.createValid,
  loading: state.loading.loading,
  error: state.application.error,
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
  ccElement: state.CCElement,
  viewsObject: state.visualization.viewsObject,
  comparisonCasters: state.ComparisonCasters,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  currentDashboard: state.visualization.currentDashboard,
  timestamps: state.timestamps,
  caseLocks: state.application.main.caseLocks,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  compareCasterInformation: state.visualization.compareCasterInformation,
  selectedPaths: state.data.selectedPaths,
  ...getElementsHashesObject(state),
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setCompareCasterInformation,
  setElements: DataActions.setElements,
  setParentPath: DataActions.setParentPath,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: CasterDialogElementType
  path: string
  paths: string[]
  allPaths?: string[]
  hideActions?: boolean
  target: any[]
  filterValue: any
  selectedParentIds: { Segment: number, SegmentGroup: number }
  onSave: (uuidsHash: any) => void
  onCreateOrCopy: () => void
  massForm: boolean
  onInput: ({ target }: { target: { name: string, value: any } }) => void
  onDeleteElement: () => void
  onUndo: (event: any) => void
  direction: string
  onPatternInput: (event: any) => void
  onMirrorElements: () => void
  onPatternUndo: (event: any) => void
  copies: number
  copyMode: string
  offset: number
  gapOffset: number
  onPatternApply: () => void
  t(key: string[] | string, params?: Record<string, unknown>): string
  isComparingCasters?: boolean
}

type State = {
  // state goes here
}

export class FormBuilder extends PureComponent<Props> {
  public override state: State = {}

  private readonly elementsHashes: ElementsHashes = {} as ElementsHashes

  private readonly copyExcludedTypes = [
    'General',
    'SegmentGroup',
    'SegmentGroupSupportPoints',
    'SupportPoint',
    'DataLine',
    'DataPoint',
  ]

  private readonly deleteExcludedTypes = [
    'General',
    'SegmentGroup',
    'SegmentGroupSupportPoints',
    'SupportPoint',
    'DataLine',
    'DataPoint',
  ]

  private readonly applyExcludedTypes = [
    'SegmentGroup',
    'DataLine',
    'DataPoint',
    'SensorPoint',
  ]

  public constructor (props: Props) {
    super(props)

    const { paths, setElements, setParentPath } = props

    this.elementsHashes = getElementsHashesObject(props)

    if (paths) {
      setElements(paths)
      setParentPath()
    }
  }

  public override componentDidMount () {
    this.handleUpdateCompareCasterInformation()
  }

  public override componentDidUpdate (prevProps: Props) {
    const {
      paths,
      setElements,
      setParentPath,
      selectedComparisonCaseIds,
    } = this.props

    const { selectedComparisonCaseIds: prevSelectedComparisonIds } = prevProps

    if (paths && !isEqual(paths, prevProps.paths)) {
      setElements(paths)
      setParentPath()
    }

    if (prevProps.AirLoop !== this.props.AirLoop) {
      this.elementsHashes.AirLoop = this.props.AirLoop
    }

    if (prevProps.CoolingLoop !== this.props.CoolingLoop) {
      this.elementsHashes.CoolingLoop = this.props.CoolingLoop
    }

    if (prevProps.CoolingZone !== this.props.CoolingZone) {
      this.elementsHashes.CoolingZone = this.props.CoolingZone
    }

    if (prevProps.LoopAssignment !== this.props.LoopAssignment) {
      this.elementsHashes.LoopAssignment = this.props.LoopAssignment
    }

    if (prevProps.Nozzle !== this.props.Nozzle) {
      this.elementsHashes.Nozzle = this.props.Nozzle
    }

    if (prevProps.Roller !== this.props.Roller) {
      this.elementsHashes.Roller = this.props.Roller
    }

    if (prevProps.RollerBearing !== this.props.RollerBearing) {
      this.elementsHashes.RollerBearing = this.props.RollerBearing
    }

    if (prevProps.RollerBody !== this.props.RollerBody) {
      this.elementsHashes.RollerBody = this.props.RollerBody
    }

    if (prevProps.SupportPoint !== this.props.SupportPoint) {
      this.elementsHashes.SupportPoint = this.props.SupportPoint
    }

    if (prevProps.SegmentGroupSupportPoints !== this.props.SegmentGroupSupportPoints) {
      this.elementsHashes.SegmentGroupSupportPoints = this.props.SegmentGroupSupportPoints
    }

    if (prevProps.Segment !== this.props.Segment) {
      this.elementsHashes.Segment = this.props.Segment
    }

    if (prevProps.SegmentGroup !== this.props.SegmentGroup) {
      this.elementsHashes.SegmentGroup = this.props.SegmentGroup
    }

    if (prevProps.SensorPoint !== this.props.SensorPoint) {
      this.elementsHashes.SensorPoint = this.props.SensorPoint
    }

    if (prevProps.StrandGuide !== this.props.StrandGuide) {
      this.elementsHashes.StrandGuide = this.props.StrandGuide
    }

    if (prevProps.DataPoint !== this.props.DataPoint) {
      this.elementsHashes.DataPoint = this.props.DataPoint
    }

    if (prevProps.DataLine !== this.props.DataLine) {
      this.elementsHashes.DataLine = this.props.DataLine
    }

    // comparing the length of the selected comparison case ids is enough to know if the selected ids have changed
    const selectedComparisonCaseIdsChanged = selectedComparisonCaseIds.length !== prevSelectedComparisonIds.length

    if (
      paths.length &&
      (!isEqual(paths, prevProps.paths) || selectedComparisonCaseIdsChanged)
    ) {
      this.handleUpdateCompareCasterInformation()
    }
  }

  private readonly handleUpdateCompareCasterInformation = () => {
    const { type } = this.props

    if (type === 'DataLine' || type === 'DataPoint') {
      return this.handleUpdateTypesThatRequireName()
    }

    if (type === 'SupportPoint') {
      return this.handleUpdateSupportPointsCompareData()
    }

    if (type === 'SegmentGroupSupportPoints') {
      // fetching of data is handled in the SegmentGroup component
      return
    }

    this.handleUpdateTypesThatRequirePositionAndSide()
  }

  private readonly handleUpdateTypesThatRequirePositionAndSide = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      Caster,
      currentSimulationCase,
    } = this.props

    const positionInfos = []

    for (const path of paths) {
      const element = getElementFromPath(path, this.elementsHashes)
      const segmentPath = path.split('/').slice(0, 2).join('/')
      const segment = getElementFromPath<Segment, SegmentMountLog>(segmentPath, this.elementsHashes)

      if (!element || !segment) {
        continue
      }

      const { passlineCoord, widthCoord } = element

      positionInfos.push(`${segment.side}_${passlineCoord}_${widthCoord}`)
    }

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const positionInfo of positionInfos) {
        const element = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[positionInfo]

        if (!element) {
          elementsToBeRequestedPerCase[caseId].push(positionInfo)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const simulationId = currentSimulationCase.id
      const casterId = Caster?.id
      const elementTypeURL = getElementTypeURL(type as CasterElementNames)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${simulationId}/${casterId}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleUpdateTypesThatRequireName = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      Caster,
      currentSimulationCase,
    } = this.props

    const names = paths
      .map(path => getElementFromPath(path, this.elementsHashes))
      .filter(element => element)
      .map((element) => element!.name)

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const name of names) {
        if (!name) {
          continue
        }

        const element = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[name]

        if (!element) {
          elementsToBeRequestedPerCase[caseId].push(name)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const simulationId = currentSimulationCase.id
      const casterId = Caster?.id
      const elementTypeURL = getElementTypeURL(type as CasterElementNames)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${simulationId}/${casterId}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleUpdateSupportPointsCompareData = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      Caster,
      currentSimulationCase,
    } = this.props

    const elements = []

    for (const path of paths) {
      const element = getElementFromPath(path, this.elementsHashes)
      const segmentGroupPath = Util.getParentInfo(path)?.path
      const segmentGroup = getElementFromPath<SegmentGroup, SegmentGroupMountLog>(segmentGroupPath, this.elementsHashes)

      if (!element || !segmentGroup) {
        continue
      }

      element.passlineCoord = segmentGroup.passlineCoord

      elements.push(element)
    }

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const element of elements) {
        const { passlineCoord, name } = element
        const elementKey = `${name}_${passlineCoord}`
        const supportPoint = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[elementKey]

        if (!supportPoint) {
          elementsToBeRequestedPerCase[caseId].push(elementKey)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const simulationId = currentSimulationCase.id
      const casterId = Caster?.id
      const elementTypeURL = getElementTypeURL(type as CasterElementNames)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${simulationId}/${casterId}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleMassValue = (key: string, firstVal: any, isCompareCaster = false, elementsHashes?: any) => {
    const { paths, editElements } = this.props
    let val = true

    if (paths?.length < 2 || (isCompareCaster && !elementsHashes)) {
      return false
    }

    paths.forEach((path) => {
      // TODO: fix type
      if (isCompareCaster) {
        const { type, id } = Util.getElementInfo(path)

        if (
          (elementsHashes?.[type] ?? {})[id] &&
          ((elementsHashes?.[type] ?? {})[id] ?? {})[key] !== firstVal
        ) {
          val = false
        }

        return
      }

      if ((editElements as any)[path] && ((editElements as any)[path] as any)[key] !== firstVal) {
        val = false
      }
    })

    return val
  }

  private readonly handleHelper = (type: string, value: any) => {
    const { onInput } = this.props
    const name = type

    onInput({ target: { name, value } })
  }

  private readonly handleGetLabel = (key: string, massValue: boolean, elementValue: any) => {
    const {
      catalogElement,
      editElements,
      type,
      selectedParentIds,
      hidePaths,
      featureFlags,
      t,
    } = this.props
    const infoIcon = <InfoIcon icon='info-circle' />
    const label = t([ `formBuilder.keys.${key}.label`, key ]) || key

    if (
      FeatureFlags.canEditNozzle(featureFlags) &&
      key === 'passlineCoord' &&
      type === 'Nozzle'
    ) {
      const passLn = []

      Object.values(editElements).forEach(element => passLn.push(Number(element.passlineCoord)))

      if (!passLn.length) {
        passLn.push(elementValue)
      }

      const value = CalculateHelperFunctions
        .calculatePassLn(Math.min(...passLn), selectedParentIds, this.elementsHashes, hidePaths)

      if (Number(elementValue) === Number(value)) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.same')}
            type={type}
          />
        )
      }

      if (!value) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.notFound')}
            type={type}
          />
        )
      }

      return (
        <LabelWithSuggestion
          onHelper={this.handleHelper}
          name={label}
          value={value || null}
          display={`(${value || null})`}
          title={t('formBuilder.suggestion.set')}
          type={type}
        />
      )
    }

    if (massValue && elementValue !== undefined && elementValue === catalogElement[key]) {
      return (
        <LabelWithSuggestion
          name={label}
          value={infoIcon}
          title={t('formBuilder.suggestion.equal')}
          type={type}
        />
      )
    }

    return (
      <LabelWithSuggestion
        onHelper={this.handleHelper}
        name={label}
        value={catalogElement[key] || null}
        display={`(${catalogElement[key] || null})`}
        title=''
        type={type}
      />
    )
  }

  private readonly handleSave = () => {
    const { onSave } = this.props

    // TODO: for SegmentGroupSupportPoints we need to filter out the ones that do not have changes

    onSave(this.state)
  }

  private readonly handleChangeItem = (type: string, key: string, comment: string) => {
    // make a new hash for each element with the uuid as key and rest of object as value
    const newState: any = {}

    if (type === undefined || key === undefined) {
      return
    }

    if (!newState[type]) {
      newState[type] = {}
    }

    newState[type][key] = comment

    this.setState(newState)
  }

  private readonly handleSavePermissions = () => {
    const { type, paths, featureFlags } = this.props

    if (type !== 'SensorPoint') {
      return FeatureFlags.canEditElement(type, featureFlags)
    }

    const sensorPointLocations: any = FeatureFlagsUtil.getSensorPointLocations(paths, this.elementsHashes)
    const sensorPointEditPermissions: any = this.getSensorPointEditPermissions()
    const permissions = Object.keys(sensorPointEditPermissions)

    for (const permission of permissions) {
      if (sensorPointLocations[permission] && !sensorPointEditPermissions[permission]) {
        return false
      }
    }

    return true
  }

  private readonly setNewCompareCasterInformation = (
    data: any,
    type: CasterElementNames,
    compareCasterInformation: CompareCasterInformation,
  ) => {
    const { setCompareCasterInformation } = this.props

    if (!data || !Object.keys(data).length) {
      return
    }

    const newCompareCasterInformation = { ...compareCasterInformation }
    const caseIds = Object.keys(data)

    for (const caseId of caseIds) {
      if (!newCompareCasterInformation[caseId]) {
        newCompareCasterInformation[caseId] = {} as any
      }

      if (!newCompareCasterInformation[caseId][type]) {
        newCompareCasterInformation[caseId][type] = {}
      }

      newCompareCasterInformation[caseId][type] = {
        ...newCompareCasterInformation[caseId][type],
        ...data[caseId],
      }
    }

    setCompareCasterInformation(newCompareCasterInformation)
  }

  private readonly getSensorPointEditPermissions = () => {
    const { featureFlags } = this.props

    return {
      MoldFace: FeatureFlags.canEditSensorPointInMoldFace(featureFlags),
      RollerBody: FeatureFlags.canEditSensorPointInRollerBody(featureFlags),
      RollerBearing: FeatureFlags.canEditSensorPointInRollerBearing(featureFlags),
      Roller: FeatureFlags.canEditSensorPointInRoller(featureFlags),
      Segment: FeatureFlags.canEditSensorPointInSegment(featureFlags),
      Nozzle: FeatureFlags.canEditSensorPointInNozzle(featureFlags),
    }
  }

  private readonly getCCWarning = (key: string, comments: any, justReturnBoolean = false) => {
    if (!comments[key] || !comments[key].text) {
      return
    }

    if (justReturnBoolean) {
      return true
    }

    return (
      <div
        style={
          {
            width: 'fit-content',
            margin: '3px',
            padding: '6px',
            paddingLeft: '23px',
            color: '#fcc203',
          }
        }
      >
        {comments[key].text}
      </div>
    )
  }

  private readonly getCCComments = (categories: any) => {
    const { ccElement, path, type } = this.props
    let { paths } = this.props

    if (!paths.length) {
      paths = [ path ]
    }

    if (!paths.length || !ccElement) {
      return {}
    }

    const keysWithCCErrors: any = {}

    const keys: string[] = []

    Object.values(categories ?? {}).forEach((cat: any) => {
      cat?.forEach((key: string) => keys.push(key))
    })

    keys.forEach(key => {
      paths.forEach(path => {
        if (!path) {
          return
        }

        const { id } = Util.getElementInfo(path)
        const element = (this.elementsHashes as any)[type][id]

        const elementKeysWithUUID = Object
          .keys(element ?? {})
          .filter(key => key.includes('uuid'))
          .map(key => key.replace('_uuid', ''))

        if (!elementKeysWithUUID.includes(key)) {
          return {}
        }

        const ccElements = Object.keys(ccElement ?? {})

        for (let i = 0; i < ccElements.length; i++) {
          if (ccElements[i].includes(element[`${key}_uuid`])) {
            if (!keysWithCCErrors[key]) {
              keysWithCCErrors[key] = {
                text: ccElement[ccElements[i]].text,
                n: 1,
                uuid: ccElement[ccElements[i]].uuid,
              }
            }
            else if (keysWithCCErrors[key].uuid === ccElement[ccElements[i]].uuid) {
              keysWithCCErrors[key].n++
            }
          }
        }
      })
    })

    Object.keys(keysWithCCErrors ?? {}).forEach(key => {
      if (keysWithCCErrors[key].n !== paths.length) {
        delete keysWithCCErrors[key]
      }
    })

    return keysWithCCErrors
  }

  // function that returns an input of type text if editElements[key] is not equal to editElementsInitial[key]
  private readonly getChangeItemInput = (key: string) => {
    const { type, editElements, editElementsInitial, paths } = this.props

    if (type === 'CCElement' || key.includes('_uuid')) {
      return null
    }

    const someValueChanged = paths.some(path => {
      if (!path || !editElements[path] || !editElements[path][key]) {
        return false
      }

      // if actual value is different than the initial value return true
      return editElements[path][key] !== editElementsInitial[path][key]
    })

    const keyUUID = `${key}_uuid`

    // use paths.some to get the first path that has a value for the key keyUUID
    const someElementHasKey = paths.some(path => {
      if (!path) {
        return false
      }

      const { id } = Util.getElementInfo(path)

      if (!id) {
        return false
      }

      const element = (this.elementsHashes as any)[type][id]

      if (!element) {
        return false
      }

      return element[keyUUID] !== undefined
    })

    if (someValueChanged && someElementHasKey) {
      return <CommentInput placeholder='Comment' type={type} elementKey={key} onChange={this.handleChangeItem} />
    }
  }

  // TODO: we could improve this, we do too many calculations, we could get the item only 1 time
  private readonly getComparisonCasterInputs = (def: any, key: string) => {
    const {
      type,
      path,
      paths,
      currentProjectCasesMetadata,
      currentSimulationCase,
      amountOfComparisonCasterColumns,
      compareCasterInformation,
      selectedComparisonCaseIds,
      t,
    } = this.props

    if (
      !selectedComparisonCaseIds.length ||
      !Object.keys(compareCasterInformation).length ||
      !amountOfComparisonCasterColumns ||
      !paths.length
    ) {
      return null
    }

    const compareCasterInputs: any = []
    const canViewAttributes = this.canViewAttributes()
    const selectedElements: FullCasterElement<CasterElementBaseEntity, BaseMountLog>[] = []

    for (const path of paths) {
      const element = getElementFromPath(path, this.elementsHashes)

      if (!element) {
        continue
      }

      selectedElements.push(element)
    }

    const currentProjectCaseIds = currentProjectCasesMetadata.map(caseMetadata => caseMetadata.id)

    const selectedCasesInOrder = currentProjectCaseIds
      .filter(caseId => selectedComparisonCaseIds.includes(caseId))
      .filter(caseId => caseId !== currentSimulationCase.id)

    for (let i = 0; i < amountOfComparisonCasterColumns; i++) {
      let value: number | string | undefined
      const caseId = selectedCasesInOrder[i]
      let compareElement: any
      const multipleValuesString = t('allInOne.multiValue')

      // move this to render function
      if (type === 'DataLine' || type === 'DataPoint') {
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          compareElement = Util.getCompareElementByName(element.name, caseId, type, compareCasterInformation)

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else if (type === 'SegmentGroupSupportPoints' || type === 'SupportPoint') { // both are support points
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          const segmentGroupPath = Util.getParentInfo(paths[0])?.path
          const { passlineCoord } = getElementFromPath(segmentGroupPath, this.elementsHashes) ?? {}

          if (passlineCoord === undefined || passlineCoord === null || isNaN(passlineCoord)) {
            continue
          }

          compareElement = Util.getCompareSupportPointByNameAndSegmentGroupPasslineCoord(
            element,
            passlineCoord,
            caseId,
            compareCasterInformation,
          )

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else if (type === 'SegmentGroup') {
        // per passlineCoord
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          compareElement = Util.getCompareSegmentGroupByPassLnCoord(element, caseId, compareCasterInformation)

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else {
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          // TODO: fix type, maybe make a new type for elements that are Segment children
          const segment = this.elementsHashes.Segment[(element as any).segmentId] as Segment | undefined

          compareElement = Util.getCompareElementByWidthAndPassLnCoordAndSide(
            element,
            segment?.side ?? null,
            caseId,
            type as CasterElementNames,
            compareCasterInformation,
          )

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }

      const newCompareCasterInput = (
        <CompareCasterInput
          key={`${key}${i}`}
          name={key}
          value={
            path && def.generate
            // FIXME: This is a temporary fix, we need to find a way to generate the value for compare casters
              ? undefined
            // ? elementsHashes ? def.generate(elementKeysObject, elementsHashes) : undefined
              : value
          }
          elementType={type}
          // FIXME: This is a temporary fix, we need to find a way to generate the value for compare casters
          // error={massForm ? this.handleMassValue(key, (elementKeysObject as any)[key], true, elementsHashes) : true}
          path={path}
          hideValue={!canViewAttributes}
        />
      )

      compareCasterInputs.push(newCompareCasterInput)
    }

    return (
      <div style={{ display: 'flex' }}>
        {compareCasterInputs.map((compareCasterInput: any) => compareCasterInput)}
      </div>
    )
  }

  private readonly getComparisonCasterColumnTitles = () => {
    const {
      amountOfComparisonCasterColumns,
      type,
      currentSimulationCase,
      currentProjectCasesMetadata,
      selectedComparisonCaseIds,
    } = this.props

    if (type === 'General' || !amountOfComparisonCasterColumns) {
      return null
    }

    const caseIds = currentProjectCasesMetadata.map(caseMetadata => caseMetadata.id)
    let comparisonCasterCounter = 1
    const comparisonCasterTitles = caseIds
      .map(() => `C${comparisonCasterCounter++}`)

    const shownComparisonCasters = []
    const currentCaseIdIndex = caseIds.findIndex(caseId => currentSimulationCase.id === caseId)

    shownComparisonCasters.push(`C${currentCaseIdIndex + 1}(R)`)

    for (let i = 0; i < caseIds.length; i++) {
      const caseId = caseIds[i]

      if (selectedComparisonCaseIds.includes(caseId)) {
        shownComparisonCasters.push(comparisonCasterTitles[i])
      }
    }

    return (
      <ComparisonCasterTitlesContainer>
        {
          shownComparisonCasters
            .slice(0, amountOfComparisonCasterColumns + 1)
            .map(title => (
              <ComparisonCasterTitle key={title}>
                {title}
              </ComparisonCasterTitle>
            ))
        }
      </ComparisonCasterTitlesContainer>
    )
  }

  private readonly getInvalidWidthValueMessage = () => {
    const { editValues } = this.props
    const { mold, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(this.elementsHashes)

    if (!moldMountLog || !mold || !editValues.General || !editValues.General.width) {
      return null
    }

    if (mold.widthMin && mold.widthMin > editValues.General.width) {
      return <ErrorMessage>Invalid width, min value is: {mold.widthMin}</ErrorMessage>
    }

    if (mold.widthMax && mold.widthMax < editValues.General.width) {
      return <ErrorMessage>Invalid width, max value is: {mold.widthMax}</ErrorMessage>
    }
  }

  private readonly canViewAttributes = (): boolean => {
    const { type, paths, featureFlags } = this.props

    // TODO: feature flags for CC element
    if (type === 'CCElement') {
      return true
    }

    if (type === 'SensorPoint') {
      return FeatureFlags.canViewSensorPointAttributesInCurrentLocations(featureFlags, paths, this.elementsHashes)
    }

    return FeatureFlags.canViewTypesAttributes(type, featureFlags)
  }

  private readonly supportPointsValuesValid = () => {
    const { type } = this.props

    if (type !== 'SegmentGroupSupportPoints') {
      return true
    }

    const { allPaths, editElements } = this.props

    let valid = true

    for (const path of allPaths ?? []) {
      if (!editElements[path]) {
        valid = false

        break
      }

      const { shimPropose: propose } = editElements[path]

      if (propose === '' || propose === undefined) {
        valid = false

        break
      }
    }

    return valid
  }

  public override render () {
    const {
      filterValue,
      type,
      path,
      hideActions,
      editElements,
      massForm,
      dirtyDeletePaths,
      onCreateOrCopy,
      onInput: handleInput,
      onDeleteElement: handleDeleteElement,
      loading,
      hasEditChanges,
      onUndo: handleUndo,
      paths,
      target,
      direction,
      onPatternInput: handlePatternInput,
      onPatternUndo: handlePatternUndo,
      copies,
      copyMode,
      offset,
      gapOffset,
      onMirrorElements: handleMirrorElements,
      createValid,
      onPatternApply,
      error,
      authenticationData,
      isComparingCasters,
      SupportPoint,
      SegmentGroupMountLog,
      SupportPointMountLog,
      caseLocks,
      featureFlags,
      t,
    } = this.props

    const definition = DEFINITION[type]

    let elementKeysObject = {}

    if (path) {
      for (const singlePath of paths) {
        elementKeysObject = {
          ...elementKeysObject,
          ...(editElements[singlePath]?.additionalData ?? {}),
          ...(editElements[singlePath] ?? {}),
        }
      }
    }

    const element = path
      ? elementKeysObject
      : Object.keys(definition.fields).reduce((acc, key) => ({
        ...acc,
        [key]: filterValue[key],
      }), {}) as any

    const additionalData = element.additionalData

    delete element.additionalData

    const elementKeys = Object
      .keys({
        ...element,
        ...additionalData,
        ...definition.fields,
      })
      .filter(key => (
        !definition.fields[key] || // if not in definition -> "other" attributes
        ( // handle hidden
          typeof definition.fields[key].hidden !== 'function'
            ? !definition.fields[key].hidden // boolean | undefined
            : !definition.fields[key].hidden(this.props) // function
        )
      ))

    const categories = elementKeys.reduce((cat, key) => {
      const { category } = definition.fields[key] || { category: 0 }

      return {
        ...cat,
        [category]: [
          ...((cat as any)[category] ?? []), // TODO: Fix type
          key,
        ],
      }
    }, {})

    const categoryList = Object.keys(categories)

    const passLn = []

    Object.values(editElements).forEach(element => passLn.push(Number(element.passlineCoord)))

    const isDeleted = dirtyDeletePaths.includes(path)

    const { isLocked } = FeatureFlags.getLockedStatus(type, authenticationData, caseLocks)

    const comments = this.getCCComments(categories)

    const invalidWidthMessage = this.getInvalidWidthValueMessage()

    const canEditElement = FeatureFlags.canEditElement(type, featureFlags)
    const canViewAttributes = this.canViewAttributes()
    const typeIsNotEditable = [ 'SensorPoint', 'DataLine', 'DataPoint' ].includes(type)

    return (
      <Form>
        {
          categoryList.map(category => (
            <Section
              key={category}
              name={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props)
                  : t([
                    `formBuilder.categories.${definition.categories[category]}.label`,
                    'formBuilder.categories.default',
                  ])
              }
              title={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props, true)
                  : t(`formBuilder.categories.${definition.categories[category]}.title`)
              }
              spaceForComparisonCasterTitle={isComparingCasters && type !== 'General'}
            >
              {this.getComparisonCasterColumnTitles()}
              {
                (categories as any)[category]
                  .sort()
                  .map((key: any, index: number) =>
                    (def => (
                      <div
                        key={`cat${index}`}
                        style={
                          this.getCCWarning(key, comments, true)
                            ? {
                              borderLeft: '8px solid #fcc203',
                              borderBottom: '1px solid #fcc203',
                              marginLeft: '-8px',
                            }
                            : undefined
                        }
                      >
                        <div style={{ display: 'flex', height: '31px' }}>
                          <Input
                            key={key}
                            label={
                              this.handleGetLabel(
                                key,
                                this.handleMassValue(
                                  key,
                                  (element as any)[key],
                                ),
                                (element as any)[key],
                              )
                            }
                            title={t(`formBuilder.keys.${key.substring(1)}.title`)}
                            name={key}
                            type={def.type ?? 'text'}
                            min={
                              def.min ??
                            (def.computeMin && def.computeMin(element)) ??
                              undefined
                            }
                            max={
                              def.max ??
                            (def.computeMax && def.computeMax(element)) ??
                              undefined
                            }
                            step={def.step}
                            decimals={def.decimals}
                            value={
                              path && def.generate
                                ? def.generate(element, this.elementsHashes) ?? ''
                                : (element as any)[key] ?? ''
                            }
                            elementType={type}
                            error={massForm ? this.handleMassValue(key, (element as any)[key]) : true}
                            disabled={
                              key === 'id' ||
                                Boolean(isComparingCasters) ||
                                !canViewAttributes ||
                                !canEditElement ||
                                (
                                  !path
                                    ? false
                                    : (typeIsNotEditable
                                      ? true
                                      : def.disabled ?? (
                                        def.computeDisabled &&
                                        def.computeDisabled(
                                          element,
                                          editElements,
                                          SegmentGroupMountLog,
                                          SupportPointMountLog,
                                          SupportPoint,
                                        )
                                      ))
                                )
                            }
                            options={def.options}
                            handleUndo={handleUndo}
                            hasFilter={type === 'DataLine' ? !(key === '_xCoords' || key[1] === 'y') : true}
                            onChange={handleInput}
                            path={path}
                            hideValue={!canViewAttributes}
                            isComparingCasters={isComparingCasters}
                            onlyPositiveNumbers={def.type === 'number' && def.positive}
                          />
                          {type !== 'General' && this.getComparisonCasterInputs(def, key)}
                        </div>
                        {type === 'General' && path && invalidWidthMessage}
                        {this.getChangeItemInput(key)}
                        {this.getCCWarning(key, comments)}
                      </div>
                    ))(definition.fields[key] ?? {}))
              }
            </Section>
          ))
        }
        {type === 'Nozzle' && !isComparingCasters && <NozzleCatalog path={path} type={type} />}
        {
          (
            (type === 'Nozzle' && FeatureFlags.canEditNozzle(featureFlags)) ||
            (type === 'Roller' && FeatureFlags.canEditRoller(featureFlags))
          ) &&
          (
            <MirrorAndRepeat
              type={type}
              paths={paths}
              target={target}
              direction={direction}
              copyMode={copyMode}
              handleInput={handlePatternInput}
              handleUndo={handlePatternUndo}
              copies={copies}
              offset={offset}
              gapOffset={gapOffset}
              handlePatternApply={onPatternApply}
            />
          )
        }
        <ButtonsBox>
          {
            !hideActions && !isLocked && this.handleSavePermissions() && !this.applyExcludedTypes.includes(type) && (
              <FlexDiv>
                <Button
                  onClick={this.handleSave}
                  disabled={
                    Boolean(isComparingCasters) ||
                    (!path && type !== 'General') ||
                    (!(hasEditChanges as any)?.[type]) ||
                    Boolean(invalidWidthMessage) ||
                    !this.supportPointsValuesValid()
                  }
                  loading={(loading as any)?.[type] && (loading as any)?.[type]?.apply}
                  error={Boolean(error['caster/apply'])}
                  value={t('formBuilder.apply')}
                  title={t(`formBuilder.${isDeleted ? 'applyDeletion' : 'applyChanges'}`)}
                />
                {
                  !this.copyExcludedTypes.includes(type) &&
                  (
                    <Button
                      onClick={onCreateOrCopy}
                      value={t(`formBuilder.${path ? 'copy' : 'create'}`)}
                      error={Boolean(error[`caster/${path ? 'copy' : 'create'}${type}`])}
                      loading={(loading as any)?.[type] && (loading as any)?.[type]?.clone}
                      disabled={
                        Boolean(isComparingCasters) || path
                          ? !(hasEditChanges as any)?.[type]
                          : !((hasEditChanges as any)?.[type] && (createValid as any)?.[type])
                      }
                    />
                  )
                }
                {
                  type === 'Nozzle' &&
                (
                  <Button
                    onClick={handleMirrorElements}
                    value={t('formBuilder.mirror.label')}
                    title={t('formBuilder.mirror.title')}
                    loading={
                      loading
                        ?.[type]
                        ?.mirror
                    }
                    error={Boolean(error[`caster/mirror${type}`])}
                    disabled={Boolean(isComparingCasters) || !path}
                  />
                )
                }
                {
                  !this.deleteExcludedTypes.includes(type) &&
                (
                  <Action
                    onClick={path ? handleDeleteElement : () => null}
                    $disabled={Boolean(isComparingCasters) || !path}
                    title={t(`formBuilder.${isDeleted ? 'restore' : 'mark'}`)}
                  >
                    {isDeleted ? <Icon icon='undo' /> : <Icon icon='trash' />}
                  </Action>
                )
                }
              </FlexDiv>
            )
          }
          {
            (
              !hideActions &&
              (
                (type === 'Nozzle' && !FeatureFlags.canEditNozzle(featureFlags)) ||
                (
                  (type === 'Roller' || type === 'RollerBody' || type === 'RollerBearing') &&
                  !FeatureFlags.canEditRoller(featureFlags)
                ) ||
                (
                  type === 'General' &&
                  !FeatureFlags.canEditMold(featureFlags)
                )
              )
            ) &&
            (
              <Action
                onClick={handleUndo}
                $viewer={!canEditElement}
                $disabled={
                  Boolean(isComparingCasters) ||
                  !hasEditChanges?.[type] ||
                  (hasEditChanges?.[type] && isDeleted)
                }
                title={t('formBuilder.reset')}
              >
                <Icon icon='reply' />
              </Action>
            )
          }
        </ButtonsBox>
      </Form>
    )
  }
}

export default withNamespaces('caster')(connector(FormBuilder as any) as any) as any
