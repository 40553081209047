export enum coolingLoopActionsEnum {
  ACTION_ADD_COOLING_LOOP = 'CoolingLoop/ACTION_ADD_COOLING_LOOP',
  DELETE = 'CoolingLoop/DELETE',
  DELETE_MULTIPLE = 'CoolingLoop/DELETE_MULTIPLE',
  UPDATE = 'CoolingLoop/UPDATE',
  RESET = 'CoolingLoop/RESET',
}

export function addCoolingLoops (coolingLoopHash: any) {
  return {
    type: coolingLoopActionsEnum.ACTION_ADD_COOLING_LOOP,
    coolingLoopHash,
  }
}

const coolingLoopReducers = {
  [coolingLoopActionsEnum.ACTION_ADD_COOLING_LOOP]: (_state: any, action: { coolingLoopHash: any }) => (
    action.coolingLoopHash
  ),

  [coolingLoopActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [coolingLoopActionsEnum.DELETE_MULTIPLE]: (state: any, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [coolingLoopActionsEnum.UPDATE]: (state: any, action: { elements: any }) => ({
    ...state,
    ...action.elements,
  }),

  [coolingLoopActionsEnum.RESET]: () => ({}),
}

export default function (state: any = {}, action: any) {
  const handler = coolingLoopReducers[action.type as coolingLoopActionsEnum]

  return handler ? handler(state, action) : state
}
