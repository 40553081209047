export enum SupportPointActionsEnum {
  ACTION_ADD_SUPPORT_POINT = 'SupportPoint/ACTION_ADD_SUPPORT_POINT',
  DELETE = 'SupportPoint/DELETE',
  DELETE_MULTIPLE = 'SupportPoint/DELETE_MULTIPLE',
  REMOVE_CHILD_ID = 'SupportPoint/REMOVE_CHILD_ID',
  UPDATE = 'SupportPoint/UPDATE',
  RENAME = 'SupportPoint/RENAME',
  RESET = 'SupportPoint/RESET',
}

export function addSupportPoint (supportPointHash: Record<number, SupportPoint>) {
  return {
    type: SupportPointActionsEnum.ACTION_ADD_SUPPORT_POINT,
    supportPointHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SupportPointReducers: Record<string, Function | undefined> = {
  [SupportPointActionsEnum.ACTION_ADD_SUPPORT_POINT]: (
    _state: SupportPointHash,
    action: { supportPointHash: SupportPointHash },
  ) => (
    action.supportPointHash
  ),

  [SupportPointActionsEnum.DELETE]: (state: SupportPointHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SupportPointActionsEnum.DELETE_MULTIPLE]: (state: SupportPointHash, action: { ids: number[] }) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SupportPointActionsEnum.UPDATE]: (state: SupportPointHash, action: { elements: SupportPointHash }) => ({
    ...state,
    ...action.elements,
  }),

  [SupportPointActionsEnum.RESET]: () => ({}),
}

export default function (
  state: SupportPointHash = {},
  action: any,
) {
  const handler = SupportPointReducers[action.type]

  return handler ? handler(state, action) : state
}
