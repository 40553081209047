import React from 'react'
import * as THREE from 'three'

import VisUtil from '@/react/visualization/VisUtil'
import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeRoller from './Roller'
import ThreeRollerBody from './RollerBody'
import ThreeSegment from './Segment'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  sensorPoint: THREE.Mesh
}

type Parent = ThreeRollerBody | ThreeSegment

export default class ThreeSensorPoint extends BaseObject<SensorPoint, BaseMountLog, Parent, Objects> {
  private static Geometry3DCache: Record<string, THREE.BoxGeometry> = {}

  private static Geometry3DMeasureCache: Record<number, THREE.Box3> = {}

  private static Geometry3DCacheNextId = 1

  private static Geometry2DCache: Record<string, THREE.PlaneGeometry> = {}

  private static readonly selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41' })

  private static readonly selectedMaterialBasic = new THREE.MeshBasicMaterial({ color: '#7eda41' })

  private static readonly colors = [ ...VisUtil.TRACE_COLORS ]

  private static readonly coloredMaterials =
    ThreeSensorPoint.colors.map(color => new THREE.MeshStandardMaterial({ color }))

  private static readonly coloredMaterialsBasic =
    ThreeSensorPoint.colors.map(color => new THREE.MeshBasicMaterial({ color }))

  private static readonly colorHelper = ThreeSensorPoint.colors.length

  protected readonly type: string = 'SensorPoint'

  private readonly clickableObjects: THREE.Object3D[]

  protected tooltipObjects: THREE.Object3D[]

  protected wCoord: number

  protected plCoord: number

  protected size: number

  protected sectionDetail?: boolean

  private colorIndex?: number

  protected hiddenKeysInTooltip = [
    'additionalData',
    'createdAt',
    'updatedAt',
    'projectId',
    'mountedAt',
    'removedAt',
    'userId',
    'casterId',
    'mountLogHash',
    'sensorPointId',
    'blueprintId',
    'entityHash',
    'passlineCoord',
    'widthCoord',
    'type',
    'size',
    'id',
    'module',
    'thicknessCoord',
  ]
  
  private static get3DGeometry (size: number) {
    const geometryKey = String(size)
    let geometry = ThreeSensorPoint.Geometry3DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.BoxGeometry(size, size, size) // no Buffer!
      geometry.translate(0, size / 2, 0)

      geometry.userData.id = ThreeSensorPoint.Geometry3DCacheNextId++

      ThreeSensorPoint.Geometry3DCache[geometryKey] = geometry
    }

    return geometry
  }
  
  private static get2DGeometry (size: number) {
    const geometryKey = String(size)
    let geometry = ThreeSensorPoint.Geometry2DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.PlaneGeometry(size, size)

      ThreeSensorPoint.Geometry2DCache[geometryKey] = geometry
    }

    return geometry
  }

  public constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.tooltipObjects = tooltipObjects
    this.size = 0.05

    this.wCoord = 0
    this.plCoord = 0
  }

  protected override internalSetValues (data: SetValuesData<SensorPoint, BaseMountLog>) {
    super.internalSetValues(data)

    const { elementData, view } = data

    this.sectionDetail = view.sectionDetail

    // eslint-disable-next-line camelcase
    const {
      type,
      passlineCoord,
      widthCoord,
      size,
    } = elementData

    this.wCoord = (widthCoord ?? 0) / 1000
    this.plCoord = (passlineCoord ?? 0) / 1000

    this.colorIndex = ((Number(type) || 1) - 1) % ThreeSensorPoint.colorHelper

    this.size = (size ?? 50) / 1000

    if (!this.sectionDetail) {
      this.objects.sensorPoint = new THREE.Mesh(
        ThreeSensorPoint.get3DGeometry(this.size),
        ThreeSensorPoint.coloredMaterials[this.colorIndex],
      )
    }
    else {
      this.objects.sensorPoint = new THREE.Mesh(
        ThreeSensorPoint.get2DGeometry(this.size),
        ThreeSensorPoint.coloredMaterialsBasic[this.colorIndex],
      )
    }

    const { sensorPoint } = this.objects

    sensorPoint.userData.type = this.type

    if (this.sectionDetail && !data.isPhantom) {
      this.getTooltips(elementData, data.path)
    }

    this.clickableObjects.push(this.objects.sensorPoint)
    this.container.add(this.objects.sensorPoint)

    this.updateTransform()

    // TODO: does this work? then remove old code
    // sensorPoint.geometryNeedsUpdate = true
    sensorPoint.geometry.getAttribute('position').needsUpdate = true
    sensorPoint.userData.path = data.path

    this.objects.sensorPoint.userData.deleted = data.isDeleted

    this.setSelected(this.objects.sensorPoint.userData.selected)
  }
  
  public updateTransform () {
    const { sensorPoint } = this.objects

    const side = this.container?.userData?.side ??
      this.parent?.container?.userData?.side ??
      this.parent?.parent?.container?.userData?.side ??
      ''

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    newSectionRotation.x = -Util.RAD90

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance
    const size = this.sectionDetail ? this.size : 0

    switch (side) {
      case StrandSides.Fixed:
        newPosition.set(this.wCoord, position.y + 0.00001, position.z + FixedSide.x - size / 2)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case StrandSides.Loose:
        newPosition.set(this.wCoord, position.y + 0.00001, position.z + LooseSide.x + size / 2)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        // newSectionRotation.y = Util.RAD180
        break
      case StrandSides.Right:
        newPosition.set(NarrowFaceRight.x - size / 2, position.y + 0.00001, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        // newSectionRotation.y = Util.RAD90
        break
      case StrandSides.Left:
        newPosition.set(NarrowFaceLeft.x + size / 2, position.y + 0.00001, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        // newSectionRotation.y = -Util.RAD90
        break
      default:
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  protected getTooltips (pointData: any, path: string) {
    const { sensorPoint } = this.objects

    let keys: string[] | null = Object
      .keys(pointData)
      .filter(key => (!this.hiddenKeysInTooltip.includes(key) && !key.includes('MountLogId')))

    keys.push(...(Object.keys(pointData.additionalData)))
    keys.sort()

    const { id } = Util.getElementInfo(path)
    let side = ''

    switch (this.container.userData.type) {
      case 'Segment':
        side = this.container.userData.side
        break
      case 'Roller':
        side = this.container.parent?.userData?.side
        break
      case 'RollerBody':
        side = this.container.parent?.parent?.userData?.side
        break
      default:
        side = ''
    }

    const pos = new THREE.Vector3(0, 0, 0)
    const name = `tooltipMarkerHeight_${side}_${id}`

    Util.createTooltipMarker(
      sensorPoint,
      this.tooltipObjects,
      name,
      pos,
      (
        <div>
          <b>{sensorPoint.userData.type}: {id}</b>
          <br />
          <br />
          {/* eslint-disable-next-line max-len */}
          {
            keys.map(key => (
              <div key={key}>{key.replace(/^_/, '')}: {pointData[key] ?? pointData?.additionalData?.[key]}</div>
            ))
          }
        </div>
      ),
    )

    // free memory
    keys = null

    const marker = sensorPoint.getObjectByName(`TooltipMarker_${name}`)
    const snap = sensorPoint.getObjectByName(`TooltipMarkerSnap_${name}`)

    if (!marker) {
      return
    }

    marker.visible = false

    if (!snap || !(snap instanceof THREE.Mesh)) {
      return
    }

    snap.material.visible = false

    snap.position.copy(new THREE.Vector3(0, 0, 0))
    snap.rotation.copy(new THREE.Euler(0, 0, Util.RAD90, 'XYZ'))
  }
  
  public getMeasures () {
    const { geometry, position: { y } } = this.objects.sensorPoint
    const { id } = geometry.userData

    if (id && !ThreeSensorPoint.Geometry3DMeasureCache[id]) {
      geometry.computeBoundingBox()

      if (geometry.boundingBox) {
        ThreeSensorPoint.Geometry3DMeasureCache[id] = geometry.boundingBox
      }
    }

    const { min, max } = ThreeSensorPoint.Geometry3DMeasureCache[id] ?? { min: { z: 0 }, max: { z: 0 } }

    return {
      heightMin: y + min.z,
      heightMax: y + max.z,
    }
  }
  
  public override setSelected (isSelected: boolean) {
    this.objects.sensorPoint.userData.selected = isSelected

    if (!this.sectionDetail) {
      this.objects.sensorPoint.material = isSelected
        ? ThreeSensorPoint.selectedMaterial
        : ThreeSensorPoint.coloredMaterials[this.colorIndex ?? Infinity] ?? ThreeSensorPoint.coloredMaterials[0]
    }
    else {
      this.objects.sensorPoint.material = isSelected
        ? ThreeSensorPoint.selectedMaterialBasic
        : ThreeSensorPoint.coloredMaterialsBasic[this.colorIndex ?? Infinity] ??
          ThreeSensorPoint.coloredMaterialsBasic[0]
    }
  }

  public override show (dontShowRollerChildren = false) {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true

    if (this.parent) {
      if (dontShowRollerChildren && this.parent.parent && this.parent.parent instanceof ThreeRoller) {
        this.parent.parent.show()
      }
      else {
        this.parent.show()
      }
    }
  }
}
