import { DataActionsEnum } from '../consts'

const additionalDataReducers = {
  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_REQUEST]: (state: DataState) => state,
  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_ERROR]: (state: DataState) => state,

  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_SUCCESS]: (state: DataState, { result: { additionalData } }: any) => ({
    ...state,
    additionalData: {
      ...state.additionalData,
      ...additionalData,
    },
    hasChanges: true,
    loading: false,
  }),
}

export default additionalDataReducers
