import LogicUtil from '@/logic/Util'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import { ElementsHashes } from '@/types/state'

const elementInfoMountLogMap: Record<Partial<FilterableElementType>, string[]> = {
  AirLoop: [],
  CoolingLoop: [ 'CoolingLoopMountLog' ],
  DataLine: [ 'DataLineMountLog' ],
  DataPoint: [
    'MoldFaceDataPointsMountLog',
    'StrandDataPointsMountLog',
    'SegmentDataPointsMountLog',
    'RollerDataPointsMountLog',
    'RollerBodyDataPointsMountLog',
  ],
  Nozzle: [ 'NozzleMountLog' ],
  Roller: [ 'RollerMountLog' ],
  RollerBearing: [ 'RollerBearingMountLog' ],
  RollerBody: [ 'RollerBodyMountLog' ],
  Segment: [ 'SegmentMountLog' ],
  SegmentGroup: [ 'SegmentGroupMountLog' ],
  SensorPoint: [
    'SegmentSensorPointsMountLog',
    'RollerSensorPointsMountLog',
    'RollerBodySensorPointsMountLog',
  ],
  SupportPoint: [ 'SupportPointMountLog' ],
}

export const buildElementMountLogsToKeyUUIDsMap = (elementsHashes: ElementsHashes): MountLogToKeyUUIDsMap => {
  const elementMountLogsToKeyUUIDsMap: MountLogToKeyUUIDsMap = {
    AirLoop: {},
    CoolingLoop: {},
    DataLine: {},
    DataPoint: {},
    Nozzle: {},
    Roller: {},
    RollerBearing: {},
    RollerBody: {},
    Segment: {},
    SegmentGroup: {},
    SensorPoint: {},
    SupportPoint: {},
  }
  const filterableElements = Object.keys(elementInfoMountLogMap) as FilterableElementType[]

  for (const filterableElement of filterableElements) {
    const correspondingMountLogs = elementInfoMountLogMap[filterableElement] as Array<keyof ElementsHashes>

    if (correspondingMountLogs.length === 0) { // AirLoop
      const hash = elementsHashes[filterableElement]
      const airLoops = Object.values(hash ?? {}) as AirLoop[]

      for (const airLoop of airLoops) {
        const additionalData = airLoop.additionalData
        const uuidAttributes = Object.keys(additionalData ?? {}).filter(key => key.includes('_uuid'))

        for (const uuidAttribute of uuidAttributes) {
          const uuid = additionalData[uuidAttribute]
          const attributeWithoutUuid = LogicUtil.fromSnakeToCamelCase(uuidAttribute.replace('_uuid', ''))

          if (!elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid]) {
            elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid] = {}
          }

          elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid][airLoop.id] = uuid as number ?? 0
        }
      }
    }
    else {
      for (const mountLogName of correspondingMountLogs) {
        const mountLogHash = elementsHashes[mountLogName]
        const mountLogs = Object.values(mountLogHash ?? {}) as any[]

        for (const mountLog of mountLogs) {
          const elementInfoKey = filterableElement.charAt(0).toLowerCase() + filterableElement.slice(1)
          const elementInfoId = mountLog[`${elementInfoKey}Id`]

          if (!elementInfoId) {
            continue
          }

          const element = elementsHashes[filterableElement]?.[elementInfoId]

          if (!element) {
            continue
          }

          const additionalData = element.additionalData
          const uuidAttributes = Object.keys(additionalData ?? {}).filter(key => key.includes('_uuid'))

          for (const uuidAttribute of uuidAttributes) {
            const uuid = additionalData[uuidAttribute]
            const attributeWithoutUuid = LogicUtil.fromSnakeToCamelCase(uuidAttribute.replace('_uuid', ''))

            if (!elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid]) {
              elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid] = {}
            }

            elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid][mountLog.id] = uuid
          }
        }
      }
    }
  }

  return elementMountLogsToKeyUUIDsMap
}
