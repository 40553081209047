import styled, { css } from 'styled-components'

export const Wrapper = styled.div`${({ theme }) =>
  css`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  color: ${theme.mainFontColor};
`}`
