import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import DataActions from '@/store/data/actions'
import { getElementsHashesObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import Util from '@/three/logic/Util'
import ThreeManager from '@/three/ThreeManager'
import { DefaultState } from '@/types/state'

import { CCGroup } from './CCGroup'
import { SectionContainer } from '../AllInOne/styles'

const connector = connect((state: DefaultState) => ({
  CCElement: state.CCElement,
  elementsHashes: getElementsHashesObject(state),
  selectedPaths: state.data.selectedPaths,
  rollerChildren: state.util.rollerChildren,
}), {
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type State = void

export class CCElementList extends Component<PropsFromRedux, State> {
  public constructor (props: PropsFromRedux) {
    super(props)
  }

  private readonly selectElementPerAttributeUUID = (uuid: string) => {
    const { elementsHashes, setSelectedElementPaths, rollerChildren, setTerm } = this.props
    let selectedPaths: string[] = []

    if (!uuid) {
      setSelectedElementPaths()

      return
    }

    const elementTypes = Object.keys(elementsHashes)
    const isElementTypeSelected: any = {
      Nozzle: false,
      Roller: false,
      RollerBearing: false,
      RollerBody: false,
      SensorPoint: false,
      DataPoint: false,
      DataLine: false,
    }

    for (const type of elementTypes) {
      Object.values((elementsHashes as any)[type]).forEach((element: any) => {
        const matchedUUID = Object
          .keys(element ?? {})
          .filter(key => (key.includes('uuid') && uuid.includes(element[key])))

        if (matchedUUID.length) {
          const path = Util.getElementPath(element, elementsHashes, `${type}:${element._id}`)

          isElementTypeSelected[type] = true

          selectedPaths.push(path)
        }
      })
    }

    const action = this.shouldRollerViewGetToggled(isElementTypeSelected, rollerChildren)

    switch (action) {
      case 'FILTER_ONLY_ROLLER':
        selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER')
        break
      case 'FILTER_ONLY_ROLLER_CHILDREN':
        selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER_CHILDREN')
        break
      case 'TOGGLE':
      // toggle roller children
        ThreeManager.toggleRollerVisibility()
        break
      default:
        break
    }

    // filter out elements which are invisible because of the filter
    setTerm('')
    setSelectedElementPaths()
    setSelectedElementPaths(selectedPaths, true)
    ThreeManager.base.jumpToFilter(selectedPaths[0])
  }

  private filteredSelectedPaths (selectedPaths: string[], selectedType: 'ROLLER_CHILDREN' | 'ROLLER') {
    return selectedPaths.filter((path: string) => {
      const { type } = Util.getElementInfo(path)

      if (selectedType === 'ROLLER' && (type === 'RollerBearing' || type === 'RollerBody')) {
        return false
      }

      return !(selectedType === 'ROLLER_CHILDREN' && type === 'Roller')
    })
  }

  private readonly shouldRollerViewGetToggled = (isElementTypeSelected: any, rollerChildren: any) => {
    if (
      rollerChildren === 1 &&
      isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'FILTER_ONLY_ROLLER'
    }

    if (
      rollerChildren !== 1 &&
      isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'FILTER_ONLY_ROLLER_CHILDREN'
    }

    if (
      rollerChildren === 1 &&
      !isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'TOGGLE'
    }

    if (
      rollerChildren !== 1 &&
      isElementTypeSelected.Roller &&
      !isElementTypeSelected.RollerBearing &&
      !isElementTypeSelected.RollerBody
    ) {
      return 'TOGGLE'
    }

    return ''
  }

  public override render () {
    const { CCElement, elementsHashes, selectedPaths } = this.props

    // cc elements grouped by group
    const ccElementsByGroup: any = { default: [] }
    const ccElements = Object.values(CCElement ?? {})
    const elementsLength = ccElements.length

    for (let i = 0; i < elementsLength; i++) {
      if (ccElements[i].group === undefined) {
        ccElementsByGroup.default.push(ccElements[i])
        continue
      }

      if (!ccElementsByGroup[ccElements[i].group]) {
        ccElementsByGroup[ccElements[i].group] = []
      }

      ccElementsByGroup[ccElements[i].group].push(ccElements[i])
    }

    let ccElementAmountCounter = 0

    return (
      <div>
        <SectionContainer style={{ paddingTop: '5px' }}>
          {
            CCElement && Object.keys(ccElementsByGroup).map((groupKey, index) => {
              const groupIndex = index === 0 ? 0 : ccElementAmountCounter

              ccElementAmountCounter += ccElementsByGroup[groupKey].length ?? 0

              return (
                <CCGroup
                  key={index}
                  index={groupIndex}
                  ccGroup={ccElementsByGroup[groupKey]}
                  groupKey={groupKey as any}
                  selectElementPerAttributeUUID={this.selectElementPerAttributeUUID}
                  elementsHashes={elementsHashes}
                  selectedPaths={selectedPaths}
                />
              )
            })
          }
        </SectionContainer>
      </div>
    )
  }
}

export default withNamespaces('caster')(connector(CCElementList as any) as any) as any
