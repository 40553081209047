import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { PureComponent, ReactNode } from 'react'

import PlotWrapper from '@/react/visualization/PlotWrapper'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import { ElementsHashes } from '@/types/state'
import { PlotConfigHash, TileConfigHash } from '@/types/visualization'

import { ViewCompareLogic } from '../ViewCompareLogic'
import { ViewLogic } from '../ViewLogic'

type Props = {
  caseIds: string[]
  /**
   * PlotConfig id
   */
  configId: string
  tileId: string
  plotConfigs: PlotConfigHash
  tileConfigs: TileConfigHash
  viewId: string
  elementsHashes: ElementsHashes
  forceUpdateHandler: () => void
  filterControlVariables: string[]
  canCompareCasters: boolean
  selectedComparisonCaseIds: string[]
  referenceDate: Date
  plotsCompareCasterInformation: Record<string, ElementsHashes>
  filteredElementCache: Record<string, string[]>
  setTileWarnings: (tileWarnings: Record<string, Record<string, any>>) => void
  mountLogToKeyUUIDsMap: MountLogToKeyUUIDsMap
  casterDataServer: CasterDataServerState
  temporalData: { [realDataUUID: string]: { x: Date, y: number }[] }
}

export class DynamicPlot extends PureComponent<Props> {
  private readonly getRealXDomain = (
    xDomain: Domain,
    xValues: number[],
    tileData: any,
    dynamicData: any[],
    minDefined: boolean,
    maxDefined: boolean,
  ): Domain => {
    const isComparingCasters = this.props.selectedComparisonCaseIds?.length
    const { xAxisPadding = 0 } = tileData

    xDomain.sort((a, b) => a - b)

    const realXDomain = (
      !isComparingCasters
        ? ViewLogic.getXDomain(xDomain, xValues, tileData)
        : ViewCompareLogic.getXDomainForDynamicPlotsWithComparisonCasters(xDomain, tileData, dynamicData)
    ) as Domain

    realXDomain.sort((a, b) => a - b)

    if (realXDomain[0] < 0 && realXDomain[1] < 0) {
      realXDomain.reverse()
    }

    realXDomain[0] = realXDomain[0] - (minDefined ? 0 : Number(xAxisPadding))
    realXDomain[1] = realXDomain[1] + (maxDefined ? 0 : Number(xAxisPadding))

    return realXDomain
  }

  private readonly getRealYDomain = (
    yDomain: Domain,
    realXDomain: Domain,
    tileData: any,
    dynamicData: any[],
    minDefined: boolean,
    maxDefined: boolean,
  ): Domain => {
    const isComparingCasters = this.props.selectedComparisonCaseIds?.length
    const { yAxisPadding = 0 } = tileData

    const realYDomain = (
      !isComparingCasters
        ? ViewLogic.getYDomain(realXDomain, yDomain, tileData, dynamicData) ?? yDomain[0]
        : ViewCompareLogic.getYDomainForDynamicPlotsWithComparisonCasters(realXDomain, yDomain, tileData, dynamicData)
    ) as Domain

    realYDomain[0] = minDefined ? yDomain[0] : realYDomain[0]
    realYDomain[1] = maxDefined ? yDomain[1] : realYDomain[1]

    realYDomain.sort((a, b) => a - b)

    realYDomain[0] = realYDomain[0] - (minDefined ? 0 : Number(yAxisPadding))
    realYDomain[1] = realYDomain[1] + (maxDefined ? 0 : Number(yAxisPadding))

    return realYDomain
  }

  private readonly getDynamicData = (
    type: CasterElementNames,
    elements: string[],
    elementsHashes: ElementsHashes,
    plotConfig: any,
    isRefIdLine: boolean,
    comparisonCasterData: any[],
    tileWarnings: Record<string, Record<string, any>>,
    hasFilter: boolean,
    attrX: string,
    attrY: string,
    plotConfigId: string,
  ) => {
    const { mountLogToKeyUUIDsMap, casterDataServer, temporalData } = this.props

    let dynamicData: any[] = []
    let isMultiLinePlot = false
    let dataLinePlotRanges: any

    if (type === 'DataLine') {
      const filterEmpty = !plotConfig.filter

      if (isRefIdLine && comparisonCasterData.length === 0) {
        dynamicData = ViewLogic.getDynamicDataFromDataLines(
          elements,
          elementsHashes,
          type,
          attrX,
          attrY,
          filterEmpty,
          isRefIdLine,
        )
      }
      else {
        dynamicData[0] = ViewLogic.getDynamicDataFromDataLines(
          elements,
          elementsHashes,
          type,
          attrX,
          attrY,
          filterEmpty,
          isRefIdLine,
        )
      }

      if (comparisonCasterData.length > 0) {
        for (let i = 0; i < comparisonCasterData.length; i++) {
          const data = comparisonCasterData[i]

          // TODO: this does not work for data coming from CasterDataServer
          dynamicData[i + 1] = ViewCompareLogic.getPointsFromComparisonDataLine(
            data.elementsHashes,
            attrX,
            attrY,
            data.caseId,
            plotConfig,
            isRefIdLine,
          )

          if (!dynamicData[i + 1].length) {
            if (!tileWarnings[plotConfigId]) {
              tileWarnings[plotConfigId] = {}
            }

            if (!tileWarnings[plotConfigId][data.casterName]) {
              tileWarnings[plotConfigId][data.casterName] = true
            }
          }

          if (!isRefIdLine) {
            dynamicData[i + 1].forEach?.((line: any) => {
              line.forEach?.((point: any) => {
                point.casterName = data.casterName
              })
            })
          }
          else {
            dynamicData[i + 1].forEach?.((point: any) => {
              point.casterName = data.casterName
            })
          }
        }
      }

      isMultiLinePlot = !(isRefIdLine && comparisonCasterData.length === 0)

      if (isRefIdLine) {
        dataLinePlotRanges = {
          x: [
            Math.min(...dynamicData.map((p: any) => p.x)),
            Math.max(...dynamicData.map((p: any) => p.x)),
          ],
          y: [
            Math.min(...dynamicData.map((p: any) => p.y)),
            Math.max(...dynamicData.map((p: any) => p.y)),
          ],
        }
      }
      else {
        dataLinePlotRanges = {
          x: [
            Math.min(...dynamicData.map(d => ViewLogic.getMultiLineMin(d, 'x'))),
            Math.max(...dynamicData.map(d => ViewLogic.getMultiLineMax(d, 'x'))),
          ],
          y: [
            Math.min(...dynamicData.map(d => ViewLogic.getMultiLineMin(d, 'y'))),
            Math.max(...dynamicData.map(d => ViewLogic.getMultiLineMax(d, 'y'))),
          ],
        }
      }

      const auxDynamicData: any[] = []

      if (!isRefIdLine) {
        dynamicData.forEach((d: any) => {
          auxDynamicData.push(...d)
        })

        dynamicData = auxDynamicData
      }
    }
    else if (comparisonCasterData.length > 0) {
      dynamicData[0] = ViewLogic.getDynamicDataFromElements(
        elements,
        elementsHashes,
        type,
        attrX,
        attrY,
        !hasFilter,
      )

      for (let i = 0; i < comparisonCasterData.length; i++) {
        const data = comparisonCasterData[i]

        let points: Array<{ x: number, y: number }> = []

        if (data.timestamp) {
          const timestamp = data.timestamp

          points = ViewCompareLogic.getPointsFromDataServerData(
            type,
            elements,
            elementsHashes,
            attrX,
            attrY,
            timestamp,
            mountLogToKeyUUIDsMap,
            casterDataServer,
          )
        }
        else {
          points = ViewCompareLogic.getPointsFromComparisonCaster(
            data.elementsHashes,
            type,
            attrX,
            attrY,
            plotConfig,
            data.caseId,
          )
        }

        if (!points.length) {
          if (!tileWarnings[plotConfigId]) {
            tileWarnings[plotConfigId] = {}
          }

          if (!tileWarnings[plotConfigId][data.casterName]) {
            tileWarnings[plotConfigId][data.casterName] = true
          }

          continue
        }

        dynamicData[i + 1] = points

        if (!dynamicData[i + 1].length) {
          if (!tileWarnings[plotConfigId]) {
            tileWarnings[plotConfigId] = {}
          }

          if (!tileWarnings[plotConfigId][data.casterName]) {
            tileWarnings[plotConfigId][data.casterName] = true
          }
        }

        // add caster name info to every point of line
        for (const el of dynamicData[i + 1]) {
          el.casterName = data.casterName
        }
      }
      // for every comparison caster, add its elements to dynamic data

      isMultiLinePlot = true
    }
    else if (attrX === 'dataOverTime' && plotConfig.filter) {
      const realDataUUID = plotConfig.filter.split('realDataUUID=')[1]

      if (!realDataUUID || !temporalData[realDataUUID]) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      dynamicData = temporalData[realDataUUID].map((mountLog: any) => ({
        x: new Date(mountLog.createdAt),
        y: mountLog[attrY] ?? mountLog?.additionalData?.[attrY],
      }))
    }
    else {
      dynamicData = ViewLogic.getDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY, !hasFilter)
    }

    return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
  }

  public override render (): ReactNode | null {
    const {
      configId: plotConfigId,
      tileId,
      plotConfigs,
      tileConfigs,
      viewId,
      forceUpdateHandler,
      elementsHashes,
      canCompareCasters,
      selectedComparisonCaseIds,
      plotsCompareCasterInformation,
      filteredElementCache,
      filterControlVariables,
      caseIds,
      casterDataServer,
    } = this.props

    const plotConfig = plotConfigs[plotConfigId]
    const tileData = tileConfigs[tileId]

    if (!plotConfig || !plotConfig.selectedY || !plotConfig.selectedX || !tileData) {
      return null
    }

    const tileWarnings: Record<string, Record<string, any>> = {}
    const [ type, attrY ] = plotConfig.selectedY.split('|') as [ CasterElementNames, string ]
    const [ , attrX ] = plotConfig.selectedX.split('|') as [ CasterElementNames, string ]

    const elements = ViewLogic
      .getDynamicElementsFromConfig(elementsHashes, plotConfig, filteredElementCache, type)
    const comparisonCasterData: any[] = []

    if (canCompareCasters && selectedComparisonCaseIds?.length) {
      for (const caseId of selectedComparisonCaseIds) {
        const data: any = {}

        if (caseId.includes('cds_timestamp')) {
          const compareEntryId = caseId.split('timestamp_')[1]
          const compareEntry = casterDataServer.compareEntries.find(entry => entry.id === compareEntryId)

          data.timestamp = compareEntry?.timestamp
          data.casterName = moment(new Date(Number(data.timestamp))).format('DD/MM/YYYY HH:mm:ss')
          comparisonCasterData.push(data)

          continue
        }

        data.elementsHashes = plotsCompareCasterInformation[caseId]

        if (!data?.elementsHashes) {
          continue
        }

        const isTimestampCaseId = caseId.includes('_')
        const realCaseId = isTimestampCaseId ? caseId.split('_')[0] : caseId
        const casterIndex = caseIds.indexOf(realCaseId)
        const timestamp = isTimestampCaseId ? caseId.split('_')[1] : undefined

        data.caseId = caseId
        data.casterName = !isTimestampCaseId
          ? `C${casterIndex + 1}`
          : `C${casterIndex + 1} - ${moment(new Date(Number(timestamp))).format('DD/MM/YYYY HH:mm:ss')}`
        comparisonCasterData.push(data)
      }
    }

    const hasFilter = ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
      ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)

    const isRefIdLine = plotConfig.filter?.includes('#ref=')

    const { dynamicData, isMultiLinePlot, dataLinePlotRanges } = this.getDynamicData(
      type,
      elements,
      elementsHashes,
      plotConfig,
      isRefIdLine,
      comparisonCasterData,
      tileWarnings,
      hasFilter,
      attrX,
      attrY,
      plotConfigId,
    )

    let hasNoData = false

    if (!dynamicData.length) {
      dynamicData.push([ { x: 0, y: 0 }, { x: 0, y: 0 } ])

      hasNoData = true
    }
    else if (dynamicData.every(data => Array.isArray(data) && !data.length) && hasFilter) {
      // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
      dynamicData[0].push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])

      hasNoData = true
    }

    const yValues = cloneDeep(dynamicData)
      .filter((el: any) => el) // FIXME: @c.bentele with CDS Data this got empty elements ...
      .map((el: any) => el.y)
      .sort((a: any, b: any) => b - a)

    let shapeIds: string[] = []
    const shapeData: any = []

    if (tileData.type === 'line' || tileData.type === 'area' || tileData.type === 'bar') {
      shapeIds = (tileData.shapeIds ?? [])
        .map(shapeId => shapeId.id)
        .filter(shapeId => shapeId !== undefined && shapeId !== '')

      for (const shape of shapeIds) {
        const plot = plotConfigs[shape]

        if (
          shape === undefined ||
          shape === '' ||
          shape === 'passlineCoord' ||
          filterControlVariables.includes(shape) ||
          !plot
        ) {
          continue
        }

        const { selectedY } = plot
        const [ type ] = selectedY.split('|')[0]
        const shapeElements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, plot, {}, type)
        const shapeAttrY = selectedY.split('|')[1]

        shapeData.push({
          shapeId: shape,
          data: ViewLogic.getShapeDynamicData(shapeElements, elementsHashes, shapeAttrY),
        })
      }
    }

    const {
      xDomainMin: xMin,
      xDomainMax: xMax,
      yDomainMin: yMin,
      yDomainMax: yMax,
      followPasslnCoord,
    } = tileData

    const isDataLine = plotConfig.selectedX.split('|')[0] === 'DataLine'

    const isxMinDefined = xMin !== undefined && String(xMin).length > 0 && !followPasslnCoord
    const isxMaxDefined = xMax !== undefined && String(xMax).length > 0 && !followPasslnCoord
    const isyMinDefined = yMin !== undefined && String(yMin).length > 0
    const isyMaxDefined = yMax !== undefined && String(yMax).length > 0

    const xDomain = [
      isxMinDefined
        ? Number(xMin)
        : !Array.isArray(dynamicData[0])
          ? dynamicData[0]?.x ?? 0
          : !(isDataLine && !isRefIdLine)
            ? ViewLogic.getMultiLineMin(dynamicData, 'x')
            : dataLinePlotRanges.x[0],
      isxMaxDefined
        ? Number(xMax)
        : !Array.isArray(dynamicData[0])
          ? dynamicData[dynamicData.length - 1]?.x ?? 0
          : !(isDataLine && !isRefIdLine)
            ? ViewLogic.getMultiLineMax(dynamicData, 'x')
            : dataLinePlotRanges.x[1],
    ] as Domain

    const yDomain = (!isMultiLinePlot
      ? [
        isyMinDefined ? Number(yMin) : yValues[0],
        isyMaxDefined ? Number(yMax) : yValues[yValues.length - 1],
      ]
      : [
        isyMinDefined ? Number(yMin) : ViewLogic.getMultiLineMin(dynamicData, 'y'),
        isyMaxDefined ? Number(yMax) : ViewLogic.getMultiLineMax(dynamicData, 'y'),
      ]) as Domain

    const isComparingCasters = comparisonCasterData.length

    const xValues = !isComparingCasters
      ? dynamicData.map((el: any) => el.x)
      : dynamicData.map(line => line.map((el: any) => el.x)).flat()

    yDomain.sort((a, b) => a - b)

    const realXDomain = this.getRealXDomain(xDomain, xValues, tileData, dynamicData, isxMinDefined, isxMaxDefined)
    const realYDomain = this.getRealYDomain(yDomain, realXDomain, tileData, dynamicData, isyMinDefined, isyMaxDefined)

    return (
      <PlotWrapper
        tileId={tileId}
        key={tileId}
        configId={plotConfigId}
        dynamicData={dynamicData}
        type={tileData.type}
        shapeIds={shapeIds}
        xRange={[ dynamicData[0]?.x ?? 0, dynamicData[dynamicData.length - 1]?.x ?? 0 ]}
        yValueRange={[ yValues[0], yValues[yValues.length - 1] ]}
        valueRange={[ yValues[0], yValues[yValues.length - 1] ]}
        xValues={dynamicData.map((el: any) => el.x)}
        xDomain={hasNoData ? [ 0, 100 ] : realXDomain}
        yDomain={hasNoData ? [ 0, 100 ] : realYDomain}
        xLabel={tileData.xLabel ?? attrX}
        yLabel={tileData.yLabel ?? attrY}
        flipAxes={plotConfig.flipAxes}
        isDynamicData
        hasNoData={hasNoData}
        isMultiLinePlot={isMultiLinePlot}
        shapeData={shapeData}
        viewId={viewId}
        forceUpdateHandler={forceUpdateHandler}
      />
    )
  }
}
