import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Input from '@/react/specific/Input'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

import DragAndDrop from './DragAndDrop'
import { Content, Dialog, DialogBackground, Header, HeaderTitle, I, SelectHeader } from './Styles'

const connector = connect(({ application }: DefaultState) => ({
  currentSimulationCase: application.main.currentSimulationCase,
}), {
  closeCommandView: VisualizationActions.handleCommandView,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  currentFile: string
  files: Array<any>
  fileSelectors: Array<any>
}

class CommandMapping extends Component<Props, State> {
  public override state: State = {
    currentFile: '',
    files: [],
    fileSelectors: [],
  }

  public override componentDidMount () {
    // FIXME: this still calls the old API
    return

    // const { currentSimulationCase } = this.props
    // const { files } = await ApiClient.get(`${'Network.URI(deprecated)'
    // }/visualization_command/files/${currentSimulationCase.id}`)
    // if (files.length > 0) {
    //   const fileSelectors = files.map((file: any) => ({ key: file._id, value: file.name }))

    //   this.setState({
    //     files,
    //     fileSelectors,
    //     currentFile: files[0]._id,
    //   })
    // }

    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInput = (event: any) => {
    const { value } = event.target

    this.setState({
      currentFile: value,
    })
  }

  private readonly handleClose = () => {
    const { closeCommandView } = this.props

    closeCommandView()
  }
  
  public override render () {
    const { fileSelectors, currentFile, files } = this.state
    const { t } = this.props

    const selectedFile = files.filter(file => file._id === currentFile)[0] ?? {}

    return (
      <div>
        <DialogBackground />
        <Dialog>
          <div>
            <Header title={t('commandAssignment.title')}>
              <I className='pe-7s-share' $left />
              <HeaderTitle>{t('commandAssignment.label')}</HeaderTitle>
              <I className='pe-7s-close' onClick={this.handleClose} title={t('commandAssignment.close')} />
            </Header>
            <Content>
              <SelectHeader>
                <span>
                  {t('commandAssignment.outputFileSelectLabel')}
                </span>
                <Input
                  name='file'
                  type='select'
                  value={currentFile}
                  selectors={fileSelectors}
                  onChange={this.handleInput}
                  style={{ 'margin-top': '10px' }}
                  noMarginTop
                />
              </SelectHeader>
              <DragAndDrop
                commands={selectedFile.commands ?? []}
                selectedFile={selectedFile}
              />
            </Content>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(CommandMapping as any) as any) as any
