import { parentInfoPerChildType } from '@/store/elements/consts'
import MainView from '@/three/views/MainView'
import type { ElementsHashes } from '@/types/state'

function removeElementFromParent (
  mountLog: any,
  mountLogType: keyof typeof MainView.MountLogIdFullPathMap,
  elementsHashes: ElementsHashes,
) {
  const parentKey = parentInfoPerChildType[mountLogType as keyof typeof parentInfoPerChildType]

  if (!parentKey) {
    return
  }

  const parentMountLogId = mountLog[parentKey]

  if (!parentMountLogId) {
    return
  }

  // capitalize first letter and remove last 2 letters
  const parentMountLogType = parentKey[0].toUpperCase() +
    parentKey.slice(1, -2) as keyof typeof MainView.MountLogIdFullPathMap
  const parentElement = elementsHashes[parentMountLogType]?.[parentMountLogId]

  if (!parentElement) {
    return
  }

  const childIdsKey = `${mountLogType[0].toLowerCase()}${mountLogType.slice(1)}s` as keyof typeof parentElement

  if (!parentElement[childIdsKey] || !Array.isArray(parentElement[childIdsKey])) {
    return
  }

  const index = parentElement[childIdsKey].indexOf(mountLog.id)

  if (index === -1) {
    return
  }

  parentElement[childIdsKey].splice(index, 1)
}

export function deleteElement (type: CasterElementNames, id: number, elementsHashes: ElementsHashes): void {
  const mountLogType = `${type}MountLog` as keyof typeof MainView.MountLogIdFullPathMap
  const mountLogId = MainView.numericIdMountLogMaps[mountLogType][id]

  if (mountLogId === undefined) {
    return
  }

  const mountLog = elementsHashes[mountLogType]?.[mountLogId]

  if (!mountLog) {
    return
  }

  Object.keys(mountLog).filter(key => key.includes('Ids')).forEach(childTypeKey => {
    const childrenType = childTypeKey.slice(1, -3)

    // FIXME
    elementsHashes[type][id][childTypeKey].forEach((childId: number) => {
      deleteElement(childrenType as CasterElementNames, childId, elementsHashes)
    })
  })

  delete elementsHashes[mountLogType][mountLogId]

  removeElementFromParent(mountLog, mountLogType, elementsHashes)
}

export function deleteAllElements (
  xmlGenParam: XMLGenParams,
  dataTypes: CasterElementNames[],
  elementsHashes: ElementsHashes,
) {
  dataTypes.forEach(type => {
    const elements = elementsHashes[type] ?? {}
    const typeIds = Object.keys(elements).filter(key => key[0] !== '#')

    for (let i = 0; i < typeIds.length; i++) {
      const { _id } = (elements as any)[typeIds[i]]

      deleteElement(type, _id, elementsHashes)
    }

    ;(xmlGenParam as any)[`_lastTypeId_${type}`] = '0'
  })
}
