import hotkeys from 'hotkeys-js'
import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { useConfig } from '@/config'
import Button from '@/react/components/Button'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  editConfigId: state.application.main.editConfigId,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string
  name: string
  error: string
  loading: boolean
}

export class EditConfigDialog extends Component<Props, State> {
  @Identifiable('EditConfigDialog') public static readonly NAME: string

  public override state: State = {
    name: '',
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    this.handleInit()

    hotkeys('Escape', this.handleClose as any)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
  }

  private readonly handleInit = async () => {
    const { editConfigId } = this.props

    try {
      const config = await ApiClient.get(`${useConfig().apiBaseURL}/visualization-configs/${editConfigId ?? ''}`)
      const name = config?.name ?? ''

      this.setState({
        name,
      })
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      this.handleClose()
    }
  }

  private readonly handleClose = () => {
    const { closeDialog, setEditConfigId } = this.props

    closeDialog(EditConfigDialog.NAME)
    setEditConfigId()
  }

  private readonly handleInput = (event: any) => {
    const { name, value }: { name: string, value: string } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = () => {
    const { name } = this.state
    const { editConfigId } = this.props

    if (!name) {
      return
    }

    this.setState({
      loading: true,
    })

    ApiClient
      .patch(`${useConfig().apiBaseURL}/visualization-configs/${editConfigId}`, { data: { name } })
      .then(() => {
        this.handleClose()
      })
      .catch((response) => {
        const { error } = response

        this.setState({
          error: error ? error.status : 'unknown',
          loading: false,
        })
      })
  }
  
  public override render () {
    const { name, error, loading } = this.state
    const { t } = this.props

    return (
      <BaseDialog
        title={t('editConfigDialog.title')}
        icon='pe-7s-folder'
        header={t('editConfigDialog.header')}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t('editConfigDialog.name.label')}
            title={t('editConfigDialog.name.label')}
            placeholder={t('editConfigDialog.name.placeholder')}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            type='primary'
            disabled={!(name.length > 0)}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            isRef
          >
            {t('editConfigDialog.save')}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t('editConfigDialog.cancel')}
          >
            {t('editConfigDialog.cancel')}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(EditConfigDialog as any) as any) as any
