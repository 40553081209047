import cloneDeep from 'lodash/cloneDeep'

import MainView from '@/three/views/MainView'
import type { ElementsHashes } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

export default class CalculateHelperFunctions {
  public static byPasslineCoordAsc<T extends BaseMountLog> (a: T, b: T) {
    return (a.passlineCoord ?? 0) - (b.passlineCoord ?? 0)
  }

  public static byPasslineCoordDesc<T extends BaseMountLog> (a: T, b: T) {
    return (b.passlineCoord ?? 0) - (a.passlineCoord ?? 0)
  }

  public static getRollerList (
    elementsHashes: ElementsHashes,
    startId: string,
    side: string,
    hidePaths: string[],
  ): RollerMountLog[] {
    const segmentGroupMountLogMap = cloneDeep(elementsHashes.SegmentGroupMountLog)

    const segmentGroupMountLogs = Object
      .values(segmentGroupMountLogMap)
      .sort(CalculateHelperFunctions.byPasslineCoordAsc)

    if (!segmentGroupMountLogs?.length || !side) {
      return []
    }

    const startIndex = segmentGroupMountLogs.findIndex(segmentGroup => segmentGroup.id === startId)
    const rollerList: RollerMountLog[] = []

    for (let i = startIndex; i < segmentGroupMountLogs.length; i++) {
      const segmentGroupMountLog = segmentGroupMountLogs[i]
      const segmentMountLogHash = ElementsUtil.getSegmentMountLogHashBySegmentGroupMountLogs(
        elementsHashes,
        [ segmentGroupMountLog ],
      )

      const segmentMountLog = Object.values(segmentMountLogHash).find(segmentMountLog => {
        const segment = elementsHashes.Segment[segmentMountLog.segmentId ?? '']

        return segment?.side === side
      })

      if (!segmentMountLog) {
        continue
      }

      const segmentGroupMountLogNumericId = MainView.numericIdMountLogMaps.SegmentGroupMountLog[segmentGroupMountLog.id]
      const segmentMountLogNumericId = MainView.numericIdMountLogMaps.SegmentMountLog[segmentMountLog.id]

      if (segmentGroupMountLogNumericId === undefined || segmentMountLogNumericId === undefined) {
        continue
      }

      // eslint-disable-next-line max-len
      const initialRollerPath =
        `SegmentGroup:${segmentGroupMountLogNumericId}/Segment:${segmentMountLogNumericId}/Roller`

      const rollerMountLogHash = ElementsUtil.getRollerMountLogHashBySegmentMountLogs(
        elementsHashes,
        [ segmentMountLog ],
      )

      const rollerMountLogs = Object.values(rollerMountLogHash)

      // get rollers and filter the ones which should be hidden
      for (const rollerMountLog of rollerMountLogs) {
        if (typeof rollerMountLog !== 'object') {
          continue
        }

        const rollerMountLogNumericId = MainView.numericIdMountLogMaps.RollerMountLog[rollerMountLog.id]
        const rollerPath = `${initialRollerPath}/:${rollerMountLogNumericId}}`

        if (hidePaths.includes(rollerPath)) {
          continue
        }

        rollerList.push(rollerMountLog)
      }
    }

    return rollerList
  }

  public static calculatePassLn (
    minPassLn: number,
    { SegmentGroup, Segment }: { SegmentGroup: number, Segment: number },
    elementsHashes: ElementsHashes,
    hidePaths: string[],
  ) {
    if (!elementsHashes.StrandGuide || !SegmentGroup || Boolean(Segment)) {
      return null
    }

    const segmentGroupMountLogHash = ElementsUtil.getSegmentGroupMountLogs(elementsHashes)
    const segmentGroupMountLogs = Object
      .values(segmentGroupMountLogHash)
      .sort(CalculateHelperFunctions.byPasslineCoordAsc)

    if (!segmentGroupMountLogs || !segmentGroupMountLogs.length) {
      return null
    }

    const firstSegmentGroup = segmentGroupMountLogs[0]
    const segmentMountLogId = MainView.numericIdMountLogMaps.SegmentMountLog[Segment]
    const segmentMountLog = elementsHashes.SegmentMountLog[segmentMountLogId]
    const segment = elementsHashes.Segment[segmentMountLog?.segmentId ?? '']

    if (!segment) {
      return null
    }

    const rollerMountLogArray = CalculateHelperFunctions.getRollerList(
      elementsHashes,
      firstSegmentGroup.id,
      segment.side ?? '',
      hidePaths,
    )

    const segmentGroupMountLogId = MainView.numericIdMountLogMaps.SegmentGroupMountLog[SegmentGroup]
    const segmentGroupMountLog = elementsHashes.SegmentGroupMountLog[segmentGroupMountLogId]

    if (rollerMountLogArray.length === 0) {
      return Number(segmentGroupMountLog?.passlineCoord ?? 0)
    }

    if (rollerMountLogArray.length === 1) {
      const firstRollerMountLog = rollerMountLogArray[0]
      const roller = elementsHashes.Roller[firstRollerMountLog.rollerId ?? '']

      return Number(firstRollerMountLog.passlineCoord) - Number(roller?.diameter ?? 0) / 2
    }

    let rollerMountLogPasslnLow = rollerMountLogArray
      .filter(roller => Number(roller.passlineCoord) <= minPassLn)
      .sort(CalculateHelperFunctions.byPasslineCoordDesc)[0]

    let rollerMountLogPasslnHigh = rollerMountLogArray
      .filter(roller => Number(roller.passlineCoord) > minPassLn)
      .sort(CalculateHelperFunctions.byPasslineCoordAsc)[0]

    if (!rollerMountLogPasslnHigh || !rollerMountLogPasslnLow) {
      const segmentGroupIndex = segmentGroupMountLogs
        .findIndex(segmentGroup => segmentGroup.id === segmentGroupMountLog.id)
      const prevSegmentGroup = segmentGroupIndex - 1

      if (prevSegmentGroup < 0) {
        const rollerMountLog = rollerMountLogArray.sort(CalculateHelperFunctions.byPasslineCoordAsc)[0]
        const roller = elementsHashes.Roller[rollerMountLog.rollerId ?? '']

        return Number(rollerMountLog.passlineCoord) - Number(roller?.diameter ?? 0) / 2
      }

      const rollerMountLogHash = ElementsUtil.getRollerMountLogHashBySegmentMountLogs(
        elementsHashes,
        [ segmentMountLog ],
      )
      const prevRollerMountLogs = Object.values(rollerMountLogHash)

      if (!rollerMountLogPasslnLow) {
        rollerMountLogPasslnLow = prevRollerMountLogs.sort(CalculateHelperFunctions.byPasslineCoordDesc)[0]
      }
      else {
        rollerMountLogPasslnLow = prevRollerMountLogs.sort(CalculateHelperFunctions.byPasslineCoordDesc)[0]
        rollerMountLogPasslnHigh = rollerMountLogArray.sort(CalculateHelperFunctions.byPasslineCoordAsc)[0]
      }
    }

    if (rollerMountLogPasslnHigh && rollerMountLogPasslnLow) {
      const rollerPasslnLow = elementsHashes.Roller[rollerMountLogPasslnLow.rollerId ?? '']
      const rollerPasslnHigh = elementsHashes.Roller[rollerMountLogPasslnHigh.rollerId ?? '']
      const low = Number(rollerMountLogPasslnLow.passlineCoord) + Number(rollerPasslnLow?.diameter ?? 0) / 2
      const high = Number(rollerMountLogPasslnHigh.passlineCoord) - Number(rollerPasslnHigh?.diameter ?? 0) / 2

      return (low + (high - low) / 2).toString()
    }
  }
}
