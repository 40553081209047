import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import Util from '@/three/logic/Util'
import Mold from '@/three/objects/Mold'

import SectionView from '.'
import DrawHandlers from './DrawHandlers'
import Getters from './Getters'

export default class SectionHandlers {
  public static hideAllButtons (buttons: any) {
    if (Object.keys(buttons).length) {
      Object.keys(buttons).forEach(name => {
        if (name === 'expandSectionViewButton') {
          return
        }

        buttons[name].visible = false
      })
    }
  }

  public static handleUpdatePlane (view: SectionView) {
    view.updatePlane = false

    const { thickness, width, minWidth, maxWidth } = Mold

    view.passlineCurve?.hideStrand()

    if (view.sectionPlaneFolded) {
      view.sectionPlaneFolded.geometry = Getters.getSectionPlaneFoldedGeometry(thickness, maxWidth ?? width, view)
    }

    view.sectionPlaneHeader = Getters.getSectionPlaneHeader(view, thickness)

    Util.addOrReplace(view.sectionPlaneFolded, view.sectionPlaneHeader)

    view.sectionPartingLineHeader = Getters.getPlanePartingLineHeader(view, thickness)

    Util.addOrReplace(view.sectionPlaneFolded, view.sectionPartingLineHeader)

    view.sectionPlaneFolded?.geometry.translate(-thickness / 2, 0, 0)

    DrawHandlers.drawStrandAndRuler(view, thickness, width, minWidth, maxWidth)
    DrawHandlers.drawPassLineCoordinates(view)
    DrawHandlers.drawNextElements(view)

    if (!view.views?.uiView?.isSectionActive) {
      view.hideSection()
    }
    else {
      const { width, height } = view.views.uiView.viewport

      view.setView(height, true)
      view.showSection(width, height)
    }

    view.updateTransform(true)
  }

  public static showClosestSnapsTooltip (snap: any, tooltips: Tooltip[]) {
    const { parent, userData: { markerName } } = snap

    const marker = parent.getObjectByName(markerName)

    if (parent.userData.deleted === false && parent.visible) {
      tooltips.push({
        tooltip: marker.userData.tooltip,
        position: 'right',
      })
    }
  }

  // TODO: these controls are disabled and the section view is not supposed to be moved maybe we can remove this?

  public static setupControls (view: SectionView, center = false) {
    if (!view.orthographicControls) {
      view.orthographicControls = new (OrbitControls as any)(view.orthographicCamera, view.renderer.domElement)
      view.orthographicControls.enabled = false
      view.orthographicControls.enableKeys = false
      view.orthographicControls.enableRotate = false
      view.orthographicControls.update()
    }

    if (center) {
      view.orthographicControls.target.copy(center)
    }

    view.controls = view.orthographicControls
    view.controls.update()
  }

  public static setupRaycaster (view: SectionView, near: number, far: number) {
    view.raycaster = new THREE.Raycaster()
    view.raycaster.near = near
    view.raycaster.far = far
  }

  public static setupCamera (view: SectionView, near: number, far: number) {
    const camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, near, far)

    camera.zoom = 6

    // noinspection JSUnusedGlobalSymbols
    view.perspectiveCamera = SectionView.debug
      ? new THREE.PerspectiveCamera()
      : camera as THREE.Camera as THREE.PerspectiveCamera
    view.orthographicCamera = camera
    view.camera = camera
  }

  public static setupSectionPlaneFolded (view: SectionView) {
    const planeGeometryFolded = new THREE.PlaneGeometry(2, 4, 1)

    // section plane folded is the box where everything is displayed
    view.sectionPlaneFolded = new THREE.Mesh(planeGeometryFolded, SectionView.planeMaterial)
    view.sectionPlaneFolded.rotateX(-Util.RAD90)
    view.sectionPlaneFolded.visible = true
    view.sectionPlaneFolded.name = 'sectionPlaneFolded'
  }
}
