import { createTheme } from '@material-ui/core'
import createPalette from '@material-ui/core/styles/createPalette'

const darkColors = {
  swatch1: '#22282e',
  swatch2: '#272d34',
  swatch3: '#2e353d',
  swatch4: '#333a44',
  swatch5: '#2a3038',
  swatch6: '#373f49',
  swatch7: '#2a80b9',
  swatch8: '#dddddd',
  swatch9: '#a2a6a9',
  swatch10: '#16a086',
  swatch11: '#18b497',
  swatch12: '#21dab8',
  swatch13: '#2a79ae',
  swatch14: '#1d94e3',
  swatch15: '#2da6f7',
  swatch16: '#e84c3d',
  swatch17: '#e77e23',
  swatch18: '#f1c40f',
  swatch19: '#5b6167',
  swatch20: '#353f49',
  swatch21: '#1d2126',
  swatch22: '#323943',
  swatch23: '#5b6167',
  swatch24: '#1d2126',
  swatch25: '#caa40e',
  swatch26: '#e0b60f',
  swatch27: '#f1c40f',
}

const lightColors = {
  swatch1: '#e5e9f2', /* Base bgr */
  swatch2: '#f9fafc', /* Tab Bgr */
  swatch3: '#f9fafc', /* Tab Bgr active */
  swatch4: '#ffffff', /* Bgr of Dashboard name and icons and Header of Plot */
  swatch5: '#ffffff', /* Bgr Modal */
  swatch6: '#e5e9f2', /* Border of plots, background of inputs */
  swatch7: '#2a80b9',
  swatch8: '#2a80b9', /* Icons, Tab Title Active, Dashboardname, Plotname, Numbers in Textplots, Text in input field */
  swatch9: '#8492a6', /* Inactive Tabs Text, N Plots contained on Dashboard Text */
  swatch10: '#16a086',
  swatch11: '#18b497',
  swatch12: '#21dab8',
  swatch13: '#2a79ae',
  swatch14: '#1d94e3',
  swatch15: '#2da6f7',
  swatch16: '#e84c3d',
  swatch17: '#e77e23',
  swatch18: '#f1c40f',
  swatch19: '#8492a6', /* Add DB, DB Split Icons */
  swatch20: '#666666',
  swatch21: '#f9fafc', /* Modal: inactive Tab */
  swatch22: '#ffffff', /* e.g. background of plot */
  swatch23: '#f9fafc',
  swatch24: '#2a80b9',
  swatch25: '#caa40e',
  swatch26: '#e0b60f',
  swatch27: '#f1c40f',
}

export default class StyleConfig {
  public static darkTheme = {
    colors: darkColors,
    mainBackground: darkColors.swatch1,
    borderColor: '#4b535c',
    mainFontColor: darkColors.swatch9,
    input: {
      background: darkColors.swatch20,
      color: darkColors.swatch9,
      placeholder: '#767a7d',
      border: '',
    },
    primary: {
      font: darkColors.swatch8,
      light: darkColors.swatch15,
      main: darkColors.swatch7,
      dark: darkColors.swatch13,
      disabled: darkColors.swatch9,
    },
    secondary: {
      font: darkColors.swatch8,
      light: darkColors.swatch12,
      main: darkColors.swatch11,
      dark: darkColors.swatch10,
      disabled: darkColors.swatch9,
    },
    tertiary: {
      font: darkColors.swatch20,
      light: darkColors.swatch27,
      main: darkColors.swatch26,
      dark: darkColors.swatch25,
      disabled: darkColors.swatch9,
    },
    warning: {
      font: '#FFFFFF',
      light: '#eb974f',
      main: '#e77e23',
      dark: '#a15818',
      disabled: '#373f49',
    },
    error: {
      font: '#FFFFFF',
      light: '#ec6f63',
      main: '#e84c3d',
      dark: '#a2352a',
      disabled: '#373f49',
    },
  }

  public static lightTheme = {
    colors: lightColors,
    mainBackground: lightColors.swatch1,
    borderColor: '#e5e9f2',
    mainFontColor: lightColors.swatch9,
    input: {
      background: lightColors.swatch6,
      color: '#151515',
      placeholder: '#b1b1b1',
      border: '',
    },
    primary: {
      font: lightColors.swatch4,
      light: lightColors.swatch15,
      main: lightColors.swatch7,
      dark: lightColors.swatch13,
      disabled: lightColors.swatch9,
    },
    secondary: {
      font: lightColors.swatch4,
      light: lightColors.swatch12,
      main: lightColors.swatch11,
      dark: lightColors.swatch10,
      disabled: lightColors.swatch9,
    },
    tertiary: {
      font: lightColors.swatch19,
      light: lightColors.swatch27,
      main: lightColors.swatch26,
      dark: lightColors.swatch25,
      disabled: lightColors.swatch9,
    },
    warning: {
      font: '#FFFFFF',
      light: '#eb974f',
      main: '#e77e23',
      dark: '#a15818',
      disabled: '#5b6167',
    },
    error: {
      font: '#FFFFFF',
      light: '#ec6f63',
      main: '#e84c3d',
      dark: '#a2352a',
      disabled: '#5b6167',
    },
  }

  public static muiTheme = (darkTheme?: any) =>
    createTheme({
      // TODO: deprecated options, fix them
      // typography: {
      //   useNextVariants: true,
      // },
      palette: createPalette({
        type: 'dark',
        primary: {
          main: '#349fe6',
        },
        secondary: {
          main: '#21dab8',
        },
        // tertiary: {
        //   main: '#dacd21',
        // },
      }),
      overrides: {
        // https://github.com/mui/material-ui-pickers/issues/1858
        // MuiModal: {
        //   root: {
        //     zIndex: '100',
        //     padding: '30px',
        //   },
        // },
        MuiPaper: {
          root: {
            backgroundColor: darkTheme ? darkColors.swatch6 : lightColors.swatch6,
          },
          rounded: {
            borderRadius: '5px',
          },
        },
        MuiTooltip: {
          tooltip: {
            backgroundColor: darkTheme ? darkColors.swatch6 : lightColors.swatch6,
            color: darkTheme ? darkColors.swatch9 : lightColors.swatch9,
          },
        },
        MuiFormControl: {
          root: {
            width: '100%',
          },
        },
        MuiInputBase: {
          root: {
            minHeight: '38px',
            borderRadius: '5px',
            background: darkTheme ? darkColors.swatch6 : lightColors.swatch6,
            color: darkTheme ? darkColors.swatch9 : lightColors.swatch20,
            padding: '0 10px',
            width: '100%',
            '&::before': {
              borderBottom: 'none!important',
            },
            '&::after': {
              borderBottom: 'none!important',
            },
            '&$error': {
              border: 'red solid 1px',
            },
          },
          multiline: {
            padding: '6px 0 7px 10px',
          },
        },
        MuiButtonBase: {
          root: {
            '&$disabled': {
              pointerEvents: 'all',
            },
          },
        },
        MuiList: {
          root: {
            background: darkTheme ? darkColors.swatch6 : lightColors.swatch6,
            borderRadius: '5px',
          },
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        MuiSelect: {
          icon: {
            color: darkTheme ? darkColors.swatch9 : lightColors.swatch20,
          },
          select: {
            '&:focus': {
              backgroundColor: darkTheme ? darkColors.swatch6 : lightColors.swatch6,
            },
          },
        },
        MuiMenuItem: {
          root: {
            color: darkTheme ? darkColors.swatch9 : lightColors.swatch20,
            '&$selected': {
              color: !darkTheme ? lightColors.swatch20 : '#FFF',
            },
          },
        },
      },
    })
}
