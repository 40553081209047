import cloneDeep from 'lodash/cloneDeep'

export default function UpdateCurrentSimulationCase ({
  commandFile,
  currentSimulationCase,
  currentProject,
  setCurrentSimulationCase,
}: {
  commandFile: CommandFile
  currentSimulationCase: SimulationCase
  currentProject: Project
  setCurrentSimulationCase: (simulationCase: SimulationCase, preventDefault: boolean) => void
}) {
  const simulationCase = currentProject.simulationCases.filter(sc => sc.id === currentSimulationCase.id)[0]
  const updatedCurrentSimulationCase = cloneDeep(simulationCase)

  if (updatedCurrentSimulationCase.commandFiles.length > 0) {
    updatedCurrentSimulationCase.commandFiles = updatedCurrentSimulationCase.commandFiles.map(file => {
      if (file && file._id === commandFile._id) {
        return commandFile
      }

      return file
    })
  }
  else {
    updatedCurrentSimulationCase.commandFiles.push(commandFile)
  }

  setCurrentSimulationCase(updatedCurrentSimulationCase, true)
}
